import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Observable, config } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState, selectCurrentTrip, Trip } from '../../../state';
import { Device, deviceIds } from '../../../../assets/js/embross-device-manager';
import { Logging } from '../../../services/logging/logging.service';
import { ApplinkService } from '../../../services/hardware/applink.service';
import { AppRoutes } from '~app/app-routes';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { AccessibilityMode } from '~app/decorators/accessibility.decorator';
import { Config } from 'protractor';
import { ConfigService } from '~app/services/api/config/config.service';
import { MachineConfig } from '~app/services/api/config/config';
import { take } from 'rxjs/operators';

@AccessibilityMode()
@Component({
  selector: 'app-checked-in-options',
  templateUrl: './checked-in-options.component.html',
  styleUrls: ['./checked-in-options.component.scss'],
})
export class CheckedInOptionsComponent implements OnInit, AfterViewInit, OnDestroy {
  barcodeReader: Device;
  AppRoutes = AppRoutes;

  private currentTripObserver$: Observable<Trip>;
  private currentTripSubscription;
  private currentNumberOfBags;
  private tripHasBags = false;
  private configuration;
  public seatsEnabled = false;

  constructor(
    public router: Router,
    private logging: Logging,
    private store: Store<AppState>,
    private applinkService: ApplinkService,
    private accessibilityService: AccessibilityService,
    private el: ElementRef,
    private configService: ConfigService
  ) {}

  ngOnInit() {
    this.barcodeReader = this.applinkService.getDeviceManager().getDevice(deviceIds.BARCODE_READER);
    this.barcodeReader.disable();
    this.logging.infoUiAlreadyCheckedInPageDisplayed(0);

    this.configService.config.pipe(take(1)).subscribe((config: MachineConfig) => {
      if (config.configuration) {
        this.configuration = config.configuration;
        this.seatsEnabled = config.configuration.enableSeats;
      }
    });

    this.currentTripObserver$ = this.store.pipe(select(selectCurrentTrip));
    this.currentTripSubscription = this.currentTripObserver$.subscribe((trip: Trip) => {
      if (trip.activeSegment) {
        this.currentNumberOfBags = trip.activeSegment.getNumBags();
        if (this.currentNumberOfBags > 0) {
          this.tripHasBags = true;
        }
        this.seatsEnabled = !trip.isNonRevSpaceAvailable() && this.configuration && this.configuration.enableSeats;
      }
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy() {
    if (this.currentTripSubscription) {
      this.currentTripSubscription.unsubscribe();
    }
  }

  onExitClick() {
    this.logging.infoUiExitSelected(0);
  }

  onPrintOnlyClick() {
    this.logging.infoUiAlreadyCheckedinPagePrintOnlySelected(0);
  }

  onModifyItineraryClick() {
    if (this.hasBags()) {
      this.logging.infoUiAlreadyCheckedInPageCheckBagsSelected(0);
    }

    this.logging.infoUiAlreadyCheckedinPageModifySelected(0);
  }

  hasBags() {
    return this.tripHasBags;
  }
}
