import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  accessibilityJson,
  isAccessibilityMode,
  isPassportInReaderTooLong,
} from '../../services/emitters/session-event-emitters';
import { AccessibilityService } from '../../services/accessibility/accessibility.service';
import { LoadingService } from '../../services/ui/loading.service';

@Component({
  selector: 'app-remove-passport',
  templateUrl: './remove-passport.component.html',
  styleUrls: ['./remove-passport.component.scss'],
})
export class RemovePassportComponent implements OnInit, OnDestroy {
  public timeLimitSubscribe: Subscription;
  public altText = accessibilityJson.passportRemoval;
  public showPopup = false;
  public elements;

  constructor(
    private accessibilityService: AccessibilityService,
    public changeRef: ChangeDetectorRef,
    public el: ElementRef,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.timeLimitSubscribe = isPassportInReaderTooLong.subscribe((value) => {
      this.showPopup = value;
    });
  }

  ngOnDestroy(): void {
    if (this.timeLimitSubscribe) {
      this.timeLimitSubscribe.unsubscribe();
    }
  }

  @ViewChild('removePassportModal', { static: false }) set updateAcaaElements(element) {
    if (isAccessibilityMode.getValue()) {
      // This modal will always show after a loading modal has already been activated.
      // We need to ensure that we overwrite the elements to read in this specific case
      if (
        this.showPopup &&
        (!this.accessibilityService.isModalActive.getValue() || this.loadingService.isLoading.getValue())
      ) {
        this.elements = element.nativeElement.querySelectorAll('[tabindex]');
        this.accessibilityService.activateModalElementsToRead(this.elements, 'remove-passport');
      }
      if (!this.showPopup && this.accessibilityService.isModalActive.getValue()) {
        this.accessibilityService.dismissModalElements(null, null, 'remove-passport');
      }
    }
  }
}
