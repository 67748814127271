import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { KeyboardState, Passenger, PaxEditInfoUpdated, ResetContent, selectCurrentPassenger } from '../../../../state';
import { Location } from '@angular/common';
import { Logging } from '~app/services/logging/logging.service';
import { takeUntil } from 'rxjs/operators';
import { AccessibilityMode } from '../../../../decorators/accessibility.decorator';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { AppRoutes } from '~app/app-routes';
import { Router } from '@angular/router';

@AccessibilityMode()
@Component({
  selector: 'app-ktn-entry',
  templateUrl: './ktn-entry.component.html',
  styleUrls: ['./ktn-entry.component.scss'],
})
export class KtnEntryComponent implements OnInit, AfterViewInit {
  passenger$: Observable<Passenger>;
  public showModal = false;
  public keyboardSubscription: Subscription;
  public ktn = null;
  unsubscribe$ = new Subject<void>();
  public elements = [];

  constructor(
    private store: Store<KeyboardState>,
    private location: Location,
    private logging: Logging,
    private router: Router,
    private el: ElementRef,
    private accessibilityService: AccessibilityService
  ) {}

  ngOnInit() {
    this.logging.infoUiEnterKTNPageDisplayed(0);
    this.passenger$ = this.store.pipe(select(selectCurrentPassenger));
    this.store.dispatch(new ResetContent());
    this.validateKeyboardEntry();
  }

  ngAfterViewInit(): void {}

  validateKeyboardEntry() {
    this.keyboardSubscription = this.store
      .pipe(select('keyboard'))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.ktn = value.content;
      });
  }

  onContinueClick() {
    this.logging.infoUiEnterKTNPageContinueSelected(0);
    this.store.dispatch(new PaxEditInfoUpdated({ ktn: this.ktn }));
    this.router.navigate([AppRoutes.PAX_EDIT_MAIN]);
  }
}
