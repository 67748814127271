import { CardReaderActionTypes } from './card-reader.actions';

export interface CardReaderState {
  deviceName: string | null;
  deviceMode: string | null;
  deviceLogs: string[];
  cardReaderInserted: string | null;
  cardReaderRemoved: string | null;
  cardReaderReadInternal: string | null;
  cardReaderDamaged: string | null;
  cardReaderStatusChange: [number, boolean];
  cardReaderStatusIsOK: string | null;
  cardReaderSwipeCount: number;
}

export const initialState: CardReaderState = {
  deviceName: '',
  deviceMode: '',
  deviceLogs: [],
  cardReaderInserted: '',
  cardReaderRemoved: '',
  cardReaderReadInternal: '',
  cardReaderDamaged: '',
  cardReaderStatusChange: [-1, false],
  cardReaderStatusIsOK: '',
  cardReaderSwipeCount: 0,
};

export function cardReaderReducers(state: CardReaderState = initialState, action): CardReaderState {
  let callbackLogs = '';
  // Different action reducer processes
  switch (action.type) {
    case CardReaderActionTypes.CardReaderEnable: {
      callbackLogs = 'card-reader enabled';
      state.deviceName = 'cardreader';
      state.deviceMode = action.payload;
      state.deviceLogs = [callbackLogs];
      return { ...state };
    }
    case CardReaderActionTypes.CardReaderDisable: {
      callbackLogs = 'card-reader disabled';
      state.deviceName = 'cardreader';
      state.deviceMode = action.payload;
      state.deviceLogs = [callbackLogs];
      return { ...state };
    }
    case CardReaderActionTypes.CardReaderInserted: {
      callbackLogs = 'Event:  cardInserted';
      state.deviceLogs = [callbackLogs];
      state.cardReaderInserted = action.payload.cardReaderInserted;
      state.cardReaderSwipeCount++;
      return { ...state };
    }
    case CardReaderActionTypes.CardReaderRemoved: {
      callbackLogs = 'Event:  cardRemoved';
      state.deviceLogs = [callbackLogs];
      return { ...state };
    }
    case CardReaderActionTypes.CardReaderReadInternal: {
      callbackLogs = 'Event: cardReadInternal, data: ' + action.payload.cardReaderReadInternal;
      state.deviceMode = action.payload.event_id;
      state.deviceLogs = [callbackLogs];
      state.cardReaderReadInternal = action.payload.cardReaderReadInternal;
      return { ...state };
    }
    case CardReaderActionTypes.CardReaderDamaged: {
      callbackLogs = 'Event:  cardDamaged';
      state.deviceLogs = [callbackLogs];
      state.cardReaderDamaged = action.payload.cardReaderDamaged;
      return { ...state };
    }
    case CardReaderActionTypes.CardReaderStatusChange: {
      callbackLogs = 'Event: statusChange';
      state.deviceLogs = [callbackLogs];
      state.cardReaderStatusChange = action.payload.cardReaderStatusChange;
      return { ...state };
    }
    case CardReaderActionTypes.CardReaderStatusIsOK: {
      callbackLogs = 'Event: statusIsOk';
      state.deviceLogs = [callbackLogs];
      state.cardReaderStatusIsOK = action.payload.cardReaderStatusIsOK;
      return { ...state };
    }
    case CardReaderActionTypes.CardReaderResetCount: {
      callbackLogs = 'Event: cardReaderResetCount';
      state.deviceLogs = [callbackLogs];
      state.cardReaderSwipeCount = 0;
      return { ...state };
    }
    default:
      callbackLogs = 'default: ' + JSON.stringify(action.payload);
      state.deviceLogs = [callbackLogs];
      return state;
  }
}
