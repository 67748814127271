import { Action } from '@ngrx/store';
import { SegmentDetail } from './segment-detail.model';
import { SegmentFlight } from './segment-flight.model';

export enum SegmentActionTypes {
  SegmentPaxSelectedForCheckin = '[Segment] Passenger selected for checkin',
  SegmentMilitaryPaxTravelType = '[Segment] Military passenger selected travel type',
  CheckinPax = '[Segment] CheckIn Passengers',
  CheckinMade = '[Segment] CheckIn Made',
  BagsContinueButtonClicked = '[Segment] Bags continue button clicked',
}

export class SegmentPaxSelectedForCheckin implements Action {
  readonly type = SegmentActionTypes.SegmentPaxSelectedForCheckin;
  constructor(public payload: { segmentDetail: SegmentDetail }) {}
}

export class SegmentMilitaryPaxTravelType implements Action {
  readonly type = SegmentActionTypes.SegmentMilitaryPaxTravelType;
  constructor(public payload: { selectedMilitaryTravelType: string; segmentFlight: SegmentFlight }[]) {}
}

export class CheckinPax implements Action {
  readonly type = SegmentActionTypes.CheckinPax;
}

export class CheckinMade implements Action {
  readonly type = SegmentActionTypes.CheckinMade;
}

export class BagsContinueButtonClicked implements Action {
  readonly type = SegmentActionTypes.BagsContinueButtonClicked;
  constructor(public payload: { tripId: string }) {}
}

export type SegmentActions =
  | SegmentPaxSelectedForCheckin
  | CheckinPax
  | CheckinMade
  | SegmentMilitaryPaxTravelType
  | BagsContinueButtonClicked;
