import { TraceLevels } from '../../../../assets/js/embross-device-manager';
import {
  BarcodeDamaged,
  BarcodeInserted,
  BarcodeReadInternal,
  BarcodeRemoved,
  BarcodeStatusChange,
  BarcodeStatusIsOK,
} from '../../../state/ha-cuss-device/barcode/barcode.actions';
import {
  PnrLocatorFlowClearState,
  PnrLocatorFlowUpdateState,
} from '../../../state/pnr-locator-flow/pnr-locator-flow.action';
import { Authenticate } from '../../../state';
import { hardwareDeviceStatus, isBarcodeAvailable, speechStatus, SPEECH_STATUS } from '../device.service';
import { activeSession, isAccessibilityMode } from '../../emitters/session-event-emitters';
import { AppRoutes } from '~app/app-routes';

export function barcodeReaderCallback(event) {
  this.applinkService
    .getDeviceManager()
    .sendLogMsg(
      TraceLevels.LOG_TRACE,
      'barcodeReaderCallback: event.key ' + event.key + ' and value ' + JSON.stringify(event.value)
    );
  switch (event.key) {
    case 'statusIsOK':
      this.barcodeReaderStore.dispatch(new BarcodeStatusIsOK(event.key, event.value));
      break;
    case 'barcodeInserted':
      this.barcodeReaderStore.dispatch(new BarcodeInserted(event.key, event.value));
      break;
    case 'barcodeDamaged':
      this.barcodeReaderStore.dispatch(new BarcodeDamaged(event.key, event.value));
      this.logging.errorHardwareBarcodeDamaged(event.value);
      break;
    case 'barcodeReadInternal':
      activeSession.emit(true);
      this.barcodeReaderStore.dispatch(new BarcodeReadInternal(event.key, event.value));
      this.onBarcodeDataLoad.emit(this.getBarcodeData());
      this.applinkService
        .getDeviceManager()
        .sendLogMsg(TraceLevels.LOG_TRACE, 'barcodeReaderCallback: this.getBarcodeData(): ' + this.getBarcodeData());
      this.logging.infoHardwareBarcodeScanned(event.value);
      /* Since we want to allow scanning the barcode from any lookup screen, we need to make sure that we do not have any stored
       information prior to making the call. In order to do so, we need to clear the PNR Flow state prior to Authenticating and
       Updating the state*/
      if (isAccessibilityMode.getValue()) {
        speechStatus.next(SPEECH_STATUS.COMPLETED);
      }
      this.appState.dispatch(new PnrLocatorFlowClearState());
      this.appState.dispatch(new Authenticate({ confirmationCode: this.getBarcodeData() }));
      this.appState.dispatch(
        new PnrLocatorFlowUpdateState({
          confirmationCode: this.getBarcodeData(),
          lookupMethod: 'barcode',
        })
      );
      break;
    case 'barcodeRemoved':
      this.barcodeReaderStore.dispatch(new BarcodeRemoved(event.key, event.value));
      break;
    case 'statusChange':
      this.barcodeReaderStore.dispatch(new BarcodeStatusChange(event.key, event.value));
      this.router.navigate([AppRoutes.MENU]);
      break;
    case 'statusResponse':
      let fatalError = true;
      if (event.value === '0') {
        fatalError = false;
        this.barcodeReaderStore.dispatch(new BarcodeStatusIsOK(event.key, event.value));
      } else {
        this.logging.errorHardwareBarcodeError(event.value);
        this.disableBarcodeReader();
      }
      hardwareDeviceStatus.barcodeDevice = [parseInt(event.value, 10), fatalError];
      isBarcodeAvailable.emit(!hardwareDeviceStatus.barcodeDevice[1]);
      this.barcodeReaderStore.dispatch(
        new BarcodeStatusChange('Status Update', [parseInt(event.value, 10), fatalError])
      );
      break;
    default:
      this.applinkService
        .getDeviceManager()
        .sendLogMsg(TraceLevels.LOG_TRACE, 'default: barcodeReaderCallback: event' + event);
  }
}
