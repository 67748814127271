import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { delay, first } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { AppState, CartState, selectCurrentTrip, Trip } from '../../state';
import { LoadingService } from '../../services/ui/loading.service';
import { Logging } from '../../services/logging/logging.service';
import { HaCussService } from '../../services/ha-cuss/ha-cuss.service';
import { SelectLanguage } from '../../state/language/language.action';
import { DeleteCart } from '~app/state/cart/cart.actions';
import { ConfigService } from '~app/services/api/config/config.service';
import { isAccessibilityMode, socketAlive } from '../../services/emitters/session-event-emitters';
import { ClearState } from '~app/state/clearstate/clearstate.actions';
import { AppRoutes } from '~app/app-routes';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { SPEECH_STATUS } from '~app/services/ha-cuss/device.service';
import { AgentAssistRoutes } from '~app/agent-assist/agent-assist-routes';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss'],
})
export class TimeoutComponent implements OnInit, OnDestroy {
  public cart$: Observable<CartState>;
  public cartSubscription: Subscription;
  public clearStateSubscription: Subscription;
  public routeEventSubscription: Subscription;
  public loadingValueSubscription: Subscription;
  public showModal = false;
  public timer: Subscription;
  public timerLimit = 0;
  public timerLimitStartOver = 25000;
  public url: string;
  public sessionEnd: boolean;
  public showDisabledPage = false;
  public config;
  public configSubscription: Subscription;

  public setTimerButtonCopy = 'components.timeout.confirm';
  public startOverButtonCopy = 'components.timeout.cancel';

  trip$: Observable<Trip>;
  tripSubscription: Subscription;
  private agentAssistTimeout = 30;

  private timeLeft = 15;
  public displayTimer = '15';
  public isInAgentAssist = false;
  interval;

  @HostListener('document:click', ['$event.target'])
  public onClick() {
    this.setTimer();
  }

  constructor(
    public router: Router,
    public loadingService: LoadingService,
    private logging: Logging,
    private store: Store<AppState>,
    private haCussService: HaCussService,
    public configService: ConfigService,
    private accessibilityService: AccessibilityService,
    public changeRef: ChangeDetectorRef,
    public el: ElementRef
  ) {
    this.cart$ = this.store.pipe(select('carts'));

    this.configSubscription = this.configService.config.subscribe((x) => {
      if (x && x.configuration) {
        this.config = x;
        this.initTimeout();
      }
    });

    this.accessibilityService.speechStatus$.subscribe((status: SPEECH_STATUS) => {
      if (status === SPEECH_STATUS.STARTED && this.showModal === false) {
        if (this.timer) {
          this.timer.unsubscribe();
        }
      }
      if (status === SPEECH_STATUS.COMPLETED && this.showModal === false) {
        this.setTimer();
      }
    });
  }

  setAcaaElementToRead() {
    if (this.showModal) {
      let elements = this.el.nativeElement.querySelectorAll('[tabindex]');
      this.accessibilityService.setModalElementsToRead({
        elements: elements,
        id: 'timeout',
        needsSorting: false,
        priority: 10,
      });
    }
  }

  setTimer(fromButton?: boolean) {
    if (fromButton) {
      this.logging.infoUiTimeOutPageYesSelected(0);
      this.dismissModal();
    }

    if (!this.url || this.sessionEnd) {
      return false;
    }

    if (this.timer) {
      this.timer.unsubscribe();
    }

    let currentTrip: Trip = null;

    this.store
      .select(selectCurrentTrip)
      .pipe(first())
      .subscribe((trip) => {
        currentTrip = trip;
      });

    const isMultiPax = currentTrip.passengers && currentTrip.passengers.length > 1;

    this.timerLimit = this.getTimeoutForUrl(this.url, isMultiPax);
    if (this.timerLimit > 0) {
      this.timer = of([this.url])
        .pipe(delay(this.timerLimit * 1000))
        .subscribe((x) => {
          this.logging.infoUiTimeOutPageDisplayed(0);
          this.displayModal();
        });
    }
  }

  setTimerStartOver() {
    if (this.timer) {
      this.timer.unsubscribe();
    }

    if (this.url.indexOf('/error') >= 0 || this.url.indexOf('/aa') >= 0) {
      this.timerLimitStartOver = 15000;
    }

    this.timer = of([this.url])
      .pipe(delay(this.timerLimitStartOver))
      .subscribe((value) => {
        this.doStartOver();
      });

    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.displayTimer = this.timeLeft.toString(10).padStart(2, '0');
      }
    }, 1000);
  }

  initTimeout() {
    this.routeEventSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.urlAfterRedirects;
        this.setTimer();
      }
    });

    this.loadingValueSubscription = this.loadingService.isLoading.subscribe((loadingState) => {
      if (loadingState) {
        if (this.timer) {
          return this.timer.unsubscribe();
        }
      }
      return this.setTimer();
    });

    socketAlive.subscribe((event) => {
      this.showDisabledPage = !event;
    });
  }

  ngOnInit() {
    this.cartSubscription = this.cart$.subscribe((state) => {
      this.sessionEnd = state.cart.sessionEnd;
      if ((this.sessionEnd || state.cart.checkConcurrentCart()) && this.timer) {
        this.timer.unsubscribe();
      }
    });
  }

  ngOnDestroy() {
    this.routeEventSubscription.unsubscribe();
    this.routeEventSubscription.unsubscribe();
    this.loadingValueSubscription.unsubscribe();

    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }

    if (this.clearStateSubscription) {
      this.clearStateSubscription.unsubscribe();
    }

    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }

    if (this.haCussService.getAtbPrinterStatus()[1] === true) {
      socketAlive.emit(false);
    }
    this.resetVisualTimer();
  }

  private resetVisualTimer() {
    this.timeLeft = 15;
    this.displayTimer = '15';
    clearInterval(this.interval);
  }

  doStartOver(fromButton?: boolean) {
    this.logChekInCancelled();
    if (fromButton) {
      this.logging.infoUiTimeOutPageNoSelected(0);
    }

    this.logging.infoUiTimeOutPageTimeOut(0);
    this.store.dispatch(new DeleteCart());

    this.clearStateSubscription = of(this.store.dispatch(new ClearState())).subscribe(() => {
      if (this.haCussService.getAtbPrinterStatus()[1] === true) {
        socketAlive.emit(false);
      } else {
        this.logging.infoUiSessionTimedOut();
        this.store.dispatch(new SelectLanguage('en'));
        this.router.navigate([AppRoutes.SPLASH_SCREEN]);
      }

      this.haCussService.enableLookupDevices();
      this.dismissModal();
    });
  }

  displayModal() {
    if (this.router.url.includes('aa')) {
      this.startOverButtonCopy = 'components.timeout.aaCancel';
      this.setTimerButtonCopy = 'components.timeout.aaConfirm';
      this.isInAgentAssist = true;
    }
    this.showModal = true;
    this.changeRef.detectChanges();
    this.setTimerStartOver();
    if (isAccessibilityMode.getValue()) {
      this.setAcaaElementToRead();
    }
  }

  dismissModal() {
    this.showModal = false;
    this.isInAgentAssist = false;
    this.resetVisualTimer();
    if (isAccessibilityMode.getValue()) {
      this.accessibilityService.dismissModalElements(null, null, 'timeout');
    }
  }

  getTimeoutForUrl(url: string, isMultiPax: boolean): number {
    if (!this.config || !this.config.configuration) {
      return 0;
    }

    let timeout = this.config.timeout.singlePaxSec;

    switch (0) {
      case url.indexOf(AppRoutes.OUT_OF_SERVICE): {
        timeout = this.config.timeout.noTimeoutSec;
        break;
      }
      case url.indexOf(AppRoutes.MENU): {
        // The Menu page has this timeout set in this component without the popup.
        timeout = 0;
        break;
      }
      case url.indexOf(AppRoutes.CONFIRMATION): {
        // The confirmation page has this timeout set in this component without the popup.
        timeout = 0;
        break;
      }
      case url.indexOf(AppRoutes.PRINT_DOCUMENTS): {
        timeout = this.config.timeout.printDocumentsSec;
        break;
      }
      case url.indexOf(AppRoutes.SPLASH_SCREEN): {
        timeout = this.config.timeout.splashScreenSec;
        break;
      }
      case url.indexOf(AppRoutes.HAZMAT): {
        timeout = this.config.timeout.hazmatSec;
        break;
      }
      case url.indexOf(AppRoutes.MORE_SEGMENTS_CHECKIN): {
        timeout = this.config.timeout.moreSegmentsCheckinSec;
        break;
      }
      case url.indexOf(AppRoutes.ERROR_SCREEN): {
        timeout = this.config.timeout.errorSec;
        break;
      }
      case url.indexOf(AgentAssistRoutes.MAIN): {
        timeout = this.agentAssistTimeout;
        break;
      }
      case url.indexOf(AppRoutes.SEATS): {
        timeout = this.config.timeout.seatsSec;
        break;
      }
      default: {
        const isAddHawaiianMilesPage = url.indexOf(AppRoutes.LOCATE_RESERVATION_HAWAIIAN_MILES) >= 0;

        if (isMultiPax) {
          timeout = this.config.timeout.multiPaxSec;
        } else if (isAddHawaiianMilesPage) {
          timeout = this.config.timeout.scanHawaiianMilesSec;
        } else {
          timeout = this.config.timeout.singlePaxSec;
        }

        break;
      }
    }

    return timeout;
  }

  logChekInCancelled() {
    const timeOutPagesNotCancelled = [
      AppRoutes.MENU,
      AppRoutes.CONFIRMATION,
      AppRoutes.ERROR_SCREEN,
      AppRoutes.LOCATE_RESERVATION,
      AppRoutes.PRINT_DOCUMENTS,
    ];

    if (timeOutPagesNotCancelled[this.router.url] >= 0) {
      this.logging.infoUiCheckinCancelled();
    }
  }
}
