import { Injectable } from '@angular/core';
import { API } from './../api.service';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  public baseUrl = `${this.api.baseUrl}/${this.api.basePath}`;

  constructor(private api: API) {}

  getCatalogs(confirmationCode: string, segmentId: string, tripId: string, bagsSource = 'farelogix') {
    if (this.api.getQueryParamsCount().length > 0) {
      this.api.clearQueryParams();
    }

    const url = `${this.baseUrl}/${this.api.apiPaths.catalogs}`;
    this.api.updateCorrelationId(tripId);

    this.api.appendQueryParams(this.api.apiQParams.trips.confirmationCode.key, confirmationCode);
    this.api.appendQueryParams(this.api.apiQParams.trips.segmentId.key, segmentId);
    this.api.appendQueryParams(this.api.apiQParams.catalogs.bagsSource.key, bagsSource);
    this.api.addDepartmentCode();

    if (bagsSource) {
      this.api.appendQueryParams(this.api.apiQParams.catalogs.bagsSource.key, bagsSource);
    }

    return this.api.doGetCall(url);
  }
}
