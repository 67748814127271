import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { KeyboardState } from '../../state/keyboard/keyboard.reducer';
import { Keyboard } from '../../state/keyboard/keyboard.model';
import { AppendContent } from '../../state/keyboard/keyboard.action';

@Component({
  selector: 'keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss'],
})
export class KeyboardComponent implements OnDestroy, OnInit, AfterViewInit {
  public keyboardSubscribe: Subscription;

  keyboard$: Observable<Keyboard>;
  constructor(private store: Store<KeyboardState>, private el: ElementRef) {}

  @Output() messageEvent = new EventEmitter<Array<ElementRef>>();

  @Input() tabIndex = '0';
  @Input() public limit: number;
  @Input() public buttonSize: string;
  @Input() set type(setType) {
    switch (setType) {
      case 'numeric':
        this.keyboard = this.numeric;
        break;
      case 'alphanumeric':
        this.keyboard = this.alphanumeric;
        break;
      case 'alphabetic':
        this.keyboard = this.alphabetic;
        break;
    }
  }

  public alphabetic: any = {
    lines: [
      {
        line: [
          {
            key: 'Q',
          },
          {
            key: 'W',
          },
          {
            key: 'E',
          },
          {
            key: 'R',
          },
          {
            key: 'T',
          },
          {
            key: 'Y',
          },
          {
            key: 'U',
          },
          {
            key: 'I',
          },
          {
            key: 'O',
          },
          {
            key: 'P',
          },
        ],
      },
      {
        line: [
          {
            key: 'A',
          },
          {
            key: 'S',
          },
          {
            key: 'D',
          },
          {
            key: 'F',
          },
          {
            key: 'G',
          },
          {
            key: 'H',
          },
          {
            key: 'J',
          },
          {
            key: 'K',
          },
          {
            key: 'L',
          },
        ],
      },
      {
        line: [
          {
            key: 'Z',
          },
          {
            key: 'X',
          },
          {
            key: 'C',
          },
          {
            key: 'V',
          },
          {
            key: 'B',
          },
          {
            key: 'N',
          },
          {
            key: 'M',
          },
        ],
      },
    ],
  };
  public numeric: any = {
    lines: [
      {
        line: [
          {
            key: '1',
          },
          {
            key: '2',
          },
          {
            key: '3',
          },
        ],
      },
      {
        line: [
          {
            key: '4',
          },
          {
            key: '5',
          },
          {
            key: '6',
          },
        ],
      },
      {
        line: [
          {
            key: '7',
          },
          {
            key: '8',
          },
          {
            key: '9',
          },
        ],
      },
      {
        line: [
          {
            key: '0',
          },
        ],
      },
    ],
  };
  public alphanumeric: any = {
    lines: [
      {
        line: [
          {
            key: 1,
          },
          {
            key: 2,
          },
          {
            key: 3,
          },
          {
            key: 4,
          },
          {
            key: 5,
          },
          {
            key: 6,
          },
          {
            key: 7,
          },
          {
            key: 8,
          },
          {
            key: 9,
          },
          {
            key: 0,
          },
        ],
      },
      {
        line: [
          {
            key: 'Q',
          },
          {
            key: 'W',
          },
          {
            key: 'E',
          },
          {
            key: 'R',
          },
          {
            key: 'T',
          },
          {
            key: 'Y',
          },
          {
            key: 'U',
          },
          {
            key: 'I',
          },
          {
            key: 'O',
          },
          {
            key: 'P',
          },
        ],
      },
      {
        line: [
          {
            key: 'A',
          },
          {
            key: 'S',
          },
          {
            key: 'D',
          },
          {
            key: 'F',
          },
          {
            key: 'G',
          },
          {
            key: 'H',
          },
          {
            key: 'J',
          },
          {
            key: 'K',
          },
          {
            key: 'L',
          },
        ],
      },
      {
        line: [
          {
            key: 'Z',
          },
          {
            key: 'X',
          },
          {
            key: 'C',
          },
          {
            key: 'V',
          },
          {
            key: 'B',
          },
          {
            key: 'N',
          },
          {
            key: 'M',
          },
        ],
      },
    ],
  };
  public keyboard: any;
  public entry = '';
  public entryActive = false;
  public entryDone = false;

  keyEntry(key) {
    if (this.limit && this.entry.length >= this.limit) {
      return false;
    }
    this.store.dispatch(new AppendContent(key));
  }

  ngOnInit(): void {
    this.keyboard$ = this.store.pipe(select('keyboard'));
    this.keyboardSubscribe = this.keyboard$.subscribe((value) => {
      if (value) {
        this.entry = value.content;
      }
    });
  }

  ngAfterViewInit(): void {
    this.messageEvent.emit(this.el.nativeElement.querySelectorAll('[tabindex]'));
  }

  ngOnDestroy() {
    if (this.keyboardSubscribe) {
      this.keyboardSubscribe.unsubscribe();
    }
  }
}
