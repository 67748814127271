import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState, Cart, selectCart, UpdateCartRequested } from '~app/state';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AppRoutes } from '~app/app-routes';

@Component({
  selector: 'app-special-items-services',
  templateUrl: './special-items-services.component.html',
  styleUrls: ['./special-items-services.component.scss'],
})
export class SpecialItemsServicesComponent implements OnInit, OnDestroy {
  private cart$: Observable<Cart>;
  private cart: Cart;
  private unsubscribe$ = new Subject<void>();

  constructor(private router: Router, private store: Store<AppState>) {}

  ngOnInit() {
    this.cart$ = this.store.pipe(select(selectCart));

    this.cart$.pipe(takeUntil(this.unsubscribe$)).subscribe((cart: Cart) => {
      this.cart = cart;
    });
  }

  sendBags() {
    if (this.cart) {
      this.store.dispatch(new UpdateCartRequested());
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  continue() {
    this.sendBags();
    this.router.navigate([AppRoutes.BAGS]);
  }
}
