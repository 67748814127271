export enum ErrorType {
  NoError = 'NoError',
  CheckInTooEarly = 'CheckInTooEarly',
  NotFound = 'NotFound',
  Timeout = 'Timeout',
  DelayedFlightNotification = 'DelayedFlightNotification',
  WrongMilesNumber = 'WrongMilesNumber',
  ReferOutToAgent = 'ReferOutToAgent',
  ReferOutToAgentDontPrint = 'ReferOutToAgentDontPrint',
  FlightCancelled = 'FlightCanceled',
  CheckInTooLate = 'CheckInTooLate',
  CheckInTooLateWithPrintBoardingPass = 'CheckInTooLateWithPrintBoardingPass',
  TicketingIssue = 'TicketingIssue',
  PrintingIssue = 'PrintingIssue',
  BagTagPrintingIssue = 'BagTagPrintingIssue',
  DoNotPrintBoardingPass = 'DoNotPrintBoardingPass',
  SabreClosed = 'SabreClosed',
  PassportScan = 'PassportScan',
  RetryCard = 'RetryCard',
  SubsequentCardError = 'SubsequentCardError',
  PaymentCardError = 'PaymentCardError',
  PrintBagTagsError = 'PrintBagTagsError',
  GenerateBagTagError = 'GenerateBagTagError',
  GenerateDocumentError = 'GenerateDocumentError',
  FailedDocumentPrintError = 'FailedDocumentPrintError',
  MilitaryNoMatchError = 'MilitaryNoMatchError',
  SPFAndBPFError = 'SeatPaymentFailureAndBagPaymentFailure',
  SPFError = 'SeatPaymentFailure',
  SAFError = 'SeatAssignmentFailure',
  BPFError = 'BagPaymentFailure',
}

export interface InfoPiece {
  translationTag: string;
  params?: object;
}

export interface ErrorCodes {
  alertReasonCode?: string;
  alertMessageCode?: string;
  displayErrorCode: boolean;
}

export interface ButtonInfo {
  route?: string;
  translationTag?: string;
}

export interface ErrorModel {
  errorType: ErrorType;
  primaryButton: ButtonInfo;
  additionalButton?: ButtonInfo;
  info: Array<InfoPiece>;
  codes?: ErrorCodes;
  image?: object;
}

export interface AlertReason {
  alertReasonCode: string;
  alertDescription: string;
}

export enum AlertReasonCode {
  PC01 = 'PC01',
  PC02 = 'PC02',
  PC03 = 'PC03',
  PC04 = 'PC04',
  PC05 = 'PC05',
  PC06 = 'PC06',
  PC07 = 'PC07',
  PC08 = 'PC08',
}

export enum AlertMessageCode {
  PC01 = 'bagFulfillmentFailure',
  PC02 = 'seatPaymentFailureBagFulfillmentFailure',
  PC03 = 'seatAssignmentFailureBagFulfillmentFailure',
  PC04 = 'seatPaymentFailureBagPaymentFailure',
  PC05 = 'seatAssignmentFailureBagPaymentFailure',
  PC06 = 'seatPaymentFailure',
  PC07 = 'seatAssignmentFailure',
  PC08 = 'bagPaymentFailure',
}

export enum AlertMessageDescription {
  CI01 = 'Passenger Name Record (PNR) Locator could not be found in SABRE',
  CI02 = "Flight segment status for all flights in the journey are not, 'HK, KK, MM, RR, SC, or TK'.",
  CI03 = "Virtual Coupon Record (VCR) does not exist or is not in an 'OK' or 'CKIN' status.",
  CI04 = 'Mismatch in itinerary and Virtual Coupon Record (VCR) trip.',
  CI05 = "Attempt to check in prior to 24 Hours before the flight's estimated time of departure.",
  CI06 = "Attempt to check in within 60 minutes of the flight's estimated time of departure.",
  CI07 = 'Attempt to check in when the flight has been cancelled.',
  CI08 = "Attempt to check in when the sabre status for the flight does not equal 'OPENCI'.",
  CI09 = 'Attempt to check in when passenger is unaccompanied minor.',
  CI10 = 'Attempt to check in for a Group booking.',
  CI11 = 'Attempt to check in when first flight of the segment is operated by another airline.',
  CI12 = 'Attempt to check in for an international flight - is this HA to an AO flight #',
  CI13 = 'Booking created prior to June 1, 2017 and may have MSR ancillary records.',
  CI14 = 'Outstanding balance on cart creating by non-Native App channel.',
  CI15 = 'Departing international location or location mobile boarding pass is not supported.',
  CI16 = 'DHS Status is not equal to Cleared, Known, or Selectee.',
  CI17 = 'Refer out due to Special Service Request (SSR).',
  CI18 = 'Refer out due to Edit Code.',
  CI19 = 'Error due to system failure.',
  CI20 = 'If Flight number is between 700-799 or 900-999 then refer to an agent. Charter flight.',
  CI21 = 'Hawaiian Airlines is not the Most Significant Carrier',
  CI22 = 'Non Revenue Traveler Could Not Be Added to Pall List.',
  CI23 = 'Revenue Traveler could not be checked-in.',
  CI24 = 'A passenger traveling with an infant on lap for the  Check-In segment',
  CI25 = 'Attempt to check in when a flight in the segment is operated & marketed by another airline',
  BP01 = 'Attempt to view Mobile Boarding Pass when DHS status is not Cleared or Known.',
  BP02 = 'Attempt to view Mobile Boarding Pass when not checked in or on PALL List',
  BP03 = 'Attempt to view Mobile Boarding Pass when Special Service Request (SSR) exist.',
  BP04 = 'Attempt to view Mobile Boarding Pass when Edit codes exist.',
  BP05 = 'Attempt to view Mobile Boarding Pass when Sabre Checkin satus is not OPENCI or FINAL.',
  INF1 = 'An infant is associated to passenger that is an unaccompanied minor',
  INF2 = 'A passenger is assigned to an exit row seat with an associated infant ',
  INF3 = 'A passenger has an associated infant and is traveling on an international route',
  // tslint:disable-next-line: max-line-length
  SS1 = 'A passenger traveling with an infant is already assigned with an exit row seat or a seat in the row before or after an exit row and tries to view or change seat.',
  SS2 = 'Restricted SSR Code and Assigned a Bulk Head seat.',
  SS3 = 'Restricted SSR Code and Assigned an exit row seat.',
  PF01 = "Credit card reader failed to retrieve the properly formatted data off of the guest's payment card",
  PF02 = "Guest's payment card could not be tokenized via the CDE Tokenizer",
  PF03 = "Payment authorization failed due to issue with charging against the guest's payment card",
  BT01 = 'Hardware printer failed to print one or more bag tags.',
  BT02 = 'Sabre or underlying service experienced an error while attempting to generate a bag tag',
  BD01 = 'Unable to successfully print all documents due to one or more document type failures',
  PR01 = 'Sabre or underlying service experienced an error while attempting to generate a payment receipt',
  PR02 = 'Kiosk hardware failed to print one or more receipts',
  PC01 = 'Bag Fulfillment Failure',
  PC02 = 'Seat Payment Failure & Bag Fulfillment Failure (SPF&&BFF)',
  PC03 = 'Seat Assignment Failure & Bag Fulfillment Failure (SAF&&BFF)',
  PC04 = 'Seat Payment Failure & Bag Payment Failure (SPF&&BPF)',
  PC05 = 'Seat Assignment Failure & Bag Payment Failure (SAF&BPF)',
  PC06 = 'Seat Payment Failure (SPF)',
  PC07 = 'Seat Assignment Failure (SAF)',
  PC08 = 'Bag Payment Failure (BPF)',
}
