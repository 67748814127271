import { AirportDate } from '../../common/airport-date.model';

export enum FlightStatuses {
  Open = 'Open',
  Upcoming = 'Upcoming',
  Closed = 'Closed',
  Delayed = 'Delayed',
}

export enum AircraftType {
  A717 = '717',
  A321 = '321',
  A330 = '330',
  A332 = '332',
  A330LIEFLAT = '',
  A330ABL = '',
  A330Default = '',
  ATR42 = 'ATR42',
  ATR_42 = 'ATR',
}

export class Flight {
  id: string;
  flightNumber: string | null;
  flightType: string | null;
  aircraftType: string | null;
  boardingStatus: string | null;
  origin: string | null;
  destination: string | null;
  scheduledDestination: string | null;
  scheduledDeparture: AirportDate | null;
  scheduledArrival: AirportDate | null;
  scheduledBoarding: string | null;
  checkInStatus: string | null;
  operatedBy: string | null;
  marketedBy: string | null;
  disclosureText: string | null;
  seatAvailability: string | null;
  standByList: string | null;
  airlineCode: string | null;

  static deserializeFromJson(json): Flight {
    return Object.assign(new Flight(), json);
  }

  get isOAFlight() {
    if (!this.marketedBy) {
      return false;
    }

    return this.marketedBy !== 'HA' ? true : false;
  }

  isOpenForCheckIn(): boolean {
    return this.checkInStatus === FlightStatuses.Open;
  }

  isOaEmpire(): boolean {
    if (this.disclosureText) {
      return this.disclosureText.toLowerCase().indexOf('empire') >= 0;
    }
    return false;
  }

  originMatchesKioskAirport(kioskOrigin: string): boolean {
    return this.origin === kioskOrigin;
  }
}
