export enum AppRoutes {
  SPLASH_SCREEN = '/splash-screen',
  MENU = '/menu',
  ITINERARY = '/itinerary',
  ERROR_SCREEN = '/error-screen',
  PRINT_DOCUMENTS = '/print-documents',
  LOCATE_RESERVATION = '/locate-reservation',
  LOCATE_RESERVATION_CONFIRMATION_CODE = '/locate-reservation/confirmation-code',
  LOCATE_RESERVATION_E_TICKET = '/locate-reservation/e-ticket',
  LOCATE_RESERVATION_HAWAIIAN_MILES = '/locate-reservation/hawaiian-miles',
  LOCATE_RESERVATION_FLIGHT_NUMBER = '/locate-reservation/flight-number',
  LOCATE_RESERVATION_LAST_NAME = '/locate-reservation/last-name',
  LOCATE_RESERVATION_BIRTHDAY = '/locate-reservation/birthday',
  LOCATE_RESERVATION_AIRPORT_CODE = '/locate-reservation/airport-code',
  LOCATE_RESERVATION_DESTINATION = '/locate-reservation/destination',
  LOCATE_RESERVATION_BARCODE = '/locate-reservation/barcode',
  BAGS = '/bags',
  SEATS = '/seats',
  SEAT_PREFERENCE = '/seat-preference',
  SEAT_CHANGE_ERROR = '/seats/seat-change-error',
  CHANGE_LANGUAGE = '/change-language',
  CONFIRMATION = '/confirmation',
  CONFIRMATION_BAGS_PRINTED = '/confirmation/bags-printed',
  CONFIRMATION_NO_PRINTED_BAGS = '/confirmation/non-printed-bags',
  STANDBY_CONFIRMATION = '/standby-confirmation',
  LOADING = '/loading',
  OPTIONS = '/options',
  OUT_OF_SERVICE = '/out-of-service',
  MORE_SEGMENTS_CHECKIN = '/more-segments-checkin',
  HAZMAT = '/hazmat',
  HAZMAT_PROHIBITED = '/hazmat/prohibited',
  HAZMAT_RESTRICTED = '/hazmat/restricted',
  HAZMAT_SELF_TAGGING = '/hazmat/self-tagging',
  HAZMAT_NO_SELF_TAGGING = '/hazmat/no-self-tagging',
  PAX_EDIT = '/pax-edit',
  PAX_EDIT_SELECT_PAX = '/pax-edit/select-pax',
  PAX_EDIT_MAIN = '/pax-edit/main',
  PAX_EDIT_KTN = '/pax-edit/ktn',
  PAX_EDIT_REDRESS = '/pax-edit/redress',
  PAX_EDIT_HAWAIIAN_MILES = '/pax-edit/hm-number',
  ABOUT_BAGS = '/about-bags',
  SPECIAL_ITEMS = '/special-items',
  MILITARY = '/military',
  MILITARY_CONFIRMATION = '/military/confirmation',
  MILITARY_THANK_YOU = '/military/thank-you',
  MILITARY_SELECTION = '/military/selection',
  MILITARY_NO_MATCH = '/military/no-match',
  PAYMENT = '/payment',
}
