import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  AppState,
  DocumentPrintingDataPrinted,
  DocumentPrintingDataRequested,
  ErrorActionTypes,
  PaymentReceiptDataPrinted,
  selectActiveSegment,
  selectCurrentTrip,
  SetBoardingPassError,
  SetGenerateDocumentError,
  SetTimeoutError,
} from '../../state';
import { BagTagDataPrinted, DocumentActionTypes } from './document.actions';
import { DocumentService } from '../../services/api/document/document.service';
import { HaCussService, onBPAdded } from '../../services/ha-cuss/ha-cuss.service';
import { Logging } from '../../services/logging/logging.service';
import {
  airportCode,
  bagTagDataLoaded,
  boardingPassDataLoaded,
  currentlyPrinting,
  PRINTING_TYPES,
} from '../../services/emitters/session-event-emitters';
import { selectCart } from '../cart/cart.selector';
import { numberToPrint } from '../../services/ha-cuss/device.service';
import { AppRoutes } from '~app/app-routes';
import { SetPrintingIssue, SetBagTagPrintingIssue } from '../error/error.action';
import { isEmpty } from 'shared/embross/helper';

@Injectable()
export class DocumentEffects {
  private errorUrl = AppRoutes.ERROR_SCREEN;
  private bagTagData = [];
  private boardingPassData = [];
  private receiptData: [] | {};

  constructor(
    private actions$: Actions,
    public router: Router,
    private store: Store<AppState>,
    public documentService: DocumentService,
    private haCussService: HaCussService,
    private zone: NgZone,
    private logging: Logging
  ) {}

  @Effect({ dispatch: false })
  getBoardingPass$ = this.actions$.pipe(
    ofType(DocumentActionTypes.DocumentPrintingDataRequested),
    withLatestFrom(this.store.pipe(select(selectCurrentTrip))),
    switchMap(([_, trip]) => {
      return from(this.documentService.setBoardingPassData(trip));
    }),
    map((result: any) => {
      this.logging.infoSessionDocumentPrintingDataRequested();
      onBPAdded.emit(result.length);
      this.logging.infoSessionBoardingPassesToPrint(result.length);
      this.boardingPassData = result;
      boardingPassDataLoaded.next(true);
    }),
    catchError(() => {
      boardingPassDataLoaded.next(true);
      return of(this.store.dispatch(new SetBoardingPassError()));
    })
  );

  @Effect({ dispatch: false })
  printBoardingPass$ = this.actions$.pipe(
    ofType(DocumentActionTypes.DocumentPrintingDataPrinted),
    map(() => {
      this.logging.infoSessionDocumentPrintingDataPrinted(this.boardingPassData);
      // If we have loaded the boarding pass data for this segment
      if (boardingPassDataLoaded.getValue()) {
        // If the length of that data is 0
        if (isEmpty(this.boardingPassData)) {
          // We know that an error has occured since the boarding pass data is returned on every call so we need to
          // notify the passenger to get assistance
          this.store.dispatch(new SetBoardingPassError());
        } else {
          // We know that we have loaded the data, it has a length, so we can proceed with printing the document
          this.printDocument(
            this.boardingPassData,
            this.haCussService,
            this.store,
            this.zone,
            this.router,
            false,
            PRINTING_TYPES.BOARDING_PASS
          );
          currentlyPrinting.next(PRINTING_TYPES.BOARDING_PASS);
        }
      } else {
        // Otherwise we know we have not loaded the data yet and need to wait for it to return
        setTimeout(() => this.store.dispatch(new DocumentPrintingDataPrinted()), 1000);
      }
    }),
    catchError(() => of(this.store.dispatch(new SetBoardingPassError())))
  );

  @Effect({ dispatch: false })
  getCounterAssistTicket$ = this.actions$.pipe(
    ofType(
      ErrorActionTypes.SetReferToAgentError,
      ErrorActionTypes.SetTicketingIssue,
      DocumentActionTypes.PrintCounterAssist
    ),
    withLatestFrom(this.store.pipe(select(selectCurrentTrip))),
    switchMap(([_, trip]) => {
      return from(this.documentService.setCounterAssistTicketData(trip, _));
    }),
    map((result) => {
      this.logging.infoHardwareCounterAssistPrinted(result.stream);
      this.printDocument([result], this.haCussService, this.store, this.zone, this.router, true, PRINTING_TYPES.NONE);
    }),
    catchError(() => of(this.store.dispatch(new SetGenerateDocumentError())))
  );

  @Effect({ dispatch: false })
  getPaymentReceipt$ = this.actions$.pipe(
    ofType(DocumentActionTypes.PaymentReceiptDataRequested),
    withLatestFrom(this.store.pipe(select(selectCurrentTrip)), this.store.pipe(select(selectCart))),
    switchMap(([_, trip, cart]) => {
      this.logging.infoSessionPaymentReceiptDataRequested();
      return from(this.documentService.setReceiptTicketData(trip, cart));
    }),
    map((result) => {
      this.receiptData = result;
      this.store.dispatch(new PaymentReceiptDataPrinted());
    }),
    catchError(() => of(this.store.dispatch(new SetGenerateDocumentError())))
  );

  @Effect({ dispatch: false })
  printPaymentReceipt$ = this.actions$.pipe(
    ofType(DocumentActionTypes.PaymentReceiptDataPrinted),
    map(() => {
      this.logging.infoSessionPaymentReceiptDataPrinted(this.receiptData);
      if (isEmpty(this.receiptData)) {
        // If we don't, then we need to throw an error to notify the passenger
        currentlyPrinting.next(PRINTING_TYPES.RECEIPT);
        numberToPrint.emit(0);
        this.store.dispatch(new SetGenerateDocumentError());
      } else {
        // We know we received information back from the API and can proceed
        this.printDocument(
          [this.receiptData],
          this.haCussService,
          this.store,
          this.zone,
          this.router,
          false,
          PRINTING_TYPES.RECEIPT
        );
        currentlyPrinting.next(PRINTING_TYPES.RECEIPT);
      }
    }),
    catchError(() => {
      currentlyPrinting.next(PRINTING_TYPES.RECEIPT);
      numberToPrint.emit(0);
      return of(this.store.dispatch(new SetPrintingIssue()));
    })
  );

  @Effect({ dispatch: false })
  getBagTags$ = this.actions$.pipe(
    ofType(DocumentActionTypes.BagTagDataRequested),
    withLatestFrom(this.store.pipe(select(selectCurrentTrip)), this.store.pipe(select(selectActiveSegment))),
    switchMap(([_, trip, segment]) => {
      if (trip.activeSegment.origin === airportCode.getValue()) {
        return from(this.documentService.setBagTagData(trip, segment));
      }

      return of([]);
    }),
    map((result) => {
      this.logging.infoSessionBagTagDataRequested();
      this.bagTagData = result;
      bagTagDataLoaded.next(true);
      this.store.dispatch(new DocumentPrintingDataRequested());
    }),
    catchError(() => {
      bagTagDataLoaded.next(true);
      return of(this.store.dispatch(new SetBagTagPrintingIssue()));
    })
  );

  @Effect({ dispatch: false })
  printBagTags$ = this.actions$.pipe(
    ofType(DocumentActionTypes.BagTagDataPrinted),
    map(() => {
      this.logging.infoSessionBagTagDataPrinted(this.bagTagData);
      if (bagTagDataLoaded.getValue()) {
        if (isEmpty(this.bagTagData)) {
          currentlyPrinting.next(PRINTING_TYPES.BAG_TAG);
          numberToPrint.emit(0);
        } else {
          this.documentService.resetBagTagKeys();
          this.printBagTag(this.bagTagData, this.haCussService, this.store, this.zone, this.router);
          currentlyPrinting.next(PRINTING_TYPES.BAG_TAG);
        }
      } else {
        setTimeout(() => this.store.dispatch(new BagTagDataPrinted()), 1000);
      }
    }),
    catchError(() => of(this.store.dispatch(new SetBagTagPrintingIssue())))
  );

  printDocument(results, haCussService, store, zone, router, resetPectab = false, printingType: PRINTING_TYPES) {
    if (
      (haCussService.getAtbPrinterStatus()[1] !== true || haCussService.peripheralOnline !== false) &&
      results.length > 0
    ) {
      results.map((document) => {
        of(haCussService.loadPECTabs(this.pectabArray(document), false, resetPectab)),
          of(haCussService.callATBPrinter(this.extractStreams(document), resetPectab));
        if (printingType === PRINTING_TYPES.BOARDING_PASS) {
          this.boardingPassData = [];
          boardingPassDataLoaded.next(false);
        } else if (printingType === PRINTING_TYPES.RECEIPT) {
          this.receiptData = [];
        }
      });
    } else {
      store.dispatch(new SetTimeoutError());
      zone.run(() => router.navigateByUrl(this.errorUrl));
    }
  }

  printBagTag(results, haCussService, store, zone, router) {
    if ((haCussService.getBagTagPrinterStatus()[1] !== true || haCussService.peripheralOnline) && results.length > 0) {
      results.map((boardingPassData) => {
        of(haCussService.loadBagTagPECTabs(this.pectabArray(boardingPassData), false)),
          of(haCussService.callBagTagPrinter(this.extractStreams(boardingPassData)));
        this.bagTagData = [];
        bagTagDataLoaded.next(false);
      });
    } else {
      store.dispatch(new SetTimeoutError());
      zone.run(() => router.navigateByUrl(this.errorUrl));
    }
  }

  pectabArray(pectabObj) {
    const ticketPecTab = [];
    Object.keys(pectabObj).forEach((key) => {
      if (!key.match(/^(stream|streamArray|bagTagStreams)$/)) {
        ticketPecTab.push(pectabObj[key]);
      }
    });

    return ticketPecTab;
  }

  extractStreams(entireDocument: Array<any>) {
    const streamArrayKey = 'streamArray';
    const streamKey = 'stream';
    const printStream = [];
    const dataStreamsToPrint = entireDocument[streamArrayKey];

    if (dataStreamsToPrint !== undefined) {
      dataStreamsToPrint.forEach((item: object) => {
        Object.entries(item).forEach(([key, value]) => {
          this.documentService.loadBagTagKey(key);
          printStream.push(value);
        });
      });
      onBPAdded.emit(printStream.length);
    } else {
      printStream.push(entireDocument[streamKey]);
    }

    return printStream;
  }
}
