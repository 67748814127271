import { Flight, Passenger } from '~app/state';

export interface SeatSelection {
  flightId: string;
  passengerId: string;
  seatSelected: string;
  seatAttributes?: string;
}

export interface SeatsPriceCatalog {
  firstClass?: number;
  extraComfort?: number;
  exitRow?: number;
}

export enum SeatType {
  AISLE = 'a',
  EXITROW = 'x',
  EXTRACOMFORT = 'e',
  FIRSTCLASS = 'f',
  PREFERRED = 'p',
  REARFACING = 'r',
  WINDOW = 'w',
}

export enum SeatState {
  ACTIVE = 'active-selected-seat',
  COMPANION = 'companion-selected-seat',
  UNAVAILABLE = 'unavailable-seat',
}
export enum SeatProducts {
  FIRSTCLASS = '0BJ',
  EXTRACOMFORT = '0BV',
  SEATCHANGE = 'Seat-change',
  //Below exists only for [FE].
  //For instances when a seat is not in a passenger's catalogs but it should still be available for selection
  ASSIGNEDSEAT = 'ASSIGNED',
  UNKNOWN = 'unknown',
}

export enum SeatFillState {
  COMPANION = '#3172C4',
  EXITROW = '#CF3912',
  EXTRACOMFORT = '#FE7502',
  FIRSTCLASS = '#704C9D',
  PREFERRED = '#FE7502',
  SELECTED = '#105792',
  UNAVAILABLE = '#666666',
}
//#TODO all SVG needs to be moved under data/media
export enum LargeSeatMapLocation {
  A717 = 'assets/planes/large-717-plane.svg',
  A321 = 'assets/planes/large-A321-plane.svg',
  A330 = 'assets/planes/large-A330-plane.svg',
  A330LIEFLAT = 'assets/planes/large-A330-plane.svg',
  A330ABL = 'assets/planes/large-A330-plane.svg',
  A330Default = 'assets/planes/large-A330-plane.svg',
  ATR42 = 'assets/planes/large-ATR-42-plane.svg',
}

//#TODO all SVG needs to be moved under data/media
export enum SmallSeatMapLocation {
  A717 = 'assets/planes/small-717-plane.svg',
  A321 = 'assets/planes/small-A321-plane.svg',
  A330 = 'assets/planes/small-A330-plane.svg',
  A330LIEFLAT = 'assets/planes/small-A330-plane.svg',
  A332 = 'assets/planes/small-A330-plane.svg',
  A330ABL = 'assets/planes/small-A330-plane.svg',
  A330Default = 'assets/planes/small-A330-plane.svg',
  ATR42 = 'assets/planes/small-ATR-42-plane.svg',
}

export class SeatChangeModel {
  public product: string;
  public price: string;
  public previousSeat: string;
  public seatSelection: SeatSelection;
  public flight: Flight;
  public seatChangeFlow: SeatChangeModalFlow;
  public passenger: Passenger;

  constructor() {}
}

export enum SeatChangeModalFlow {
  EXITFLOW = 'EXITROWFLOW',
  FIRSTCLASSUPGRADEFLOW = 'FIRSTCLASSUPGRADECLASSFLOW',
  PREMIUMUPGRADEFLOW = 'PREMIUMUPGRADECLASSFLOW',
  SEATCHANGEFLOW = 'SEATCHANGEFLOW',
}
