import { Passenger } from '../trip/passenger.model';
import { BagTypeId, CatalogIdTypes } from '../trip/passenger.catalog.model';
import { SeatProducts } from '~app/models/seat-map/seat-map.model';

export class Cart {
  id: string;
  expiration: Date;
  state: string;
  grandTotal: number;
  confirmationCode: string;
  passengers: string[];
  totalPrices: CartPrices[];
  associatedPassengers: { passengerId: string }[];
  items: CartItem[];
  sessionEnd = false;
  relatedCarts: RelatedCart[];
  paxSelectedForSpecialItems: Passenger;

  private localStringConfig = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  static deserializeFromJson(json): Cart {
    const cart: Cart = Object.assign(new Cart(), json);

    if (cart && cart.items) {
      cart.items.forEach((item) => {
        if (!item.quantity) {
          item.quantity = 1;
        }
      });
    }

    return cart;
  }

  private filterByBagType(filter: BagFilter): Array<CartItem> {
    if (!this.items) {
      return [];
    }

    return this.items.filter((cartItem: CartItem) => {
      if (filter.isRegularBag) {
        return cartItem.productId === BagTypeId.Regular;
      }

      return cartItem.productId !== BagTypeId.Regular && cartItem.catalogId !== CatalogIdTypes.Seats;
    });
  }

  private filterByCatalogId(catalogId: CatalogIdTypes) {
    if (!this.items) {
      return [];
    }

    return this.items.filter((cartItem: CartItem) => {
      return cartItem.catalogId === catalogId;
    });
  }

  private filterCartItemsPerPax(cartItemsFilter: CartItemsFilter) {
    if (!cartItemsFilter.cartItems) {
      return [];
    }

    return cartItemsFilter.cartItems.filter(
      (cartItem: CartItem) => cartItem.associatedPassenger.passengerId === cartItemsFilter.passengerId
    );
  }

  private getCartItemsTotalItems(cartItems: Array<CartItem> = []): number {
    const total = cartItems.reduce((previousValue, item: CartItem) => previousValue + item.quantity, 0);
    return total;
  }

  private getCartItemsTotalPrice(cartItems: Array<CartItem> = []): number {
    const total = cartItems.reduce((previousValue, item: CartItem) => {
      return previousValue + parseFloat(item.price);
    }, 0);

    if (total) {
      return total;
    }

    return 0;
  }

  localeValue(value: number): string {
    return value.toLocaleString('en', this.localStringConfig);
  }

  getGrandTotalPrice(): string {
    if (this.grandTotal > 0) {
      return this.localeValue(this.grandTotal);
    }

    const totalSpecialItemsPrice = this.getSpecialItemsTotalPrice();
    const totalRegularBagsPrice = this.getRegularBagsTotalPrice();
    const totalSeatUpgradesPrice = this.getSeatUpgradesPrice();

    const totalPrice = totalSpecialItemsPrice + totalRegularBagsPrice + totalSeatUpgradesPrice;

    return this.localeValue(totalPrice);
  }

  getGrandTotalPriceForBagsAndSpecialItems(): string {
    const totalSpecialItemsPrice = this.getSpecialItemsTotalPrice();
    const totalRegularBagsPrice = this.getRegularBagsTotalPrice();

    const totalPrice = totalSpecialItemsPrice + totalRegularBagsPrice;

    return this.localeValue(totalPrice);
  }

  getRegularBagsTotalPrice(): number {
    const regularBags = this.filterByBagType({ isRegularBag: true });
    return this.getCartItemsTotalPrice(regularBags);
  }

  getRegularBagsTotalPricePerPax(passengerId: string): number {
    const regularBags = this.filterByBagType({ isRegularBag: true });
    const regularBagsPax = this.filterCartItemsPerPax({
      cartItems: regularBags,
      passengerId,
    });

    return this.getCartItemsTotalPrice(regularBagsPax);
  }

  getRegularBagsTotalPricePerPaxLocated(passengerId: string): string {
    const totalPrice = this.getRegularBagsTotalPricePerPax(passengerId);
    return this.localeValue(totalPrice);
  }

  getSpecialItemsTotalPrice(): number {
    const specialItems = this.filterByBagType({ isRegularBag: false });
    return this.getCartItemsTotalPrice(specialItems);
  }

  getSpecialBagsTotalPricePerPax(passengerId: string): number {
    const specialItems = this.filterByBagType({ isRegularBag: false });
    const specialItemsPax = this.filterCartItemsPerPax({
      cartItems: specialItems,
      passengerId,
    });

    return this.getCartItemsTotalPrice(specialItemsPax);
  }

  getSpecialBagsTotalPricePerPaxLocated(passengerId: string): string {
    const totalPrice = this.getSpecialBagsTotalPricePerPax(passengerId);
    return this.localeValue(totalPrice);
  }

  getAdditionalTotalItems(): number {
    return this.getCartItemsTotalItems(this.items);
  }

  getRegularBagsTotalItems(): number {
    const regularBags = this.filterByBagType({ isRegularBag: true });
    return this.getCartItemsTotalItems(regularBags);
  }

  getRegularBagsTotalItemsPerPax(passengerId: string): number {
    const regularBags = this.filterByBagType({ isRegularBag: true });
    const regularBagsPax = this.filterCartItemsPerPax({
      cartItems: regularBags,
      passengerId,
    });

    return this.getCartItemsTotalItems(regularBagsPax);
  }

  getSpecialItemsTotalItems(): number {
    const specialBags = this.filterByBagType({ isRegularBag: false });
    return this.getCartItemsTotalItems(specialBags);
  }

  getSpecialItemsTotalItemsPerPax(passengerId: string): number {
    const specialItems = this.filterByBagType({ isRegularBag: false });
    const specialItemsPax = this.filterCartItemsPerPax({
      cartItems: specialItems,
      passengerId,
    });

    return this.getCartItemsTotalItems(specialItemsPax);
  }

  getSeatUpgradesPrice(): number {
    const seatItems = this.filterByCatalogId(CatalogIdTypes.Seats);
    const total = this.getCartItemsTotalPrice(seatItems);

    return total;
  }

  getSeatUpgradesLocated(): string {
    const total = this.getSeatUpgradesPrice();
    return this.localeValue(total);
  }

  getSeatUpgradesPerPax(passengerId: string): number {
    const seatItems = this.filterByCatalogId(CatalogIdTypes.Seats);
    const paxSeats = this.filterCartItemsPerPax({
      cartItems: seatItems,
      passengerId: passengerId,
    });

    const total = this.getCartItemsTotalPrice(paxSeats);

    return total;
  }

  getSeatUpgradesPerPaxLocated(passengerId): string {
    const totalPrice = this.getSeatUpgradesPerPax(passengerId);
    return this.localeValue(totalPrice);
  }

  checkConcurrentCart(): boolean {
    if (
      this.relatedCarts &&
      this.relatedCarts.length > 0 &&
      this.relatedCarts[0].state !== RelatedCartState.PROCESSING
    ) {
      return true;
    }

    return false;
  }

  checkConcurrentRelatedCart(): boolean {
    if (
      this.relatedCarts &&
      this.relatedCarts.length > 0 &&
      this.relatedCarts[0].state === RelatedCartState.PROCESSING
    ) {
      return true;
    }

    return false;
  }

  checkIfAllPassengerAreFirstClass(): boolean {
    let upgradesPerFlight = {};
    //take all first class upgrade seat items associated pax
    const seatItems = this.items
      .filter((item) => item.productId == SeatProducts.FIRSTCLASS)
      .map((item) => item.associatedPassenger);

    //count amount of upgrades per flight
    seatItems.forEach((item) => {
      upgradesPerFlight[item.flightId] = seatItems.reduce((upgrades, currItem) => {
        if (currItem.flightId === item.flightId) {
          upgrades++;
        }
        return upgrades;
      }, 0);
    });
    //check if a flight does not have all pax upgraded
    for (let flightId in upgradesPerFlight) {
      if (upgradesPerFlight[flightId] !== this.passengers.length) {
        return false;
      }
    }

    return true;
  }
}

interface BagFilter {
  isRegularBag: boolean;
}

interface CartItemsFilter {
  passengerId: string;
  cartItems: Array<CartItem>;
}

export interface CartPrices {
  catalogId: string;
  price: number;
}

export interface CartItem {
  productId: string;
  catalogId: string;
  quantity?: number;
  price?: string;
  expirationTime?: Date;
  assignment?: string;
  adjustments?: Adjustment[];
  associatedPassenger: AssociatedPassenger;
}

export interface Adjustment {
  associationId: string;
  designator: string;
  type: string;
  adjustmentAmount: number;
}

export interface AssociatedPassenger {
  passengerId: string;
  segmentId?: string;
  flightId?: string;
}

export interface Checkout {
  checkoutTransactionId: string;
  confirmationCode: string;
  pointOfSale: PointOfSale;
  payments: Payment[];
}

export interface Payment {
  paymentType: string;
  paymentToken: string;
  expirationDate: string;
  cardType: string;
  firstName: string;
  lastName: string;
  amount: string;
}

export interface AssociatedPassenger {
  passengerId: string;
}
export interface PointOfSale {
  location: KioskLocation;
}

export enum RelatedCartState {
  PROCESSING = 'PROCESSING',
}

export interface RelatedCart {
  id: string;
  state: RelatedCartState;
  segmentId: string;
  associatedPassengers: AssociatedPassenger[];
}

export interface KioskLocation {
  cityCode: string;
  stationCode: string;
}

export interface PartialCarts {
  status: string;
  count: number;
  type: string;
  results: PartialCartsResults[];
}

export interface PartialCartsResults {
  rangeReference: string;
  status: number;
  errors?: PartialCartsErrors[];
}

export interface PartialCartsErrors {
  code: string;
  canRetry: boolean;
  message: string;
  info: PartialCartsErrorsInfo[];
}

export interface PartialCartsErrorsInfo {
  element: string;
  errorPayload: string;
  errorPayloadType: string;
}

export enum PartialCartsTypes {
  BFF = 'bagFulfillment',
  BPF = 'bagPayment',
  SAF = 'seatAssignment',
  SPF = 'seatPayment',
}
