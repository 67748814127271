import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { AppRoutes } from '~app/app-routes';
import { Router } from '@angular/router';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import {
  AppState,
  FlightStatus,
  SegmentDetail,
  SegmentDetailAndPax,
  SegmentFlight,
  SegmentMilitaryPaxTravelType,
  selectCurrentTrip,
  SetMilitaryTravelTypeRequested,
  Trip,
} from '~app/state';

import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { MILITARY_TRAVEL_TYPE } from '~app/models/military/military.model';

@Component({
  selector: 'app-military-selection',
  templateUrl: './military-selection.component.html',
  styleUrls: ['./military-selection.component.scss'],
})
export class MilitarySelectionComponent implements OnInit, OnDestroy {
  backRoute = AppRoutes.MILITARY_CONFIRMATION;
  codeDone = true;
  currentTrip: Trip = null;

  paxsAndSegmentDetails: SegmentDetailAndPax[] = null;
  militaryTravelType = MILITARY_TRAVEL_TYPE;

  isAllOfficialOrdersSelected = false;
  isAllTravelLeisureSelected = false;

  flightStatus$: Observable<FlightStatus>;
  unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private el: ElementRef,
    private accessibilityService: AccessibilityService,
    private store: Store<AppState>,
    private changeRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.store
      .pipe(
        select(selectCurrentTrip),
        filter((trip) => this.validateTrip(trip)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((trip) => {
        this.currentTrip = trip;
        this.paxsAndSegmentDetails = this.currentTrip.getSegmentDetailsAndPaxSelected();
        this.updateACAAElements();
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  validateTrip(trip: Trip) {
    if (trip && trip.activeSegment) {
      return true;
    }
    return false;
  }

  selectAllOfficialOrders() {
    if (!this.isAllOfficialOrdersSelected) {
      this.toggleSelectAll(true, false);
      const allPaxMilitaryOfficialOrders = this.paxsAndSegmentDetails.map((segmentDetailAndPax) => {
        return {
          selectedMilitaryTravelType: MILITARY_TRAVEL_TYPE.OFFICIAL_ORDERS,
          segmentFlight: segmentDetailAndPax.segmentDetail.flightDetails[0],
        };
      });

      this.store.dispatch(new SegmentMilitaryPaxTravelType(allPaxMilitaryOfficialOrders));
    } else {
      this.selectAllNone();
    }
  }

  selectAllLeisureTravel() {
    if (!this.isAllTravelLeisureSelected) {
      this.toggleSelectAll(false, true);
      const allPaxMilitaryLeisureTravel = this.paxsAndSegmentDetails.map((segmentDetailAndPax) => {
        return {
          selectedMilitaryTravelType: MILITARY_TRAVEL_TYPE.LEISURE_TRAVEL,
          segmentFlight: segmentDetailAndPax.segmentDetail.flightDetails[0],
        };
      });

      this.store.dispatch(new SegmentMilitaryPaxTravelType(allPaxMilitaryLeisureTravel));
    } else {
      this.selectAllNone();
    }
  }

  selectAllNone() {
    const allNone = this.paxsAndSegmentDetails.map((segmentDetailAndPax) => {
      return {
        selectedMilitaryTravelType: MILITARY_TRAVEL_TYPE.NONE,
        segmentFlight: segmentDetailAndPax.segmentDetail.flightDetails[0],
      };
    });
    this.toggleSelectAll(false);
    this.store.dispatch(new SegmentMilitaryPaxTravelType(allNone));
  }

  changeMilitaryTravelType(event, segmentFlight: SegmentFlight, selectedMilitaryTravelType: string) {
    event.cancelBubble = true;

    if (segmentFlight.militaryTravelType !== selectedMilitaryTravelType) {
      this.toggleSelectAll();
      this.store.dispatch(
        new SegmentMilitaryPaxTravelType([
          {
            selectedMilitaryTravelType: selectedMilitaryTravelType,
            segmentFlight,
          },
        ])
      );
    }
  }

  toggleSelectAll(isAllOfficialOrdersSelected = false, isAllTravelLeisureSelected = false) {
    if (!isAllOfficialOrdersSelected && !isAllTravelLeisureSelected) {
      this.isAllOfficialOrdersSelected = isAllOfficialOrdersSelected;
      this.isAllTravelLeisureSelected = isAllTravelLeisureSelected;
    } else {
      this.isAllOfficialOrdersSelected = isAllOfficialOrdersSelected;
      this.isAllTravelLeisureSelected = !this.isAllOfficialOrdersSelected;
    }
  }

  isSelected(segmentDetail: SegmentDetail, expectedMilitaryTravelType: string) {
    return segmentDetail.flightDetails[0].militaryTravelType === expectedMilitaryTravelType;
  }

  navigateTo() {
    const navigateBackToBags = this.paxsAndSegmentDetails.every((segment) => {
      return segment.segmentDetail.flightDetails.every((segmentFlight, idx) => {
        return segmentFlight.militaryTravelType === MILITARY_TRAVEL_TYPE.NONE;
      });
    });

    if (navigateBackToBags) {
      this.router.navigateByUrl(AppRoutes.BAGS);
    } else {
      this.store.dispatch(new SetMilitaryTravelTypeRequested());
    }
  }

  onGridScrolled(): void {
    this.updateACAAElements();
  }

  updateACAAElements() {
    this.changeRef.detectChanges();
    let elements = [...this.el.nativeElement.querySelectorAll('[tabindex]')];

    this.accessibilityService.addPageElementsToRead({
      elements: elements,
      position: 2,
      replace: true,
    });
  }
}
