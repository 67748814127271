import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { LoadingService } from '~app/services/ui/loading.service';
import { accessibilityJson, bagTagsPrinted } from '~app/services/emitters/session-event-emitters';
import { AppState, DisplayFlightInfoForKioskOrigin, FlightStatus, selectCurrentTripFlightStatus } from '~app/state';
import { HaCussService } from '~app/services/ha-cuss/ha-cuss.service';
import { Logging } from '~app/services/logging/logging.service';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { takeUntil } from 'rxjs/operators';
import { ConfigService } from '~app/services/api/config/config.service';
import { BagTagTypes, MachineConfig } from '~app/services/api/config/config';

@Component({
  selector: 'app-bags-printed',
  templateUrl: './bags-printed.component.html',
  styleUrls: ['./bags-printed.component.scss'],
})
export class BagsPrintedConfirmationComponent implements OnInit, OnDestroy, AfterViewInit {
  unsubscribe$ = new Subject<void>();
  flightStatus$: Observable<FlightStatus>;
  public bagTagType: string;
  public linerLessAlttext = accessibilityJson.linerlessBagTags;
  public linedAltText = accessibilityJson.linedBagTags;
  public lined = BagTagTypes.LINED;
  public linerless = BagTagTypes.LINERLESS;
  private configSubscription: Subscription;

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private haCussService: HaCussService,
    public loadingService: LoadingService,
    protected logging: Logging,
    public accessibilityService: AccessibilityService,
    private configService: ConfigService
  ) {
    this.flightStatus$ = this.store.pipe(select(selectCurrentTripFlightStatus));
  }

  ngOnInit() {
    this.store.dispatch(new DisplayFlightInfoForKioskOrigin());
    this.configSubscription = this.configService.config
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((config: MachineConfig) => {
        this.bagTagType = config.bagTagType;
      });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy() {
    bagTagsPrinted.next(false);

    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.haCussService.enableLookupDevices();
  }
}
