import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-bags-header',
  templateUrl: './bags-header.component.html',
  styleUrls: ['./bags-header.component.scss'],
})
export class BagsHeaderComponent implements OnInit, AfterViewInit {
  unsubscribe$ = new Subject<void>();
  @Output() messageEvent = new EventEmitter<Array<any>>();
  @Input() isOA: boolean;

  constructor(private el: ElementRef, private accessibilityService: AccessibilityService) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.accessibilityService.isModalActive.pipe(takeUntil(this.unsubscribe$)).subscribe((isModalActive) => {
      if (!isModalActive) {
        this.messageEvent.emit(this.getAcaaElements());
      }
    });
  }

  getAcaaElements() {
    return this.el.nativeElement.querySelectorAll('[tabindex]');
  }
}
