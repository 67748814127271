// AUTO-GENERATED -- do not edit this file!
// The components are defined in this module based on the subdirectories of /src/app/components
// Use app-components.shared.module.ts to modify the imports, etc of this module.
// tslint:disable

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@hawaiianair/core';
import { AppSharedModule } from '~shared/app.shared.module';
import { AboutBagsComponent } from './routes/about-bags/about-bags.component';
import { BagsComponent } from './routes/bags/bags.component';
import { BagsDetailsComponent } from './routes/bags/bags-details/bags-details.component';
import { BagsDetailsOAComponent } from './routes/bags/bags-details-oa/bags-details-oa.component';
import { BagsHeaderComponent } from './routes/bags/bags-header/bags-header.component';
import { BagsNoticeComponent } from './routes/bags/bags-notice/bags-notice.component';
import { BagsPrintedConfirmationComponent } from './routes/confirmation/bags-printed/bags-printed.component';
import { BagsServicesComponent } from './routes/bags/bags-services/bags-services.component';
import { ByAirportCodeComponent } from './routes/locate-reservation/by-airport-code/by-airport-code.component';
import { ByBarcodeComponent } from './routes/locate-reservation/by-barcode/by-barcode.component';
import { ByBirthdayComponent } from './routes/locate-reservation/by-birthday/by-birthday.component';
import { ByConfirmationCodeComponent } from './routes/locate-reservation/by-confirmation-code/by-confirmation-code.component';
import { ByDestinationComponent } from './routes/locate-reservation/by-destination/by-destination.component';
import { ByETicketComponent } from './routes/locate-reservation/by-e-ticket/by-e-ticket.component';
import { ByFlightNumberComponent } from './routes/locate-reservation/by-flight-number/by-flight-number.component';
import { ByHawaiianMilesComponent } from './routes/locate-reservation/by-hawaiian-miles/by-hawaiian-miles.component';
import { ByNameComponent } from './routes/locate-reservation/by-name/by-name.component';
import { ChangeLanguageComponent } from './routes/change-language/change-language.component';
import { CheckedInOptionsComponent } from './routes/checked-in-options/checked-in-options.component';
import { ConfirmationAgricultureWarningComponent } from './routes/confirmation/confirmation-agriculture-warning/confirmation-agriculture-warning.component';
import { ConfirmationComponent } from './routes/confirmation/confirmation.component';
import { ConfirmationFlightStatusComponent } from './routes/confirmation/confirmation-flight-status/confirmation-flight-status.component';
import { ErrorScreenComponent } from './routes/error-screen/error-screen.component';
import { FlightGridComponent } from './routes/seat-preference/flight-grid/flight-grid.component';
import { HawaiianMilesEntryComponent } from './routes/pax-edit/hawaiian-miles-entry/hawaiian-miles-entry.component';
import { HazmatAgricultureWarningComponent } from './routes/hazmat/hazmat-agriculture-warning/hazmat-agriculture-warning.component';
import { HazmatComponent } from './routes/hazmat/hazmat.component';
import { ItineraryComponent } from './routes/itinerary/itinerary.component';
import { ItineraryFlightDetailsComponent } from './routes/itinerary/itinerary-flight-details/itinerary-flight-details.component';
import { ItineraryPassengerDetailsComponent } from './routes/itinerary/itinerary-passenger-details/itinerary-passenger-details.component';
import { ItineraryServicesComponent } from './routes/itinerary/itinerary-services/itinerary-services.component';
import { KtnEntryComponent } from './routes/pax-edit/ktn-entry/ktn-entry.component';
import { LocateReservationComponent } from './routes/locate-reservation/locate-reservation.component';
import { LocateReservationTabsComponent } from './routes/locate-reservation/locate-reservation-tabs/locate-reservation-tabs.component';
import { LocateReservationTitleComponent } from './routes/locate-reservation/locate-reservation-title/locate-reservation-title.component';
import { MenuComponent } from './routes/menu/menu.component';
import { MilitaryComponent } from './routes/military/military.component';
import { MilitaryConfirmationComponent } from './routes/military/military-confirmation/military-confirmation.component';
import { MilitaryNoMatchComponent } from './routes/military/military-no-match/military-no-match.component';
import { MilitarySelectionComponent } from './routes/military/military-selection/military-selection.component';
import { MilitaryThankYouComponent } from './routes/military/military-thank-you/military-thank-you.component';
import { MoreSegmentsCheckinComponent } from './routes/more-segments-checkin/more-segments-checkin.component';
import { NoSelfTaggingComponent } from './routes/hazmat/no-self-tagging/no-self-tagging.component';
import { NonPrintedBagsConfirmationComponent } from './routes/confirmation/non-printed-bags/non-printed-bags.component';
import { PaxCartTableComponent } from './routes/payment/pax-cart-table/pax-cart-table.component';
import { PaxEditMainComponent } from './routes/pax-edit/pax-edit-main/pax-edit-main.component';
import { PaymentComponent } from './routes/payment/payment.component';
import { PrintDocumentsComponent } from './routes/print-documents/print-documents.component';
import { ProhibitedComponent } from './routes/hazmat/prohibited/prohibited.component';
import { RedressEntryComponent } from './routes/pax-edit/redress-entry/redress-entry.component';
import { RestrictedComponent } from './routes/hazmat/restricted/restricted.component';
import { SeatPreferenceComponent } from './routes/seat-preference/seat-preference.component';
import { SelectPaxComponent } from './routes/pax-edit/select-pax/select-pax.component';
import { SelfTaggingComponent } from './routes/hazmat/self-tagging/self-tagging.component';
import { SpecialItemsComponent } from './routes/special-items/special-items.component';
import { SpecialItemsDetailsComponent } from './routes/special-items/special-items-details/special-items-details.component';
import { SpecialItemsHeaderComponent } from './routes/special-items/special-items-header/special-items-header.component';
import { SpecialItemsServicesComponent } from './routes/special-items/special-items-services/special-items-services.component';
import { SplashScreenComponent } from './routes/splash-screen/splash-screen.component';
import { StandbyConfirmationComponent } from './routes/standby-confirmation/standby-confirmation.component';
import { TotalTableComponent } from './routes/payment/total-table/total-table.component';

@NgModule({
  imports: [
    AppSharedModule,
    CoreModule.withComponents({
      'AboutBags': AboutBagsComponent,
      'Bags': BagsComponent,
      'BagsDetails': BagsDetailsComponent,
      'BagsDetailsOA': BagsDetailsOAComponent,
      'BagsHeader': BagsHeaderComponent,
      'BagsNotice': BagsNoticeComponent,
      'BagsPrintedConfirmation': BagsPrintedConfirmationComponent,
      'BagsServices': BagsServicesComponent,
      'ByAirportCode': ByAirportCodeComponent,
      'ByBarcode': ByBarcodeComponent,
      'ByBirthday': ByBirthdayComponent,
      'ByConfirmationCode': ByConfirmationCodeComponent,
      'ByDestination': ByDestinationComponent,
      'ByETicket': ByETicketComponent,
      'ByFlightNumber': ByFlightNumberComponent,
      'ByHawaiianMiles': ByHawaiianMilesComponent,
      'ByName': ByNameComponent,
      'ChangeLanguage': ChangeLanguageComponent,
      'CheckedInOptions': CheckedInOptionsComponent,
      'Confirmation': ConfirmationComponent,
      'ConfirmationAgricultureWarning': ConfirmationAgricultureWarningComponent,
      'ConfirmationFlightStatus': ConfirmationFlightStatusComponent,
      'ErrorScreen': ErrorScreenComponent,
      'FlightGrid': FlightGridComponent,
      'HawaiianMilesEntry': HawaiianMilesEntryComponent,
      'Hazmat': HazmatComponent,
      'HazmatAgricultureWarning': HazmatAgricultureWarningComponent,
      'Itinerary': ItineraryComponent,
      'ItineraryFlightDetails': ItineraryFlightDetailsComponent,
      'ItineraryPassengerDetails': ItineraryPassengerDetailsComponent,
      'ItineraryServices': ItineraryServicesComponent,
      'KtnEntry': KtnEntryComponent,
      'LocateReservation': LocateReservationComponent,
      'LocateReservationTabs': LocateReservationTabsComponent,
      'LocateReservationTitle': LocateReservationTitleComponent,
      'Menu': MenuComponent,
      'Military': MilitaryComponent,
      'MilitaryConfirmation': MilitaryConfirmationComponent,
      'MilitaryNoMatch': MilitaryNoMatchComponent,
      'MilitarySelection': MilitarySelectionComponent,
      'MilitaryThankYou': MilitaryThankYouComponent,
      'MoreSegmentsCheckin': MoreSegmentsCheckinComponent,
      'NoSelfTagging': NoSelfTaggingComponent,
      'NonPrintedBagsConfirmation': NonPrintedBagsConfirmationComponent,
      'PaxCartTable': PaxCartTableComponent,
      'PaxEditMain': PaxEditMainComponent,
      'Payment': PaymentComponent,
      'PrintDocuments': PrintDocumentsComponent,
      'Prohibited': ProhibitedComponent,
      'RedressEntry': RedressEntryComponent,
      'Restricted': RestrictedComponent,
      'SeatPreference': SeatPreferenceComponent,
      'SelectPax': SelectPaxComponent,
      'SelfTagging': SelfTaggingComponent,
      'SpecialItems': SpecialItemsComponent,
      'SpecialItemsDetails': SpecialItemsDetailsComponent,
      'SpecialItemsHeader': SpecialItemsHeaderComponent,
      'SpecialItemsServices': SpecialItemsServicesComponent,
      'SplashScreen': SplashScreenComponent,
      'StandbyConfirmation': StandbyConfirmationComponent,
      'TotalTable': TotalTableComponent
    }, {
      
    }),
    RouterModule.forChild([ {
        path: 'SeatsModule',
        loadChildren: () => import('./routes/seats/seats.module').then(m => m.SeatsModule)
      }
    ]),
  ],
  exports: [
    AppSharedModule,
  ],
  declarations: [
    AboutBagsComponent,
    BagsComponent,
    BagsDetailsComponent,
    BagsDetailsOAComponent,
    BagsHeaderComponent,
    BagsNoticeComponent,
    BagsPrintedConfirmationComponent,
    BagsServicesComponent,
    ByAirportCodeComponent,
    ByBarcodeComponent,
    ByBirthdayComponent,
    ByConfirmationCodeComponent,
    ByDestinationComponent,
    ByETicketComponent,
    ByFlightNumberComponent,
    ByHawaiianMilesComponent,
    ByNameComponent,
    ChangeLanguageComponent,
    CheckedInOptionsComponent,
    ConfirmationAgricultureWarningComponent,
    ConfirmationComponent,
    ConfirmationFlightStatusComponent,
    ErrorScreenComponent,
    FlightGridComponent,
    HawaiianMilesEntryComponent,
    HazmatAgricultureWarningComponent,
    HazmatComponent,
    ItineraryComponent,
    ItineraryFlightDetailsComponent,
    ItineraryPassengerDetailsComponent,
    ItineraryServicesComponent,
    KtnEntryComponent,
    LocateReservationComponent,
    LocateReservationTabsComponent,
    LocateReservationTitleComponent,
    MenuComponent,
    MilitaryComponent,
    MilitaryConfirmationComponent,
    MilitaryNoMatchComponent,
    MilitarySelectionComponent,
    MilitaryThankYouComponent,
    MoreSegmentsCheckinComponent,
    NoSelfTaggingComponent,
    NonPrintedBagsConfirmationComponent,
    PaxCartTableComponent,
    PaxEditMainComponent,
    PaymentComponent,
    PrintDocumentsComponent,
    ProhibitedComponent,
    RedressEntryComponent,
    RestrictedComponent,
    SeatPreferenceComponent,
    SelectPaxComponent,
    SelfTaggingComponent,
    SpecialItemsComponent,
    SpecialItemsDetailsComponent,
    SpecialItemsHeaderComponent,
    SpecialItemsServicesComponent,
    SplashScreenComponent,
    StandbyConfirmationComponent,
    TotalTableComponent
  ],
})
export class AppComponentsModule { }
