import { Component, Input, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { KeyboardState } from '../../../state/keyboard/keyboard.reducer';
import { Keyboard } from '../../../state/keyboard/keyboard.model';
import { GoBack, ResetContent } from '../../../state/keyboard/keyboard.action';

@Component({
  selector: 'app-clear',
  templateUrl: './clear.component.html',
  styleUrls: ['./clear.component.scss'],
})
export class ClearComponent implements OnDestroy {
  public keyboardSubscribe: Subscription;
  keyboard$: Observable<Keyboard>;

  public utilsValue = false;
  public entry = '';
  public goBackValue;
  public manageActiveValue;

  constructor(private store: Store<KeyboardState>) {
    this.keyboard$ = store.pipe(select('keyboard'));
    this.keyboardSubscribe = this.keyboard$.subscribe((value) => {
      this.entry = value.content;
    });
  }

  @Input() set utils(value) {
    this.utilsValue = value;
  }

  @Input() set goBack(value) {
    this.goBackValue = value;
  }

  @Input() set manageActive(value) {
    this.manageActiveValue = value;
  }

  clear() {
    if (this.entry !== undefined && this.entry.length < 1) {
      return false;
    }
    this.store.dispatch(new ResetContent());
    if (this.goBackValue) {
      this.store.dispatch(new GoBack());
    }
  }

  ngOnDestroy() {
    if (this.keyboardSubscribe) {
      this.keyboardSubscribe.unsubscribe();
    }
  }
}
