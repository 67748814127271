import { NgModule, ErrorHandler } from '@angular/core';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
// so we can format dates and numbers and things
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeZh from '@angular/common/locales/zh';
registerLocaleData(localeJa);
registerLocaleData(localeKo);
registerLocaleData(localeZh);
import { BrowserModule } from '@angular/platform-browser';
import { LoggingModule, LogLevel } from '@hawaiianair/logging';
import { CoreModule } from '@hawaiianair/core';
import { HACommonModule, APP_ENVIRONMENT } from '@hawaiianair/common';
import { AppComponent } from './app.component';
import { dynamicRoutes } from './app-dynamic.routes';
import { AppComponentsModule } from './components/app-components.module';
import { AppSharedModule } from '~shared/app.shared.module';
import { environment } from '~environments/environment';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { StateModule } from './state/state.module';
import { ApiModule } from './services/api/api.module';
import { LoadingService } from './services/ui/loading.service';
import { ErrorInterceptor } from './state/error/error-interceptor';
import { DeviceService } from './services/ha-cuss/device.service';
import { ConfigService } from './services/api/config/config.service';
import { API } from './services/api/api.service';
import { LibAngularPaymentModule } from 'lib-ha-angular-payment';
import { WINDOW_PROVIDERS } from './utils/window';
import { ApplinkService } from './services/hardware/applink.service';
import { GlobalErrorHandler } from './services/error/global-error-handler.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    // withServerTransition is needed to enable universal rendering
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    ...CoreModule.forRoot({
      options: { enableTracing: false },
      dynamicRoutes,
    }),
    AppSharedModule.forRoot(),
    AppComponentsModule,
    HACommonModule.forRoot(),
    LoggingModule.forRoot({
      logLevel: environment.logging.level ? LogLevel.Trace : LogLevel.Error,
      logToConsole: true,
      logToUrl: '',
    }),
    AppRoutingModule,
    FormsModule,
    StateModule,
    ApiModule,
    HttpClientModule,
    LibAngularPaymentModule.forRoot(environment),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    ApplinkService,
    LoadingService,
    DeviceService,
    API,
    ConfigService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    WINDOW_PROVIDERS,
    // IMPORTANT: you must set the base href with this token, not a <base> tag in the HTML.
    { provide: APP_BASE_HREF, useValue: '/' },

    // the framework will pull configuration values from this object
    { provide: APP_ENVIRONMENT, useValue: environment },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function ConfigLoader(configService: ConfigService) {
  return () => configService.getConfig();
}
