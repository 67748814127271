export interface Catalog {
  id: string;
  version: string;
  entries: PassengerCatalog[];
}

export enum CatalogIdTypes {
  Bags = 'Bags',
  Seats = 'MobileCheckInSeats',
}

export enum BagTypeId {
  Regular = '0GO',
  Allowances = '0DF',
}

export enum ProductType {
  RegularBag = 'regularBag',
  SpecialBag = 'specialBag',
}

interface PriceQuote {
  quantity: number | null;
  inventoryAvailable?: number | null;
  totalPrice: number | null;
}

export interface ProductAttribute {
  name: string;
  unit: string;
  value?: string;
  subAttributes?: ProductSubAttribute[];
}

export interface ProductSubAttribute {
  name: string;
  type: string;
  value: string;
}

export class Product {
  productId: string | null;
  reasonForIssuanceCode?: string | null;
  reasonForIssuanceSubCode?: string | null;
  commercialName?: string | null;
  group?: string | null;
  type: string | null;
  freeItemCount?: number | null;
  priceQuotes: PriceQuote[] | null;
  availability?: string | null;
  attributes?: ProductAttribute[];
  productLabel?: string;
  sizeLimitLabel?: string;
  weightLimitLabel?: string;
  icon?: string;
  maxQuantity?: number | null;

  hasAttributes() {
    return this.attributes.length > 0;
  }

  get weightLimit() {
    if (!this.hasAttributes()) {
      return 0;
    }

    const weightAttribute = this.attributes.find((attribute: ProductAttribute) => attribute.name === 'MaxWeight');
    if (!weightAttribute) {
      return 0;
    }

    return parseFloat(weightAttribute.value);
  }

  get lengthLimit() {
    if (!this.hasAttributes()) {
      return 0;
    }

    const maxDimensionsAttribute = this.attributes.find(
      (attribute: ProductAttribute) => attribute.name === 'MaxDimensions'
    );
    if (!maxDimensionsAttribute) {
      return 0;
    }

    if (maxDimensionsAttribute.subAttributes.length === 0) {
      return 0;
    }

    const lengthAttribute = maxDimensionsAttribute.subAttributes.find(
      (attribute: ProductSubAttribute) => attribute.name === 'Length'
    );
    if (!lengthAttribute) {
      return 0;
    }

    return parseFloat(lengthAttribute.value);
  }
}

export class PassengerCatalog {
  flightId?: string | null;
  passengerId?: string | null;
  segmentId?: string | null;
  products: Product[] | null = [];

  static deserializeFromJson(json): PassengerCatalog {
    return Object.assign(new PassengerCatalog(), json);
  }
}
