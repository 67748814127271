import { Action } from '@ngrx/store';
import { Passenger } from '..';

export enum PaxEditActionTypes {
  PaxEditRequested = '[PaxEdit] Passenger editing requested',
  PaxSelectedForEdit = '[PaxEdit] Passenger selected for edit',
  PaxSelectedChanged = '[PaxEdit] Passenger selected changed',
  PaxEditInfoUpdated = '[PaxEdit] Passenger info updated',
  PaxEditConfirmed = '[PaxEdit] Passenger info update confirmed',
}

export class PaxEditRequested implements Action {
  readonly type = PaxEditActionTypes.PaxEditRequested;
}

export class PaxSelectedForEdit implements Action {
  readonly type = PaxEditActionTypes.PaxSelectedForEdit;
  constructor(public payload: { passengerId: string; addHawaiianMiles?: boolean }) {}
}

export class PaxSelectedChanged implements Action {
  readonly type = PaxEditActionTypes.PaxSelectedChanged;
  constructor(public payload: { passenger: Passenger }) {}
}

export class PaxEditInfoUpdated implements Action {
  readonly type = PaxEditActionTypes.PaxEditInfoUpdated;
  constructor(public payload: { ktn?: string; redress?: string; hawaiianMiles?: string }) {}
}

export class PaxEditConfirmed implements Action {
  readonly type = PaxEditActionTypes.PaxEditConfirmed;
  constructor(public payload: { addHawaiianMiles?: boolean }) {}
}

export type PaxEditActions =
  | PaxEditRequested
  | PaxSelectedForEdit
  | PaxSelectedChanged
  | PaxEditInfoUpdated
  | PaxEditConfirmed;
