import { Action } from '@ngrx/store';
import { maskPCI } from '../../../../shared/embross/helper';
import { deviceIds } from '../../../../assets/js/embross-device-manager';

export enum BarcodeActionTypes {
  BarcodeEnable = '[Barcode] Device Enabled',
  BarcodeDisable = '[Barcode] Device Disabled',
  BarcodeInserted = '[Barcode] Inserted',
  BarcodeRemoved = '[Barcode] Removed',
  BarcodeReadInternal = '[Barcode] Read Internal',
  BarcodeDamaged = '[Barcode] Damaged',
  BarcodeStatusChange = '[Barcode] Status Change',
  BarcodeStatusIsOK = '[Barcode] Status is Ok',
}

export class BarcodeEnable implements Action {
  readonly type = BarcodeActionTypes.BarcodeEnable;

  constructor(readonly payload: string) {}
}

export class BarcodeDisable implements Action {
  readonly type = BarcodeActionTypes.BarcodeDisable;

  constructor(readonly payload: string) {}
}

export class BarcodeInserted implements Action {
  readonly type = BarcodeActionTypes.BarcodeInserted;
  payload: { event_id: string; barcodeInserted: string };

  constructor(eventId: string, barcodeInserted: string) {
    this.payload = { event_id: eventId, barcodeInserted };
  }
}

export class BarcodeRemoved implements Action {
  readonly type = BarcodeActionTypes.BarcodeRemoved;
  payload: { event_id: string; barcodeRemoved: string };

  constructor(eventId: string, barcodeRemoved: string) {
    this.payload = { event_id: eventId, barcodeRemoved };
  }
}

export class BarcodeReadInternal implements Action {
  readonly type = BarcodeActionTypes.BarcodeReadInternal;
  payload: { event_id: string; barcodeData: string };

  constructor(eventId: string, barcodeData: string) {
    barcodeData = maskPCI(barcodeData, deviceIds.BARCODE_READER);
    this.payload = { event_id: eventId, barcodeData };
  }
}

export class BarcodeDamaged implements Action {
  readonly type = BarcodeActionTypes.BarcodeDamaged;
  payload: { event_id: string; barcodeDamaged: string };

  constructor(eventId: string, barcodeDamaged: string) {
    this.payload = { event_id: eventId, barcodeDamaged };
  }
}

export class BarcodeStatusChange implements Action {
  readonly type = BarcodeActionTypes.BarcodeStatusChange;
  payload: { event_id: string; barcodeStatusChange: [number, boolean] };

  constructor(eventId: string, barcodeStatusChange: [number, boolean]) {
    this.payload = { event_id: eventId, barcodeStatusChange };
  }
}

export class BarcodeStatusIsOK implements Action {
  readonly type = BarcodeActionTypes.BarcodeStatusIsOK;
  payload: { event_id: string; barcodeStatusIsOK: string };

  constructor(eventId: string, barcodeStatusIsOK: string) {
    this.payload = { event_id: eventId, barcodeStatusIsOK };
  }
}

export type BarcodeActions =
  | BarcodeEnable
  | BarcodeDisable
  | BarcodeInserted
  | BarcodeRemoved
  | BarcodeReadInternal
  | BarcodeDamaged
  | BarcodeStatusChange
  | BarcodeStatusIsOK;
