// Map object doesn't have serialization for devtools so this fix allows it to serialize and display it on redux Devtools.
export function fixDevToolsMapPrototype() {
  if (process.env.NODE_ENV !== 'production') {
    const map: any = Map.prototype;
    map.toJSON = function() {
      const obj = {};
      this.forEach((value, key) => (obj[key] = value));
      return obj;
    };
  }
}
