import { FlightStatus } from './flight-status.model';
import { FlightStatusActions, FlightStatusActionTypes } from './flight-status.actions';

export interface FlightStatusState {
  flightStatuses: Map<string, FlightStatus>;
}

export function flightStatusReducer(
  state = { flightStatuses: new Map<string, FlightStatus>() },
  action: FlightStatusActions
): FlightStatusState {
  switch (action.type) {
    case FlightStatusActionTypes.FlightStatusLoaded: {
      const flightStatus = action.payload.flightStatus as FlightStatus;

      const flightStatuses = new Map<string, FlightStatus>(state.flightStatuses);
      flightStatuses.set(flightStatus.flightId, flightStatus);

      return {
        ...state,
        flightStatuses,
      };
    }

    default: {
      return state;
    }
  }
}
