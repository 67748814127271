import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  AppState,
  Cart,
  FlightStatus,
  PaxSelectedForEdit,
  SegmentPaxSelectedForCheckin,
  selectCart,
  selectCurrentTrip,
  selectCurrentTripFlightStatus,
  Trip,
} from '../../../state';
import { activeSession } from '~services/emitters/session-event-emitters';
import { Logging } from '~services/logging/logging.service';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-itinerary',
  templateUrl: './itinerary.component.html',
  styleUrls: ['./itinerary.component.scss'],
})
export class ItineraryComponent implements OnInit {
  trip$: Observable<Trip>;
  flightStatus$: Observable<FlightStatus>;
  cart$: Observable<Cart>;

  private element;

  constructor(
    private store: Store<AppState>,
    private logging: Logging,
    private accessibilityService: AccessibilityService,
    private changeRef: ChangeDetectorRef,
    private el: ElementRef
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.logging.infoUiItineraryPageDisplayed(0);
    this.trip$ = this.store.pipe(select(selectCurrentTrip)).pipe(filter((trip) => this.validateTrip(trip)));

    this.flightStatus$ = this.store
      .pipe(select(selectCurrentTripFlightStatus))
      .pipe(filter((flightStatus) => flightStatus !== undefined));

    this.cart$ = this.store.pipe(select(selectCart)).pipe(filter((cart) => cart !== undefined));

    activeSession.emit(true);
  }

  validateTrip(trip: Trip) {
    if (!trip || !trip.activeSegment) {
      return false;
    }

    return true;
  }

  onPassengerCheckInSelectionEv($event) {
    const { segmentDetail } = $event;
    this.store.dispatch(new SegmentPaxSelectedForCheckin({ segmentDetail }));
  }

  onEditPassenger($event) {
    const { passengerId } = $event;
    this.store.dispatch(new PaxSelectedForEdit({ passengerId }));
  }

  onGridRendered() {
    this.setElementsToRead({ startIndex: 0 });
  }

  onGridScrolled() {
    this.setElementsToRead({ startIndex: 4 });
    this.accessibilityService.waitAndReadCurrentElement();
  }

  private setElementsToRead({ startIndex }) {
    this.changeRef.detectChanges();

    const headerElementsToRead = this.getHeaderElementsToRead();
    const detailElementsToRead = this.getDetailElementsToRead();
    const buttonElementsToRead = this.getButtonElementsToRead();
    const startOverElementToRead = this.getStartOverElementToRead();

    const allElements = [
      ...headerElementsToRead,
      ...detailElementsToRead,
      ...buttonElementsToRead,
      ...startOverElementToRead,
    ];

    this.accessibilityService.setElementsToRead(allElements, startIndex, false);
  }

  private getHeaderElementsToRead() {
    const itineraryDetailElements = Array.from(this.element.querySelectorAll('.itinerary-header [tabindex]'));
    const departureTimeElements = Array.from(this.element.querySelectorAll('.departure-time [tabindex]'));

    return [...itineraryDetailElements, ...departureTimeElements];
  }

  private getDetailElementsToRead() {
    return Array.from(this.element.querySelectorAll('.itinerary-details [tabindex]'));
  }

  private getButtonElementsToRead() {
    return Array.from(this.element.querySelectorAll('.itinerary-services-container [tabindex]'));
  }

  private getStartOverElementToRead() {
    return Array.from(this.element.querySelectorAll('#start-over-exit-button'));
  }
}
