import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { from } from 'rxjs';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppState, DeleteCart, selectCurrentTrip } from '../index';
import {
  CartActionTypes,
  CartUpdated,
  EmptyCheckout,
  EmptyCheckoutSuccessful,
  PaxSelectedChangedForSpecialItems,
  PaxSelectedForSpecialItems,
} from './cart.actions';
import { selectCart } from './cart.selector';
import { CartService } from '~app/services/api/cart/cart.service';
import { Router } from '@angular/router';
import { Cart, Checkout } from './cart.model';
import { Logging } from '../../services/logging/logging.service';
import { ApplinkService } from '../../services/hardware/applink.service';
import { AppRoutes } from '~app/app-routes';
import { DocumentService } from '../../services/api/document/document.service';
import { ErrorActionTypes, SetTimeoutError } from '~app/state/error/error.action';

@Injectable()
export class CartEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    public cartService: CartService,
    private store: Store<AppState>,
    private logging: Logging,
    private applinkService: ApplinkService,
    private documentService: DocumentService
  ) {}

  @Effect({ dispatch: false })
  updateCartAndGoToNextStep$ = this.actions$.pipe(
    ofType(CartActionTypes.UpdateCartAndGoToNextStep),
    withLatestFrom(this.store.select(selectCart), this.store.select(selectCurrentTrip)),
    switchMap(([_, cart, trip]) => from(this.cartService.updateCart(cart, trip.id))),
    map((response) => {
      if (response instanceof SetTimeoutError) {
        this.store.dispatch(new SetTimeoutError());
        return;
      }

      if (!response || !response.results) {
        return false;
      }
      const updatedCart: Cart = Cart.deserializeFromJson(response.results[0]);

      this.store.dispatch(new CartUpdated({ cart: updatedCart }));

      if (updatedCart && updatedCart.grandTotal > 0) {
        this.router.navigate([AppRoutes.PAYMENT]);
      } else {
        this.store.dispatch(new EmptyCheckout());
        this.actions$.pipe(ofType(CartActionTypes.EmptyCheckoutSuccessful)).subscribe(() => {
          this.router.navigate([AppRoutes.HAZMAT_PROHIBITED]);
        });
      }

      return updatedCart;
    })
  );

  @Effect({ dispatch: false })
  emptyCheckout$ = this.actions$.pipe(
    ofType(CartActionTypes.EmptyCheckout),
    withLatestFrom(this.store.select(selectCart), this.store.select(selectCurrentTrip)),
    switchMap(([_, cart, trip]) =>
      from(
        this.cartService.checkoutCart(
          this.getEmptyCheckout(cart.confirmationCode),
          cart.id,
          trip.id,
          this.applinkService.getKioskId()
        )
      )
    ),
    map(() => {
      // We need to delete the cart even if its a zero dollar cart
      this.logging.infoApiCheckoutSuccessful();
      this.store.dispatch(new EmptyCheckoutSuccessful());
      this.store.dispatch(new DeleteCart());
      return true;
    })
  );

  @Effect({ dispatch: false })
  deleteCart$ = this.actions$.pipe(
    ofType(CartActionTypes.DeleteCart),
    withLatestFrom(this.store.select(selectCart), this.store.select(selectCurrentTrip)),
    map(([_, cart, trip]) => {
      if (cart.id) {
        from(this.cartService.deleteCart(trip, cart)).pipe(
          map((cartResult) => {
            return true;
            /*For now we can ignore the result since it will be an empty body coming back*/
          })
        );
      }
    })
  );

  @Effect({ dispatch: false })
  updateCartRequested$ = this.actions$.pipe(
    ofType(CartActionTypes.UpdateCartRequested),
    withLatestFrom(this.store.select(selectCart), this.store.select(selectCurrentTrip)),
    switchMap(([_, cart, trip]) => {
      return from(this.cartService.updateCart(cart, trip.id)).pipe(
        map((response) => {
          if (response instanceof SetTimeoutError) {
            this.store.dispatch(new SetTimeoutError());
            return;
          }
          if (!response || !response.results) {
            return false;
          }

          const updatedCart: Cart = Cart.deserializeFromJson(response.results[0]);

          // api does not return items yet;
          if (updatedCart.items && updatedCart.items.length === 0) {
            updatedCart.items = cart.items;
          }

          if (cart && cart.paxSelectedForSpecialItems) {
            updatedCart.paxSelectedForSpecialItems = cart.paxSelectedForSpecialItems;
          }

          this.store.dispatch(new CartUpdated({ cart: updatedCart }));
          return updatedCart;
        })
      );
    })
  );

  @Effect({ dispatch: false })
  selectPaxForSpecialItems$ = this.actions$.pipe(
    ofType(CartActionTypes.PaxSelectedForSpecialItems),
    withLatestFrom(this.store.select(selectCurrentTrip)),
    map(([action, trip]) => {
      const { passengerId } = (action as PaxSelectedForSpecialItems).payload;

      const passenger = trip.passengers.filter((pax) => pax.id === passengerId)[0];
      this.store.dispatch(new PaxSelectedChangedForSpecialItems({ passenger }));
      this.router.navigate([AppRoutes.SPECIAL_ITEMS]);
    })
  );

  @Effect({ dispatch: false })
  seatChangeError$ = this.actions$.pipe(
    ofType(ErrorActionTypes.SetSeatChangeError),
    map(() => this.router.navigate([AppRoutes.SEAT_CHANGE_ERROR]))
  );

  getEmptyCheckout(confirmationCode): Checkout {
    const pointOfSale = this.documentService.getPointOfSale();
    return {
      checkoutTransactionId: '001',
      confirmationCode,
      pointOfSale,
      payments: [],
    };
  }
}
