import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppState, FlightStatusRequested } from '../index';
import { FlightService } from '../../services/api/flight/flight.service';
import { FlightStatusActionTypes, FlightStatusLoaded } from './flight-status.actions';
import { FlightStatus } from './flight-status.model';

@Injectable()
export class FlightStatusEffects {
  constructor(private actions$: Actions, private store: Store<AppState>, private flightService: FlightService) {}

  @Effect({ dispatch: false })
  loadFlightStatus$ = this.actions$.pipe(
    ofType(FlightStatusActionTypes.FlightStatusRequested),
    map((action: FlightStatusRequested) => action.payload.flightId),
    switchMap((flightId) => {
      return from(this.flightService.getFlightStatusDataBasedId(flightId));
    }),
    map((flightData) => {
      if (!flightData || !flightData.results || flightData.results.length === 0) {
        return;
      }

      const flightStatus = FlightStatus.deserializeFromJson(flightData.results[0]);
      this.store.dispatch(new FlightStatusLoaded({ flightStatus }));
    })
  );
}
