import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppState, BagsContinueButtonClicked, EmptyCheckout, selectCart, selectCurrentTrip, Trip } from '~app/state';
import { select, Store } from '@ngrx/store';
import { CartActionTypes, UpdateCartRequested } from '~app/state/cart/cart.actions';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { Cart } from '~app/state/cart/cart.model';
import { takeUntil } from 'rxjs/operators';
import { CartService } from '~app/services/api/cart/cart.service';
import { AppRoutes } from '~app/app-routes';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { ConfigService } from '~app/services/api/config/config.service';
import { Logging } from '~app/services/logging/logging.service';
import { Actions, ofType } from '@ngrx/effects';
import { isEmpty } from '~embross/helper';

@Component({
  selector: 'app-bags-services',
  templateUrl: './bags-services.component.html',
  styleUrls: ['./bags-services.component.scss'],
})
export class BagsServicesComponent implements OnInit, OnDestroy {
  private cart$: Observable<Cart>;
  private trip$: Observable<Trip>;

  unsubscribe$ = new Subject<void>();
  AppRoutes = AppRoutes;

  public showModal = false;
  public isMilitaryModalOpen = false;
  public continueButtonClicked = false;
  public cart: Cart;
  public trip: Trip;
  public tripSubscription: Subscription;
  public tripId: string;

  private enableMilitaryBags: Boolean;
  @Input() totalOfBags: string;
  @Input() isOA: boolean;

  @ViewChild('militaryModal', { static: false }) set modalElementsToRead(element) {
    if (!element || !this.isMilitaryModalOpen) {
      return;
    }

    let elements = element.nativeElement.querySelectorAll('[tabindex]');
    this.accessibilityService.activateModalElementsToRead(elements, 'bags-service');
  }

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private cartService: CartService,
    private configService: ConfigService,
    private changeRef: ChangeDetectorRef,
    public accessibilityService: AccessibilityService,
    private logging: Logging,
    private actions$: Actions
  ) {}

  ngOnInit() {
    this.cart$ = this.store.pipe(select(selectCart));

    this.cart$.pipe(takeUntil(this.unsubscribe$)).subscribe((cart: Cart) => {
      this.cart = cart;
    });

    this.trip$ = this.store.pipe(select(selectCurrentTrip));

    this.tripSubscription = this.trip$.pipe(takeUntil(this.unsubscribe$)).subscribe((trip: Trip) => {
      this.tripId = trip.id;
      this.trip = trip;
    });

    this.configService.config.pipe(takeUntil(this.unsubscribe$)).subscribe((config) => {
      if (config) {
        this.enableMilitaryBags = config.militaryBags;
      }
    });

    this.actions$.pipe(takeUntil(this.unsubscribe$), ofType(CartActionTypes.CartUpdated)).subscribe((cartData: any) => {
      const { cart } = cartData.payload;
      // We only want to show the bags notice modal if we actually added a bag to the cart
      if (this.continueButtonClicked) {
        this.validateCartAndContinue(cart);
      }
      this.continueButtonClicked = false;
    });
  }

  private validateCartAndContinue(cart) {
    // We have to make sure that we have bags AND they aren't free bags
    if (this.arePaidBagsPresent(cart)) {
      this.showModal = true;
    } else {
      // Otherwise we need to make sure that if we have seats, do we need to ask for payment
      this.validatePaymentRequired(cart);
    }
  }

  private validatePaymentRequired(cart) {
    if (cart.grandTotal > 0) {
      this.router.navigate([AppRoutes.PAYMENT]);
    } else {
      this.store.dispatch(new EmptyCheckout());
      this.actions$
        .pipe(takeUntil(this.unsubscribe$), ofType(CartActionTypes.EmptyCheckoutSuccessful))
        .subscribe(() => {
          this.router.navigate([AppRoutes.HAZMAT_PROHIBITED]);
        });
    }
  }

  private arePaidBagsPresent(cart) {
    return cart && !isEmpty(cart) && cart.items.find((item) => item.catalogId === 'Bags' && parseFloat(item.price) > 0);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  militaryButtonClicked() {
    this.logging.infoUiMilitaryPersonnelBagsOptionSelected(0);
    this.enableMilitaryBags ? this.router.navigate([AppRoutes.MILITARY_CONFIRMATION]) : this.showMilitaryModal();
  }

  showMilitaryModal() {
    this.isMilitaryModalOpen = true;
  }

  dismissMilitaryModal(goToTheSplashScreen) {
    this.isMilitaryModalOpen = false;
    this.changeRef.detectChanges();
    this.accessibilityService.dismissModalElements(null, null, 'bags-service');

    if (goToTheSplashScreen) {
      this.logging.infoUiCheckinCancelled();
      this.router.navigateByUrl(AppRoutes.SPLASH_SCREEN);
    }
  }

  sendBags() {
    if (this.cart) {
      this.store.dispatch(new UpdateCartRequested());
    }
  }

  displayModal(displayModal) {
    this.showModal = displayModal;
  }

  continue() {
    this.continueButtonClicked = true;
    this.store.dispatch(new BagsContinueButtonClicked({ tripId: this.tripId }));
  }
}
