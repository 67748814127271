import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DeviceService } from '../ha-cuss/device.service';
import { activeSession } from '../emitters/session-event-emitters';

@Injectable()
export class LoadingService {
  public isLoading = new BehaviorSubject<boolean>(false);

  constructor(public router: Router, private deviceService: DeviceService) {
    activeSession.subscribe((value) => {
      this.disableRequiredHardware(value);
    });
  }

  public get loadingStatus(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  public showLoading() {
    this.isLoading.next(true);
  }

  public hideLoading() {
    this.isLoading.next(false);
  }

  private disableRequiredHardware(active: string) {
    if (active) {
      this.deviceService.disableLookupDevices();
    }
  }
}
