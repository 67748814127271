import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { AppState, Keyboard, PnrLocatorFlow, PnrLocatorFlowUpdateState, SetContent } from '../../../../state';
import { Logging } from '~app/services/logging/logging.service';
import { AppRoutes } from '~app/app-routes';
import { AccessibilityMode } from '~app/decorators/accessibility.decorator';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';

@AccessibilityMode()
@Component({
  selector: 'app-by-airport-code',
  templateUrl: './by-airport-code.component.html',
  styleUrls: ['./by-airport-code.component.scss', './../locate-reservation.component.scss'],
})
export class ByAirportCodeComponent implements OnInit, OnDestroy, AfterViewInit {
  keyboard$: Observable<Keyboard>;
  pnrLocatorFlow$: Observable<PnrLocatorFlow>;
  locateReservationByDestinationRoute = AppRoutes.LOCATE_RESERVATION_DESTINATION;

  public pnrLocatorFlowSubscriber;
  public code = '';
  public codeDone = false;
  public keyboardSubscriber;
  public elements = [];

  constructor(
    private store: Store<AppState>,
    public router: Router,
    private logging: Logging,
    private accessibilityService: AccessibilityService,
    private el: ElementRef
  ) {
    this.pnrLocatorFlow$ = store.pipe(select('pnrLocatorFlow'));
    this.keyboard$ = store.pipe(select('keyboard'));
  }

  ngOnInit() {
    this.logging.infoUiAirportCodeLookupPageDisplayed();
    this.pnrLocatorFlowSubscriber = this.pnrLocatorFlow$.pipe(take(1)).subscribe((value) => {
      let destination = '';
      if (value.destination) {
        destination = value.destination;
      }
      this.store.dispatch(new SetContent(destination));
      this.enableKeyboard();
    });
  }

  ngAfterViewInit() {}

  enableKeyboard() {
    this.keyboardSubscriber = this.keyboard$.subscribe((value) => {
      if (value.content !== undefined) {
        this.code = value.content;
        this.codeDone = false;
        if (value.content.length >= 3) {
          this.codeDone = true;
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.keyboardSubscriber) {
      this.keyboardSubscriber.unsubscribe();
    }
    if (this.pnrLocatorFlowSubscriber) {
      this.pnrLocatorFlowSubscriber.unsubscribe();
    }
  }

  validateAirportCode() {
    if (!this.codeDone) {
      return false;
    }
    this.logging.infoUiAirportCodeLookupPageContinueSelected(this.code);
    this.store.dispatch(new PnrLocatorFlowUpdateState({ destination: this.code, lookupMethod: 'destinationLastName' }));
    this.router.navigate([AppRoutes.LOCATE_RESERVATION_LAST_NAME]);
  }
}
