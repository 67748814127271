import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { AppRoutes } from '~app/app-routes';
import { accessibilityJson } from '~app/services/emitters/session-event-emitters';

@Component({
  selector: 'app-by-barcode',
  templateUrl: './by-barcode.component.html',
  styleUrls: ['./by-barcode.component.scss'],
})
export class ByBarcodeComponent implements OnInit, AfterViewInit {
  menuRoute = AppRoutes.MENU;
  altText = accessibilityJson.barcodeInsertion;

  constructor(private accessibilityService: AccessibilityService, private el: ElementRef) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    const elements = this.el.nativeElement.querySelectorAll('[tabindex]');
    this.accessibilityService.setElementsToRead(elements);
  }
}
