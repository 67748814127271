import { PassportActionTypes } from './passport.actions';
import { IPaxDetails } from '../../../services/ha-cuss/device-parser/device-interfaces/device-interfaces';
import { hardwareDeviceStatus } from '../../../services/ha-cuss/device.service';
import { Parser } from '../../../services/ha-cuss/device-parser/parser';

export interface PassportState {
  deviceName: string | null;
  deviceMode: string | null;
  deviceLogs: string[];
  passportInserted: string | null;
  passportDamaged: string | null;
  passportRemoved: string | null;
  passportData;
  passportStatusChange: [number, boolean] | any;
  passportStatusIsOK: string;
}

export const initialState: PassportState = {
  deviceName: '',
  deviceMode: '',
  deviceLogs: [],
  passportInserted: '',
  passportDamaged: '',
  passportRemoved: '',
  passportData: setDefaultPassportData(),
  passportStatusChange: hardwareDeviceStatus.passportDevice,
  passportStatusIsOK: '',
};

export function passportReducers(state: PassportState = initialState, action): PassportState {
  let callbackLogs = '';
  const INVALID_PASSPORT = 'Invalid Passport';
  // Different action reducer processes
  switch (action.type) {
    case PassportActionTypes.PassportEnable: {
      callbackLogs = 'Passport Reader Enabled';
      state.deviceName = 'passport';
      state.deviceMode = action.payload;
      state.deviceLogs = [callbackLogs];
      state.passportInserted = '';
      state.passportDamaged = '';
      state.passportRemoved = '';
      state.passportData = setDefaultPassportData();
      state.passportStatusIsOK = '';
      return { ...state };
    }
    case PassportActionTypes.PassportDisable: {
      callbackLogs = 'Passport Reader Disabled';
      state.deviceName = 'passport';
      state.deviceMode = action.payload;
      state.deviceLogs = [callbackLogs];
      state.passportInserted = '';
      state.passportDamaged = '';
      state.passportRemoved = '';
      state.passportData = setDefaultPassportData();
      state.passportStatusIsOK = '';
      return { ...state };
    }
    case PassportActionTypes.PassportInserted: {
      callbackLogs = 'Event: passportInserted';
      state.deviceLogs = [callbackLogs];
      state.passportInserted = action.payload.passportInserted;
      return { ...state };
    }
    case PassportActionTypes.PassportDamaged: {
      callbackLogs = 'Event: passportDamaged';
      state.deviceLogs = [callbackLogs];
      state.passportDamaged = action.payload.passportDamaged;
      return { ...state };
    }
    case PassportActionTypes.PassportRemoved: {
      callbackLogs = 'Event: passportRemoved';
      state.deviceLogs = [callbackLogs];
      state.passportRemoved = action.payload.passportRemoved;
      return { ...state };
    }
    case PassportActionTypes.PassportReadInternal: {
      callbackLogs = 'Event:  passportReadInternal - Passport date read: ' + action.payload.passportData;
      state.deviceLogs = [callbackLogs];
      if (action.payload.passportData !== '') {
        const tracks = action.payload.passportData.split('_@');
        if (tracks[0] !== null && tracks[1] !== null) {
          state.passportData = Parser.getPaxDetailFromPassport(tracks[0], tracks[1]);
        } else {
          state.passportData = INVALID_PASSPORT;
        }
      } else {
        state.passportData = INVALID_PASSPORT;
      }
      return { ...state };
    }
    case PassportActionTypes.PassportStatusChange: {
      callbackLogs = 'Event: statusIsOk';
      state.deviceLogs = [callbackLogs];
      state.deviceMode = '[Passport] Status Change';
      state.passportStatusChange = action.payload.passportStatusChange;
      return { ...state };
    }
    case PassportActionTypes.PassportStatusIsOK: {
      state.deviceLogs = [callbackLogs];
      state.deviceMode = '[Passport] Status is Ok';
      state.passportStatusIsOK = action.payload.passportStatusIsOK;
      return { ...state };
    }
    case PassportActionTypes.PassportStatusResponse: {
      state.deviceLogs = [callbackLogs];
      state.deviceMode = '[Passport] Status Response';
      if (action.payload.passportStatusResponse !== '0') {
        state.passportStatusChange = [300, true];
      } else {
        state.passportStatusChange = [-1, false];
      }
      return { ...state };
    }
    default:
      callbackLogs = 'default: ' + JSON.stringify(action.payload);
      state.deviceLogs = [callbackLogs];
      return state;
  }
}

export function setDefaultPassportData(): IPaxDetails {
  return {
    documentType: '',
    lastName: '',
    firstName: '',
    middleName: '',
    number: '',
    issueCountry: '',
    nationality: '',
    dateOfBirth: '',
    expiryDate: '',
    gender: '',
    pnr: '',
  };
}
