import { Injectable } from '@angular/core';
import { API } from '../api.service';
import { ConfigService } from '../config/config.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public airportCode: string;
  public addedDateQueryParams = false;
  public addedAirportCode = false;

  constructor(private api: API, public configService: ConfigService) {
    configService.config.subscribe((config) => {
      if (config && config.configuration) {
        this.airportCode = config.airportCode;
      }
    });
  }

  authenticate(hashParams) {
    this.api.addDefaultHeaders();

    for (const key in hashParams) {
      if (!this.api.apiQParams.trips[key] || hashParams[key] === null || hashParams[key].length === 0) {
        continue;
      }

      this.api.appendQueryParams(this.api.apiQParams.trips[key].key, hashParams[key]);

      if (!this.addedDateQueryParams && this.api.shouldIncludeDateQueryParams(key)) {
        this.api.addDateQueryParams();
        this.addedDateQueryParams = true;
      }

      if (!this.addedAirportCode && this.api.shouldIncludeAirportCode(key)) {
        this.api.appendAirportCodeToQueryParams(this.airportCode);
        this.addedAirportCode = true;
      }
    }
    this.api.updateCorrelationId();
    return this.api.doGetCallTripAuth();
  }
}
