import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { of, Subscription } from 'rxjs';
import { Logging } from '../../services/logging/logging.service';
import { AppRoutes } from '~app/app-routes';

@Component({
  selector: 'app-exit-button',
  templateUrl: './exit-button.component.html',
  styleUrls: ['./exit-button.component.scss'],
})
export class ExitButtonComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() customTabIndex = '2';
  @Input() exitButtonCopy = 'components.exitButtonComponent.exitButton';
  @Input() exitButtonIcon = '/assets/icons/icon-exit.svg';
  @Output() messageEvent = new EventEmitter<any>();
  @ViewChild('acaa', { static: false }) buttonElement: ElementRef;

  clearStateSubscription: Subscription;

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private logging: Logging,
    private el: ElementRef
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.messageEvent.emit(this.el.nativeElement.querySelector('#exit-button'));
  }

  ngOnDestroy() {
    if (this.clearStateSubscription) {
      this.clearStateSubscription.unsubscribe();
    }
  }

  exit() {
    this.logging.infoUiExitSelected(0);
    this.clearStateSubscription = of(this.store.dispatch({ type: 'clearState' })).subscribe(() => {
      this.router.navigate([AppRoutes.SPLASH_SCREEN]);
    });
  }
}
