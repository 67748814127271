import { HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';
import { Logging } from './logging.service';
import { deepSearch } from './utils';
import { isEmpty } from '../../../shared/embross/helper';

export class LoggingInterceptor {
  constructor(protected logging: Logging) {}

  handlePnrLookupResponse(request: HttpRequest<any>, response: HttpResponse<any>, lookupMethod: string) {
    this.logging.infoUiPnrLocateTime();
    if (this.isTripResponse(request, response)) {
      if (response.body.count > 0) {
        this.logging.infoApiPnrSuccessful(request, response, lookupMethod);
      } else {
        this.logging.infoApiPnrFailed(request, response);
      }
    }
  }

  isTripResponse(request: HttpRequest<any>, response: HttpResponse<any>): boolean {
    if (isEmpty(response.body)) {
      return;
    }
    return response.body.type === 'HA.Trips.Trip' && request.method === 'GET';
  }

  handleAlertReasonCodeResponse(request: HttpRequest<any>, response: HttpResponse<any>) {
    const searchAlert = (key, value) => {
      return key === 'alertReasonCode' && value.length > 0;
    };

    if (deepSearch(searchAlert, response.body)) {
      this.logging.infoApiAlertReasonCode(request, response);
    }
  }

  isCheckinRequest(request: HttpRequest<any>): boolean {
    let isCheckin: boolean;

    try {
      const isPatch = request.method === 'PATCH';
      const isToTrips = request.url.includes('/trips');
      const isCheckIn = request.body[0].path.includes('isCheckedIn');

      isCheckin = isPatch && isToTrips && isCheckIn;
    } catch (e) {
      isCheckin = false;
    }

    return isCheckin;
  }

  handlePatchTripsResponse(request: HttpRequest<any>, response: HttpResponse<any>) {
    if (this.isCheckinRequest(request)) {
      this.logging.infoApiCheckinSuccessful(request, response);
    }
  }

  handleCheckinFailed(request: HttpRequest<any>, error: HttpErrorResponse) {
    if (this.isCheckinRequest(request)) {
      this.logging.infoApiCheckinFailed(request, error);
    }
  }

  isPatchToPax(request: HttpRequest<any>): boolean {
    let isPatchToPax: boolean;

    try {
      const isPatch = request.method === 'PATCH';
      const isToPax = request.url.includes('/passengers');

      isPatchToPax = isPatch && isToPax;
    } catch (e) {
      isPatchToPax = false;
    }

    return isPatchToPax;
  }

  handlePatchPaxResponse(request: HttpRequest<any>, response: HttpResponse<any>) {
    if (this.isPatchToPax(request)) {
      this.logging.infoApiPassengersUpdateSuccessful(request, response);
    }
  }

  handlePaxUpdateFailed(request: HttpRequest<any>, error: HttpErrorResponse) {
    if (this.isPatchToPax(request)) {
      this.logging.infoApiPassengersUpdateFailed(request, error);
    }
  }

  isGetToCatalogs(request: HttpRequest<any>): boolean {
    let isCatalogsCall: boolean;

    try {
      const isGet = request.method === 'GET';
      const isCatalog = request.url.includes('/catalog');

      isCatalogsCall = isGet && isCatalog;
    } catch (e) {
      isCatalogsCall = false;
    }

    return isCatalogsCall;
  }

  handleCatalogsResponse(request: HttpRequest<any>, response: HttpResponse<any>) {
    if (this.isGetToCatalogs(request)) {
      this.logging.infoApiCatalogsSuccessful(request, response);
    }
  }

  handleCatalogsFailed(request: HttpRequest<any>, error: HttpErrorResponse) {
    if (this.isGetToCatalogs(request)) {
      this.logging.infoApiCatalogsFailed(request, error);
    }
  }

  isPutToCart(request: HttpRequest<any>): boolean {
    let isPutToCart: boolean;

    try {
      const isPut = request.method === 'PUT';
      const isCart = request.url.includes('/cart');

      isPutToCart = isPut && isCart;
    } catch (e) {
      isPutToCart = false;
    }

    return isPutToCart;
  }

  handleBagSaveSuccessful(request: HttpRequest<any>, response: HttpResponse<any>) {
    if (this.isPutToCart(request)) {
      this.logging.infoApiBagSaveSuccessful(request, response);
    }
  }

  handleBagSaveFailed(request: HttpRequest<any>, error: HttpErrorResponse) {
    if (this.isPutToCart(request)) {
      this.logging.infoApiBagSaveFailed(request, error);
    }
  }

  isGetToCart(request: HttpRequest<any>): boolean {
    let isGetCart: boolean;

    try {
      const isPut = request.method === 'GET';
      const isCart = request.url.includes('/cart');

      isGetCart = isPut && isCart;
    } catch (e) {
      isGetCart = false;
    }

    return isGetCart;
  }

  handleGetCartSuccessful(request: HttpRequest<any>, response: HttpResponse<any>) {
    if (this.isGetToCart(request)) {
      this.logging.infoApiGetCartSuccessful(request, response);
    }
  }

  handleGetCartFailed(request: HttpRequest<any>, error: HttpErrorResponse) {
    if (this.isGetToCart(request)) {
      this.logging.infoApiGetCartFailed(request, error);
    }
  }
  handleDeleteCartSuccessful(request: HttpRequest<any>, response: HttpResponse<any>) {
    if (this.isGetToCart(request)) {
      this.logging.infoApiDeleteCartSuccessful(request, response);
    }
  }

  handleDeleteCartFailed(request: HttpRequest<any>, error: HttpErrorResponse) {
    if (this.isGetToCart(request)) {
      this.logging.infoApiDeleteCartFailed(request, error);
    }
  }
}
