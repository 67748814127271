import { Cart } from './cart.model';
import { CartActions, CartActionTypes } from './cart.actions';
import { CatalogIdTypes } from '../trip/passenger.catalog.model';

export interface CartState {
  cart: Cart;
}

export function cartReducer(state = { cart: new Cart() }, action: CartActions): CartState {
  switch (action.type) {
    case CartActionTypes.CartCreated: {
      const { cart } = action.payload;

      return { ...state, cart };
    }

    case CartActionTypes.RelatedCartRetrieved:
    case CartActionTypes.CartUpdated: {
      const { cart } = action.payload;

      const currentCart = state.cart;
      /*
      we should not override the seat items in state with the items received from payload.
      loading the returned items into the state will break subsequent updateCart calls
      This is due to the object model of the cart items
      cart.items = currentCart.items;
      */
      return { ...state, cart };
    }

    case CartActionTypes.AddBag: {
      const payload = action.payload;

      if (!state.cart.items) {
        state.cart.items = [];
      }

      const cartItems = state.cart.items;

      const bagItem = cartItems.find(
        (x) =>
          x.productId === payload.productId &&
          x.associatedPassenger.passengerId === payload.passengerId &&
          x.associatedPassenger.segmentId === payload.segmentId
      );

      if (bagItem) {
        bagItem.quantity++;
      } else {
        cartItems.push({
          productId: payload.productId,
          quantity: 1,
          catalogId: CatalogIdTypes.Bags,
          associatedPassenger: {
            passengerId: payload.passengerId,
            segmentId: payload.segmentId,
          },
        });
      }

      const { cart } = { cart: state.cart };

      return { ...state, cart };
    }

    case CartActionTypes.RemoveBag: {
      const payload = action.payload;

      const cartItems = state.cart.items;

      const bagItemIndex = cartItems.findIndex(
        (x) =>
          x.productId === payload.productId &&
          x.associatedPassenger.passengerId === payload.passengerId &&
          x.associatedPassenger.segmentId === payload.segmentId
      );

      const bagItem = cartItems[bagItemIndex];

      if (bagItem && bagItem.quantity > 0) {
        bagItem.quantity--;
      }

      if (bagItem && bagItem.quantity === 0) {
        cartItems.splice(bagItemIndex, 1);
      }

      const { cart } = { cart: state.cart };

      return { ...state, cart };
    }
    case CartActionTypes.AddSeat: {
      const payload = action.payload;

      const cartItems = state.cart.items;

      const SeatItemIndex = cartItems.findIndex(
        (x) =>
          x.associatedPassenger.passengerId === payload.passengerId &&
          x.associatedPassenger.flightId === payload.flightId
      );
      const seatItem = {
        productId: payload.productId,
        quantity: 1,
        catalogId: CatalogIdTypes.Seats,
        assignment: payload.assignment,
        price: payload.price,
        associatedPassenger: {
          passengerId: payload.passengerId,
          segmentId: payload.segmentId,
          flightId: payload.flightId,
        },
      };

      if (SeatItemIndex === -1) {
        cartItems.push(seatItem);
      } else {
        cartItems.splice(SeatItemIndex, 1, seatItem);
      }

      const { cart } = { cart: state.cart };

      return {
        ...state,
        cart,
      };
    }
    case CartActionTypes.RemoveSeat: {
      const payload = action.payload;

      const cartItems = state.cart.items;

      const SeatItemIndex = cartItems.findIndex(
        (x) =>
          x.productId === payload.productId &&
          x.associatedPassenger.passengerId === payload.passengerId &&
          x.associatedPassenger.segmentId === payload.segmentId &&
          x.associatedPassenger.flightId === payload.flightId
      );

      cartItems.splice(SeatItemIndex, 1);

      const { cart } = { cart: state.cart };

      return {
        ...state,
        cart,
      };
    }
    case CartActionTypes.PaxSelectedChangedForSpecialItems: {
      const { cart } = { cart: state.cart };
      cart.paxSelectedForSpecialItems = action.payload.passenger;

      return {
        ...state,
        cart,
      };
    }

    case CartActionTypes.SessionEnd: {
      const { cart } = { cart: state.cart };

      cart.sessionEnd = true;

      return { ...state, cart };
    }

    default: {
      return state;
    }
  }
}
