import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState, CartState, selectCurrentTrip, Trip } from '~app/state';
import { SelectLanguage } from '~app/state/language/language.action';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { ConfigService } from '~app/services/api/config/config.service';
import { delay, takeUntil } from 'rxjs/operators';
import { Cart } from '~app/state/cart/cart.model';
import { AppRoutes } from '~app/app-routes';
import { isAccessibilityMode } from '../../services/emitters/session-event-emitters';
import { SPEECH_STATUS } from '~app/services/ha-cuss/device.service';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';

@Component({
  selector: 'app-session-end-modal',
  templateUrl: './session-end-modal.component.html',
  styleUrls: ['./session-end-modal.component.scss'],
})
export class SessionEndModalComponent implements OnInit, OnDestroy {
  private cart$: Observable<CartState>;
  public showModal = false;
  public config: any;
  private isMultiPax: boolean;
  public cartSubscription: Subscription;
  public configSubscription: Subscription;
  private clearStateSubscription: Subscription;
  private unsubscribe$ = new Subject<void>();
  public timer;
  public cart: Cart;
  public currentTrip: Trip;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private configService: ConfigService,
    private accessibilityService: AccessibilityService,
    public changeRef: ChangeDetectorRef,
    public el: ElementRef
  ) {
    this.accessibilityService.speechStatus$.subscribe((status: SPEECH_STATUS) => {
      if (status === SPEECH_STATUS.STARTED && this.showModal === false) {
        if (this.timer) {
          this.timer.unsubscribe();
        }
      }
      if (status === SPEECH_STATUS.COMPLETED && this.showModal === false) {
        this.toggleModal();
      }
    });
  }

  ngOnInit() {
    this.cart$ = this.store.pipe(select('carts'));
    this.configSubscription = this.configService.config.pipe(takeUntil(this.unsubscribe$)).subscribe((config) => {
      if (config && config.configuration) {
        this.config = config;
        this.toggleModal();
      }
    });

    this.cartSubscription = this.cart$.pipe(takeUntil(this.unsubscribe$)).subscribe((state) => {
      this.cart = state.cart;
      this.toggleModal();
    });
  }

  setAcaaElementToRead() {
    this.changeRef.detectChanges();
    this.accessibilityService.activateModalElementsToRead(
      this.el.nativeElement.querySelectorAll('.modal-holder [tabindex]'),
      'session-ended'
    );
  }

  toggleModal() {
    if (!this.cart || !this.cart.sessionEnd) {
      return false;
    }
    this.store
      .select(selectCurrentTrip)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((trip) => {
        this.currentTrip = trip;
      });
    this.isMultiPax = this.currentTrip.passengers && this.currentTrip.passengers.length > 1;
    this.showModal = true;
    this.setTimer();
    if (isAccessibilityMode.getValue()) {
      setTimeout(() => {
        this.setAcaaElementToRead();
      }, 1000);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setTimer() {
    const timeout = this.getTimeout();

    this.timer = of(null)
      .pipe(delay(timeout))
      .subscribe(() => this.doSessionEnd());
  }

  getTimeout() {
    let time = this.config.timeout.singlePaxSec;
    if (this.isMultiPax) {
      time = this.config.timeout.multiPaxSec;
    }
    return time * 1000;
  }

  doSessionEnd() {
    this.clearStateSubscription = of(this.store.dispatch({ type: 'clearState' }))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.store.dispatch(new SelectLanguage('en'));
        this.router.navigate([AppRoutes.MENU]);
        this.showModal = false;
        if (isAccessibilityMode.getValue()) {
          this.accessibilityService.dismissModalElements(null, null, 'session-ended');
        }
      });
  }
}
