import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccessibilityGuard } from './services/accessibility/accessibility.guard';

import { ItineraryComponent } from './components/routes/itinerary/itinerary.component';
import { MenuComponent } from './components/routes/menu/menu.component';

import { ChangeLanguageComponent } from './components/routes/change-language/change-language.component';
import { ErrorScreenComponent } from './components/routes/error-screen/error-screen.component';
import { HazmatComponent } from './components/routes/hazmat/hazmat.component';
import { RestrictedComponent } from './components/routes/hazmat/restricted/restricted.component';
import { ProhibitedComponent } from './components/routes/hazmat/prohibited/prohibited.component';
import { SelfTaggingComponent } from './components/routes/hazmat/self-tagging/self-tagging.component';
import { NoSelfTaggingComponent } from './components/routes/hazmat/no-self-tagging/no-self-tagging.component';
import { ByConfirmationCodeComponent } from './components/routes/locate-reservation/by-confirmation-code/by-confirmation-code.component';
import { ByETicketComponent } from './components/routes/locate-reservation/by-e-ticket/by-e-ticket.component';
import { ByHawaiianMilesComponent } from './components/routes/locate-reservation/by-hawaiian-miles/by-hawaiian-miles.component';
import { ByFlightNumberComponent } from './components/routes/locate-reservation/by-flight-number/by-flight-number.component';
import { ByNameComponent } from './components/routes/locate-reservation/by-name/by-name.component';
import { ByBirthdayComponent } from './components/routes/locate-reservation/by-birthday/by-birthday.component';
import { LocateReservationComponent } from './components/routes/locate-reservation/locate-reservation.component';
import { PrintDocumentsComponent } from './components/routes/print-documents/print-documents.component';
import { ConfirmationComponent } from './components/routes/confirmation/confirmation.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { PaxEditMainComponent } from './components/routes/pax-edit/pax-edit-main/pax-edit-main.component';
import { SelectPaxComponent } from './components/routes/pax-edit/select-pax/select-pax.component';
import { RedressEntryComponent } from './components/routes/pax-edit/redress-entry/redress-entry.component';
import { KtnEntryComponent } from './components/routes/pax-edit/ktn-entry/ktn-entry.component';
import { HawaiianMilesEntryComponent } from './components/routes/pax-edit/hawaiian-miles-entry/hawaiian-miles-entry.component';
import { CheckedInOptionsComponent } from './components/routes/checked-in-options/checked-in-options.component';
import { OutOfServiceComponent } from './shared/out-of-service/out-of-service.component';
import { SeatPreferenceComponent } from './components/routes/seat-preference/seat-preference.component';
import { StandbyConfirmationComponent } from './components/routes/standby-confirmation/standby-confirmation.component';
import { MoreSegmentsCheckinComponent } from './components/routes/more-segments-checkin/more-segments-checkin.component';
import { ByAirportCodeComponent } from './components/routes/locate-reservation/by-airport-code/by-airport-code.component';
import { ByDestinationComponent } from './components/routes/locate-reservation/by-destination/by-destination.component';
import { ByBarcodeComponent } from './components/routes/locate-reservation/by-barcode/by-barcode.component';
import { BagsComponent } from './components/routes/bags/bags.component';
import { AboutBagsComponent } from './components/routes/about-bags/about-bags.component';
import { PaymentComponent } from './components/routes/payment/payment.component';
import { SpecialItemsComponent } from './components/routes/special-items/special-items.component';
import { BagsPrintedConfirmationComponent } from './components/routes/confirmation/bags-printed/bags-printed.component';
import { NonPrintedBagsConfirmationComponent } from './components/routes/confirmation/non-printed-bags/non-printed-bags.component';
import { ConfigGuard } from './services/api/config/config.guard';
import { SplashScreenComponent } from './components/routes/splash-screen/splash-screen.component';
import { MilitaryComponent } from './components/routes/military/military.component';
import { MilitaryConfirmationComponent } from './components/routes/military/military-confirmation/military-confirmation.component';
import { MilitaryThankYouComponent } from './components/routes/military/military-thank-you/military-thank-you.component';
import { MilitarySelectionComponent } from './components/routes/military/military-selection/military-selection.component';
import { MilitaryNoMatchComponent } from './components/routes/military/military-no-match/military-no-match.component';
import { BagsDetailsOAComponent } from './components/routes/bags/bags-details-oa/bags-details-oa.component';

const routes: Routes = [
  { path: '', redirectTo: '/splash-screen', pathMatch: 'full' },
  { path: 'menu', canActivate: [AccessibilityGuard], component: MenuComponent },
  {
    path: 'itinerary',
    canActivate: [AccessibilityGuard, ConfigGuard],
    component: ItineraryComponent,
  },
  {
    path: 'error-screen',
    canActivate: [AccessibilityGuard, ConfigGuard],
    component: ErrorScreenComponent,
  },
  {
    path: 'print-documents',
    canActivate: [AccessibilityGuard, ConfigGuard],
    component: PrintDocumentsComponent,
  },
  {
    path: 'locate-reservation',
    component: LocateReservationComponent,
    children: [
      {
        path: 'confirmation-code',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: ByConfirmationCodeComponent,
      },
      { path: 'e-ticket', canActivate: [AccessibilityGuard, ConfigGuard], component: ByETicketComponent },
      {
        path: 'hawaiian-miles',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: ByHawaiianMilesComponent,
      },
      {
        path: 'flight-number',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: ByFlightNumberComponent,
      },
      { path: 'last-name', canActivate: [AccessibilityGuard, ConfigGuard], component: ByNameComponent },
      {
        path: 'birthday',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: ByBirthdayComponent,
      },
      {
        path: 'airport-code',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: ByAirportCodeComponent,
      },
      {
        path: 'destination',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: ByDestinationComponent,
      },
      { path: 'barcode', canActivate: [AccessibilityGuard, ConfigGuard], component: ByBarcodeComponent },
    ],
  },
  { path: 'bags', canActivate: [AccessibilityGuard, ConfigGuard], component: BagsComponent },
  {
    path: 'seats',
    canActivate: [AccessibilityGuard, ConfigGuard],
    loadChildren: () => import('./components/routes/seats/seats.module').then((m) => m.SeatsModule),
  },
  { path: 'splash-screen', canActivate: [AccessibilityGuard], component: SplashScreenComponent },
  {
    path: 'seat-preference',
    canActivate: [AccessibilityGuard, ConfigGuard],
    component: SeatPreferenceComponent,
  },
  {
    path: 'change-language',
    canActivate: [AccessibilityGuard, ConfigGuard],
    component: ChangeLanguageComponent,
  },
  {
    path: 'confirmation',
    component: ConfirmationComponent,
    children: [
      {
        path: 'bags-printed',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: BagsPrintedConfirmationComponent,
      },
      {
        path: 'non-printed-bags',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: NonPrintedBagsConfirmationComponent,
      },
    ],
  },
  {
    path: 'standby-confirmation',
    canActivate: [AccessibilityGuard, ConfigGuard],
    component: StandbyConfirmationComponent,
  },
  { path: 'loading', canActivate: [AccessibilityGuard, ConfigGuard], component: LoadingComponent },
  {
    path: 'options',
    canActivate: [AccessibilityGuard, ConfigGuard],
    component: CheckedInOptionsComponent,
  },
  {
    path: 'out-of-service',
    canActivate: [AccessibilityGuard, ConfigGuard],
    component: OutOfServiceComponent,
  },
  {
    path: 'more-segments-checkin',
    canActivate: [AccessibilityGuard, ConfigGuard],
    component: MoreSegmentsCheckinComponent,
  },
  {
    path: 'hazmat',
    component: HazmatComponent,
    children: [
      {
        path: 'prohibited',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: ProhibitedComponent,
      },
      {
        path: 'restricted',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: RestrictedComponent,
      },
      {
        path: 'self-tagging',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: SelfTaggingComponent,
      },
      {
        path: 'no-self-tagging',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: NoSelfTaggingComponent,
      },
    ],
  },
  {
    path: 'pax-edit',
    children: [
      {
        path: 'select-pax',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: SelectPaxComponent,
      },
      { path: 'main', canActivate: [AccessibilityGuard, ConfigGuard], component: PaxEditMainComponent },
      { path: 'ktn', canActivate: [AccessibilityGuard, ConfigGuard], component: KtnEntryComponent },
      {
        path: 'redress',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: RedressEntryComponent,
      },
      {
        path: 'hm-number',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: HawaiianMilesEntryComponent,
      },
    ],
  },
  { path: 'about-bags', canActivate: [AccessibilityGuard, ConfigGuard], component: AboutBagsComponent },
  {
    path: 'special-items',
    canActivate: [AccessibilityGuard, ConfigGuard],
    component: SpecialItemsComponent,
  },
  { path: 'payment', canActivate: [AccessibilityGuard, ConfigGuard], component: PaymentComponent },
  {
    path: 'military',
    component: MilitaryComponent,
    children: [
      {
        path: 'confirmation',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: MilitaryConfirmationComponent,
      },
      {
        path: 'thank-you',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: MilitaryThankYouComponent,
      },
      {
        path: 'selection',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: MilitarySelectionComponent,
      },
      {
        path: 'no-match',
        canActivate: [AccessibilityGuard, ConfigGuard],
        component: MilitaryNoMatchComponent,
      },
    ],
  },
  /**
   * This section below will hold all of the lazy loaded routes and modules.
   *
   * List:
   * AgentAssistModule - Module dedicated to all logic related to Agent Assist Functionality
   */
  {
    path: 'aa',
    canActivate: [],
    loadChildren: () => import('./agent-assist/agent-assist.module').then((m) => m.AgentAssistModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
