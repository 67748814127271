import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ChangeLanguageButtonComponent } from './change-language-button/change-language-button.component';
import { CheckInInProgressComponent } from './checkin-in-progress/checkin-in-progress.component';
import { ExitButtonComponent } from './exit-button/exit-button.component';
import { FooterNavComponent } from './footer-nav/footer-nav.component';
import { GoBackButtonComponent } from './go-back-button/go-back-button.component';
import { GridComponent } from './grid/grid.component';
import { KeyboardComponent } from './keyboard/keyboard.component';
import { LoadingComponent } from './loading/loading.component';
import { OutOfServiceComponent } from './out-of-service/out-of-service.component';
import { PaymentInProgressAlertComponent } from './payment-in-progress-alert/payment-in-progress-alert.component';
import { RemoveCardComponent } from './remove-card/remove-card.component';
import { RemovePassportComponent } from './remove-passport/remove-passport.component';
import { ScrollableListComponent } from './scrollable-list/scrollable-list.component';
import { SessionEndModalComponent } from './session-end-modal/session-end-modal.component';
import { StartOverComponent } from './start-over/start-over.component';
import { TimeoutComponent } from './timeout/timeout.component';
import { SpacebarComponent } from './keyboard/spacebar/spacebar.component';
import { TimerDateComponent } from './header/timer-date/timer-date.component';
import { ClearComponent } from './keyboard/clear/clear.component';
import { StepperComponent } from './header/stepper/stepper.component';
import { BackspaceComponent } from './keyboard/backspace/backspace.component';
import { HeaderComponent } from './header/header.component';
import { HACommonModule } from '@hawaiianair/common';
import { LoggingModule } from '@hawaiianair/logging';
import { CoreModule } from '@hawaiianair/core';
import { FocusAccessibilityDirective } from '../directives/focus-accessibility.directive';
import { RoutingService } from '../services/routing/routing.service';
import { InlineSVGModule } from 'ng-inline-svg';

/*
  This module is imported by the generated app-components.module.ts.
  You can use this module to provide shared Angular components that are not
  app components, etc to the generated module.
*/
@NgModule({
  imports: [
    CommonModule,
    HACommonModule,
    TranslateModule,
    FormsModule,
    LoggingModule,
    CoreModule.withRoutes({}),
    InlineSVGModule,
  ],
  declarations: [
    // add declared classes here
    ChangeLanguageButtonComponent,
    CheckInInProgressComponent,
    ExitButtonComponent,
    FooterNavComponent,
    GoBackButtonComponent,
    GridComponent,
    KeyboardComponent,
    LoadingComponent,
    OutOfServiceComponent,
    PaymentInProgressAlertComponent,
    RemoveCardComponent,
    RemovePassportComponent,
    ScrollableListComponent,
    SessionEndModalComponent,
    StartOverComponent,
    TimeoutComponent,
    BackspaceComponent,
    SpacebarComponent,
    HeaderComponent,
    TimerDateComponent,
    ClearComponent,
    StepperComponent,
    FocusAccessibilityDirective,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    InlineSVGModule,
    ChangeLanguageButtonComponent,
    CheckInInProgressComponent,
    ExitButtonComponent,
    FooterNavComponent,
    GoBackButtonComponent,
    GridComponent,
    KeyboardComponent,
    LoadingComponent,
    OutOfServiceComponent,
    PaymentInProgressAlertComponent,
    RemoveCardComponent,
    RemovePassportComponent,
    ScrollableListComponent,
    SessionEndModalComponent,
    StartOverComponent,
    TimeoutComponent,
    BackspaceComponent,
    SpacebarComponent,
    HeaderComponent,
    TimerDateComponent,
    ClearComponent,
    StepperComponent,
    FormsModule,
    FocusAccessibilityDirective,
    InlineSVGModule,
    // should also add declared classes here - anything that components should be able to reference
  ],
  providers: [
    // only add providers here that are scoped to all imported instances of this module
  ],
})
export class AppSharedModule {
  static forRoot(): ModuleWithProviders<AppSharedModule> {
    return {
      ngModule: AppSharedModule,

      providers: [
        // add any ROOT providers (services, etc) to this list
        RoutingService,
      ],
    };
  }
}
