import { Injectable } from '@angular/core';
import { deviceIds, DeviceManager, TraceLevels } from '../../../assets/js/embross-device-manager';
import {
  almostOutOfTimeEvent,
  fallbackAirportCode,
  kioskId,
  loadKioskId,
  notificationTextEvent,
  notifyActiveEvent,
  socketAlive,
} from '../emitters/session-event-emitters';

@Injectable()
export class ApplinkService {
  private readonly localIp = '127.0.0.1';
  private readonly deviceManager: DeviceManager;

  private systemManagerKioskId = null;
  private actualCUSSKioskId = null;
  private airportCode = null;
  private location = null;
  private companyCode = null;
  private appName = null;
  private currentState = null;

  constructor() {
    this.deviceManager = new DeviceManager(this.localIp, 8091, false, 'BOS');
    this.deviceManager.setAppManager(
      this.appMgrOnChange.bind(this),
      this.appMgrOnActive.bind(this),
      this.appMgrOnEvent.bind(this)
    );

    this.deviceManager.onConnected = this.OnConnected.bind(this);
    this.deviceManager.appManager.onSocketOpened = this.OnSocketOpened.bind(this);
    this.deviceManager.appManager.onActive = this.onActive.bind(this);

    // Connect to the AppLink
    this.deviceManager.connect();

    this.deviceManager.addDevices(deviceIds.PASSPORT_READER, this.passportReaderOnEvent, true);
    // setup cardReader
    this.deviceManager.addDevices(deviceIds.CARD_READER, this.cardReaderOnEvent, true);
    // setup barcodeReader
    this.deviceManager.addDevices(deviceIds.BARCODE_READER, this.barcodeReaderOnEvent, true);
    // setup ATBPrinter
    this.deviceManager.addDevices(deviceIds.ATB_PRINTER, this.ATBPrinterOnEvent, true);
    // setup BagtagPrinter
    this.deviceManager.addDevices(deviceIds.BAGTAG_PRINTER, this.BagtagPrinterOnEvent, true);
    // setup Accessibility Pad
    this.deviceManager.addDevices(deviceIds.CUSS_ACCESSIBILITY, this.cussAccessibilityOnEvent, true);
  }

  getDeviceManager() {
    return this.deviceManager;
  }

  appMgrOnChange(change) {
    this.getDeviceManager().sendLogMsg(
      TraceLevels.LOG_TRACE,
      'appMgrOnChange() is called from client: ' + change.key + ' value ' + change.value
    );
    this.getKioskProperties(change.key, change.value);
  }

  appMgrOnActive() {
    notifyActiveEvent.emit(true);
    this.getDeviceManager().sendLogMsg(TraceLevels.LOG_TRACE, 'appMgrOnActive called');
    this.getDeviceManager()
      .getDevice(deviceIds.CARD_READER)
      .enable();
    this.getDeviceManager()
      .getDevice(deviceIds.BARCODE_READER)
      .enable();
    this.getDeviceManager()
      .getDevice(deviceIds.ATB_PRINTER)
      .enable();
    this.getDeviceManager()
      .getDevice(deviceIds.PASSPORT_READER)
      .enable();
    this.getDeviceManager()
      .getDevice(deviceIds.BAGTAG_PRINTER)
      .enable();
    this.getDeviceManager()
      .getDevice(deviceIds.CUSS_ACCESSIBILITY)
      .enableHeadset();
  }

  appMgrOnEvent(event) {
    this.getDeviceManager().sendLogMsg(
      TraceLevels.LOG_EXT_TRACE,
      'appMgrOnEvent() is called from client: ' + event.key + ' ' + event.value
    );
    if (event.key === 'notificationText') {
      this.getDeviceManager().sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'notificationText: event.value ' + event.value);
      notificationTextEvent.emit(event.value);
    } else if (event.key === 'almostOutOfTime') {
      this.getDeviceManager().sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'almostOutOfTime: event.value ' + event.value);
      almostOutOfTimeEvent.emit(true);
    } else if (event.key === 'notifyActive') {
      this.getDeviceManager().sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'notifyActive: event.value ' + event.value);
      notifyActiveEvent.emit(event.value);
    }
  }

  OnConnected() {
    socketAlive.emit(true);
    // INITIALIZE applog
    if (this.getDeviceManager().setLogger('appManager', true)) {
      // default log file
      this.getDeviceManager().sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'DeviceManager opened');
      const version = navigator.appVersion;
      this.getDeviceManager().sendLogMsg(TraceLevels.LOG_EXT_TRACE, '***** Browser: ' + version);
    } else {
      this.getDeviceManager().sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'appManager log not initialized!!!');
    }
    // send version
    this.getDeviceManager().appManager.sendVersion('1.0.0', true);
  }

  OnSocketOpened(restarting) {
    if (restarting) {
      this.getDeviceManager().sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'OnSocketOpened - restarting');
    } else {
      this.getDeviceManager().sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'OnSocketOpened');
      this.getDeviceManager().appManager.available();
    }
  }

  onActive(evt) {
    this.getDeviceManager().sendLogMsg(TraceLevels.LOG_TRACE, 'onActive - main evt: ' + evt.key + ' ' + evt.value);
    // continue notification if active is true
    if (evt.value) {
      this.appMgrOnActive();
    }
  }

  passportReaderOnEvent(event) {
    this.getDeviceManager().sendLogMsg(
      TraceLevels.LOG_EXT_TRACE,
      'passportReaderOnEvent() called ... event : ' + JSON.stringify(event)
    );
  }

  barcodeReaderOnEvent(event) {
    this.getDeviceManager().sendLogMsg(
      TraceLevels.LOG_EXT_TRACE,
      'barcodeReaderOnEvent() called ... event : ' + JSON.stringify(event)
    );
  }

  ATBPrinterOnEvent(event) {
    this.getDeviceManager().sendLogMsg('ATBPrinterOnEvent() called ... event : ' + JSON.stringify(event));
  }

  BagtagPrinterOnEvent(event) {
    this.getDeviceManager().sendLogMsg('BagtagPrinterOnEvent() called ... event : ' + JSON.stringify(event));
  }

  cardReaderOnEvent(event) {
    this.getDeviceManager().sendLogMsg('cardReaderOnEvent() called ... event : ' + JSON.stringify(event));
  }

  cussAccessibilityOnEvent(event) {
    this.getDeviceManager().sendLogMsg('cussAccessibilityOnEvent() called ... event : ' + JSON.stringify(event));
  }

  getKioskProperties(key, value) {
    if (key === 'kioskId' && value) {
      // @ts-ignore
      loadKioskId.emit(value);
      kioskId.next(value);
    } else if (key === 'airportCode' && value != null) {
      fallbackAirportCode.next(value);
      this.airportCode = value;
    } else if (key === 'getCurrentState' && value != null) {
      this.currentState = value;
    } else if (key === 'location' && value != null) {
      this.location = value;
    } else if (key === 'companyCode' && value != null) {
      this.companyCode = value;
    } else if (key === 'appName' && value != null) {
      this.appName = value;
    }
  }

  getKioskId() {
    return kioskId.getValue();
  }

  getSystemManagerKioskId() {
    return this.systemManagerKioskId;
  }

  getCurrentState() {
    return this.currentState;
  }

  getActualCUSSKioskId() {
    return this.actualCUSSKioskId;
  }

  getAirportCode() {
    return this.airportCode;
  }

  getLocation() {
    return this.location;
  }

  getCompanyCode() {
    return this.companyCode;
  }

  getAppName() {
    return this.appName;
  }
}
