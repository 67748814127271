import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ClearStateType } from '../clearstate/clearstate.actions';
import { map } from 'rxjs/operators';
import { API } from '~app/services/api/api.service';

@Injectable()
export class ClearStateEffects {
  constructor(private actions$: Actions, private api: API) {}

  @Effect({ dispatch: false })
  clearHeaders$ = this.actions$.pipe(
    ofType(ClearStateType.ClearState),
    map(() => {
      this.api.clearHeaders();
    })
  );
}
