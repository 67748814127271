import { Store } from '@ngrx/store';
import { AppState } from '../index';
import { ErrorType } from '../../models/error/error.model';
import { Segment } from '../trip/segment/segment.model';
import { getCheckInOpenedDate, getSegmentAlertCodesData } from '../../utils/helpers';
import {
  SetBoardingPassError,
  SetCancelledFlightError,
  SetReferToAgentError,
  SetTicketingIssue,
  SetTooEarlyError,
  SetTooLateError,
  SetTooLateWithPrintBoardingPassError,
} from './error.action';

export const setCheckInCancelledFlightError = (alertReasonCode: string, store: Store<AppState>) => {
  store.dispatch(new SetCancelledFlightError({ alertReasonCode }));
};

export const setCheckInTooEarlyError = (alertReasonCode: string, segment: Segment, store: Store<AppState>) => {
  const departureDate = new Date(segment.departure.airportDateTimeString);
  const checkInDate = getCheckInOpenedDate(departureDate);
  store.dispatch(new SetTooEarlyError({ checkInDate, alertReasonCode }));
};

export const setReferOutToAgentError = (alertReasonCode: string, store: Store<AppState>) => {
  store.dispatch(new SetReferToAgentError({ alertReasonCode }));
};

export const setTicketingIssue = (alertReasonCode: string, store: Store<AppState>) => {
  store.dispatch(new SetTicketingIssue({ alertReasonCode }));
};

export const setCheckInTooLateError = (alertReasonCode: string, store: Store<AppState>) => {
  store.dispatch(new SetTooLateError({ alertReasonCode }));
};

export const setCheckInTooLateWithPrintBoardingPassError = (alertReasonCode: string, store: Store<AppState>) => {
  store.dispatch(new SetTooLateWithPrintBoardingPassError({ alertReasonCode }));
};

export const setDoNotPrintBoardingPass = (alertReasonCode: string, store: Store<AppState>) => {
  store.dispatch(new SetBoardingPassError({ alertReasonCode }));
};

const LOCATE_METHODS = ['confirmationCode', 'hawaiianMilesNumber', 'ticketNumber'];

export function setSegmentRelatedError(segment: Segment, store: Store<AppState>): void {
  const errorDataCodes = getSegmentAlertCodesData(segment);
  const alertMessageCode = errorDataCodes.alertMessageCode;
  const alertReasonCode = errorDataCodes.alertReasonCode;

  switch (alertMessageCode) {
    case ErrorType.SabreClosed:
    case ErrorType.ReferOutToAgent: {
      setReferOutToAgentError(alertReasonCode, store);
      break;
    }
    case ErrorType.CheckInTooEarly: {
      setCheckInTooEarlyError(alertReasonCode, segment, store);
      break;
    }
    case ErrorType.FlightCancelled: {
      setCheckInCancelledFlightError(alertReasonCode, store);
      break;
    }
    case ErrorType.TicketingIssue: {
      setTicketingIssue(alertReasonCode, store);
      break;
    }
    case ErrorType.CheckInTooLate: {
      segment.details[0].isCheckedIn
        ? setCheckInTooLateWithPrintBoardingPassError(alertReasonCode, store)
        : setCheckInTooLateError(alertReasonCode, store);
      break;
    }
    case ErrorType.DoNotPrintBoardingPass: {
      setDoNotPrintBoardingPass(alertReasonCode, store);
      break;
    }

    default: {
      break;
    }
  }
}

// For unit testing purposes
export const errorHelpers = {
  setSegmentRelatedError,
};
