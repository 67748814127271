import { Component, Input, OnInit } from '@angular/core';
import { AppRoutes } from '~app/app-routes';

@Component({
  selector: 'app-locate-reservation-tabs',
  templateUrl: './locate-reservation-tabs.component.html',
  styleUrls: ['./locate-reservation-tabs.component.scss'],
})
export class LocateReservationTabsComponent implements OnInit {
  public componentActiveTab: string;
  AppRoutes = AppRoutes;

  constructor() {}

  ngOnInit() {}

  @Input() set activeTab(value: string) {
    this.componentActiveTab = value;
  }
}
