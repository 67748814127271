import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, of, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { CardReaderEnablePayment, Cart, ErrorState, selectCart, TripSetSelectedPax } from '../../../state';
import { HaCussService } from '../../../services/ha-cuss/ha-cuss.service';
import { Logging } from '../../../services/logging/logging.service';
import { socketAlive } from '../../../services/emitters/session-event-emitters';
import { AppRoutes } from '~app/app-routes';
import { AccessibilityService } from '../../../services/accessibility/accessibility.service';
import { ErrorModel, ErrorType } from '~app/models/error/error.model';
import { RoutingService } from '../../../services/routing/routing.service';

@Component({
  selector: 'app-error-screen',
  templateUrl: './error-screen.component.html',
  styleUrls: ['./error-screen.component.scss'],
})
export class ErrorScreenComponent implements OnInit, OnDestroy, AfterViewInit {
  errorInfo: ErrorModel;
  error$: Observable<ErrorState>;
  errorSubscription: Subscription;
  public back;
  public showErrorCode = false;
  public clearStateSubscription: Subscription;
  public showDisabled;
  public cart: Cart;
  private cartSubscription: Subscription;

  constructor(
    private store: Store<ErrorState>,
    private router: Router,
    private logging: Logging,
    public location: Location,
    private haCussService: HaCussService,
    private routingService: RoutingService,
    private accessibilityService: AccessibilityService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.errorInfo = {
      errorType: ErrorType.NoError,
      primaryButton: { route: '/menu', translationTag: 'errorScreen.common' },
      info: [],
      codes: {
        displayErrorCode: false,
      },
    };

    this.error$ = this.store.pipe(select('error'));

    this.errorSubscription = this.error$.subscribe((error) => {
      if (!error.error) {
        return;
      }

      this.errorInfo = error.error.withDefault(this.errorInfo);

      if (this.errorInfo.codes) {
        this.logging.infoUiErrorPageDisplayed(
          this.errorInfo.codes.alertReasonCode,
          this.errorInfo.codes.alertMessageCode,
          0
        );
        if (this.errorInfo.codes.alertReasonCode) {
          this.showErrorCode = true;
        }
        switch (this.errorInfo.errorType) {
          case ErrorType.PrintBagTagsError:
          case ErrorType.GenerateBagTagError:
            this.logging.infoUiPrintDocumentFailed(
              0,
              'bagTag',
              this.errorInfo.codes.alertMessageCode,
              this.errorInfo.codes.alertReasonCode
            );
            break;
          case ErrorType.GenerateDocumentError:
          case ErrorType.FailedDocumentPrintError:
            this.logging.infoUiPrintDocumentFailed(
              0,
              'receipt',
              this.errorInfo.codes.alertMessageCode,
              this.errorInfo.codes.alertReasonCode
            );
            break;
        }
      } else {
        this.logging.infoUiErrorPageDisplayed();
      }
    });
    socketAlive.subscribe((value) => {
      this.showDisabled = !value;
    });

    this.haCussService.disableBarcodeReader();
    this.haCussService.disablePassportReader();
  }

  ngAfterViewInit(): void {
    const elements = this.getElements();
    this.accessibilityService.setElementsToRead(elements);
  }

  getElements() {
    return this.el.nativeElement.querySelectorAll('[tabindex]');
  }

  ngOnDestroy() {
    if (this.haCussService.getAtbPrinterStatus()[1] === true) {
      socketAlive.emit(false);
    } else {
      this.haCussService.enableATBPrinter();
    }
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
    if (this.clearStateSubscription) {
      this.clearStateSubscription.unsubscribe();
    }
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }

  navigateTo(route: string) {
    if (this.haCussService.getAtbPrinterStatus()[1] === true) {
      socketAlive.emit(false);

      setTimeout(() => {
        this.router.navigate([route]);
      }, 5000);

      return;
    }

    switch (route) {
      case 'goBack':
        this.router.navigate([this.routingService.getPrevCheckInRoute()]);
        break;

      case 'paymentError':
        this.router.navigate([this.routingService.getPrevCheckInRoute()]);
        this.store.dispatch(new CardReaderEnablePayment());
        break;

      case 'cardSwipe':
        this.cartSubscription = this.store.pipe(select(selectCart)).subscribe((cart) => {
          this.cart = cart;

          if (this.cart.id === undefined) {
            this.router.navigate([AppRoutes.MENU]);
            return;
          }

          this.router.navigate([this.routingService.getPrevCheckInRoute()]);
        });
        break;

      case AppRoutes.ITINERARY:
      case AppRoutes.OPTIONS:
      case AppRoutes.PAX_EDIT_MAIN:
        this.router.navigate([route]);
        break;
      case AppRoutes.HAZMAT_PROHIBITED:
        this.store.dispatch(new TripSetSelectedPax());
        this.router.navigate([route]);
        break;

      default:
        this.clearStateSubscription = of(this.store.dispatch({ type: 'clearState' })).subscribe(() => {
          this.router.navigate([this.errorInfo.primaryButton.route]);
        });
        break;
    }
  }
}
