import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, PnrLocatorFlowClearState } from '~app/state';
import { environment } from '~environments/environment';
import { startSession } from '~app/app.component';
import { HaCussService } from '~services/ha-cuss/ha-cuss.service';
import { Logging } from '~services/logging/logging.service';
import { SelectLanguage } from '~app/state/language/language.action';
import { isBarcodeAvailable, isPassportAvailable } from '~services/ha-cuss/device.service';
import { activeSession, isAccessibilityMode, locatedPnr, socketAlive } from '~services/emitters/session-event-emitters';
import { Subscription } from 'rxjs';
import { AccessibilityService } from '~services/accessibility/accessibility.service';

import { AppRoutes } from '~app/app-routes';
import { ConfigService } from '~app/services/api/config/config.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy, AfterViewInit {
  public timeout;
  public validSession = false;
  public barcodeEnabled;
  public passportEnabled;
  public showSplashScreen: true;
  public versionNumber: string;
  public configSubscription: Subscription;
  public timeoutMenuConfig;
  public elements = [];
  AppRoutes = AppRoutes;

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private logging: Logging,
    private haCussService: HaCussService,
    public configService: ConfigService,
    private accessibilityService: AccessibilityService,
    private el: ElementRef
  ) {
    this.store.dispatch(new PnrLocatorFlowClearState());

    activeSession.subscribe((isActive) => {
      this.validSession = isActive;
    });

    socketAlive.subscribe((isAlive) => {
      this.showSplashScreen = isAlive;
    });

    this.versionNumber = `${environment.version}-${environment.buildNumber}`;
    this.barcodeEnabled = true;
    this.passportEnabled = true;

    this.configSubscription = this.configService.config.subscribe((x) => {
      if (x && x.configuration) {
        this.timeoutMenuConfig = x.timeout.menuSec;
      }
    });
  }

  @HostListener('document:click', ['$event.target'])
  public onClick() {
    this.setTimer();
  }

  ngOnInit() {
    if (!isAccessibilityMode.getValue()) {
      this.haCussService.setAvailable();
    }
    // Report no active session
    activeSession.emit(false);
    locatedPnr.next(false);
    startSession(this.logging);

    this.configService.getConfig();
    this.haCussService.enableLookupDevices();

    isBarcodeAvailable.subscribe((value) => {
      this.barcodeEnabled = value;
    });

    isPassportAvailable.subscribe((value) => {
      this.passportEnabled = value;
    });

    this.haCussService.watchDevices();
    this.logging.resetSession();
    this.logging.infoUiHomePageDisplayed();

    this.setTimer();

    this.haCussService.enableATBPrinter();
    this.haCussService.clearBpData();
  }

  ngOnDestroy() {
    clearTimeout(this.timeout);
    this.haCussService.unwatchDevices();
  }

  ngAfterViewInit() {
    const elements = this.el.nativeElement.querySelectorAll('[tabindex]');
    this.accessibilityService.setPageElementsToRead({
      elements,
    });
  }

  setTimer() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (!this.validSession && !isAccessibilityMode.getValue()) {
        this.router.navigate([AppRoutes.SPLASH_SCREEN]);
      }
    }, this.timeoutMenuConfig * 1000);
  }

  selectLang(lang: string) {
    this.logging.infoUiLanguageChanged(lang, 0);
    this.store.dispatch(new SelectLanguage(lang));
  }
}
