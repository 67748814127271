import { Component, OnDestroy } from '@angular/core';
import * as dateFns from 'date-fns';

@Component({
  selector: 'app-timer-date',
  templateUrl: './timer-date.component.html',
  styleUrls: ['./timer-date.component.scss'],
})
export class TimerDateComponent implements OnDestroy {
  public timer: any = [];
  public date: any = [];
  public welcomeMessage = 'Mahalo!';
  public timestamps: any = [];
  public days: any = [];
  public interval;

  constructor() {
    this.interval = setInterval(() => {
      this.timer = new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      this.date = dateFns.format(new Date(), 'dddd [-] MMMM D');
    }, 500);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
