import { Action } from '@ngrx/store';

export enum PnrLocatorFlowActionTypes {
  pnrLocatorFlowUpdateState = '[pnrLocatorFlow] Add Flight Number',
  pnrLocatorFlowClearState = '[pnrLocatorFlow] Clear State',
}

export class PnrLocatorFlowUpdateState implements Action {
  readonly type = PnrLocatorFlowActionTypes.pnrLocatorFlowUpdateState;
  constructor(private payload: {}) {}
}

export class PnrLocatorFlowClearState implements Action {
  readonly type = PnrLocatorFlowActionTypes.pnrLocatorFlowClearState;
}
