import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { SelectLanguage } from '../../../../state/language/language.action';
import { Store } from '@ngrx/store';
import { AppState } from '~app/state';
import { Logging } from '~app/services/logging/logging.service';
import { Router } from '@angular/router';
import { AppRoutes } from '~app/app-routes';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';

@Component({
  selector: 'app-restricted',
  templateUrl: './restricted.component.html',
  styleUrls: ['./restricted.component.scss', '../hazmat.component.scss'],
})
export class RestrictedComponent implements AfterViewInit {
  constructor(
    private store: Store<AppState>,
    private logging: Logging,
    public router: Router,
    private accessibilityService: AccessibilityService,
    private el: ElementRef
  ) {}

  selectLang(lang: string) {
    this.logging.infoUiLanguageChanged(lang, 0);
    this.store.dispatch(new SelectLanguage(lang));
  }

  continue() {
    this.logging.infoUiHazMatPage2ContinueSelected(0);
    this.router.navigate([AppRoutes.HAZMAT_NO_SELF_TAGGING]);
  }

  ngAfterViewInit(): void {
    this.logging.infoUiHazMatPage2Displayed(0);
  }
}
