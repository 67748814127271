import { BarcodeActionTypes } from './barcode.actions';
import { hardwareDeviceStatus } from '../../../services/ha-cuss/device.service';
import { Parser } from '../../../services/ha-cuss/device-parser/parser';

export interface BarcodeState {
  deviceName: string | null;
  deviceMode: string | null;
  deviceLogs: string[];
  barcodeInserted: string | null;
  barcodeDamaged: string | null;
  barcodeRemoved: string | null;
  barcodeData: string | null;
  barcodeStatusChange: [number, boolean] | any;
  barcodeStatusIsOK: string;
}

export const initialState: BarcodeState = {
  deviceName: '',
  deviceMode: '',
  deviceLogs: [],
  barcodeInserted: '',
  barcodeDamaged: '',
  barcodeRemoved: '',
  barcodeData: '',
  barcodeStatusChange: hardwareDeviceStatus.barcodeDevice,
  barcodeStatusIsOK: '',
};

export function barcodeReducers(state: BarcodeState = initialState, action): BarcodeState {
  let callbackLogs = '';
  // Different action reducer processes
  switch (action.type) {
    case BarcodeActionTypes.BarcodeEnable: {
      callbackLogs = 'Event: barcode-enable';
      state.deviceName = 'barcode';
      state.deviceMode = '[Barcode] Device Enabled';
      state.deviceLogs = [callbackLogs];
      state.barcodeInserted = '';
      state.barcodeDamaged = '';
      state.barcodeRemoved = '';
      state.barcodeData = '';
      state.barcodeStatusIsOK = '';
      return { ...state };
    }
    case BarcodeActionTypes.BarcodeDisable: {
      callbackLogs = 'Event: barcode-disable';
      state.deviceName = 'barcode';
      state.deviceMode = '[Barcode] Device Disabled';
      state.deviceLogs = [callbackLogs];
      state.barcodeInserted = '';
      state.barcodeDamaged = '';
      state.barcodeRemoved = '';
      state.barcodeData = '';
      state.barcodeStatusIsOK = '';
      return { ...state };
    }
    case BarcodeActionTypes.BarcodeInserted: {
      callbackLogs = 'Event: barcodeInserted';
      state.deviceLogs = [callbackLogs];
      state.deviceMode = '[Barcode] Inserted';
      state.barcodeInserted = action.payload.barcodeInserted;
      return { ...state };
    }
    case BarcodeActionTypes.BarcodeDamaged: {
      callbackLogs = 'Event: barcodeDamaged';
      state.deviceLogs = [callbackLogs];
      state.deviceMode = '[Barcode] Damaged';
      state.barcodeDamaged = action.payload.barcodeDamaged;
      return { ...state };
    }
    case BarcodeActionTypes.BarcodeRemoved: {
      callbackLogs = 'Event: barcodeRemoved';
      state.deviceLogs = [callbackLogs];
      state.deviceMode = '[Barcode] Removed';
      state.barcodeRemoved = action.payload.barcodeRemoved;
      return { ...state };
    }
    case BarcodeActionTypes.BarcodeReadInternal: {
      callbackLogs = 'Event: barcodeReadInternal - Barcode Data: ' + action.payload.barcodeData;
      state.deviceLogs = [callbackLogs];
      state.deviceMode = '[Barcode] Read Internal';
      if (action.payload.barcodeData !== null) {
        const barcodePNR = Parser.getPNRFromBarcode(action.payload.barcodeData);
        if (barcodePNR.length === 6 && Parser.isContainsAlphaOnly(barcodePNR)) {
          state.barcodeData = barcodePNR.toUpperCase();
        } else {
          state.barcodeData = 'Invalid barcode scanned';
        }
      } else {
        state.barcodeData = action.payload.barcodeData;
      }
      return { ...state };
    }
    case BarcodeActionTypes.BarcodeStatusChange: {
      callbackLogs = 'Event: statusChange';
      state.deviceMode = '[Barcode] Status Change';
      state.deviceLogs = [callbackLogs];
      state.barcodeStatusChange = action.payload.barcodeStatusChange;
      return { ...state };
    }
    case BarcodeActionTypes.BarcodeStatusIsOK: {
      callbackLogs = 'Event: statusIsOk';
      state.deviceLogs = [callbackLogs];
      state.deviceMode = '[Barcode] Status is Ok';
      state.barcodeStatusIsOK = action.payload.barcodeStatusIsOK;
      return { ...state };
    }
    default:
      callbackLogs = 'default: ' + JSON.stringify(action.payload);
      state.deviceLogs = [callbackLogs];
      return state;
  }
}
