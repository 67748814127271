import { Action } from '@ngrx/store';

export enum BagTagPrinterActionTypes {
  BagTagPrinterEnable = '[BagTag] Device Enabled',
  BagTagPrinterDisable = '[BagTag] Device Disabled',
  BagTagPECTabLoadingComplete = '[BagTag] Table Loading Complete',
  BagTagPrintingComplete = '[BagTag] Printing Complete',
  BagTagFailed = '[BagTag] Device Failed',
  BagTagPectabFailed = '[BagTag] Table Failed',
  BagTagPectabLoaded = '[BagTag] Table Loaded',
  BagTagPrinterStatusChange = '[BagTag] Status Change',
  BagTagPrinted = '[BagTag] Printed',
  BagTagPrinterStatusIsOK = '[BagTag] Status is Ok',
}

export class BagTagPrinterEnable implements Action {
  readonly type = BagTagPrinterActionTypes.BagTagPrinterEnable;
  constructor(readonly payload: string) {}
}

export class BagTagPrinterDisable implements Action {
  readonly type = BagTagPrinterActionTypes.BagTagPrinterDisable;
  constructor(readonly payload: string) {}
}

export class BagTagPECTabLoadingComplete implements Action {
  readonly type = BagTagPrinterActionTypes.BagTagPECTabLoadingComplete;
  payload: { event_id: string; bagTagPecTabLoadingComplete: string };
  constructor(eventId: string, bagTagPecTabLoadingComplete: string) {
    this.payload = { event_id: eventId, bagTagPecTabLoadingComplete };
  }
}

export class BagTagPrintingComplete implements Action {
  readonly type = BagTagPrinterActionTypes.BagTagPrintingComplete;
  payload: { event_id: string; bagTagPrintingComplete: string };
  constructor(eventId: string, bagTagPrintingComplete: string) {
    this.payload = { event_id: eventId, bagTagPrintingComplete };
  }
}

export class BagTagFailed implements Action {
  readonly type = BagTagPrinterActionTypes.BagTagFailed;
  payload: { event_id: string; bagTagFailed: string };
  constructor(eventId: string, bagTagFailed: string) {
    this.payload = { event_id: eventId, bagTagFailed };
  }
}

export class BagTagPectabFailed implements Action {
  readonly type = BagTagPrinterActionTypes.BagTagPectabFailed;
  payload: { event_id: string; bagTagPectabFailed: string };
  constructor(eventId: string, bagTagPectabFailed: string) {
    this.payload = { event_id: eventId, bagTagPectabFailed };
  }
}

export class BagTagPectabLoaded implements Action {
  readonly type = BagTagPrinterActionTypes.BagTagPectabLoaded;
  payload: { event_id: string; bagTagPectabLoaded: string };
  constructor(eventId: string, bagTagPectabLoaded: string) {
    this.payload = { event_id: eventId, bagTagPectabLoaded };
  }
}

export class BagTagPrinterStatusChange implements Action {
  readonly type = BagTagPrinterActionTypes.BagTagPrinterStatusChange;
  payload: { event_id: string; bagTagPrinterStatusChange: [number, boolean] };
  constructor(eventId: string, bagTagPrinterStatusChange: [number, boolean]) {
    this.payload = { event_id: eventId, bagTagPrinterStatusChange };
  }
}

export class BagTagPrinted implements Action {
  readonly type = BagTagPrinterActionTypes.BagTagPrinted;
  payload: { event_id: string; bagTagPrinted: string };
  constructor(eventId: string, bagTagPrinted: string) {
    this.payload = { event_id: eventId, bagTagPrinted };
  }
}

export class BagTagPrinterStatusIsOK implements Action {
  readonly type = BagTagPrinterActionTypes.BagTagPrinterStatusIsOK;
  payload: { event_id: string; bagTagPrinterStatusIsOK: string };
  constructor(eventId: string, bagTagPrinterStatusIsOK: string) {
    this.payload = { event_id: eventId, bagTagPrinterStatusIsOK };
  }
}
export type BagTagPrinterActions =
  | BagTagPrinterEnable
  | BagTagPrinterDisable
  | BagTagPECTabLoadingComplete
  | BagTagPrintingComplete
  | BagTagFailed
  | BagTagPectabFailed
  | BagTagPectabLoaded
  | BagTagPrinterStatusChange
  | BagTagPrinted
  | BagTagPrinterStatusIsOK;
