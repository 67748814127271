import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../index';
import { LoadingService } from '../../services/ui/loading.service';
import { LoadingFlowActionTypes } from './loading-flow.action';
import { selectCurrentLoadingState } from './loading-flow.selectors';
import { Router } from '@angular/router';
import { LoadingFlowState } from './loading-flow.reducer';
import { AppRoutes } from '~app/app-routes';

@Injectable()
export class LoadingStateEffects {
  // Rules of keys(requests) that should run in bachground for some pages.
  rulesOfLoadingKeys = {
    catalogs: {
      ignoreLoadingPages: [
        AppRoutes.LOCATE_RESERVATION_CONFIRMATION_CODE,
        AppRoutes.ITINERARY,
        AppRoutes.OPTIONS,
        AppRoutes.PRINT_DOCUMENTS,
        AppRoutes.HAZMAT,
        AppRoutes.CONFIRMATION,
      ],
    },
    documents: {
      ignoreLoadingPages: [AppRoutes.PRINT_DOCUMENTS, AppRoutes.HAZMAT, AppRoutes.CONFIRMATION],
    },
  };

  @Effect({ dispatch: false })
  loadingStateToggle$ = this.actions$.pipe(
    ofType(LoadingFlowActionTypes.ToggleState),
    withLatestFrom(this.store.select(selectCurrentLoadingState)),
    map(([_, state]) => {
      let showLoading = false;

      showLoading = this.shouldShowLoading(state);

      if (!showLoading) {
        this.loadingService.hideLoading();
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    public loadingService: LoadingService,
    private router: Router
  ) {}

  shouldShowLoading(loadingFlowState: LoadingFlowState): boolean {
    let hasTrueState = false;

    for (const key in loadingFlowState) {
      if (loadingFlowState[key] && !this.ignoreKey(key)) {
        hasTrueState = true;
        this.loadingService.showLoading();
      }
    }

    return hasTrueState;
  }

  ignoreKey(key: string): boolean {
    const currentUrl = this.router.url;
    let shoulBeIgnored = false;
    const ruleToIgnoreKey = this.rulesOfLoadingKeys[key];

    if (ruleToIgnoreKey) {
      ruleToIgnoreKey.ignoreLoadingPages.forEach((page: string) => {
        if (currentUrl.match(page)) {
          shoulBeIgnored = true;
        }
      });
    }

    return shoulBeIgnored;
  }
}
