import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  AppState,
  ErrorActionTypes,
  PartialCarts,
  PartialCartsResults,
  PartialCartsTypes,
  SessionEnd,
  SetBPFError,
  SetNotFoundError,
  SetPartialSuccessError,
  SetReferToAgentError,
  SetSAFAndBPFError,
  SetSAFError,
  SetSPFAndBPFError,
  SetSPFError,
} from '../index';
import {
  SetMilitaryNoMatchError,
  SetReferToAgentDontPrintError,
  SetSeatChangeError,
  SetTimeoutError,
} from './error.action';
import { containsOnly, getFirstParameterThatMatches } from '../../utils/helpers';
import { Logging } from '../../services/logging/logging.service';
import { LoggingInterceptor } from '../../services/logging/logging-interceptor';
import { selectPnrLocatorFlow, ToggleState } from '..';
import { Router } from '@angular/router';
import { AppRoutes } from '~app/app-routes';
import { AlertMessageCode, AlertReasonCode } from '~app/models/error/error.model';
import { AgentAssistRoutes } from '~app/agent-assist/agent-assist-routes';
import { ApiRoutes } from '~app/services/api/api-routes';

const LOCATE_METHODS = ['confirmationCode', 'hawaiianMilesNumber', 'ticketNumber'];

@Injectable()
export class ErrorInterceptor extends LoggingInterceptor implements HttpInterceptor {
  public lookupMethod: string;

  constructor(private store: Store<AppState>, private router: Router, protected logging: Logging) {
    super(logging);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store
      .select(selectPnrLocatorFlow)
      .subscribe((pnr) => (this.lookupMethod = pnr.lookupMethod))
      .unsubscribe();
    this.logging.infoApiRequestStarted(request);
    let shouldDispatchLoading = false;
    if (request.body) {
      shouldDispatchLoading = this.validateRequestForLoading(request, shouldDispatchLoading);
    }
    shouldDispatchLoading ? this.callToggleState(request, false) : this.callToggleState(request, true);

    return next.handle(request).pipe(
      tap(
        (event) => {
          // --- Logging stuff
          try {
            if (event instanceof HttpResponse) {
              this.logging.infoApiRequestEnded(request, event);
              this.callToggleState(request, false);
              this.handlePnrLookupResponse(request, event, this.lookupMethod);
              this.handlePatchTripsResponse(request, event);
              this.handlePatchPaxResponse(request, event);
              this.handleAlertReasonCodeResponse(request, event);
              this.handleCatalogsResponse(request, event);
              this.handleBagSaveSuccessful(request, event);
              this.handleGetCartSuccessful(request, event);
              this.handleDeleteCartSuccessful(request, event);
            }
          } catch (e) {
            this.logging.logException(e);
          }
          // --- End of logging stuff
          if (event instanceof HttpResponse) {
            const { body } = event;
            if (body) {
              if (body.count === 0) {
                this.store.dispatch(new SetTimeoutError());
              }
            }

            // Partial Carts Logic
            if (event.status === 207) {
              // We know we have either encountered an error or received a partial carts response
              // Set a variable to the correct type now that we know its a partial success
              const partialCarts: PartialCarts = event.body;
              // Find the object in the results array that failed, could be multiple
              const partialCartsErrors = this.getPartialCartsErrors(partialCarts);

              this.handlePartialCartsErrors(partialCartsErrors);
            }
          }
        },
        (error) => {
          const { url } = error;
          // --- Logging stuff
          try {
            if (error instanceof HttpErrorResponse) {
              this.logging.infoApiRequestEnded(request, error);
              this.callToggleState(request, false);
              this.logging.errorApiTrackException(request, error);
              this.handleCheckinFailed(request, error);
              this.handlePaxUpdateFailed(request, error);
              this.handleCatalogsFailed(request, error);
              this.handleBagSaveFailed(request, error);
              this.handleGetCartFailed(request, error);
              this.handleDeleteCartFailed(request, error);
            }
          } catch (e) {
            this.logging.logException(e);
          }
          // --- End of logging stuff
          if (error instanceof HttpErrorResponse) {
            if (url) {
              if (
                url.includes(ApiRoutes.MACHINE_CONFIG) ||
                url.includes(ApiRoutes.LAUNCHER) ||
                url.includes(ApiRoutes.BAGS) ||
                url.includes(AgentAssistRoutes.MAIN)
              ) {
                return;
              }

              if (url.includes(ApiRoutes.CATALOGS) || url.includes('/passenger')) {
                throw error;
              }
            }

            if (request.method === 'DELETE' || this.checkCartConcurrency(error)) {
              return;
            }

            switch (error.status) {
              case 409: {
                if (this.lookupMethod === 'creditCard' && error.error.count > 1) {
                  this.router.navigate([AppRoutes.LOCATE_RESERVATION_BIRTHDAY]);
                  break;
                }

                this.logging.infoApiPnrFailed(request, error);

                if (error.error && error.error.count > 0) {
                  this.store.dispatch(new SetReferToAgentDontPrintError());
                  throw error;
                }

                const entry = getFirstParameterThatMatches(LOCATE_METHODS, url);
                if (entry) {
                  this.store.dispatch(new SetNotFoundError({ entry }));
                  throw error;
                }

                this.store.dispatch(new SetReferToAgentDontPrintError());
                throw error;
              }
              case 400: {
                if (request.body[0].path.includes('militaryTravelType')) {
                  // Allow catchError in the effect to handle this
                  this.store.dispatch(new SetMilitaryNoMatchError());
                  return;
                }
                this.dispatchGenericError(error);
                break;
              }

              case 422: {
                if (request.url.includes('items')) {
                  this.store.dispatch(new SetSeatChangeError());
                  return;
                }
                this.dispatchGenericError(error);
                break;
              }

              case 500: {
                if (request.url.includes(ApiRoutes.CARTS) && request.method === 'PUT') {
                  throw error;
                }

                this.dispatchGenericError(error);
                break;
              }

              case 0: {
                // We have received an empty response, most likely due to a timeout since we do not know what happened
                // we will show a generic timeout
                this.dispatchGenericError(error);
                break;
              }

              default:
                this.dispatchGenericError(error);
            }

            // Make sure logging gets it.
            throw error;
          }
        }
      )
    );
  }

  private handlePartialCartsErrors(partialCartsErrors: string[]) {
    if (
      containsOnly(
        [PartialCartsTypes.SPF, PartialCartsTypes.BPF, PartialCartsTypes.SAF, PartialCartsTypes.BFF],
        partialCartsErrors
      )
    ) {
      this.dispatchSetPartialSuccessError(
        SetSPFAndBPFError,
        ErrorActionTypes.SetSPFAndBPFError,
        AlertReasonCode.PC04,
        AlertMessageCode.PC04
      );
    } else if (
      containsOnly([PartialCartsTypes.SAF, PartialCartsTypes.BPF, PartialCartsTypes.BFF], partialCartsErrors)
    ) {
      this.dispatchSetPartialSuccessError(
        SetSAFAndBPFError,
        ErrorActionTypes.SetSAFAndBPFError,
        AlertReasonCode.PC05,
        AlertMessageCode.PC05
      );
    } else if (
      containsOnly([PartialCartsTypes.SPF, PartialCartsTypes.SAF, PartialCartsTypes.BFF], partialCartsErrors)
    ) {
      this.dispatchSetReferToAgentError(AlertReasonCode.PC02, AlertMessageCode.PC02);
    } else if (containsOnly([PartialCartsTypes.SAF, PartialCartsTypes.BFF], partialCartsErrors)) {
      this.dispatchSetReferToAgentError(AlertReasonCode.PC03, AlertMessageCode.PC03);
    } else if (containsOnly([PartialCartsTypes.SPF, PartialCartsTypes.SAF], partialCartsErrors)) {
      this.dispatchSetPartialSuccessError(
        SetSPFError,
        ErrorActionTypes.SetSPFError,
        AlertReasonCode.PC06,
        AlertMessageCode.PC06
      );
    } else if (containsOnly([PartialCartsTypes.BFF], partialCartsErrors)) {
      this.dispatchSetReferToAgentError(AlertReasonCode.PC01, AlertMessageCode.PC01);
    } else if (containsOnly([PartialCartsTypes.SAF], partialCartsErrors)) {
      this.dispatchSetPartialSuccessError(
        SetSAFError,
        ErrorActionTypes.SetSAFError,
        AlertReasonCode.PC07,
        AlertMessageCode.PC07
      );
    } else if (containsOnly([PartialCartsTypes.BPF, PartialCartsTypes.BFF], partialCartsErrors)) {
      this.dispatchSetPartialSuccessError(
        SetBPFError,
        ErrorActionTypes.SetBPFError,
        AlertReasonCode.PC08,
        AlertMessageCode.PC08
      );
    }
  }

  private getPartialCartsErrors(partialCarts: PartialCarts) {
    return partialCarts.results
      .filter((partialCartsResults: PartialCartsResults) => partialCartsResults.status !== 200)
      .map((partialCartsResults) => partialCartsResults.rangeReference);
  }

  private validateRequestForLoading(request: HttpRequest<any>, shouldDispatchLoading: boolean) {
    if (/(\/bags)/.test(request.url)) {
      shouldDispatchLoading = true;
    } else if (
      request.body.documentType !== null &&
      request.body.documentType !== undefined &&
      /(\/documents)/.test(request.url)
    ) {
      shouldDispatchLoading = request.body.documentType.match(/^(receipt)$/);
    }
    return shouldDispatchLoading;
  }

  callToggleState(request, state) {
    if (request.method !== 'DELETE') {
      this.store.dispatch(new ToggleState({ url: request.url, state }));
    }
  }

  checkCartConcurrency(response) {
    if (response.error && response.error.errors) {
      const codeObj = response.error.errors.map((errorObj) => errorObj.code);
      if (
        (/\/carts/.test(response.url) && codeObj.indexOf(400) >= 0) ||
        (/\/checkout/.test(response.url) && codeObj.indexOf(500) >= 0 && !/payment/.test(this.router.url))
      ) {
        this.store.dispatch(new SessionEnd());
        return true;
      }
    }
    return false;
  }

  private dispatchSetReferToAgentError(alertReasonCode: AlertReasonCode, alertMessageCode: AlertMessageCode) {
    this.store.dispatch(new SetReferToAgentError({ alertReasonCode, alertMessageCode }));
  }

  private dispatchSetPartialSuccessError(
    clazz: typeof SetPartialSuccessError,
    errorActionType: ErrorActionTypes,
    alertReasonCode: AlertReasonCode,
    alertMessageCode: AlertMessageCode
  ) {
    this.store.dispatch(new clazz(errorActionType, { alertReasonCode, alertMessageCode }));
  }

  private dispatchGenericError(response: HttpErrorResponse) {
    this.store.dispatch(new SetTimeoutError());
    throw response;
  }
}
