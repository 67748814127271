import { ScrollableList } from './scrollable-list.model';
import { ItineraryViewActions, ItineraryViewActionTypes } from './itinerary-view.actions';

export interface ItineraryViewState {
  scrollableList: ScrollableList;
}

export const initialState: ItineraryViewState = {
  scrollableList: null,
};

export function itineraryViewReducer(state = initialState, action: ItineraryViewActions): ItineraryViewState {
  switch (action.type) {
    case ItineraryViewActionTypes.LoadItineraryView: {
      const { itineraryView } = action.payload;
      return { ...state, ...itineraryView };
    }
    case ItineraryViewActionTypes.LoadScrollableList: {
      const { scrollableList } = action.payload;
      return { ...state.scrollableList, scrollableList };
    }
    case ItineraryViewActionTypes.ScrollUpPassengerList: {
      const { scrollableList } = action.payload;
      scrollableList.startIndex = scrollableList.startIndex - 1;
      scrollableList.endIndex = scrollableList.endIndex - 1;
      return { ...state.scrollableList, scrollableList };
    }
    case ItineraryViewActionTypes.ScrollDownPassengerList: {
      const { scrollableList } = action.payload;
      scrollableList.startIndex = scrollableList.startIndex + 1;
      scrollableList.endIndex = scrollableList.endIndex + 1;
      return { ...state.scrollableList, scrollableList };
    }
    default:
      return state;
  }
}
