import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '~app/app-routes';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent {
  public active = false;
  public scrollDown = true;
  public scrollUp = false;
  public iconColor = 'green';

  public progress = 0;
  public amount = 200;

  public activeList;
  public steps;
  public itemsPerStep = 4;
  public items = [];

  public bagsRoute = AppRoutes.BAGS;

  public arrowTabIndex = 0;

  @Input() set values(values) {
    this.items = values;
    this.steps = Math.ceil(this.items.length / this.itemsPerStep);

    if (this.steps > 1) {
      this.active = true;
    } else {
      this.active = false;
    }

    this.assembleList();
  }

  @Input() public upArrowAccessibilityRole: string;
  @Input() public downArrowAccessibilityRole: string;

  @Input() public row: TemplateRef<any>;

  @Input() set customArrowTabIndex(tabIndex) {
    this.arrowTabIndex = tabIndex ? tabIndex : 0;
  }

  @Input() set color(color) {
    this.iconColor = color;
  }

  @Input() set rows(value) {
    this.itemsPerStep = value;
    this.steps = Math.ceil(this.items.length / this.itemsPerStep);

    if (this.steps > 1) {
      this.active = true;
    } else {
      this.active = false;
    }

    this.assembleList();
  }

  @Output() messageEvent = new EventEmitter<any>();

  @ViewChild('holder', { static: true }) holder: ElementRef;
  @ViewChild('grid', { static: true }) grid: ElementRef;
  @ViewChild('scrollUp', { static: false }) buttonUpElement: ElementRef;
  @ViewChild('scrollDown', { static: false }) buttonDownElement: ElementRef;

  constructor(public Element: ElementRef, public router: Router) {
    if (this.router.url === this.bagsRoute) {
      this.itemsPerStep = 3;
    }

    if (this.router.url === AppRoutes.SPECIAL_ITEMS) {
      this.itemsPerStep = 5;
    }
  }

  assembleList() {
    this.activeList = [];
    const init = this.progress * this.itemsPerStep;
    this.activeList = this.items.slice(init, init + this.itemsPerStep);
  }

  onScrollUp() {
    if (this.progress <= 0) {
      return false;
    }

    this.scrollDown = true;
    this.progress--;

    if (this.progress <= 0) {
      this.scrollUp = false;
    }
    this.assembleList();
    this.messageEvent.emit();
  }

  onScrollDown() {
    if (this.progress >= this.steps - 1) {
      return false;
    }

    this.progress++;
    this.scrollUp = true;

    if (this.progress === this.steps - 1) {
      this.scrollDown = false;
    }
    this.assembleList();
    this.messageEvent.emit();
  }
}
