import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { API } from '../api.service';
import { environment } from '../../../../environments/environment';
import { MachineConfig } from './config';
import { airportCode, configurationSettings, kioskId } from '../../emitters/session-event-emitters';
import { Logging } from '../../logging/logging.service';

const defaultConfiguration = require('../../../../assets/config.json')[0];

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public config = new BehaviorSubject<MachineConfig | any>([]);
  private delay = 2000;
  private apiService: any;
  private numberOfRetries = 3;

  constructor(private injector: Injector, private logging: Logging) {}

  async getConfig() {
    if (!environment.cussEnabled && !environment.kioskId) {
      this.config.next(defaultConfiguration);
    } else {
      this.exponentialBackoff(this.numberOfRetries, this.delay);
    }
  }

  async getLauncher() {
    this.setApiService(kioskId.getValue());
    return this.apiService.doVersionCall();
  }

  exponentialBackoff(retries, delay) {
    this.validateKioskId(kioskId.getValue(), retries, delay);
  }

  validateKioskId(id, retries, delay) {
    id = environment.kioskId ? environment.kioskId : id;

    this.setApiService(id);
    this.apiService.doConfigCall().then((configuration) => {
      if (!configuration) {
        this.reloadAndRetry(retries, delay);
      } else {
        if (configuration.status) {
          this.reloadAndRetry(retries, delay);
        } else {
          const returnedConfig: MachineConfig = configuration[0];
          this.config.next(returnedConfig);
          configurationSettings.next(returnedConfig.configuration);
          airportCode.next(returnedConfig.airportCode);
          this.logging.infoUiSessionStarted(0, returnedConfig);
          this.logging.infoApiConfigResponse(returnedConfig);
        }
      }
      return;
    });
  }

  private setApiService(id) {
    this.apiService = this.injector.get(API);
    if (this.apiService.getQueryParamsCount().length > 0) {
      this.apiService.clearQueryParams();
    }
    this.apiService.appendQueryParams(this.apiService.apiQParams.config.machineId.key, id);
  }

  reloadAndRetry(retries, delay) {
    if (this.numberOfRetries > 0) {
      setTimeout(() => {
        this.exponentialBackoff(--this.numberOfRetries, delay);
      }, delay);
    } else {
      this.config.next(defaultConfiguration);
      configurationSettings.next(defaultConfiguration.configuration);
      airportCode.next(defaultConfiguration.airportCode);
    }
  }

  public get configData(): Observable<any> {
    return this.config.asObservable();
  }
}
