import { Component, Input } from '@angular/core';
import { Cart } from '~app/state';

@Component({
  selector: 'app-total-table',
  templateUrl: './total-table.component.html',
  styleUrls: ['./total-table.component.scss', '../payment.component.scss'],
})
export class TotalTableComponent {
  public total;

  @Input() public currency: string;

  @Input() set cart(cart: Cart) {
    this.total = cart.getGrandTotalPrice();
  }
}
