import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Logging } from '~app/services/logging/logging.service';
import { sortByTabIndexValue } from '~app/utils/query-by-tabindex';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { isAccessibilityMode } from '~app/services/emitters/session-event-emitters';
import { SPEECH_STATUS } from '~app/services/ha-cuss/device.service';
import { Trip, selectCurrentTrip, AppState } from '~app/state';
import { Subject, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-special-items',
  templateUrl: './special-items.component.html',
  styleUrls: ['./special-items.component.scss'],
})
export class SpecialItemsComponent implements AfterViewInit, OnInit, OnDestroy {
  private element;

  isOA: boolean;
  trip: Trip;

  unsubscribe$ = new Subject<void>();
  trip$: Observable<Trip>;

  constructor(
    private logging: Logging,
    private el: ElementRef,
    private changeRef: ChangeDetectorRef,
    private accessibilityService: AccessibilityService,
    private store: Store<AppState>
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.trip$ = this.store.pipe(select(selectCurrentTrip));

    this.trip$.pipe(takeUntil(this.unsubscribe$)).subscribe((trip: Trip) => {
      this.trip = trip;
      if (this.trip) {
        const activeSegment = this.trip.findActiveSegment();
        if (activeSegment) {
          this.isOA = activeSegment.isOASegment;
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.logging.infoUiSpecialItemsPageDisplayed(0);

    if (isAccessibilityMode.getValue()) {
      this.setElementsToRead({ startIndex: 0 });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onGridScrolled() {
    if (isAccessibilityMode.getValue()) {
      this.setElementsToRead({ startIndex: 1 });
      this.accessibilityService.waitAndReadCurrentElement();
    }
  }

  private setElementsToRead({ startIndex }) {
    this.changeRef.detectChanges();

    const headerElementsToRead = this.getHeaderElementsToRead();
    const productElementsToRead = this.getProductElementsToRead();
    const scrollElementsToRead = this.getScrollElementsToRead();
    const footerElementsToRead = this.getFooterElementsToRead();
    const buttonElementsToRead = this.getButtonElementsToRead();
    const startOverElement = this.getStartOverElementToRead();

    const elementsToRead = [
      ...headerElementsToRead,
      ...productElementsToRead,
      ...scrollElementsToRead,
      ...footerElementsToRead,
      ...buttonElementsToRead,
      ...startOverElement,
    ];

    this.accessibilityService.setElementsToRead(elementsToRead, startIndex, false);
  }

  private getHeaderElementsToRead() {
    const elementsToRead = Array.from(this.element.querySelectorAll('#special-items-title-text'));
    return elementsToRead;
  }

  private getProductElementsToRead() {
    const elementsToRead = [];
    const productsContainer = Array.from(this.element.querySelectorAll('.product-container'));

    productsContainer.forEach((productContainer: HTMLElement) => {
      const productElementsToRead = Array.from(productContainer.querySelectorAll('[tabindex]')) as HTMLElement[];
      const productElementsToReadSorted = sortByTabIndexValue(productElementsToRead);

      elementsToRead.push(...productElementsToReadSorted);
    });

    return elementsToRead;
  }

  private getScrollElementsToRead() {
    const scrollUp = this.element.querySelector('.grid-handler #grid-scroll-up-button');
    const scrollDown = this.element.querySelector('.grid-handler #grid-scroll-down-button');

    return [scrollUp, scrollDown];
  }

  private getFooterElementsToRead() {
    const elementsToRead = Array.from(this.element.querySelectorAll('.bottom-bar [tabindex]'));
    return elementsToRead;
  }

  private getButtonElementsToRead() {
    const elementsToRead = Array.from(this.element.querySelectorAll('.bags-services-container button'));
    return elementsToRead;
  }

  private getStartOverElementToRead() {
    const startOver = Array.from(this.element.querySelectorAll('#start-over-exit-button'));
    return startOver;
  }
}
