import { AuthTokenActionTypes } from './auth-token.actions';

export interface AuthTokenState {
  access_token: string | null;
  expiration: string | null;
  expires_in: string | null;
  token_type: string | null;
  pnr: string | null;
}

export const initialAuthTokenState: AuthTokenState = {
  access_token: '',
  expiration: '',
  expires_in: '',
  token_type: '',
  pnr: '',
};

export function AuthTokenReducer(state = initialAuthTokenState, action): AuthTokenState {
  switch (action.type) {
    case AuthTokenActionTypes.AddAuthToken:
      state = action.payload;
      return state;

    case AuthTokenActionTypes.ClearAuthToken:
      return initialAuthTokenState;

    default:
      return state;
  }
}
