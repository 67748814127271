import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { Logging } from '../logging/logging.service';
import * as StackTrace from 'stacktrace-js';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error) {
    const loggingService = this.injector.get(Logging);
    try {
      // tslint:disable-next-line: deprecation
      const location = this.injector.get(LocationStrategy);
      const message = error.message ? error.message : error.toString();
      const url = location instanceof PathLocationStrategy ? location.path() : '';

      // get the last 10 stack trace lines
      StackTrace.fromError(error)
        .then((stackframes) => {
          const stackString = stackframes
            .splice(0, 20)
            .map(function(sf) {
              return sf.toString();
            })
            .join('\n');
          // log all the information we know about error
          loggingService.logException({ message, url, stackTrace: stackString });
        })
        .catch(() => {
          // Log the information we do have if we fail to parse stack trace
          loggingService.logException({ message, url });
        });
    } catch (err) {
      // Still log something if we fail to get any error data
      loggingService.logException({ message: `Failed to parse error ${err.toString()}` });
    }

    throw error;
  }
}
