import { AppState } from '../../index';
import { createSelector } from '@ngrx/store';
import { CardReaderState } from './card-reader.reducers';

const selectCardReader = (state: AppState) => state.cardReader;

export const selectCardSwipeCount = createSelector(
  selectCardReader,
  (state: CardReaderState) => state.cardReaderSwipeCount
);
