import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppState, Trip } from '~app/state';
import { Store } from '@ngrx/store';
import { Cart } from '~app/state/cart/cart.model';
import { AccessibilityMode } from '~app/decorators/accessibility.decorator';

@AccessibilityMode()
@Component({
  selector: 'app-pax-cart-table',
  templateUrl: './pax-cart-table.component.html',
  styleUrls: ['./pax-cart-table.component.scss', '../payment.component.scss'],
})
export class PaxCartTableComponent implements OnInit {
  @Input() public currency: string;
  @Input() public trip: Trip;
  @Input() public cart: Cart;

  public active = false;
  public scrollDown = true;
  public scrollUp = false;
  public progress = 0;
  public feeSummaryList: FeeSummary[] = [];
  public itemsPerStep = 1;
  public steps;
  public activeList;

  @Output() gridRendered = new EventEmitter<any>();
  @Output() gridScrolled = new EventEmitter<any>();
  private isGridRendered = false;

  @ViewChild('grid', { static: false }) set updateElement(_) {
    if (this.isGridRendered) {
      return;
    }

    this.gridRendered.emit({});
    this.isGridRendered = true;
  }

  constructor(public store: Store<AppState>) {}

  ngOnInit() {
    if (!this.cart || !this.cart.passengers) {
      return;
    }

    this.cart.passengers.forEach((pax) => {
      const regularBagsPrice = this.cart.getRegularBagsTotalPricePerPaxLocated(pax);
      const specialItemsPrice = this.cart.getSpecialBagsTotalPricePerPaxLocated(pax);

      const regularBagsTotalItems = this.cart.getRegularBagsTotalItemsPerPax(pax);
      const specialItemsTotalItems = this.cart.getSpecialItemsTotalItemsPerPax(pax);

      const seatPrice = this.cart.getSeatUpgradesPerPaxLocated(pax);

      this.feeSummaryList.push({
        name: this.trip.getPassengerNamesById(pax),
        regularBags: {
          value: regularBagsPrice,
          quantity: regularBagsTotalItems,
        },
        specialItems: {
          value: specialItemsPrice,
          quantity: specialItemsTotalItems,
        },
        seat: seatPrice,
      });
    });

    this.assembleList();
  }

  assembleList() {
    const initIndex = this.progress * this.itemsPerStep;

    this.activeList = [];
    this.activeList = this.feeSummaryList.slice(initIndex, this.itemsPerStep + initIndex);

    this.steps = Math.ceil(this.feeSummaryList.length / this.itemsPerStep);

    if (this.steps > 1) {
      this.active = true;
    }
  }

  onScrollUp() {
    if (this.progress <= 0) {
      return false;
    }

    this.scrollDown = true;
    this.progress--;

    this.assembleList();
    this.gridScrolled.emit({});

    if (this.progress <= 0) {
      this.scrollUp = false;
    }
  }

  onScrollDown() {
    if (this.progress >= this.steps - 1) {
      return false;
    }

    this.progress++;
    this.scrollUp = true;

    this.assembleList();
    this.gridScrolled.emit({});

    if (this.progress === this.steps - 1) {
      this.scrollDown = false;
    }
  }
}

interface FeeSummaryBags {
  quantity: number;
  value: string;
}

interface FeeSummary {
  name: string;
  regularBags: FeeSummaryBags;
  specialItems: FeeSummaryBags;
  seat: string;
}
