import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { SetContent } from '../../../../state/keyboard/keyboard.action';
import { take } from 'rxjs/operators';
import { PnrLocatorFlow } from '../../../../state/pnr-locator-flow/pnr-locator-flow.model';
import { KeyboardState } from '../../../../state/keyboard/keyboard.reducer';
import { Keyboard } from '../../../../state/keyboard/keyboard.model';
import { Router } from '@angular/router';
import { PnrLocatorFlowUpdateState } from '../../../../state/pnr-locator-flow/pnr-locator-flow.action';
import { PnrLocatorFlowState } from '../../../../state/pnr-locator-flow/pnr-locator-flow.reducer';
import { Logging } from '~app/services/logging/logging.service';
import { AppRoutes } from '~app/app-routes';
import { AccessibilityMode } from '../../../../decorators/accessibility.decorator';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';

@AccessibilityMode()
@Component({
  selector: 'app-by-name',
  templateUrl: './by-name.component.html',
  styleUrls: ['./by-name.component.scss', './../locate-reservation.component.scss'],
})
export class ByNameComponent implements OnInit, OnDestroy, AfterViewInit {
  keyboard$: Observable<Keyboard>;
  pnrLocatorFlow$: Observable<PnrLocatorFlow>;

  public pnrLocatorFlowSubscriber: Subscription;
  public keyboardSubscriber: Subscription;
  public code = '';
  public codeDone = false;
  public elements = [];
  public previousRoute: AppRoutes;

  constructor(
    private store: Store<KeyboardState>,
    private router: Router,
    private logging: Logging,
    public pnrLocatorFlowStore: Store<PnrLocatorFlowState>,
    private accessibilityService: AccessibilityService,
    private el: ElementRef
  ) {
    this.pnrLocatorFlow$ = pnrLocatorFlowStore.pipe(select('pnrLocatorFlow'));
    this.keyboard$ = store.pipe(select('keyboard'));
  }

  ngOnInit() {
    this.logging.infoUiFlightNumberLastNameLookupPageDisplayed(0);

    this.pnrLocatorFlowSubscriber = this.pnrLocatorFlow$.pipe(take(1)).subscribe((value: PnrLocatorFlow) => {
      let lastName = '';
      if (value) {
        lastName = value.lastName;
        this.previousRoute = this.determinePreviousRoute(value.lookupMethod);
      }
      this.store.dispatch(new SetContent(lastName));
      this.enableKeyboard();
    });
  }
  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.pnrLocatorFlowSubscriber) {
      this.pnrLocatorFlowSubscriber.unsubscribe();
    }
    if (this.keyboardSubscriber) {
      this.keyboardSubscriber.unsubscribe();
    }
  }

  determinePreviousRoute(lookupMethod): AppRoutes {
    switch (lookupMethod) {
      case 'flightNumberLastName':
        return AppRoutes.LOCATE_RESERVATION_FLIGHT_NUMBER;
      case 'destinationLastName':
        return AppRoutes.LOCATE_RESERVATION_DESTINATION;
    }
  }

  enableKeyboard() {
    this.keyboardSubscriber = this.keyboard$.subscribe((value) => {
      if (value) {
        this.code = value.content.toUpperCase();
        this.codeDone = value.content.length >= 1;
      }
    });
  }

  validateLastName() {
    this.logging.infoUiFlightNumberLastNameLookupPageContinueSelected(0);

    if (!this.codeDone) {
      return false;
    }
    this.store.dispatch(new PnrLocatorFlowUpdateState({ lastName: this.code }));
    this.router.navigate([AppRoutes.LOCATE_RESERVATION_BIRTHDAY]);
  }
}
