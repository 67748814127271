import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { HaCussService } from '../../../services/ha-cuss/ha-cuss.service';
import { select, Store } from '@ngrx/store';
import {
  AppState,
  BagTagDataPrinted,
  Cart,
  CartUpdated,
  DocumentPrintingDataPrinted,
  PaymentReceiptDataRequested,
  SegmentDetail,
  selectActiveSegmentDetails,
  selectCart,
  selectCurrentTrip,
  Trip,
} from '../../../state';
import { filter, first, takeUntil } from 'rxjs/operators';
import { DeviceService, numberToPrint, SPEECH_STATUS } from '../../../services/ha-cuss/device.service';
import { environment } from 'environments/environment';
import {
  accessibilityJson,
  currentlyPrinting,
  isAccessibilityMode,
  PRINTING_TYPES,
} from '../../../services/emitters/session-event-emitters';
import { utils } from '../../../utils/helpers';
import { ConfigService } from '../../../services/api/config/config.service';
import { Logging } from '../../../services/logging/logging.service';
import { AppRoutes } from '~app/app-routes';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { isEmpty } from 'shared/embross/helper';

@Component({
  selector: 'app-print-documents',
  templateUrl: './print-documents.component.html',
  styleUrls: ['./print-documents.component.scss'],
})
export class PrintDocumentsComponent implements OnInit, OnDestroy, AfterViewInit {
  leftToPrintSubscription: Subscription;
  isFinishedPrintingInterval;
  printManagementInterval;
  printNext = false;
  currentlyPrinting: PRINTING_TYPES;
  finishedPrinting = false;
  trip$: Observable<Trip>;
  cart$: Observable<Cart>;
  segmentDetails$: Observable<SegmentDetail[]>;
  tripSubscription: Subscription;
  cartSubscription: Subscription;
  segmentSubscription: Subscription;
  currentlyPrintingSubscription: Subscription;

  public config;
  public configSubscription: Subscription;
  public isReceiptsEnabled: boolean;
  public altText = accessibilityJson.documentsPrinting;
  public isBagTagEnabled: boolean;
  private cartTotal = 0;

  unsubscribe$ = new Subject<void>();

  intervalTime = 2 * 1000;

  private element;

  constructor(
    private router: Router,
    private haCussService: HaCussService,
    private deviceService: DeviceService,
    private store: Store<AppState>,
    public configService: ConfigService,
    private logging: Logging,
    private accessibilityService: AccessibilityService,
    private el: ElementRef
  ) {
    this.element = el.nativeElement;
    this.enableATBPrinter();
    this.deviceService.disableLookupDevices();
  }

  getNextRoute() {
    this.tripSubscription = this.trip$.pipe(first(), takeUntil(this.unsubscribe$)).subscribe((trip: Trip) => {
      if (isAccessibilityMode.getValue() && this.accessibilityService.getSpeechStatus() === SPEECH_STATUS.STARTED) {
        return;
      }
      if (trip && trip.hasNextSegmentsToCheckin()) {
        return this.router.navigate([AppRoutes.MORE_SEGMENTS_CHECKIN]);
      }
      return this.router.navigate([AppRoutes.CONFIRMATION]);
    });
  }

  ngOnInit() {
    this.logging.infoUiPrintingDocsPageDisplayed(0);

    if (!environment.cussEnabled) {
      setTimeout(() => {
        this.loadTripAndCartData();
        this.getNextRoute();
      }, 2000);
      return;
    }

    this.isFinishedPrintingInterval = utils.setInterval(() => {
      if (this.finishedPrinting || !this.haCussService.devManagerActive()) {
        this.getNextRoute();
      }
    }, this.intervalTime);

    this.loadTripAndCartData();

    this.leftToPrintSubscription = numberToPrint.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      value === 0 ? (this.printNext = true) : (this.printNext = false);
    });

    this.currentlyPrintingSubscription = currentlyPrinting.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      this.currentlyPrinting = value;
    });

    this.store.dispatch(new DocumentPrintingDataPrinted());

    this.configSubscription = this.configService.config.pipe(takeUntil(this.unsubscribe$)).subscribe((x) => {
      if (x && x.configuration) {
        this.config = x;
        this.printManagement();
      }
    });

    this.isBagTagEnabled = this.config.configuration.enableBagTags;
    this.isReceiptsEnabled = this.config.configuration.enableReceipts;

    this.cartSubscription = this.cart$
      .pipe(
        filter((cart) => !isEmpty(cart)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((cart: Cart) => {
        this.cartTotal = cart.grandTotal || 0;
      });
  }

  ngAfterViewInit() {
    const elements = this.element.querySelectorAll('[tabindex]');
    this.accessibilityService.setAndReadAllElements({ elements }).subscribe(() => {});
  }

  ngOnDestroy() {
    clearInterval(this.isFinishedPrintingInterval);
    clearInterval(this.printManagementInterval);

    this.store.dispatch(new CartUpdated({ cart: new Cart() }));

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadTripAndCartData() {
    this.trip$ = this.store.pipe(select(selectCurrentTrip));
    this.cart$ = this.store.pipe(select(selectCart));
    this.segmentDetails$ = this.store.pipe(select(selectActiveSegmentDetails));
  }

  enableATBPrinter() {
    this.haCussService.enableATBPrinter();
  }

  printManagement() {
    this.printManagementInterval = utils.setInterval(() => {
      // If we have finished the current printing task..
      if (this.printNext) {
        switch (this.currentlyPrinting) {
          // If we are currently printing the boarding pass, we need to print bag tags
          case PRINTING_TYPES.BOARDING_PASS: {
            this.printBagTag();
            break;
          }
          // If we are currently printing bag tags, we need to print receipts
          case PRINTING_TYPES.BAG_TAG: {
            this.printReceipt();
            break;
          }
          // Once we have printed the receipts, we can move on to the confirmation page
          case PRINTING_TYPES.RECEIPT: {
            this.finishedPrinting = true;
            break;
          }
          default: {
            break;
          }
        }
      }
    }, this.intervalTime);
  }

  printReceipt() {
    if (this.cartTotal > 0 && this.isReceiptsEnabled) {
      this.printNext = false;
      this.store.dispatch(new PaymentReceiptDataRequested());
    } else {
      this.finishedPrinting = true;
    }
  }

  printBagTag() {
    // Listen to the config service and get the bag tag printing values
    // Dispatch BagTagDataRequested here
    this.segmentSubscription = this.segmentDetails$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((details: SegmentDetail[]) => {
        const bags = details.find((o) => o.bags.length > 0);
        if (this.isBagTagEnabled && bags) {
          this.printNext = false;
          this.store.dispatch(new BagTagDataPrinted());
        } else {
          this.printNext = true;
          currentlyPrinting.next(PRINTING_TYPES.BAG_TAG);
        }
      });
  }
}
