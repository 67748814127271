import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTrips from './trip.reducer';
import { selectFlightStatuses } from '../flight-status/flight-status.selectors';
import { isEmpty } from 'shared/embross/helper';

export const selectTripState = createFeatureSelector<fromTrips.TripState>('trips');

export const selectCurrentTrip = createSelector(selectTripState, (tripState) => tripState.trip);

export const selectActiveSegment = createSelector(selectCurrentTrip, (trip) => (trip ? trip.activeSegment : null));

export const selectActiveSegmentDetails = createSelector(selectActiveSegment, (segment) =>
  segment ? segment.details : []
);

export const selectCurrentTripFlightStatus = createSelector(
  selectCurrentTrip,
  selectFlightStatuses,
  (trip, flightStatuses) => {
    if (!trip || !trip.activeSegment || !trip.activeSegment.nextFlight) {
      return;
    }

    const flightId = trip.activeSegment.nextFlight.id;
    return flightStatuses.get(flightId);
  }
);

export const selectSelectedPax = createSelector(selectCurrentTrip, (trip) => {
  return trip.activeSegment.details.filter((x) => x.selected);
});

export const selectPassengers = createSelector(selectCurrentTrip, (trip) => {
  const passengers = trip.passengers;
  if (!isEmpty(passengers)) {
    passengers.forEach((passenger) => {
      passenger.flightDetails = trip.activeSegment.details.filter(
        (x) => x.passengerId === passenger.id
      )[0].flightDetails;
      passenger.isSelected = trip.activeSegment.details.filter((pax) => pax.passengerId === passenger.id)[0].selected;
    });
  }
  return passengers;
});

export const selectFlights = createSelector(selectCurrentTrip, (trip) => {
  return trip.activeSegment ? trip.activeSegment.flights : null;
});
