import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { Device, deviceIds, TraceLevels } from '../../../assets/js/embross-device-manager';

import { select, Store } from '@ngrx/store';
import { BarcodeState } from '../../state/ha-cuss-device/barcode/barcode.reducer';
import { ATBPrinterState } from '../../state/ha-cuss-device/atb-printer/atb-printer.reducers';
import { BarcodeActionTypes, BarcodeDisable, BarcodeEnable } from '../../state/ha-cuss-device/barcode/barcode.actions';
import {
  ATBPrinterActionTypes,
  ATBPrinterDisable,
  ATBPrinterEnable,
} from '../../state/ha-cuss-device/atb-printer/atb-printer.actions';
import { AppState, ErrorState, SetBagTagPrintingIssue, SetPrintingIssue } from '../../state';
import { selectBarcodeData, selectBarcodeStatus } from '../../state/ha-cuss-device/barcode/barcode.selector';
import {
  selectAtbPrinterMode,
  selectAtbPrinterStatus,
  selectAtbPrinterValue,
} from '../../state/ha-cuss-device/atb-printer/atb-printer.selector';
import { Router } from '@angular/router';
import { PassportState } from '../../state/ha-cuss-device/passport/passport.reducer';
import {
  PassportActionTypes,
  PassportDisable,
  PassportEnable,
} from '../../state/ha-cuss-device/passport/passport.actions';
import { BagTagPrinterState } from '../../state/ha-cuss-device/bagtag-printer/bagtag-printer.reducers';
import {
  BagTagPrinterActionTypes,
  BagTagPrinterDisable,
  BagTagPrinterEnable,
} from '../../state/ha-cuss-device/bagtag-printer/bagtag-printer.actions';
import { CardReaderState } from '../../state/ha-cuss-device/card-reader/card-reader.reducers';
import {
  CardReaderActionTypes,
  CardReaderDisable,
  CardReaderEnable,
} from '../../state/ha-cuss-device/card-reader/card-reader.actions';
import { Logging } from '../logging/logging.service';
import { selectPassportData, selectPassportStatus } from '../../state/ha-cuss-device/passport/passport.selector';
import { selectBagTagPrinterStatus } from '../../state/ha-cuss-device/bagtag-printer/bagtag.selector';
import { DeviceService, leftToPrint, numberToPrint } from './device.service';
import { selectCardSwipeCount } from '../../state/ha-cuss-device/card-reader/card-reader.selector';
import { barcodeReaderCallback } from './callbacks/barcode-reader-callback';
import { cardReaderCallback } from './callbacks/card-reader-callback';
import { passportReaderCallback } from './callbacks/passport-reader-callback';
import { atbPrinterCallback } from './callbacks/atb-printer-callback';
import { bagTagPrinterCallback } from './callbacks/bagtag-printer-callback';
import {
  almostOutOfTimeEvent,
  notificationTextEvent,
  notifyActiveEvent,
  paymentAttempts,
  socketAlive,
} from '../emitters/session-event-emitters';
import { ApplinkService } from '../hardware/applink.service';
import { CussAccessibilityState } from '../../state/ha-cuss-device/cuss-accessibility/cuss-accessibility.reducers';
import { cussAccessibilityCallback } from './callbacks/cuss-accessibility-callback';
import { AppRoutes } from '~app/app-routes';

export const onBPAdded = new EventEmitter<number>();

@Injectable({ providedIn: 'root' })
export class HaCussService {
  public peripheralOnline: boolean;
  private barcodeReader: Device;
  private atbPrinter: Device;
  private passportReader: Device;
  private bagTagPrinter: Device;
  private cardReader: Device;
  private cussAccessibility: Device;
  private passportData;
  private passportStatus;
  private readonly printTimeout: number;
  private barcodeData: string;
  private barcodeStatus;
  private atbPrinterData: string;
  private atbPrinterStatus;
  private bagTagPrinterStatus;
  private atbPrinterMode: string;
  private onBarcodeDataLoad = new EventEmitter<string>();
  private notificationText: string;
  private notifyActive: boolean;
  private almostOutOfTime: string;
  private onTicketPrinting = new EventEmitter<boolean>();
  private onBagTagPrinting = new EventEmitter<boolean>();
  private isTicketPrinting = false;
  private isBagTagPrinting = true;
  private pecTabCalls = 0;
  private atbPrinterCalls = 0;
  private numToPrint = 0;
  private printRemaining;
  private bpArray = [];
  private interval;
  private cardInReader;
  private swipeCount: number;
  private devMgr;
  private readonly barcodeReaderCallback;
  private readonly cardReaderCallback;
  private readonly passportReaderCallback;
  private readonly atbPrinterCallback;
  private readonly bagTagPrinterCallback;
  private readonly cussAccessibilityCallback;

  constructor(
    private deviceService: DeviceService,
    private passportReaderStore: Store<PassportState>,
    private barcodeReaderStore: Store<BarcodeState>,
    private atbPrinterStore: Store<ATBPrinterState>,
    private bagTagPrinterStore: Store<BagTagPrinterState>,
    private cardReaderStore: Store<CardReaderState>,
    private cussAccessibilityStore: Store<CussAccessibilityState>,
    private appState: Store<AppState>,
    private errorState: Store<ErrorState>,
    private zone: NgZone,
    private router: Router,
    private logging: Logging,
    private applinkService: ApplinkService
  ) {
    this.printTimeout = 10000;
    this.appState.pipe(select(selectBarcodeData)).subscribe((data) => {
      this.barcodeData = data;
    });
    this.appState.pipe(select(selectBarcodeStatus)).subscribe((status) => {
      this.barcodeStatus = status;
    });
    this.appState.pipe(select(selectAtbPrinterValue)).subscribe((data) => {
      this.atbPrinterData = data;
    });
    this.appState.pipe(select(selectAtbPrinterStatus)).subscribe((status) => {
      this.atbPrinterStatus = status;
    });
    this.appState.pipe(select(selectBagTagPrinterStatus)).subscribe((status) => {
      this.bagTagPrinterStatus = status;
    });
    this.appState.pipe(select(selectAtbPrinterMode)).subscribe((mode) => {
      this.atbPrinterMode = mode;
    });
    this.appState.pipe(select(selectPassportData)).subscribe((data) => {
      this.passportData = data;
    });
    this.appState.pipe(select(selectPassportStatus)).subscribe((status) => {
      this.passportStatus = status;
    });
    this.appState.pipe(select(selectCardSwipeCount)).subscribe((count) => {
      this.swipeCount = count;
    });
    socketAlive.subscribe((isConnected) => {
      this.peripheralOnline = isConnected;
    });
    notificationTextEvent.subscribe((event) => {
      this.notificationText = event;
    });
    notifyActiveEvent.subscribe((event) => {
      this.notifyActive = event;
    });
    almostOutOfTimeEvent.subscribe((event) => {
      this.almostOutOfTime = event;
      if (this.almostOutOfTime === 'true') {
        this.setActive();
      }
    });
    onBPAdded.subscribe((value) => {
      this.numToPrint = value;
      this.bpArray = [];
    });
    numberToPrint.subscribe((value) => {
      this.printRemaining = value;
    });
    this.onTicketPrinting.subscribe((value) => {
      this.isTicketPrinting = value;
    });
    this.onBagTagPrinting.subscribe((value) => {
      this.isBagTagPrinting = value;
    });
    paymentAttempts.subscribe((value) => {
      this.swipeCount = value;
    });

    /**
     * Get reference to singleton of device manager
     */
    this.devMgr = this.applinkService.getDeviceManager();

    /**
     * Set up the callbacks
     */
    this.barcodeReaderCallback = barcodeReaderCallback.bind(this);
    this.cardReaderCallback = cardReaderCallback.bind(this);
    this.passportReaderCallback = passportReaderCallback.bind(this);
    this.atbPrinterCallback = atbPrinterCallback.bind(this);
    this.bagTagPrinterCallback = bagTagPrinterCallback.bind(this);
    this.cussAccessibilityCallback = cussAccessibilityCallback.bind(this);

    /**
     * Assign Devices
     */
    this.barcodeReader = this.devMgr.getDevice(deviceIds.BARCODE_READER);
    this.cardReader = this.devMgr.getDevice(deviceIds.CARD_READER);
    this.passportReader = this.devMgr.getDevice(deviceIds.PASSPORT_READER);
    this.atbPrinter = this.devMgr.getDevice(deviceIds.ATB_PRINTER);
    this.bagTagPrinter = this.devMgr.getDevice(deviceIds.BAGTAG_PRINTER);
    this.cussAccessibility = this.devMgr.getDevice(deviceIds.CUSS_ACCESSIBILITY);

    /**
     * Enable Devices for startup
     */
    this.enableLookupDevices();
    this.enableATBPrinter();
    this.enableBagTagPrinter();
    this.initializeCussAccessibility();
  }

  /**
   * Enables all the devices which are used to locate a PNR
   */
  public enableLookupDevices() {
    this.enableBarcodeReader();
    this.enablePassportReader();
    this.enableCardReaderInfo();
  }

  // Initialize CUSS ACCESSIBILITY
  initializeCussAccessibility() {
    this.cussAccessibility.OnDeviceEvent = this.cussAccessibilityCallback;
  }

  enableCussAccessibilityKeyPad() {
    this.cussAccessibility.enableKeypad();
    this.cussAccessibility.enableTTS();
  }

  disableCussAccessibilityTTS() {
    this.cussAccessibility.disableTTS();
  }

  // ATB PRINTER ENABLE
  enableATBPrinter() {
    this.atbPrinterStore.dispatch(new ATBPrinterEnable(ATBPrinterActionTypes.ATBPrinterEnable));
    this.atbPrinter.OnDeviceEvent = this.atbPrinterCallback;
    this.atbPrinter.enable();
    this.logging.infoHardwareATBPrinterEnabled();
  }

  loadPECTabs(pecTABSArray: string[], isBase64Encoded: boolean, resetPectab: boolean) {
    if (this.pecTabCalls === 0 || resetPectab) {
      this.applinkService
        .getDeviceManager()
        .sendLogMsg(
          TraceLevels.LOG_TRACE,
          'loadPECTabs(pecTABSArray: string[], isBase64Encoded: boolean) pecTABSArray: ' + pecTABSArray
        );
      this.atbPrinter.loadPECTABArray(pecTABSArray, isBase64Encoded);
      this.atbPrinter.statusIsOK();
      this.atbPrinterCalls = 0;
    }
    this.pecTabCalls++;
  }

  // ATB PRINTER
  async callATBPrinter(boardingPassStream, resetPectab: boolean) {
    this.applinkService
      .getDeviceManager()
      .sendLogMsg(
        TraceLevels.LOG_TRACE,
        'callATBPrinter(boardingPassStream: string[]) boardingPassStream: ' + boardingPassStream
      );
    if (resetPectab) {
      this.bpArray = [];
    }
    this.bpArray.push(boardingPassStream);
    if (this.bpArray.length === this.numToPrint || resetPectab) {
      if (this.getAtbPrinterStatus()[1] !== true) {
        numberToPrint.emit(this.numToPrint);
        // Flatten arrays
        this.bpArray = [].concat.apply([], this.bpArray);
        this.atbPrinter.printArray(this.bpArray, this.printTimeout);
        this.pecTabCalls = 0;
      } else {
        leftToPrint.emit(0);
      }
    } else {
      this.onTicketPrinting.emit(false);
    }
  }

  // ATB PRINTER DISABLE
  disableATBPrinter() {
    this.atbPrinterStore.dispatch(new ATBPrinterDisable(ATBPrinterActionTypes.ATBPrinterDisable));
    this.atbPrinter.disable();
  }

  // BARCODE READER ENABLE
  enableBarcodeReader() {
    this.barcodeReaderStore.dispatch(new BarcodeEnable(BarcodeActionTypes.BarcodeEnable));
    this.barcodeReader.OnDeviceEvent = this.barcodeReaderCallback;
    this.barcodeReader.enable();
    this.logging.infoHardwareBarcodeEnabled();
  }

  // BARCODE READER DISABLE
  disableBarcodeReader() {
    this.barcodeReaderStore.dispatch(new BarcodeDisable(BarcodeActionTypes.BarcodeDisable));
    this.barcodeReader.disable();
  }

  // PASSPORT READER ENABLE
  enablePassportReader() {
    this.passportReaderStore.dispatch(new PassportEnable(PassportActionTypes.PassportEnable));
    this.passportReader.OnDeviceEvent = this.passportReaderCallback;
    this.passportReader.enable();
  }

  // PASSPORT READER DISABLE
  disablePassportReader() {
    this.passportReaderStore.dispatch(new PassportDisable(PassportActionTypes.PassportDisable));
    this.passportReader.disable();
  }

  // BAGTAG PRINTER ENABLE
  enableBagTagPrinter() {
    this.bagTagPrinterStore.dispatch(new BagTagPrinterEnable(BagTagPrinterActionTypes.BagTagPrinterEnable));
    this.bagTagPrinter.OnDeviceEvent = this.bagTagPrinterCallback;
    this.bagTagPrinter.enable();
    this.logging.infoHardwareBagTagPrinterEnabled();
  }

  // LOAD BAGTAG PECTABS
  loadBagTagPECTabs(pecTABSArray: string[], isBase64Encoded: boolean) {
    this.applinkService
      .getDeviceManager()
      .sendLogMsg(
        TraceLevels.LOG_TRACE,
        'loadBagTagPECTabs(pecTABSArray: string[], isBase64Encoded: boolean) pecTABSArray: ' + pecTABSArray
      );
    this.bagTagPrinter.loadPECTABArray(pecTABSArray, isBase64Encoded);
    this.bagTagPrinter.statusIsOK();
  }

  // BAGTAG PRINTER
  async callBagTagPrinter(bagTagPrinterStream: string[]) {
    this.applinkService
      .getDeviceManager()
      .sendLogMsg(
        TraceLevels.LOG_TRACE,
        'callBagTagPrinter(bagTagPrinterStream: string[]) bagTagPrinterStream: ' + bagTagPrinterStream
      );
    if (this.getBagTagPrinterStatus()[1] !== true) {
      numberToPrint.emit(this.numToPrint);
      this.bagTagPrinter.printArray(bagTagPrinterStream, this.printTimeout);
    } else {
      this.appState.dispatch(new SetBagTagPrintingIssue());
      this.zone.run(() => this.router.navigateByUrl(AppRoutes.ERROR_SCREEN));
    }
  }

  // BAGTAG PRINTER DISABLE
  disableBagTagPrinter() {
    this.atbPrinterStore.dispatch(new BagTagPrinterDisable(BagTagPrinterActionTypes.BagTagPrinterDisable));
    this.atbPrinter.disable();
  }

  // CARD READER INFO ENABLE
  enableCardReaderInfo() {
    this.cardReaderStore.dispatch(new CardReaderEnable(CardReaderActionTypes.CardReaderEnable));
    this.cardReader.OnDeviceEvent = this.cardReaderCallback;
    this.cardReader.enable(3);
  }

  // CARD READER PAYMENT ENABLE
  enableCardReaderPayment() {
    this.cardReaderStore.dispatch(new CardReaderEnable(CardReaderActionTypes.CardReaderEnable));
    this.cardReader.enable(1);
    this.cardReader.OnDeviceEvent = this.cardReaderCallback;
  }

  // CARD READER INFO DISABLE
  disableCardReader() {
    this.cardReaderStore.dispatch(new CardReaderDisable(CardReaderActionTypes.CardReaderDisable));
    this.deviceService.disableCC();
  }

  onPrintingIssue() {
    this.errorState.dispatch(new SetPrintingIssue());
    leftToPrint.emit(0);
  }

  getKioskId() {
    return this.applinkService.getKioskId();
  }

  getSystemManagerKioskId() {
    return this.applinkService.getSystemManagerKioskId();
  }

  getActualCUSSKioskId() {
    return this.applinkService.getActualCUSSKioskId();
  }

  getAirportCode() {
    return this.applinkService.getAirportCode();
  }

  getLocation() {
    return this.applinkService.getLocation();
  }

  getCompanyCode() {
    return this.applinkService.getCompanyCode();
  }

  getAppName() {
    return this.applinkService.getAppName();
  }

  getBarcodeData() {
    return this.barcodeData;
  }

  getBarcodeStatus() {
    return this.barcodeStatus;
  }

  getPassportStatus() {
    return this.passportStatus;
  }

  getAtbPrinterStatus() {
    return this.atbPrinterStatus;
  }

  getBagTagPrinterStatus() {
    return this.bagTagPrinterStatus;
  }

  setAvailable() {
    this.applinkService
      .getDeviceManager()
      .getAppManager()
      .available();
  }

  setUnavailable() {
    this.applinkService
      .getDeviceManager()
      .getAppManager()
      .unavailable();
  }

  setState(state: number) {
    this.applinkService
      .getDeviceManager()
      .getAppManager()
      .setState(state);
  }

  setActive() {
    this.applinkService
      .getDeviceManager()
      .getAppManager()
      .setActiveWhenActive();
  }

  isCurrentlyActive() {
    return this.notifyActive;
  }

  clearBpData() {
    this.bpArray = [];
  }

  public watchDevices() {
    this.interval = setInterval(() => {
      this.barcodeReader.status();
      this.getBarcodeStatus();

      this.passportReader.status();
      this.getPassportStatus();
    }, 1000);
  }

  public unwatchDevices() {
    clearInterval(this.interval);
  }

  public devManagerActive() {
    return this.devMgr.isConnected();
  }
}
