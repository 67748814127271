import { Action } from '@ngrx/store';

export enum LoadingFlowActionTypes {
  ToggleState = '[loadingFlow] Toggle Loading Flow',
  ResetLoadingState = '[loadingFlow] Reset Loading Flow',
}

export class ToggleState implements Action {
  readonly type = LoadingFlowActionTypes.ToggleState;
  constructor(private payload: { url: string; state: boolean }) {}
}

export class ResetLoadingState implements Action {
  readonly type = LoadingFlowActionTypes.ResetLoadingState;
}
