import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AgentAssistRoutes } from '~app/agent-assist/agent-assist-routes';

@Injectable()
export class RoutingService {
  public visitedRoutes: RouteList = {
    currentRoute: '',
    visitedCheckInRoutes: [],
    visitedAaRoutes: [],
  };

  constructor(private router: Router) {
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (this.visitedRoutes.currentRoute.includes(AgentAssistRoutes.MAIN)) {
        this.visitedRoutes.visitedAaRoutes.push(this.visitedRoutes.currentRoute);
      } else if (this.visitedRoutes.currentRoute) {
        this.visitedRoutes.visitedCheckInRoutes.push(this.visitedRoutes.currentRoute);
      }
      this.visitedRoutes.currentRoute = event.url;
    });
  }

  public getPrevCheckInRoute(): string {
    return this.visitedRoutes.visitedCheckInRoutes.pop();
  }

  public getPrevAaRoute(): string {
    return this.visitedRoutes.visitedAaRoutes.pop();
  }
}

export interface RouteList {
  currentRoute: string;
  visitedCheckInRoutes: string[];
  visitedAaRoutes: string[];
}
