import { EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as enJson from '../../../assets/i18n/en.json';
import { Configuration } from '../api/config/config';

export enum PRINTING_TYPES {
  NONE = 'NOT_PRINTING',
  BOARDING_PASS = 'BOARDING_PASS',
  BAG_TAG = 'BAG_TAG',
  RECEIPT = 'RECEIPT',
}

export interface SpecialItems {
  productId: string;
  commercialName: string;
  type: string;
  freeItemCount: number;
  attributes: object[];
  productLabel: string;
  icon: string;
}

export const accessibilityJson = enJson.altText;

export const kioskId = new BehaviorSubject<string>('');
export const kioskLocation = new BehaviorSubject<string>('');
export const airportCode = new BehaviorSubject<string>('');
export const activeSession = new EventEmitter<boolean>();
export const isCardInReaderTooLong = new EventEmitter<boolean>();
export const paymentAttempts = new EventEmitter<number>();
export const currentlyPrinting = new BehaviorSubject<PRINTING_TYPES>(PRINTING_TYPES.NONE);
export const locatedPnr = new BehaviorSubject<boolean>(false);
export const isPassportInReaderTooLong = new EventEmitter<boolean>();
export const bagTagsPrinted = new BehaviorSubject<boolean>(false);
export const bagTagDataLoaded = new BehaviorSubject<boolean>(false);
export const socketAlive = new EventEmitter<boolean>();
export const almostOutOfTimeEvent = new EventEmitter<boolean>();
export const notificationTextEvent = new EventEmitter<string>();
export const notifyActiveEvent = new EventEmitter<boolean>();
export const loadKioskId = new EventEmitter<any>();
export const isAccessibilityMode = new BehaviorSubject<boolean>(false);
export const boardingPassDataLoaded = new BehaviorSubject<boolean>(false);
export const fallbackAirportCode = new BehaviorSubject<string>('');
export const specialItemsCatalog = new BehaviorSubject<Array<SpecialItems>>([]);
export const configurationSettings = new BehaviorSubject<Configuration>(null);
