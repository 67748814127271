import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-self-tagging',
  templateUrl: './self-tagging.component.html',
  styleUrls: ['./self-tagging.component.scss'],
})
export class SelfTaggingComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
