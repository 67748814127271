import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { select, Store } from '@ngrx/store';
import { AppState, selectCurrentTrip, SetReferToAgentDontPrintError, Trip, TripActionTypes } from '~app/state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { sortByTabIndexValue } from '~app/utils/query-by-tabindex';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-bags',
  templateUrl: './bags.component.html',
  styleUrls: ['./bags.component.scss'],
})
export class BagsComponent implements OnInit, OnDestroy {
  totalOfBags: string;
  private element;
  headerElements: ElementRef;
  detailsElements: ElementRef;
  servicesElements: ElementRef;
  isOA: boolean;
  trip: Trip;
  unsubscribe$ = new Subject<void>();
  trip$: Observable<Trip>;

  constructor(
    private accessibilityService: AccessibilityService,
    private store: Store<AppState>,
    private el: ElementRef,
    private changeRef: ChangeDetectorRef,
    private actions$: Actions
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.trip$ = this.store.pipe(select(selectCurrentTrip));

    this.trip$.pipe(takeUntil(this.unsubscribe$)).subscribe((trip: Trip) => {
      this.trip = trip;

      if (!this.trip) {
        return;
      }

      const activeSegment = this.trip.findActiveSegment();
      if (activeSegment) {
        this.isOA = activeSegment.isOASegment;
      }
    });

    // After we have attempted to get catalogs again we must finally show error screen
    this.actions$.pipe(takeUntil(this.unsubscribe$), ofType(TripActionTypes.CatalogsChanged)).subscribe((data: any) => {
      const { bagsCatalogs } = data.payload;
      if (bagsCatalogs.length === 0) {
        this.store.dispatch(new SetReferToAgentDontPrintError());
      }
    });
  }

  onGridRendered() {
    this.updateACAAElements({ startIndex: 0, readElement: false });
  }

  onGridScrolled() {
    this.updateACAAElements({ startIndex: 16, readElement: true });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  updateACAAElements({ startIndex, readElement }) {
    this.changeRef.detectChanges();

    const elements = [
      ...this.getBaggageRestrictionElementsToRead(),
      ...this.getMilitaryElementToRead(),
      ...this.getAboutBagsElementToRead(),
      ...this.getHeaderElementsToRead(),
      ...this.getGridElementsToRead(),
      ...this.getButtonElementsToRead(),
      ...this.getStartOverElementToRead(),
    ];

    this.accessibilityService.setPageElementsToRead({
      elements,
      navigationIndex: startIndex,
      needsSorting: false,
      needsToReadFirstElement: readElement,
    });
  }

  private getBaggageRestrictionElementsToRead() {
    return Array.from(this.element.querySelectorAll('.bags-baggage-restrictions [tabindex]'));
  }

  private getMilitaryElementToRead() {
    return Array.from(this.element.querySelectorAll('#bags-military-bags-button'));
  }

  private getAboutBagsElementToRead() {
    return Array.from(this.element.querySelectorAll('#about-bags-button'));
  }

  private getHeaderElementsToRead() {
    return Array.from(this.element.querySelectorAll('#bags-title-text'));
  }

  private getGridElementsToRead() {
    const elementsToRead = [];

    const gridContainer = Array.from(this.element.querySelectorAll('.bags-details-grid'));

    gridContainer.forEach((itineraryContainer: HTMLElement) => {
      const itineraryElementsToRead = Array.from(itineraryContainer.querySelectorAll('[tabindex]')) as HTMLElement[];
      const itineraryElementsToReadSorted = sortByTabIndexValue(itineraryElementsToRead);

      elementsToRead.push(...itineraryElementsToReadSorted);
    });

    return elementsToRead;
  }

  private getButtonElementsToRead() {
    return Array.from(this.element.querySelectorAll('#bags-services-calculate-button, #bags-services-continue-button'));
  }

  private getStartOverElementToRead() {
    return Array.from(this.element.querySelectorAll('#start-over-exit-button'));
  }

  receiveTotalOfBags(totalOfBags) {
    this.changeRef.detectChanges();
    this.totalOfBags = totalOfBags;
  }
}
