import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SegmentDetailAndPax, Trip, SegmentDetail } from '~app/state';
import { Logging } from '~app/services/logging/logging.service';
import { GridComponent } from '~app/shared/grid/grid.component';

@Component({
  selector: 'app-itinerary-passenger-details',
  templateUrl: './itinerary-passenger-details.component.html',
  styleUrls: ['./itinerary-passenger-details.component.scss'],
})
export class ItineraryPassengerDetailsComponent {
  public currentTrip;
  public paxsAndSegmentDetails;
  public seatPreference;
  public isNonRevSpaceAvailable: boolean;
  public specialItems;

  public totalPaxCount: number;
  public totalRegularBagsCount: number;
  public totalSpecialItemsCount: number;

  @Output() passengerCheckInSelection = new EventEmitter<any>();
  @Output() editPassengerClick = new EventEmitter<any>();
  @Output() gridRendered = new EventEmitter<any>();
  @Output() gridScrolled = new EventEmitter<any>();

  private isGridRendered = false;

  @ViewChild(GridComponent, { static: false }) set onElementsRendered(element: GridComponent) {
    if (this.isGridRendered) {
      return;
    }

    const gridElement = element.Element.nativeElement;
    // icons are rendered after passenger, so that's the safest way to be sure if grid is fully rendered
    const iconElements = gridElement.querySelectorAll('.app-icon--check-selected, .app-icon--check-unselected');

    if (iconElements.length == 0) {
      return;
    }

    this.isGridRendered = true;
    this.gridRendered.emit({});
  }

  @Input() set trip(trip: Trip) {
    this.currentTrip = trip;
    this.getPax();

    this.totalPaxCount = this.currentTrip.activeSegment.getNumOfPaxsSelectedForCheckin();
    this.totalRegularBagsCount = this.currentTrip.activeSegment.getNumRegularBags();
    this.totalSpecialItemsCount = this.currentTrip.activeSegment.getNumSpecialItems();
  }

  constructor(public changeRef: ChangeDetectorRef, private logging: Logging) {}

  getPax() {
    if (!this.currentTrip) {
      return;
    }

    this.paxsAndSegmentDetails = this.currentTrip.getSegmentDetailsAndPax();
    this.isNonRevSpaceAvailable = this.currentTrip.isNonRevSpaceAvailable();
  }

  doesThisPassengerHasAnyInfo(segmentDetailAndPax: SegmentDetailAndPax) {
    const { passenger } = segmentDetailAndPax;
    if (passenger.hawaiianMilesNumber || passenger.redressNumber || passenger.knownTravelerNumber) {
      return true;
    }

    return false;
  }

  canUnselectPax(paxSegmentDetail: SegmentDetail): boolean {
    const numOfPaxsSelectedForCheckin = this.currentTrip.activeSegment.getNumOfPaxsSelectedForCheckin();
    // at least one pax should be selected
    if (paxSegmentDetail.selected && numOfPaxsSelectedForCheckin === 1) {
      return false;
    }

    return true;
  }

  onChangePaxCheckedInSelectionEv(event, paxSegmentDetail) {
    event.cancelBubble = true;

    if (this.canUnselectPax(paxSegmentDetail)) {
      this.passengerCheckInSelection.emit({ segmentDetail: paxSegmentDetail });
    }
  }

  onGridScrolled() {
    this.gridScrolled.emit({});
  }

  editPassenger(passengerId: string) {
    this.logging.infoUiItineraryPageAddHMKTNRedressSelected(0);
    this.editPassengerClick.emit({ passengerId });
  }
}
