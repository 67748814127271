import { ElementRef } from '@angular/core';

const sortByTabIndexValue = (elements: Array<HTMLElement>): Array<HTMLElement> => {
  const sortedArray = Array.from(elements).sort((a, b) => {
    return a.getAttribute('tabindex').localeCompare(b.getAttribute('tabindex'), 'en', { numeric: true });
  });

  return sortedArray;
};

const queryByTabIndex = (elementEf: ElementRef): Array<HTMLElement> => {
  let elements = [...elementEf.nativeElement.querySelectorAll('[tabindex]')];
  if (elements) {
    elements = sortByTabIndexValue(elements);
  }

  return elements;
};

export { queryByTabIndex, sortByTabIndexValue };
