import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { AppState, SegmentDetailAndPax, Trip, selectCurrentTrip, Passenger } from '~app/state';
import { PaxSelectedForSpecialItems } from '~app/state/cart/cart.actions';
import { Observable, Subject } from 'rxjs';
import { Cart } from '~app/state/cart/cart.model';
import { GridComponent } from '~app/shared/grid/grid.component';
import { TripDataFixtures } from '~app/fixtures-unit-test/trip.fixtures';
import { Router } from '@angular/router';
import { AppRoutes } from '~app/app-routes';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-bags-details-oa',
  templateUrl: './bags-details-oa.component.html',
  styleUrls: ['./bags-details-oa.component.scss'],
})
export class BagsDetailsOAComponent implements OnInit, OnDestroy {
  @Output() messageEvent = new EventEmitter<any>();

  private trip: Trip;
  public trip$: Observable<Trip>;

  public segmentDetailsAndPax: SegmentDetailAndPax[] = [];

  unsubscribe$ = new Subject<void>();

  @ViewChild(GridComponent, { static: false }) gridElement: GridComponent;

  constructor(
    private store: Store<AppState>,
    public changeRef: ChangeDetectorRef,
    private el: ElementRef,
    private router: Router
  ) {}

  ngOnInit() {
    this.trip$ = this.store.pipe(select(selectCurrentTrip));

    this.trip$.pipe(takeUntil(this.unsubscribe$)).subscribe((trip: Trip) => {
      this.trip = trip;
      this.segmentDetailsAndPax = this.trip.getSegmentDetailsAndPaxSelected();

      this.updateAcaaElements(false);
    });
  }

  gridScrolled(): void {
    this.updateAcaaElements(true);
  }

  updateAcaaElements(hasScrolled: boolean) {
    this.changeRef.detectChanges();
    const elements = [...this.el.nativeElement.querySelectorAll('[tabindex]')];

    this.messageEvent.emit({ elements: elements, scroll: hasScrolled });

    return elements;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  goToBags(passenger: Passenger) {
    // add logging events
    this.store.dispatch(new PaxSelectedForSpecialItems({ passengerId: passenger.id }));
    this.router.navigate([AppRoutes.SPECIAL_ITEMS]);
  }
}
