import { Component, OnDestroy, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, CardReaderResetCount, PnrLocatorFlowClearState } from '../../../state';
import { startSession } from '~app/app.component';
import { HaCussService } from '~services/ha-cuss/ha-cuss.service';
import { SelectLanguage } from '~app/state/language/language.action';
import { ConfigService } from '~app/services/api/config/config.service';
import {
  activeSession,
  isAccessibilityMode,
  isCardInReaderTooLong,
  isPassportInReaderTooLong,
  locatedPnr,
} from '~services/emitters/session-event-emitters';
import { Logging } from '~services/logging/logging.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { AppRoutes } from '~app/app-routes';
import { Router } from '@angular/router';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit, OnDestroy, AfterViewInit {
  public enableBagsConfig: boolean;

  shouldHideSplashScreen = false;
  interval;
  unsubscribe$ = new Subject<void>();

  constructor(
    private haCussService: HaCussService,
    private store: Store<AppState>,
    private logging: Logging,
    private accessibilityService: AccessibilityService,
    private el: ElementRef,
    public configService: ConfigService,
    public router: Router
  ) {
    this.store.dispatch(new PnrLocatorFlowClearState());
  }

  ngOnInit() {
    if (!isAccessibilityMode.getValue()) {
      this.haCussService.setAvailable();
    }

    // Report no active session
    activeSession.emit(false);
    locatedPnr.next(false);

    this.configService.config.pipe(takeUntil(this.unsubscribe$)).subscribe((x) => {
      if (x.configuration) {
        this.enableBagsConfig = x.configuration.enableBags;
      }
    });

    isCardInReaderTooLong.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      this.shouldHideSplashScreen = value;
    });

    isPassportInReaderTooLong.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      this.shouldHideSplashScreen = value;
    });

    activeSession.pipe(takeUntil(this.unsubscribe$)).subscribe((isActive) => {
      this.shouldHideSplashScreen = isActive;
    });

    startSession(this.logging);

    this.configService.getConfig();
    this.store.dispatch(new SelectLanguage('en'));

    this.haCussService.clearBpData();
    this.haCussService.enableLookupDevices();

    this.store.dispatch(new CardReaderResetCount());
  }

  ngAfterViewInit() {
    const elements = this.el.nativeElement.querySelectorAll('[tabindex]');
    this.accessibilityService.setPageElementsToRead({
      elements: [...elements],
      needsToReadFirstElement: false,
    });
  }

  navigateToMenu() {
    this.router.navigateByUrl(AppRoutes.MENU);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
