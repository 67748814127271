import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AccessibilityService } from './accessibility.service';
import { SPEECH_STATUS } from '../ha-cuss/device.service';
import { isAccessibilityMode } from '../emitters/session-event-emitters';
import { utils } from '~app/utils/helpers';
import { from, Subject, of } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccessibilityGuard implements CanActivate {
  private speechStatus: SPEECH_STATUS;
  private nextRoute: string;
  private hardwareReadDelay = 5;

  constructor(private accessibilityService: AccessibilityService, private router: Router) {
    this.accessibilityService.speechStatus$.subscribe((status: SPEECH_STATUS) => {
      this.speechStatus = status;

      if (this.nextRoute) {
        this.router.navigate([this.nextRoute]);
        this.nextRoute = null;
      }
    });
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      if (!isAccessibilityMode.getValue()) {
        resolve(true);
        return;
      }

      const unsubscribe$ = new Subject();

      of(state)
        .pipe(delay(this.hardwareReadDelay), takeUntil(unsubscribe$))
        .subscribe(({ url }) => {
          if (this.speechStatus == SPEECH_STATUS.COMPLETED || this.speechStatus == SPEECH_STATUS.NONE) {
            resolve(true);
          } else {
            this.nextRoute = url;
            resolve(false);
          }

          unsubscribe$.next();
          unsubscribe$.complete();
        });
    });
  }
}
