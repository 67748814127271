import { Directive, ElementRef, HostListener } from '@angular/core';
import { isAccessibilityMode } from '../services/emitters/session-event-emitters';

@Directive({
  selector: '[appFocusAccessibility]',
})
export class FocusAccessibilityDirective {
  constructor(private el: ElementRef) {}

  @HostListener('focus') onFocus() {
    this.focus();
  }

  @HostListener('blur') onBlur() {
    this.blur();
  }

  focus(): void {
    if (isAccessibilityMode.getValue()) {
      this.el.nativeElement.classList.add('acaa-focus');
    }
  }

  blur(): void {
    this.el.nativeElement.classList.remove('acaa-focus');
  }
}
