import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-itinerary-flight-details',
  templateUrl: './itinerary-flight-details.component.html',
  styleUrls: ['./itinerary-flight-details.component.scss'],
})
export class ItineraryFlightDetailsComponent implements OnInit {
  @Input() flightStatus;
  @Input() trip;

  singleStop = false;
  multipleStops = false;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (this.trip) {
      this.checkForStops();
    }
  }

  checkForStops() {
    if (this.trip.activeSegment.getNumberOfStops() === 1) {
      this.singleStop = true;
    } else if (this.trip.activeSegment.getNumberOfStops() > 1) {
      this.multipleStops = true;
    }
  }
}
