import { Injectable } from '@angular/core';
import { AppInsightsService } from '@markpieszak/ng-application-insights';

import { Logger } from './logger.service';
import { LogEvent } from './log-event.interface';

const toItsValuePairs = (obj) => (key) => ({ key, value: obj[key] });
const toFlatProperties = (flat, { key, value }) => Object.assign({}, flat, { [key]: JSON.stringify(value) });

@Injectable()
export class InsightsLogger extends Logger {
  constructor(private appInsights: AppInsightsService) {
    super();
    this.appInsights.queue.push(() => {
      this.appInsights.context.addTelemetryInitializer((envelope) => {
        const telemetryItem = envelope.data.baseData;

        if (telemetryItem.data) {
          telemetryItem.data = '{hidden}';
        }
      });
    });
  }

  log(event: LogEvent) {
    try {
      const flattened = this.flattenEventKeysObject(event);
      const properties: {} = this.convertToProperties(flattened);

      this.appInsights.trackEvent(event.message, properties);
      this.appInsights.flush();
    } catch (err) {
      // We just need the app to keep on going, no need for fancy handling here.
      console.error(err);
    }
  }

  convertToProperties(event) {
    return Object.keys(event)
      .map(toItsValuePairs(event))
      .reduce(toFlatProperties, {});
  }

  flattenEventKeysObject(event) {
    let copy = JSON.parse(JSON.stringify(event));

    copy = Object.assign({}, copy, copy.keys);

    delete copy.keys;

    return copy;
  }
}
