import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { AppState, TripActionTypes, UpdateTripDataRequested, Cart, selectCart } from '../../../../state';
import { Store, select } from '@ngrx/store';
import { BagTagDataRequested } from '../../../../state/document/document.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { AppRoutes } from '~app/app-routes';
import { Router } from '@angular/router';
import { Logging } from '~app/services/logging/logging.service';

@Component({
  selector: 'app-prohibited',
  templateUrl: './prohibited.component.html',
  styleUrls: ['./prohibited.component.scss', '../hazmat.component.scss'],
})
export class ProhibitedComponent implements OnInit, AfterViewInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  AppRoutes = AppRoutes;
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private el: ElementRef,
    private accessibilityService: AccessibilityService,
    private router: Router,
    private logging: Logging
  ) {}

  ngOnInit() {
    this.actions$.pipe(takeUntil(this.unsubscribe$), ofType(TripActionTypes.TripLoaded)).subscribe(() => {
      // This call can take a while, so we can pre-load the response
      this.store.dispatch(new BagTagDataRequested());
    });

    this.store.pipe(select(selectCart), takeUntil(this.unsubscribe$)).subscribe((cart: Cart) => {
      cart && cart.id
        ? this.store.dispatch(new UpdateTripDataRequested())
        : this.store.dispatch(new BagTagDataRequested());
    });
  }

  ngAfterViewInit(): void {
    this.logging.infoUiHazMatPage1Displayed(0);
  }

  getElements() {
    return this.el.nativeElement.querySelectorAll('[tabindex]');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  continueButtonClicked() {
    this.logging.infoUiHazMatPage1ContinueSelected(0);
    this.router.navigate([this.AppRoutes.HAZMAT_RESTRICTED]);
  }
}
