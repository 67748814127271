import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { KeyboardState, Passenger, PaxEditInfoUpdated, ResetContent, selectCurrentPassenger } from '../../../../state';
import { Logging } from '~app/services/logging/logging.service';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { AccessibilityMode } from '~app/decorators/accessibility.decorator';
import { AppRoutes } from '~app/app-routes';
import { Router } from '@angular/router';

@AccessibilityMode()
@Component({
  selector: 'app-redress-entry',
  templateUrl: './redress-entry.component.html',
  styleUrls: ['./redress-entry.component.scss'],
})
export class RedressEntryComponent implements OnInit, OnDestroy, AfterViewInit {
  passenger$: Observable<Passenger>;

  public showModal = false;
  public keyboardSubscription: Subscription;
  public redress = null;
  public elements = [];

  constructor(
    private store: Store<KeyboardState>,
    private location: Location,
    private logging: Logging,
    private router: Router,
    private accessibilityService: AccessibilityService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.logging.infoUiEnterRedressPageDisplayed(0);
    this.passenger$ = this.store.pipe(select(selectCurrentPassenger));
    this.store.dispatch(new ResetContent());
    this.validateKeyboardEntry();
  }

  ngOnDestroy() {
    if (this.keyboardSubscription) {
      this.keyboardSubscription.unsubscribe();
    }
  }

  validateKeyboardEntry() {
    const keyboardSelector = this.store.pipe(select('keyboard'));
    this.keyboardSubscription = keyboardSelector.subscribe((value) => {
      this.redress = value.content;
    });
  }

  onContinueClick() {
    this.logging.infoUiEnterRedressPageContinueSelected(0);
    this.store.dispatch(new PaxEditInfoUpdated({ redress: this.redress }));
    this.router.navigate([AppRoutes.PAX_EDIT_MAIN]);
  }

  ngAfterViewInit(): void {}
}
