import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { Authenticate, Keyboard, KeyboardState, PnrLocatorFlowUpdateState, ResetContent } from '../../../../state';
import { Logging } from '~app/services/logging/logging.service';
import { AppRoutes } from '~app/app-routes';

@Component({
  selector: 'app-by-confirmation-code',
  templateUrl: './by-confirmation-code.component.html',
  styleUrls: ['./by-confirmation-code.component.scss', './../locate-reservation.component.scss'],
})
export class ByConfirmationCodeComponent implements OnInit, OnDestroy, AfterViewInit {
  public keyboardSubscribe: Subscription;
  keyboard$: Observable<Keyboard>;
  menuRoute = AppRoutes.MENU;

  public code = '';
  public codeDone = false;

  constructor(
    public store: Store<KeyboardState>,
    public router: Router,
    private accessibilityService: AccessibilityService,
    private el: ElementRef,
    private logging: Logging
  ) {
    store.dispatch(new ResetContent());
    this.keyboard$ = store.pipe(select('keyboard'));
  }

  ngOnInit() {
    this.logging.infoUiConfirmationCodeLookupPageDisplayed(0);
    this.keyboardSubscribe = this.keyboard$.subscribe((value) => {
      if (value) {
        this.code = value.content;
        this.codeDone = value.content.length >= 6;
      }
    });
  }

  ngAfterViewInit(): void {
    const allElements = this.getElements();
    this.accessibilityService.setElementsToRead(allElements);
  }

  getElements() {
    return this.el.nativeElement.querySelectorAll('[tabindex]');
  }

  validateCode() {
    if (!this.codeDone) {
      return false;
    }

    this.code = this.code.toUpperCase();

    this.store.dispatch(new Authenticate({ confirmationCode: this.code }));
    this.store.dispatch(
      new PnrLocatorFlowUpdateState({ confirmationCode: this.code, lookupMethod: 'confirmationCode' })
    );

    this.logging.infoUiConfirmationCodeLookupPageContinueSelected(0);
  }

  ngOnDestroy() {
    if (this.keyboardSubscribe) {
      this.keyboardSubscribe.unsubscribe();
    }
  }
}
