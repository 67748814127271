/**
 * This the Optional pattern, with another name.
 * It's commonly used in functional languages and approaches.
 *
 * It's used to replace the use of null or undefined. If there is
 * a value that can be null or undefined, or optional, it can be
 * declared like this:
 * ```
 * prop: Maybe<SomeType>
 * ```
 *
 * Then it can be mapped over, like an array.
 * Example:
 * ```
 * prop
 *  .map(toSomething)
 *  .map(toSomethingElse)
 *  .withDefault(defaultValue);
 * ```
 *
 * Map works only when the value is not null or undefined.
 * `withDefault` will return a default value in case the value is
 * null or undefined at the end of the chain.
 *
 * It is a good way to avoid null and undefined runtime errors.
 *
 * More info:
 * https://en.wikipedia.org/wiki/Monad_(functional_programming)#An_example:_Maybe
 * https://guide.elm-lang.org/error_handling/maybe.html
 */
export default class Maybe<T> {
  private constructor(private value: T | null) {}

  static just<T>(value: T) {
    if (value === null || value === undefined) {
      throw Error('Provided value must not be empty');
    }
    return new Maybe(value);
  }

  static nothing<T>() {
    return new Maybe<T>(null);
  }

  static fromValue<T>(value: T) {
    return value ? Maybe.just(value) : Maybe.nothing<T>();
  }

  withDefault(defaultValue: T) {
    return this.value === null ? defaultValue : this.value;
  }

  map<R>(f: (wrapped: T) => R): Maybe<R> {
    if (this.value === null) {
      return Maybe.nothing<R>();
    } else {
      return Maybe.just(f(this.value));
    }
  }

  toJSON() {
    return this.value;
  }
}
