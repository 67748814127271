import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoadingService } from '../../services/ui/loading.service';
import { Router } from '@angular/router';
import { AppRoutes } from '~app/app-routes';
import { accessibilityJson, isAccessibilityMode } from '../../services/emitters/session-event-emitters';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { Store } from '@ngrx/store';
import { AppState, ResetLoadingState, SetTimeoutError } from '../../state';
import { SPEECH_STATUS } from '../../services/ha-cuss/device.service';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public isError = false;
  public unsubscribe$ = new Subject<void>();
  public altText = accessibilityJson.loading;
  public elements;
  private loadingTimeout;
  private timeout = 120000;

  constructor(
    public loadingService: LoadingService,
    public router: Router,
    private accessibilityService: AccessibilityService,
    public el: ElementRef,
    public changeRef: ChangeDetectorRef,
    private store: Store<AppState>
  ) {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    combineLatest([this.loadingService.isLoading, this.accessibilityService.speechStatus$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([currentLoadingState, speechStatus]) => {
        if (this.isLoading !== currentLoadingState) {
          if (isAccessibilityMode.getValue() && speechStatus == SPEECH_STATUS.STARTED) {
            return;
          }

          this.isLoading = currentLoadingState;
          this.isError = this.router.url.includes(AppRoutes.ERROR_SCREEN);
          this.updateTimer(currentLoadingState);
        }
      });

    if (this.router.url === AppRoutes.LOADING) {
      this.isLoading = true;
    }
  }

  @ViewChild('loadingModal', { static: false }) set updateACAAElements(element) {
    if (this.isLoading && element) {
      const elements = element.nativeElement.querySelectorAll('[tabindex]');
      const zIndex = +window.getComputedStyle(this.el.nativeElement.querySelectorAll('.loading-modal')[0]).zIndex;

      this.accessibilityService.setModalElementsToRead({
        id: 'loading',
        elements: elements,
        priority: zIndex,
      });
    } else {
      if (this.router.url === '/' || this.router.url === AppRoutes.SPLASH_SCREEN) {
        return;
      }

      this.accessibilityService.dismissModalElementsToRead({ modalId: 'loading' });
    }
  }

  updateTimer(isLoading: boolean) {
    if (isLoading) {
      this.loadingTimeout = setTimeout(() => {
        this.store.dispatch(new ResetLoadingState());
        this.loadingService.hideLoading();
        this.store.dispatch(new SetTimeoutError());
      }, this.timeout);
    } else {
      if (this.loadingTimeout) {
        clearTimeout(this.loadingTimeout);
      }
    }
  }
}
