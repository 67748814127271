import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '~app/services/api/config/config.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState, selectCurrentTrip } from '~app/state';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit, OnDestroy {
  public showSeats = false;
  public showBags = false;
  public bothEnabled = false;
  public noneEnabled = false;
  public unsubscribe$ = new Subject<void>();

  constructor(private store: Store<AppState>, public router: Router, public configService: ConfigService) {}

  ngOnInit() {
    this.configService.config.pipe(takeUntil(this.unsubscribe$)).subscribe((config) => {
      if (!config || !config.configuration) {
        return;
      }

      this.showSeats = config.configuration.enableSeats;
      this.showBags = config.configuration.enableBags;

      this.bothEnabled = this.showSeats && this.showBags;
      this.noneEnabled = !this.showSeats && !this.showBags;
    });

    this.store.pipe(select(selectCurrentTrip), takeUntil(this.unsubscribe$)).subscribe((trip) => {
      if (!trip) {
        return;
      }

      this.showSeats = trip.isNonRevSpaceAvailable() ? false : this.showSeats;

      this.bothEnabled = this.showSeats && this.showBags;
      this.noneEnabled = !this.showSeats && !this.showBags;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showStepper(route) {
    const possibleRoutes = [
      /^\/itinerary/,
      /^\/seat-preference/,
      /^\/hazmat/,
      /^\/locate-reservation/,
      /^\/print-documents/,
      /^\/confirmation/,
      /^\/pax-edit/,
      /^\/seats/,
      /^\/seat-change-error/,
      /^\/bags/,
      /^\/options/,
      /^\/payment/,
      /^\/special-items/,
      /^\/military/,
    ];

    return possibleRoutes.reduce((a, b) => a || RegExp(b).test(route), false);
  }

  currentStep(route) {
    switch (true) {
      case /^\/locate-reservation/.test(route):
      case /^\/seat-preference/.test(route):
      case /^\/options/.test(route):
      case /^\/pax-edit/.test(route):
      case /^\/itinerary/.test(route): {
        return 'step-1';
      }
      case /^\/seats/.test(route):
      case /^\/seat-change-error/.test(route): {
        return 'step-2';
      }
      case /^\/payment/.test(route):
      case /^\/special-items/.test(route):
      case /^\/bags/.test(route):
      case /^\/military/.test(route): {
        return this.showSeats ? 'step-3' : 'step-2';
      }
      case /^\/confirmation/.test(route):
      case /^\/print-documents/.test(route):
      case /^\/hazmat/.test(route): {
        if (this.showSeats && this.showBags) {
          return 'step-4';
        } else if (this.showSeats || this.showBags) {
          return 'step-3';
        }
        return 'step-2';
      }
      default: {
        return false;
      }
    }
  }
}
