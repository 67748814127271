import { deviceIds } from '../../assets/js/embross-device-manager';

export function maskPCI(value, deviceId) {
  deviceId = deviceId || -1;
  try {
    let maxLth = 6;

    switch (deviceId) {
      case deviceIds.PASSPORT_READER:
        maxLth = 5;
        break;
      case deviceIds.BARCODE_READER:
        if (value.length > 20 && value.indexOf('M1') === 0) {
          return 'M1' + repeatChar('N', 18) + value.substring(20);
        } else {
          maxLth = 0;
        }
        break;
      default:
    }
    if (value.length === 6) {
      return value;
    } else if (maxLth > 0) {
      return value.substring(0, maxLth) + (value.length > maxLth ? repeatChar('N', value.length - maxLth) : '');
    } else {
      return value.replace(/./gi, 'N');
    }
  } catch (err) {
    return '';
  }
}

export function isEmpty(obj) {
  return (
    obj === undefined ||
    obj === null ||
    obj === '' ||
    (typeof obj === 'object' && Object.keys(obj).length === 0) ||
    (Array.isArray(obj) && obj.length === 0) ||
    (Array.isArray(obj) && obj.includes(undefined))
  );
}

export function repeatChar(ch, n) {
  let ret = '';
  for (ret = ''; ret.length < n; ) {
    ret += ch;
  }
  return ret;
}
