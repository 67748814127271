import { TraceLevels } from '../../../../assets/js/embross-device-manager';
import {
  CardReaderDamaged,
  CardReaderInserted,
  CardReaderReadInternal,
  CardReaderRemoved,
  CardReaderStatusChange,
  CardReaderStatusIsOK,
} from '../../../state/ha-cuss-device/card-reader/card-reader.actions';

export function cardReaderCallback(event) {
  this.applinkService
    .getDeviceManager()
    .sendLogMsg(TraceLevels.LOG_TRACE, 'cardReaderCallback: event ' + event.key + ' and value ' + event.value);
  switch (event.key) {
    case 'cardInserted':
      this.cardReaderStore.dispatch(new CardReaderInserted(event.key, event.value));
      break;
    case 'cardDamaged':
      this.cardReaderStore.dispatch(new CardReaderDamaged(event.key, event.value));
      break;
    case 'cardRemoved':
      this.cardReaderStore.dispatch(new CardReaderRemoved(event.key, event.value));
      break;
    case 'cardReadInternal':
      this.logging.infoHardwareCardReaderSwipe();
      this.cardReaderStore.dispatch(new CardReaderReadInternal(event.key, event.value));
      break;
    case 'statusChange':
      this.cardReaderStore.dispatch(new CardReaderStatusChange(event.key, event.value));
      if (event.value[1] === true) {
        this.logging.errorHardwareCardReaderError(event.value[0], event.value[1]);
      }
      break;
    case 'statusIsOK':
      this.cardReaderStore.dispatch(new CardReaderStatusIsOK(event.key, event.value));
      break;
    default:
      this.applinkService
        .getDeviceManager()
        .sendLogMsg(
          TraceLevels.LOG_TRACE,
          'default: cardReaderCallback event ' + event.key + ' and value ' + JSON.stringify(event.value)
        );
  }
}
