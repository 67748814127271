import { Action } from '@ngrx/store';

export enum PassportActionTypes {
  PassportEnable = '[Passport] Device Enabled',
  PassportDisable = '[Passport] Device Disabled',
  PassportInserted = '[Passport] Inserted',
  PassportRemoved = '[Passport] Removed',
  PassportReadInternal = '[Passport] Read Internal',
  PassportDamaged = '[Passport] Damaged',
  PassportStatusChange = '[Passport] Status Change',
  PassportStatusIsOK = '[Passport] Status is Ok',
  PassportStatusResponse = '[Passport] Status Response',
}

export class PassportEnable implements Action {
  readonly type = PassportActionTypes.PassportEnable;

  constructor(readonly payload: string) {}
}

export class PassportDisable implements Action {
  readonly type = PassportActionTypes.PassportDisable;

  constructor(readonly payload: string) {}
}

export class PassportInserted implements Action {
  readonly type = PassportActionTypes.PassportInserted;
  payload: { event_id: string; passportInserted: string };

  constructor(eventId: string, passportInserted: string) {
    this.payload = { event_id: eventId, passportInserted };
  }
}

export class PassportRemoved implements Action {
  readonly type = PassportActionTypes.PassportRemoved;
  payload: { event_id: string; passportRemoved: string };

  constructor(eventId: string, passportRemoved: string) {
    this.payload = { event_id: eventId, passportRemoved };
  }
}

export class PassportReadInternal implements Action {
  readonly type = PassportActionTypes.PassportReadInternal;
  payload: { event_id: string; passportData: string };

  constructor(eventId: string, passportData: string) {
    this.payload = { event_id: eventId, passportData };
  }
}

export class PassportDamaged implements Action {
  readonly type = PassportActionTypes.PassportDamaged;
  payload: { event_id: string; passportDamaged: string };

  constructor(eventId: string, passportDamaged: string) {
    this.payload = { event_id: eventId, passportDamaged };
  }
}

export class PassportStatusChange implements Action {
  readonly type = PassportActionTypes.PassportStatusChange;
  payload: { event_id: string; passportStatusChange: [number, boolean] };

  constructor(eventId: string, passportStatusChange: [number, boolean]) {
    this.payload = { event_id: eventId, passportStatusChange };
  }
}

export class PassportStatusIsOK implements Action {
  readonly type = PassportActionTypes.PassportStatusIsOK;
  payload: { event_id: string; passportStatusIsOK: string };

  constructor(eventId: string, passportStatusIsOK: string) {
    this.payload = { event_id: eventId, passportStatusIsOK };
  }
}

export class PassportStatusResponse implements Action {
  readonly type = PassportActionTypes.PassportStatusResponse;
  payload: { event_id: string; passportStatusResponse: string };

  constructor(eventId: string, passportStatusResponse: string) {
    this.payload = { event_id: eventId, passportStatusResponse };
  }
}

export type PassportActions =
  | PassportEnable
  | PassportDisable
  | PassportInserted
  | PassportRemoved
  | PassportReadInternal
  | PassportDamaged
  | PassportStatusChange
  | PassportStatusIsOK
  | PassportStatusResponse;
