import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-flight-data',
  templateUrl: './confirmation-flight-status.component.html',
  styleUrls: ['./confirmation-flight-status.component.scss'],
})
export class ConfirmationFlightStatusComponent {
  @Input() flightStatus;
  @Input() isPrintedBags;

  constructor() {}
}
