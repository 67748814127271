import { LanguageActionTypes } from './language.action';
import Maybe from '../../fixtures-unit-test/maybe';

export interface LanguageState {
  languageId: Maybe<string>;
}

export const initialLanguageState: LanguageState = {
  languageId: Maybe.just('en'),
};

export function languageReducer(state = initialLanguageState, action): LanguageState {
  switch (action.type) {
    case LanguageActionTypes.LanguageSelected:
      return {
        languageId: Maybe.fromValue(action.payload),
      };

    default:
      return state;
  }
}
