import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Cart } from '~app/state/cart/cart.model';
import { Store } from '@ngrx/store';
import { AppState, Trip, UpdateCartAndGoToNextStep } from '~app/state';
import { Router } from '@angular/router';
import { AppRoutes } from '~app/app-routes';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { isAccessibilityMode } from '~app/services/emitters/session-event-emitters';

@Component({
  selector: 'app-bags-notice',
  templateUrl: './bags-notice.component.html',
  styleUrls: ['./bags-notice.component.scss'],
})
export class BagsNoticeComponent implements OnChanges {
  @Input() cart: Cart;
  @Input() trip: Trip;
  @Input() showModal: boolean;
  @Output() showModalChanged: EventEmitter<any> = new EventEmitter<any>();

  public cartSubscription: Subscription;

  specialItemsCount;
  specialItemsTotalPrice;

  regularBagsCount;
  regularBagsTotalPrice;

  totalPrice = '';

  unsubscribe$ = new Subject<void>();

  private localStringConfig = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private el: ElementRef,
    private accessibilityService: AccessibilityService
  ) {}

  ngOnChanges(): void {
    // sometimes the component reacts to changes when cart is not parsed yet
    if (this.cart && this.cart instanceof Cart) {
      if (this.trip && this.trip.activeSegment) {
        this.regularBagsCount = this.trip.activeSegment.getNumRegularBags();
        this.regularBagsTotalPrice = this.cart.getRegularBagsTotalPrice().toLocaleString('en', this.localStringConfig);
        this.specialItemsCount = this.trip.activeSegment.getNumSpecialItems();
        this.specialItemsTotalPrice = this.cart
          .getSpecialItemsTotalPrice()
          .toLocaleString('en', this.localStringConfig);
        this.totalPrice = this.cart.getGrandTotalPriceForBagsAndSpecialItems();
      }
    }
  }

  @ViewChild('bagsModal', { static: false }) set updateAcaaElements(element) {
    if (this.showModal && element) {
      const elements = element.nativeElement.querySelectorAll('[tabindex]');
      this.accessibilityService.activateModalElementsToRead(elements, 'bags-notice');
    }
  }

  back() {
    this.accessibilityService.dismissModalElements(null, null, 'bags-notice');
    this.showModal = false;
    this.showModalChanged.emit(this.showModal);
  }

  continue() {
    this.accessibilityService.dismissModalElements(null, null, 'bags-notice');
    this.showModal = false;

    if (this.cart && this.cart.items.length > 0) {
      this.store.dispatch(new UpdateCartAndGoToNextStep());
    } else {
      this.router.navigate([AppRoutes.HAZMAT_PROHIBITED]);
    }
  }
}
