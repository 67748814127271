import { Action } from '@ngrx/store';

export enum LanguageActionTypes {
  LanguageSelected = '[Language] Selected',
}

export class SelectLanguage implements Action {
  readonly type = LanguageActionTypes.LanguageSelected;
  constructor(private payload: string) {}
}

export type LanguageActions = SelectLanguage;
