import { SegmentFlight } from './segment-flight.model';
import { Passenger } from '../passenger.model';
import { seatNumberParse } from '~app/utils/helpers';
import { BagTypeId, Product } from '../passenger.catalog.model';
import { MILITARY_TRAVEL_TYPE } from '~app/models/military/military.model';

export interface SegmentDetailBags {
  productId?: string;
  count: number;
  type: string;
  isPendingCheckout: boolean;
  receiptNumber: string;
  weightLimit?: number;
  lengthLimit?: number;
}

export class SegmentDetail implements SegmentDetail {
  id: string;
  alertReasonCode: string | null;
  alertMessageCode: string | null;
  bags: SegmentDetailBags[] | null;
  canCheckIn: boolean | false;
  passengerName: {
    firstName: string | null;
    lastName: string | null;
    middleName: string | null;
  };
  flightDetails: SegmentFlight[] | null;
  isCheckedIn: boolean | false;
  selected: boolean | true;
  passengerId: string | null;
  isUpgradeEligible?: boolean | false;
  isDowngradeEligible?: boolean | false;
  segmentId: string | null;
  seatPreference?: string | null;
  isMilitary: boolean;

  static deserializeFromJson(json): SegmentDetail {
    const segmentDetail = Object.assign(new SegmentDetail(), json);
    segmentDetail.passenger = Passenger.deserializeFromJson(segmentDetail.passenger);

    const flights = json.flightDetails || [];
    segmentDetail.flightDetails = flights.map((flight) => SegmentFlight.deserializeFromJson(flight));

    segmentDetail.isMilitary = segmentDetail.flightDetails[0].militaryTravelType !== MILITARY_TRAVEL_TYPE.NONE;

    return segmentDetail;
  }

  getNumBags(): number {
    let numBags = 0;

    this.bags.forEach((bag) => {
      if (typeof bag === 'number') {
        numBags += bag;
      }

      if (bag && typeof bag.count === 'number') {
        numBags += bag.count;
      }
    });

    return numBags;
  }

  getPaidBagsCount(): number {
    if (!this.bags) {
      return 0;
    }

    return this.bags
      .filter((bag) => !bag.isPendingCheckout && bag.productId === BagTypeId.Regular)
      .map((bag) => bag.count)
      .reduce((x, y) => x + y, 0);
  }

  getNumOfRegularBags(): number {
    let numOfRegularBags = 0;

    this.bags.forEach((bag) => {
      if (bag && bag.productId === BagTypeId.Regular) {
        numOfRegularBags += bag.count;
      }
    });

    return numOfRegularBags;
  }

  getNumOfSpecialItems(): number {
    let numOfSpecialItems = 0;

    this.bags.forEach((bag) => {
      if (bag && bag.productId !== BagTypeId.Regular && bag.productId !== BagTypeId.Allowances) {
        numOfSpecialItems += bag.count;
      }
    });

    return numOfSpecialItems;
  }

  getSpecialBagsForItem(product: Product): SegmentDetailBags[] {
    return this.bags.filter((bag: SegmentDetailBags) => {
      return bag.productId === product.productId;
    });
  }

  getNumberOfSpecialBagsForItem(product: Product): number {
    let bags = this.getSpecialBagsForItem(product);

    if (!bags || bags.length === 0) {
      return 0;
    }

    const total = bags.reduce((previousValue: number, bag: SegmentDetailBags) => {
      return (previousValue += bag.count);
    }, 0);

    return total;
  }

  getPaidSpecialItemsCountForItem(product: Product): number {
    let bags = this.getSpecialBagsForItem(product);

    if (!bags || bags.length === 0) {
      return 0;
    }

    return bags.reduce((previousValue: number, bag: SegmentDetailBags) => {
      return !bag.isPendingCheckout ? previousValue + bag.count : previousValue;
    }, 0);
  }

  addRegularBag() {
    this.bags.push({
      count: 1,
      productId: BagTypeId.Regular,
      type: 'regularBags',
      isPendingCheckout: true,
      receiptNumber: '',
    });
  }

  addSpecialItem(item: Product) {
    const bag: SegmentDetailBags = {
      count: 1,
      productId: item.productId,
      type: item.type,
      isPendingCheckout: true,
      receiptNumber: '',
    };

    if (item.weightLimit > 0) {
      bag.weightLimit = item.weightLimit;
    }

    if (item.lengthLimit > 0) {
      bag.lengthLimit = item.lengthLimit;
    }

    this.bags.push(bag);
  }

  removeRegularBag() {
    this.removedBag(BagTypeId.Regular);
  }

  removeSpecialItem(item: Product) {
    this.removedBag(item.productId);
  }

  removedBag(type: BagTypeId | string) {
    const indexOfBagToRemove = this.bags.findIndex((bag) => bag.productId === type && bag.isPendingCheckout);

    if (indexOfBagToRemove >= 0) {
      this.bags.splice(indexOfBagToRemove, 1);
    }
  }

  getSeatsAsString(): string {
    const seatNumbers = [];
    this.flightDetails.map((flightDetail) => seatNumbers.push(seatNumberParse(flightDetail.seatNumber)));

    return seatNumbers.filter(Boolean).join(', ');
  }
}
