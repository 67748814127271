import { TraceLevels } from '../../../../assets/js/embross-device-manager';
import {
  PassportDamaged,
  PassportInserted,
  PassportReadInternal,
  PassportRemoved,
  PassportStatusChange,
  PassportStatusIsOK,
  PassportStatusResponse,
} from '../../../state/ha-cuss-device/passport/passport.actions';
import { hardwareDeviceStatus, isPassportAvailable } from '../device.service';

export function passportReaderCallback(event) {
  this.applinkService
    .getDeviceManager()
    .sendLogMsg(
      TraceLevels.LOG_TRACE,
      'passportReaderCallback: passportReaderCallback ' + event.key + ' and value ' + event.value
    );
  switch (event.key) {
    case 'statusIsOK':
      this.passportReaderStore.dispatch(new PassportStatusIsOK(event.key, event.value));
      break;
    case 'passportInserted':
      this.passportReaderStore.dispatch(new PassportInserted(event.key, event.value));
      break;
    case 'passportDamaged':
      this.passportReaderStore.dispatch(new PassportDamaged(event.key, event.value));
      this.logging.errorHardwarePassportDamaged();
      break;
    case 'passportRemoved':
      this.passportReaderStore.dispatch(new PassportRemoved(event.key, event.value));
      break;
    case 'passportReadInternal':
      this.passportReaderStore.dispatch(new PassportReadInternal(event.key, event.value));
      this.logging.infoHardwarePassportScanned('PassportDataScanned');
      if (this.passportData === 'Invalid Passport') {
        this.logging.infoPassportDataInvalid();
      }
      break;
    case 'statusChange':
      this.passportReaderStore.dispatch(new PassportStatusChange(event.key, event.value));
      break;
    case 'statusResponse':
      this.passportReaderStore.dispatch(new PassportStatusResponse(event.key, event.value));
      if (event.value === '0') {
        hardwareDeviceStatus.passportDevice = [parseInt(event.value, 10), false];
      } else {
        hardwareDeviceStatus.passportDevice = [parseInt(event.value, 10), true];
      }
      isPassportAvailable.emit(!hardwareDeviceStatus.passportDevice[1]);
      break;
    default:
      this.applinkService
        .getDeviceManager()
        .sendLogMsg(
          TraceLevels.LOG_TRACE,
          'default: passportReaderCallback: event.key ' + event.key + ' and value ' + JSON.stringify(event.value)
        );
  }
}
