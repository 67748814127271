import { AppState } from '../../index';
import { createSelector } from '@ngrx/store';
import { PassportState } from './passport.reducer';

const selectPassport = (state: AppState) => state.passport;

export const selectPassportData = createSelector(
  selectPassport,
  (state: PassportState) => state.passportData
);

export const selectPassportStatus = createSelector(
  selectPassport,
  (state: PassportState) => state.passportStatusChange
);
