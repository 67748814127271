import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '~app/app-routes';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { Logging } from '~app/services/logging/logging.service';

@Component({
  selector: 'app-no-self-tagging',
  templateUrl: './no-self-tagging.component.html',
  styleUrls: ['./no-self-tagging.component.scss', '../hazmat.component.scss'],
})
export class NoSelfTaggingComponent implements OnInit, AfterViewInit {
  constructor(
    public router: Router,
    private accessibilityService: AccessibilityService,
    private el: ElementRef,
    private logging: Logging
  ) {}

  ngOnInit() {}

  continue() {
    this.logging.infoUiHazMatPage3ContinueSelected(0);
    this.router.navigate([AppRoutes.PRINT_DOCUMENTS]);
  }

  ngAfterViewInit(): void {
    this.logging.infoUiHazMatPage3Displayed(0);
  }
}
