import { HeaderKeys } from './../app/services/api/api.config';
import { Environment } from './environment-interfaces';
import * as packageSettings from '../../package.json';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  production: false,
  name: 'test',
  cussEnabled: true,
  kioskId: '',
  logging: {
    level: 'DEBUG',
    environmentName: 'TEST',
    applicationName: 'HA-Kiosk',
    domain: 'DoT Check-in',
    apiKey: 'yyvlobkaeyuvx3c0h0x7jywist1ctecbhrbdgpc8',
    instrumentationKey: 'b5298cad-a98c-423f-89ba-8912e141f132',
  },
  sampleData: {
    cardData:
      '{"track3":"","track1":"B4111111111111111^ANDERSON\\/STEVEN^1705101100001350000","track2":"4111111111111111=2309101100001350000"}',
  },
  version: packageSettings.version,
  buildNumber: packageSettings.buildNumber,
  api: {
    baseUrl: 'https://kiosk-test.nonprod.itservices.hawaiianairlines.com',
    basePath: {
      default: 'exp-kiosk/v1/api',
      config: 'exp-cx-config-admin/v1/api',
    },
    tokenEnv: 'test',
    headers: {
      xHaChannel: {
        key: HeaderKeys.XHaChannel,
        value: 'KIOSK',
      },
      xHaBusinessDomain: {
        key: HeaderKeys.XHaBusinessDomain,
        value: 'CHECKIN',
      },
      contentType: {
        key: HeaderKeys.ContentType,
        value: 'application/json',
      },
      xRootCorrelationId: {
        key: HeaderKeys.XRootCorrelationId,
        value: '',
      },
      Authorization: {
        key: HeaderKeys.Authorization,
        value: 'Basic YWI1MTJjYmJmNjJkNGVlZjk5YmRkNDY2NWJhZjJmZjQ6RTM1MTIzN0Y5OTA2NDA1NWFDYTUyYmUxNzlhZjBGNTA=',
      },
      xRootSessionId: {
        key: HeaderKeys.XRootSessionId,
        value: '',
      },
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
