import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState, PassengerName, selectCart } from '~app/state';

@Component({
  selector: 'app-special-items-header',
  templateUrl: './special-items-header.component.html',
  styleUrls: ['./special-items-header.component.scss'],
})
export class SpecialItemsHeaderComponent implements OnInit, OnDestroy {
  @Input() isOA: boolean;
  private paxEditInfoSub: Subscription;
  public pax: PassengerName;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.paxEditInfoSub = this.store.pipe(select(selectCart)).subscribe((cart) => {
      if (cart && cart.paxSelectedForSpecialItems) {
        this.pax = cart.paxSelectedForSpecialItems.passengerName;
      }
    });
  }

  ngOnDestroy() {
    if (this.paxEditInfoSub) {
      this.paxEditInfoSub.unsubscribe();
    }
  }
}
