import { Injectable } from '@angular/core';
import * as dateFns from 'date-fns';

import { API } from '../api.service';
import { HaCussService } from '../../ha-cuss/ha-cuss.service';
import { selectedError } from '../../../state/error/error.selector';
import { AppState, DocumentActionTypes, Segment, SegmentDetail, Trip } from '../../../state';
import { select, Store } from '@ngrx/store';
import { Receipt } from '../../../state/document/document.model';
import { Payment, PointOfSale } from '../../../state/cart/cart.model';
import { ConfigService } from '../config/config.service';
import { TripService } from '../trip/trip.service';
import { MachineConfig } from '../config/config';
import { kioskId, SpecialItems, specialItemsCatalog } from '../../emitters/session-event-emitters';
import { isEmpty } from '../../../../shared/embross/helper';
import { AlertReasonCode } from '~app/models/error/error.model';
import { SeatProducts } from '~app/models/seat-map/seat-map.model';

@Injectable({ providedIn: 'root' })
export class DocumentService {
  currentError;
  private cartPaymentInformation: Payment;
  private bagTagKeys: string[] = [];

  constructor(
    private api: API,
    private haCuss: HaCussService,
    private store: Store<AppState>,
    private configService: ConfigService,
    private tripService: TripService
  ) {
    this.store.pipe(select(selectedError)).subscribe((currentError) => {
      this.currentError = currentError;
    });
  }
  private baseUrl = `${this.api.baseUrl}/${this.api.basePath}/${this.api.apiPaths.document.root}`;
  private bagsUrl = `${this.api.baseUrl}/${this.api.basePath}/${this.api.apiPaths.bags}`;

  static getCustomerLastName(detail) {
    let lastName: string;

    if (!detail || typeof detail !== 'object' || Object.keys(detail).length === 0) {
      lastName = '';
    } else {
      lastName = 'passengerName' in detail ? detail.passengerName.lastName : '';
    }

    return lastName;
  }

  static getCustomerFirstName(detail) {
    let firstName: string;

    if (!detail || typeof detail !== 'object' || Object.keys(detail).length === 0) {
      firstName = '';
    } else {
      firstName = 'passengerName' in detail ? detail.passengerName.firstName : '';
    }

    return firstName;
  }

  setCartPaymentInformation(cardInfo) {
    this.cartPaymentInformation = cardInfo;
  }

  setBoardingPassData(trip) {
    this.resetQueryParams();

    const segment = trip.activeSegment;
    let paxInfo = [];

    paxInfo = this.setPaxInfoSelected(segment);

    const body = {
      isHAKiosk: true,
      documentType: this.api.apiPaths.document.boardingPass,
      documentData: {
        tripId: trip.id,
        segmentId: segment.id,
        passengerInformation: paxInfo,
      },
    };

    this.api.updateCorrelationId(trip.id);

    return this.api.doPostCall(this.baseUrl, body);
  }

  private setPaxInfoSelected(segment) {
    let paxInfo = [];
    segment.getPaxIdsSelectedForCheckin().map((paxId) => {
      paxInfo.push({ id: paxId });
    });
    return paxInfo;
  }

  setCounterAssistTicketData(trip, action?) {
    this.resetQueryParams();

    const segment = trip.activeSegment;
    const date = dateFns.format(new Date(), 'YYYY-MM-DD');

    if (action && action.type === DocumentActionTypes.PrintCounterAssist) {
      segment.details[0].alertReasonCode = action.payload.alertReasonCode;
      segment.details[0].alertMessageCode = action.payload.alertMessageCode;
    } else if (this.currentError.value.codes.alertReasonCode.match(/^(Issue With Payment)$/)) {
      segment.details[0].alertReasonCode = this.currentError.value.codes.alertReasonCode;
    } else
      switch (this.currentError.value.codes.alertReasonCode) {
        case AlertReasonCode.PC01:
        case AlertReasonCode.PC02:
        case AlertReasonCode.PC03:
          segment.details[0].alertReasonCode = this.currentError.value.codes.alertReasonCode;
      }

    const description = !isEmpty(this.currentError.value.codes.alertMessageCode)
      ? this.currentError.value.codes.alertMessageCode
      : '';

    const body = {
      isHAKiosk: true,
      documentType: this.api.apiPaths.document.counterAssist,
      documentData: {
        errorCode: segment.details[0].alertReasonCode,
        errorMessage1: segment.details[0].alertMessageCode,
        errorMessage2: description,
        confirmationCode: trip.confirmationCode,
        flightNumber: segment.flights[0].flightNumber,
        additionalDetails: {
          kioskId: this.haCuss.getKioskId(),
          createdDate: date,
        },
        isOaEmpire: trip.flights[0].isOaEmpire().toString(),
        origin: trip.activeSegment.origin,
        checkinMethod: 'KIOSK',
        bookingBarcode: trip.confirmationCode,
      },
    };

    this.api.updateCorrelationId(trip.id);

    return this.api.doPostCall(this.baseUrl, body);
  }

  setReceiptTicketData(trip, cart) {
    this.resetQueryParams();

    const segment = trip.activeSegment;
    const date = dateFns.format(new Date(), 'DDMMMYY');
    const body: Receipt = this.getReceiptBody(cart, trip, date, segment);

    this.api.updateCorrelationId(trip.id);

    return this.api.doPostCall(this.baseUrl, body);
  }

  setBagTagData(trip: Trip, segment: Segment): Promise<any> {
    this.resetQueryParams();

    let bagsToPrint = {};
    let body = {};

    const details = trip.activeSegment.details;

    bagsToPrint = details
      .filter((segmentDetail) => segmentDetail.selected === true)
      .map((segmentDetail) => {
        return {
          id: segmentDetail.passengerId,
          bags: segmentDetail.bags.map((bag) => {
            return {
              productId: bag.productId,
              quantity: bag.count,
              type: bag.type,
              weight: {
                unit: 'LBS',
                value: bag.weightLimit || '50',
              },
              length: {
                unit: 'IN',
                value: bag.lengthLimit || '0',
              },
            };
          }),
        };
      });

    const tripId = trip.id;
    const segmentId = segment.id;

    this.configService.config
      .subscribe((response) => {
        if (response && response.configuration) {
          body = {
            isHAKiosk: true,
            documentType: this.api.apiPaths.document.bagTag,
            documentData: {
              tripId,
              segmentId,
              kioskId: this.haCuss.getKioskId(),
              kioskStock: response.bagTag.size,
              kioskStockOrientation: response.bagTag.orientation,
              kioskBrand: response.bagTag.brand,
              passengerInformation: bagsToPrint,
            },
          };

          this.api.updateCorrelationId(tripId);
        }
      })
      .unsubscribe();

    return this.api.doPostCall(this.baseUrl, body);
  }

  resetBagTagKeys() {
    this.bagTagKeys = [];
  }

  loadBagTagKey(key) {
    this.bagTagKeys.push(key);
  }

  getNextBagTagKey() {
    if (this.bagTagKeys.length === 0) {
      return null;
    } else {
      const nextKey = this.bagTagKeys[0];
      this.bagTagKeys.shift();

      return nextKey;
    }
  }

  reportBagTagPrinted(bagTagKey, activeSegment: Segment, currentTrip: Trip) {
    let paxLastNames = [];
    if (this.api.getQueryParamsCount().length > 0) {
      this.api.clearQueryParams();
    }

    const body = [
      {
        path: `${bagTagKey}/printed`,
        op: 'replace',
        value: 'true',
      },
    ];

    this.api.updateCorrelationId(bagTagKey);

    // Add the queryParams required for the API to locate the trip associated with the successful bag tag print
    this.api.appendQueryParams('confirmationCode', currentTrip.confirmationCode);
    this.api.appendQueryParams('airlineCode', activeSegment.flights[0].airlineCode);
    this.api.appendQueryParams('flightNumber', activeSegment.flights[0].flightNumber);
    this.api.appendQueryParams('departureDate', activeSegment.departure.airportDateTimeString.split('T')[0]);
    this.api.appendQueryParams('origin', activeSegment.flights[0].origin);
    // Create a string of all the last names on the PNR
    activeSegment.getPaxLastNames().map((lastName: string) => {
      paxLastNames.push(lastName);
    });
    this.api.appendQueryParams('lastNames', paxLastNames.toString());

    return this.api.doPatchCall(this.bagsUrl, body);
  }

  private resetQueryParams() {
    if (this.api.getQueryParamsCount().length > 0) {
      this.api.clearQueryParams();
    }
  }

  private getReceiptBody(cart, trip, date, segment) {
    return {
      isHAKiosk: true,
      documentType: this.api.apiPaths.document.receipt,
      documentData: {
        // This is the name on the card that is used
        cardName: `${this.cartPaymentInformation.lastName}/${this.cartPaymentInformation.firstName}` || '',
        // This is the auth code
        authorizationCode: this.cartPaymentInformation.cardType || '',
        // Total price for the cart
        totalPrice: this.addDecimals(cart.grandTotal.toString(10)) || '',
        // Confirmation Code
        confirmationCode: trip.confirmationCode || '',
        // Hawaiian Miles Number
        hawaiianMilesNumber: trip.passengers.hawaiianMilesNumber || '',
        // Kiosk that performed the transaction
        issueLocation: kioskId.getValue() || '',
        // Date of transaction
        issueDate: date || '',
        // Form of payment that is used, defaults to CreditDebit
        formOfPayment: this.mapTypeToName(this.cartPaymentInformation.cardType),
        // Card number
        cardNumber: this.getShortCardNum() || '',
        // Items that have been purchased
        lineItem: this.getLineItem(cart, segment, date),
        bagText: '',
        // Is the flight Hawaiian or OA
        isOAEmpire: trip.flights[0].isOaEmpire().toString() || '',
      },
    };
  }

  private getShortCardNum() {
    const shortNum = this.cartPaymentInformation.paymentToken.substr(
      this.cartPaymentInformation.paymentToken.length - 4
    );
    return shortNum.padStart(this.cartPaymentInformation.paymentToken.length, 'X');
  }

  private getLineItem(cart, segment, date) {
    const lineItems = [];
    const itemsPurchased = cart.items.filter((item) => parseFloat(item.price) > 0);

    itemsPurchased.forEach((item) => {
      const id = item.associatedPassenger.passengerId;
      const associatedDetail = this.tripService
        .getCartLineItemInfo()
        .find((segmentDetail) => segmentDetail.passengerId === id);

      const lineItem = {
        // The number of the receipt
        receiptNumber: this.getReceiptNumber(item, associatedDetail),
        // Human readable name of the items
        description: `${this.prepareItemDescription(item)} (${item.quantity})` || '',
        // Price of the item
        itemPrice: `${this.addDecimals(item.price)}` || '',
        // Flight number
        flightNumber: segment.flights[0].flightNumber || '',
        // Departure date
        departureDate: date || '',
        lastName: DocumentService.getCustomerLastName(associatedDetail) || '',
        firstName: DocumentService.getCustomerFirstName(associatedDetail) || '',
      };

      lineItems.push(lineItem);
    });

    return lineItems;
  }

  /**
   * This function is responsible for getting the receipt number for specific item
   * @param item: the special items object
   * @param detail: Associated passenger segment detail
   */
  private getReceiptNumber(item: SpecialItems, detail: SegmentDetail) {
    let receiptNumber = '';

    // this logic assume there are two product category -- seats and bags
    // if the product is of seat type then grab its receipt number
    // from flightDetails
    if (
      (<any>Object).values(SeatProducts).includes(item.productId) &&
      detail.flightDetails &&
      detail.flightDetails.length > 0 &&
      detail.flightDetails[0].ancillaryItems &&
      detail.flightDetails[0].ancillaryItems.length > 0
    ) {
      receiptNumber = detail.flightDetails[0].ancillaryItems[0].receiptNumber;
    }
    // else receipt number for bags is located in a unique place
    else if (detail.bags && detail.bags.length > 0) {
      const latestReceiptIndex = detail.bags.length - 1;
      receiptNumber = detail.bags[latestReceiptIndex].receiptNumber;
    }

    return receiptNumber;
  }

  /**
   * This function is responsible for preparing the line item on the receipt
   * @param item: Line item on the receipt
   */
  private prepareItemDescription<T>(item: SpecialItems) {
    const splitWord = 'UP';
    const desiredIndex = 0;
    const subStringStart = 0;
    const subStringEnd = 20;
    // First we locate the productName, then we split on the word UP, then trim the string to make sure it fits
    // in the description field
    return this.findProductLabel(item.productId)
      .split(splitWord)
      [desiredIndex].substr(subStringStart, subStringEnd);
  }

  /**
   * This function locates the product label for the provided productId
   * @param productId: ID from the product
   */
  private findProductLabel(productId: string): string {
    const supportedItemsCatalog = specialItemsCatalog.getValue();
    // We add the Regular Bag mapping since it does not exist by default on special items
    supportedItemsCatalog.push(
      {
        productId: '0GO',
        commercialName: '',
        type: '',
        freeItemCount: 0,
        attributes: [],
        productLabel: 'Regular Bag',
        icon: '',
      },
      {
        productId: '0BJ',
        commercialName: '',
        type: '',
        freeItemCount: 0,
        attributes: [],
        productLabel: 'Seat Change',
        icon: '',
      },
      {
        productId: '0BV',
        commercialName: '',
        type: '',
        freeItemCount: 0,
        attributes: [],
        productLabel: 'Seat Change',
        icon: '',
      }
    );
    let product = supportedItemsCatalog.find((specialItem) => specialItem.productId == productId);
    return product ? product.productLabel : '';
  }

  getPointOfSale(): PointOfSale {
    const pointOfSale: PointOfSale = {
      location: {
        cityCode: '',
        stationCode: '',
      },
    };

    this.configService.config.subscribe((response: MachineConfig) => {
      pointOfSale.location.cityCode = response.airportCode;
    });

    return pointOfSale;
  }

  private addDecimals(num): string {
    // Convert input string to a number and store as a variable.
    let paddedValue = '';
    const value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    const res = num.split('.');
    // If there is no decimal point or only one decimal place found.
    if (res.length == 1 || res[1].length < 3) {
      // Set the number to two decimal places
      paddedValue = value.toFixed(2);
    } else {
      paddedValue = String(value);
    }
    // Return updated or original number.
    return paddedValue;
  }

  private mapTypeToName(paymentType: string): string {
    let typeString: string;
    switch (paymentType) {
      case '001': {
        typeString = 'VISA';
        break;
      }
      case '002': {
        typeString = 'MASTERCARD';
        break;
      }
      case '003': {
        typeString = 'AMEX';
        break;
      }
      case '004': {
        typeString = 'DISCOVER';
        break;
      }
      case '005': {
        typeString = 'DINERS CLUB';
        break;
      }
      case '007': {
        typeString = 'JCB';
        break;
      }
      case '040': {
        typeString = 'UATP';
        break;
      }
      default: {
        typeString = 'CreditDebit';
        break;
      }
    }
    return typeString;
  }
}
