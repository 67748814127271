import { CussAccessibilityActionTypes } from './cuss-accessibility.actions';
import { isAccessibilityMode } from '../../../services/emitters/session-event-emitters';

export interface CussAccessibilityState {
  headsetInserted: boolean;
}

export const initialState: CussAccessibilityState = {
  headsetInserted: false,
};

export function cussAccessibilityReducers(
  state: CussAccessibilityState = initialState,
  action
): CussAccessibilityState {
  let callbackLogs = '';
  switch (action.type) {
    case CussAccessibilityActionTypes.HeadsetInserted: {
      isAccessibilityMode.next(true);
      state.headsetInserted = true;
      return { ...state };
    }
    case CussAccessibilityActionTypes.HeadsetRemoved: {
      isAccessibilityMode.next(false);
      state.headsetInserted = false;
      return { ...state };
    }
    default:
      callbackLogs = 'default: ' + JSON.stringify(action.payload);
      return state;
  }
}
