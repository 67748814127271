import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

import { bagTagsPrinted, isAccessibilityMode } from '~app/services/emitters/session-event-emitters';
import { ClearState } from '~app/state/clearstate/clearstate.actions';
import { delay, takeUntil } from 'rxjs/operators';
import { AppState, CussAccessibilityActionTypes } from '../../../state';
import { HaCussService } from '~services/ha-cuss/ha-cuss.service';
import { Logging } from '~services/logging/logging.service';
import { AppRoutes } from '~app/app-routes';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { Actions, ofType } from '@ngrx/effects';
import { SPEECH_STATUS } from '~services/ha-cuss/device.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit, OnDestroy, AfterViewInit {
  public timeout;
  public bagsPrinted = false;

  private unsubscribe$ = new Subject<void>();
  private gifBagPrintedDuration = 18.5 * 1000;

  isLoading$ = new BehaviorSubject<boolean>(true);

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private actions$: Actions,
    private haCussService: HaCussService,
    protected logging: Logging,
    private accessibilityService: AccessibilityService,
    private el: ElementRef,
    private changeRef: ChangeDetectorRef
  ) {
    bagTagsPrinted.subscribe((result) => {
      this.bagsPrinted = result;
    });
  }

  ngOnInit() {
    this.logging.infoUiConfirmationPageDisplayed();
    this.logging.infoUiCheckinComplete();

    if (!isAccessibilityMode.getValue()) {
      this.isLoading$.pipe(delay(this.gifBagPrintedDuration), takeUntil(this.unsubscribe$)).subscribe(() => {
        this.clearCacheAndNavigateToSplashScreen();
      });
    }
  }

  ngAfterViewInit() {
    this.accessibilityService.dismissModalElementsToRead({ modalId: 'confirmation' });
    this.beginReading();
  }

  beginReading() {
    this.changeRef.detectChanges();
    const elements = this.el.nativeElement.querySelectorAll('[tabindex]');

    const unsubReading$ = new Subject<void>();
    this.actions$
      .pipe(takeUntil(this.unsubscribe$), ofType(CussAccessibilityActionTypes.HeadsetRemoved))
      .subscribe(() => {
        this.accessibilityService.speechStatus$.next(SPEECH_STATUS.COMPLETED);
        unsubReading$.next();
        unsubReading$.complete();
      });

    this.accessibilityService
      .setAndReadAllElements(elements)
      .pipe(takeUntil(unsubReading$))
      .subscribe(
        // Subscription
        () => {},
        // Error
        () => {},
        // Complete
        () => {
          this.clearCacheAndNavigateToSplashScreen();
        }
      );
  }

  clearCacheAndNavigateToSplashScreen() {
    this.accessibilityService.speechStatus$.pipe(takeUntil(this.unsubscribe$)).subscribe((status: SPEECH_STATUS) => {
      if (status != SPEECH_STATUS.STARTED) {
        this.store.dispatch(new ClearState());
        this.router.navigate([AppRoutes.SPLASH_SCREEN]);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.haCussService.enableLookupDevices();
  }
}
