import { Injectable } from '@angular/core';
import { AppState } from '~app/state';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StoreConfigService {
  static storeSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private store: Store<AppState>) {}
  clearStoreSubject() {
    StoreConfigService.storeSubject$.next(null);
  }
  getStoreSubjectValue() {
    StoreConfigService.storeSubject$.getValue();
  }
  setStoreSubject(value) {
    StoreConfigService.storeSubject$.next(value);
  }
  unsubscribeStoreSubject() {
    StoreConfigService.storeSubject$.unsubscribe();
  }
}
