export function AccessibilityMode(): ClassDecorator {
  return function(constructor: any) {
    const ngAfterViewInit = 'ngAfterViewInit';
    const original = constructor.prototype[ngAfterViewInit];

    if (original) {
      constructor.prototype[ngAfterViewInit] = function(...args) {
        this.elements = this.el.nativeElement.querySelectorAll('[tabindex]');
        this.accessibilityService.setElementsToRead(this.elements);
        original && original.apply(this, args);
      };
    }
  };
}
