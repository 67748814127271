import { BagTypeId, PassengerCatalog, Product } from './passenger.catalog.model';
import { SegmentFlight } from './segment/segment-flight.model';

export class Passenger {
  id: string;
  passengerName: PassengerName;
  externalId: string | null;
  dateOfBirth: string | null;
  gender: string | null;
  redressNumber: string | null;
  knownTravelerNumber: string | null;
  seatPreference: string | null;
  isMinor: boolean | null;
  hasInfant: boolean | null;
  loyalty?: PassengerLoyalty;
  seatsCatalog?: PassengerCatalog[];
  bagsCatalog?: PassengerCatalog;
  flightDetails: SegmentFlight[];
  isSelected: boolean | true;

  static deserializeFromJson(json): Passenger {
    const passenger = new Passenger();
    passenger.loyalty = {
      isCardHolder: null,
      level: '',
      membershipId: '',
      programName: '',
    };

    return Object.assign(passenger, json);
  }

  private filterProductsById(catalogFilter: CatalogFilter): Array<Product> {
    const { include = [], exclude = [] } = catalogFilter;

    if (!this.bagsCatalog || !this.bagsCatalog.products) {
      return null;
    }

    const catalog = this.bagsCatalog.products.filter((product) => {
      if (exclude.includes(product.productId)) {
        return false;
      }

      if (include.includes(product.productId)) {
        return true;
      }

      const hasIncludeRequirements = include.length > 0;
      if (hasIncludeRequirements) {
        return false;
      }

      return true;
    });

    return catalog;
  }

  get fullName() {
    return this.passengerName.firstName + ' ' + this.passengerName.lastName;
  }

  get hawaiianMilesNumber() {
    return this.loyalty.membershipId;
  }

  set hawaiianMilesNumber(value: string) {
    this.loyalty.membershipId = value;
  }

  get customerLevel() {
    return this.loyalty.level;
  }

  set customerLevel(value: string) {
    this.loyalty.level = value;
  }

  isHawaiianMilesEliteMember() {
    const hawaiianMilesEliteMembershipLevels = ['Gold', 'Platinum', 'Premier'];
    return hawaiianMilesEliteMembershipLevels.indexOf(this.loyalty.level) >= 0;
  }

  getRegularBagsCatalog(): Product {
    const regularBagItem = this.filterProductsById({
      include: [BagTypeId.Regular],
      exclude: [BagTypeId.Allowances],
    });

    if (regularBagItem) {
      return regularBagItem[0];
    }

    return null;
  }

  getOABagsCatalog(): Array<Product> {
    const products = this.filterProductsById({
      exclude: [BagTypeId.Allowances],
    });

    return products;
  }

  getSpecialBagsCatalog(): Array<Product> {
    const products = this.filterProductsById({
      exclude: [BagTypeId.Regular, BagTypeId.Allowances],
    });

    return products;
  }

  getTotalFreeRegularBags(): number {
    const allowances = this.filterProductsById({ include: [BagTypeId.Allowances] });

    if (allowances) {
      const allowance = allowances[0];
      return allowance.freeItemCount;
    }

    return 0;
  }
}

export interface PassengerName {
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
}

export interface PassengerLoyalty {
  isCardHolder: boolean | null;
  membershipId: string | null;
  level: string | null;
  programName: string | null;
}

interface CatalogFilter {
  include?: Array<string>;
  exclude?: Array<string>;
}
