import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';

import { select, Store } from '@ngrx/store';
import { Logging } from '~app/services/logging/logging.service';
import { Authenticate, Keyboard, KeyboardState, PnrLocatorFlowUpdateState, ResetContent } from '../../../../state';
import { AppRoutes } from '~app/app-routes';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { AccessibilityMode } from '~app/decorators/accessibility.decorator';

@AccessibilityMode()
@Component({
  selector: 'app-by-hawaiian-miles',
  templateUrl: './by-hawaiian-miles.component.html',
  styleUrls: ['./by-hawaiian-miles.component.scss', './../locate-reservation.component.scss'],
})
export class ByHawaiianMilesComponent implements OnInit, OnDestroy, AfterViewInit {
  public keyboardSubscribe: Subscription;
  keyboard$: Observable<Keyboard>;
  menuRoute = AppRoutes.MENU;

  public code = '';
  public codeDone = false;
  public elements = [];

  constructor(
    private store: Store<KeyboardState>,
    public router: Router,
    private logging: Logging,
    private accessibilityService: AccessibilityService,
    private el: ElementRef
  ) {
    store.dispatch(new ResetContent());
    this.keyboard$ = store.pipe(select('keyboard'));
  }

  ngOnInit() {
    this.logging.infoUiHawaiianMilesLookupPageDisplayed(0);
    this.keyboardSubscribe = this.keyboard$.subscribe((value) => {
      if (value) {
        this.code = value.content;
        this.codeDone = value.content.length >= 9;
      }
    });
  }

  validateHawaiianMiles() {
    if (!this.codeDone) {
      return false;
    }
    this.logging.infoUiHawaiianMilesLookupPageContinueSelected(0);
    this.code = this.code.toUpperCase();
    this.store.dispatch(new Authenticate({ hawaiianMilesNumber: this.code }));
    this.store.dispatch(
      new PnrLocatorFlowUpdateState({ hawaiianMilesNumber: this.code, lookupMethod: 'hawaiianMilesNumber' })
    );
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.keyboardSubscribe) {
      this.keyboardSubscribe.unsubscribe();
    }
  }
}
