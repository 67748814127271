import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'page-locate-reservation',
  templateUrl: './locate-reservation.component.html',
  styleUrls: ['./locate-reservation.component.scss'],
})
export class LocateReservationComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
