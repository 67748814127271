import { BagTagPrinterState } from './bagtag-printer.reducers';
import { AppState } from '../../index';
import { createSelector } from '@ngrx/store';

const selectBagTagPrinter = (state: AppState) => state.bagTagPrinter;

export const selectBagTagPrinterStatus = createSelector(
  selectBagTagPrinter,
  (state: BagTagPrinterState) => state.bagTagPrinterStatusChange
);
