import { Trip } from './trip.model';
import { TripActions, TripActionTypes } from './trip.actions';
import { TripEffects } from './trip.effects';

export interface TripState {
  trip: Trip;
}

export function tripReducer(state = { trip: new Trip() }, action: TripActions): TripState {
  switch (action.type) {
    case TripActionTypes.TripLoaded: {
      const { trip } = action.payload;
      let selectedPaxIds;

      if (state.trip.id !== undefined) {
        selectedPaxIds = state.trip.activeSegment.getPaxIdsSelectedForCheckin();
      }

      Trip.setSelectedSegmentDetails(trip, selectedPaxIds);

      return {
        ...state,
        trip,
      };
    }

    case TripActionTypes.TripChanged: {
      let { newTrip } = action.payload;
      let selectedPaxIds;

      if (!newTrip) {
        newTrip = state.trip.clone();
      }

      if (state.trip.id !== undefined) {
        selectedPaxIds = state.trip.activeSegment.getPaxIdsSelectedForCheckin();
      }

      Trip.setSelectedSegmentDetails(newTrip, selectedPaxIds);

      return {
        ...state,
        trip: newTrip,
      };
    }

    case TripActionTypes.CatalogsChanged: {
      if (state.trip) {
        const newTrip = state.trip.clone();
        const { seatsCatalogs, bagsCatalogs } = action.payload;
        newTrip.passengers.forEach((passenger) => {
          passenger.seatsCatalog = seatsCatalogs.filter((catalog) => catalog.passengerId === passenger.id);
          passenger.bagsCatalog = bagsCatalogs.filter((catalog) => catalog.passengerId === passenger.id)[0];
        });

        return {
          ...state,
          trip: newTrip,
        };
      }

      return {
        ...state,
      };
    }

    case TripActionTypes.UpdatePaxSelectedForCheckin: {
      if (state.trip) {
        const newTrip = state.trip.clone();
        const paxIds = action.payload.paxIds;
        paxIds.forEach((id) => {
          newTrip.activeSegment.details.find((segmentDetail) => segmentDetail.id.includes(id)).selected = true;
        });
        return {
          ...state,
          trip: newTrip,
        };
      }

      return {
        ...state,
      };
    }

    case TripActionTypes.TripSetSelectedPax: {
      if (state.trip) {
        const newTrip = state.trip.clone();
        let selectedPaxIds;

        if (state.trip.id !== undefined) {
          selectedPaxIds = state.trip.activeSegment.getPaxIdsSelectedForCheckin();
        }

        Trip.setSelectedSegmentDetails(newTrip, selectedPaxIds);

        return {
          ...state,
          trip: newTrip,
        };
      }

      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}
