import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, selectTripState, TripLoadRequested } from '~app/state';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AppRoutes } from '~app/app-routes';

@Component({
  selector: 'app-payment-in-progress-alert',
  templateUrl: './payment-in-progress-alert.component.html',
  styleUrls: ['./payment-in-progress-alert.component.scss'],
})
export class PaymentInProgressAlertComponent implements OnInit {
  public showModal = false;
  public cartSubscription: Subscription;
  public passengers = [];

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit() {
    this.cartSubscription = this.store
      .select((state) => state.carts.cart)
      .subscribe((cart) => {
        if (cart.checkConcurrentRelatedCart()) {
          const passengersIds = cart.relatedCarts[0].associatedPassengers.map((x) => x.passengerId);
          this.store
            .select(selectTripState)
            .pipe(first())
            .subscribe((result) => {
              this.passengers = result.trip.passengers
                .filter((x) => passengersIds.find((paxId) => paxId === x.id))
                .map((x) => `${x.passengerName.firstName} ${x.passengerName.lastName}`);
              this.showModal = true;
            });
        } else {
          this.showModal = false;
        }
      });
  }

  doCloseModal() {
    this.showModal = false;
    this.store.dispatch(new TripLoadRequested());
    this.router.navigate([AppRoutes.ITINERARY]);
  }
}
