import { AirportDate } from '../common/airport-date.model';

export class FlightStatus {
  id: string;
  flightId: string;
  arrival: AirportDate | null;
  departure: AirportDate | null;
  version: string | null;
  origin: string | null;
  originGate: string | null;
  destination: string | null;
  flightStatus: string | null;
  flightSubStatus: string | null;
  boardingStatus: string | null;
  boardingStartTime: AirportDate | null;
  boardingEndTime: AirportDate | null;

  static deserializeFromJson(json): FlightStatus {
    return Object.assign(new FlightStatus(), json);
  }

  getFlightStatusDescription(): string {
    let statusDescription = null;

    if (this.flightSubStatus) {
      statusDescription = this.flightSubStatus;
    } else {
      statusDescription = this.flightStatus;
    }

    if (statusDescription) {
      statusDescription = statusDescription
        .replace(/[A-Z]/g, (found) => {
          return ' ' + found;
        })
        .trim();
    }

    return statusDescription;
  }
}
