import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { CartItem, Passenger, SegmentDetail, MarketType } from '../../state';
import { StoreRecorder } from './store-recorder.service';
import { CatalogIdTypes, PassengerCatalog, Product } from '../../state/trip/passenger.catalog.model';
import { kioskLocation, loadKioskId } from '../emitters/session-event-emitters';
import { AlertMessageDescription, AlertReason } from '~app/models/error/error.model';
import { EnvironmentService } from '~services/environment/environment.service';

@Injectable({ providedIn: 'root' })
export class DataSource {
  tripLoaded$: Observable<any>;
  cartLoaded$: Observable<any>;
  tripLoadedListeners: Array<() => void>;
  cartLoadedListeners: Array<() => void>;
  private savedKioskId;

  constructor(
    private storeRecorder: StoreRecorder,
    private router: Router,
    private environmentService: EnvironmentService
  ) {
    this.tripLoaded$ = this.storeRecorder.segment$.pipe(filter((segment) => segment !== null));

    this.tripLoaded$.subscribe(() => {
      this.flushListeners();
    });

    this.cartLoaded$ = this.storeRecorder.cart$.pipe(filter((cart) => cart !== null));

    this.cartLoaded$.subscribe(() => {
      this.flushListeners();
    });

    loadKioskId.subscribe((value) => {
      this.savedKioskId = value;
    });

    this.tripLoadedListeners = [];
    this.cartLoadedListeners = [];
  }

  onLoadedTrip(listener: () => void) {
    this.tripLoadedListeners.push(listener);
  }

  onLoadedCart(listener: () => void) {
    this.cartLoadedListeners.push(listener);
  }

  flushListeners() {
    this.tripLoadedListeners.forEach((listener) => listener());
    this.cartLoadedListeners.forEach((listener) => listener());

    this.tripLoadedListeners = [];
    this.cartLoadedListeners = [];
  }

  url() {
    return this.router.url;
  }

  language(): string {
    const appState = this.storeRecorder.getAppState();

    return appState.language.languageId.withDefault('en');
  }

  numberOfSelectedPax(): number {
    try {
      const activeSegmentDetails = this.storeRecorder.getActiveSegmentDetails();

      const selectedPax = activeSegmentDetails.filter((segmentDetail: SegmentDetail) => segmentDetail.selected).length;

      return selectedPax;
    } catch (e) {
      return 0;
    }
  }

  getAlertReasonCodesForActiveSegment(): AlertReason[] {
    try {
      const activeSegmentDetails = this.storeRecorder.getActiveSegmentDetails();
      const sortedAlertReasonCodes = activeSegmentDetails[0].alertReasonCode.split(',').sort();

      const alertReasons = sortedAlertReasonCodes.map((reasonCode) => {
        return {
          alertReasonCode: reasonCode,
          alertDescription: AlertMessageDescription[reasonCode],
        } as AlertReason;
      });

      return alertReasons;
    } catch (e) {
      return null;
    }
  }

  getAlertMessageCodes(): string[] {
    try {
      const activeSegmentDetails = this.storeRecorder.getActiveSegmentDetails();

      const sortedAlertMessageCodes = activeSegmentDetails[0].alertMessageCode.split(',').sort();

      return sortedAlertMessageCodes;
    } catch (e) {
      return null;
    }
  }

  appVersion(): string {
    return this.environmentService.getEnvironment().version;
  }

  environment(): string {
    return this.environmentService.getEnvironment().logging.environmentName;
  }

  kioskId(): string {
    return this.savedKioskId;
  }

  sessionId(): string {
    return window.sessionStorage.getItem('SESSIONID');
  }

  localTime(): string {
    const utc = Date.now();
    const offset = new Date().getTimezoneOffset();
    const msOffset = offset * 60 * 1000;

    return utc + msOffset + '';
  }

  systemTime(): string {
    return Date.now() + '';
  }

  hasAlreadyCheckedIn(): boolean {
    try {
      const activeSegmentDetails = this.storeRecorder.getActiveSegmentDetails();

      const checkedIn = activeSegmentDetails.filter((segment: SegmentDetail) => segment.isCheckedIn).length;

      return checkedIn > 0;
    } catch (e) {
      return false;
    }
  }

  getCheckedInPaxNames(): string[] {
    const activeSegmentDetails = this.storeRecorder.getActiveSegmentDetails();
    return activeSegmentDetails
      .filter((segment: SegmentDetail) => segment.isCheckedIn)
      .map((segment) => `${segment.passengerName.lastName.slice(0, 2)}/${segment.passengerName.firstName.charAt(0)}`);
  }

  getMarketType(): MarketType {
    let marketType;

    try {
      marketType = this.storeRecorder.getActiveSegment().marketType;
    } catch (e) {
      marketType = null;
    }

    return marketType;
  }

  getMarketedBy(): string {
    let marketedBy;

    try {
      marketedBy = this.storeRecorder.getActiveSegment().flights[0].marketedBy;
    } catch (e) {
      marketedBy = null;
    }

    return marketedBy;
  }

  getOperatedBy(): string {
    let operatedBy;

    try {
      operatedBy = this.storeRecorder.getActiveSegment().flights[0].operatedBy;
    } catch (e) {
      operatedBy = null;
    }

    return operatedBy;
  }

  getSeatPreference(): string {
    try {
      const segmentDetails = this.storeRecorder.getActiveSegmentDetails();

      const seatPreferences = segmentDetails.map((segmentDetai) => segmentDetai.seatPreference);

      return seatPreferences.join(',');
    } catch (e) {
      return null;
    }
  }

  getSeatNumbers(): string {
    try {
      const segmentDetails = this.storeRecorder.getActiveSegmentDetails();

      const seatNumbers = segmentDetails.map((segmentDetai) => segmentDetai.getSeatsAsString());

      return seatNumbers.join(',');
    } catch (e) {
      return null;
    }
  }

  isNonRev(): boolean {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.isPassTravel;
    } catch (e) {
      return false;
    }
  }

  numberOfPax(): number {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      const passengers = trip.passengers;

      return passengers.length;
    } catch (e) {
      return 0;
    }
  }

  totalBags(): number {
    try {
      const details = this.storeRecorder.getActiveSegmentDetails();

      return details.map((detail) => detail.bags.length).reduce((total, bags) => total + bags, 0);
    } catch (e) {
      return 0;
    }
  }

  totalSpecialItems(): number {
    return 0;
  }

  pnr(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.confirmationCode;
    } catch (e) {
      console.error('Logger >> ', e);
      return null;
    }
  }

  channel(): string {
    return 'kiosk';
  }

  origin(): string {
    try {
      const segment = this.storeRecorder.getActiveSegment();

      return segment.origin;
    } catch (e) {
      return null;
    }
  }

  destination(): string {
    try {
      const segment = this.storeRecorder.getActiveSegment();

      return segment.destination;
    } catch (e) {
      return null;
    }
  }

  totalFlightsInSegment(): number {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.flights.length;
    } catch (e) {
      return null;
    }
  }

  isPassTraveler(): boolean {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.isPassTravel;
    } catch (e) {
      return null;
    }
  }

  isPositiveSpace(): boolean {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.isPositiveSpace;
    } catch (e) {
      return null;
    }
  }

  ktnUpdated(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      const ktns = trip.passengers.map((pax) => pax.knownTravelerNumber).join(',');

      return ktns;
    } catch (e) {
      return null;
    }
  }

  redressUpdated(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      const redresss = trip.passengers.map((pax) => pax.redressNumber).join(',');

      return redresss;
    } catch (e) {
      return null;
    }
  }

  hmUpdated(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      const hmNumbers = trip.passengers.map((pax) => pax.hawaiianMilesNumber).join(',');

      return hmNumbers;
    } catch (e) {
      return null;
    }
  }

  browserUrl(): string {
    return window.location.href;
  }

  userAgent(): string {
    return window.navigator.userAgent;
  }

  alreadyCheckedInPaxNames(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();
      const checkedInPax = trip
        .getSegmentDetailsAndPax()
        .filter((element) => element.segmentDetail.isCheckedIn === true)
        .map((element) => element.passenger);

      return checkedInPax
        .map((pax) => pax.passengerName.firstName.slice(0, 3) + pax.passengerName.lastName[0])
        .join(',');
    } catch (e) {
      return null;
    }
  }

  paxNames(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();
      const paxList = trip.getSegmentDetailsAndPax().map((element) => element.passenger);

      return paxList.map((pax) => pax.passengerName.firstName.slice(0, 3) + pax.passengerName.lastName[0]).join(',');
    } catch (e) {
      return null;
    }
  }

  boardingStartTime(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.activeSegment.nextFlight.scheduledBoarding;
    } catch (e) {
      return null;
    }
  }

  confirmationCode(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.confirmationCode;
    } catch (e) {
      return null;
    }
  }

  HAMemberStatus(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.passengers.map((passenger) => passenger.customerLevel).join(',');
    } catch (e) {
      return null;
    }
  }

  hawaiianMilesNumber(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.passengers.map((passenger) => passenger.hawaiianMilesNumber).join(',');
    } catch (e) {
      return null;
    }
  }

  positiveSpace(): boolean {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.isPositiveSpace || trip.isNonRevSpaceAvailable();
    } catch (e) {
      return null;
    }
  }

  totalRegularBagsAllowance(passengerId = null): number {
    try {
      const trip = this.storeRecorder.getCurrentTrip();
      const passengers = passengerId
        ? trip.passengers.filter((passenger) => passenger.id === passengerId)
        : trip.passengers;
      return +passengers
        .map((passenger) => passenger.getTotalFreeRegularBags())
        .reduce((totalFreeBags, passengerBags) => totalFreeBags + +passengerBags, 0);
    } catch (e) {
      return null;
    }
  }

  totalSeatsAllowance(passengerId = null): number {
    try {
      let totalSeats = 0;
      const trip = this.storeRecorder.getCurrentTrip();
      const passengers = passengerId
        ? trip.passengers.filter((passenger) => passenger.id === passengerId)
        : trip.passengers;
      const seatsCatalog: PassengerCatalog[] = [];
      passengers.forEach((passenger) => seatsCatalog.push(...passenger.seatsCatalog));

      const products: Product[] = [];
      seatsCatalog.forEach((catalog) => products.push(...catalog.products));

      products.forEach((product) => {
        totalSeats += product.priceQuotes.filter((quote) => quote.totalPrice == 0).length;
      });

      return totalSeats;
    } catch (e) {
      return null;
    }
  }

  totalAllowances(passengerId = null): number {
    try {
      return this.totalRegularBagsAllowance(passengerId) + this.totalSeatsAllowance(passengerId);
    } catch (e) {
      return null;
    }
  }

  totalRegularBags(): number {
    try {
      let totalBags = 0;
      const cart = this.storeRecorder.getCurrentCart();
      const products = cart.items.filter((item) => item.productId === '0GO');
      products.forEach((product) => {
        totalBags += product.quantity;
      });

      return totalBags;
    } catch (e) {
      return null;
    }
  }

  runningBaggageTotal(): number {
    try {
      const cart = this.storeRecorder.getCurrentCart();

      return cart.totalPrices.find((item) => item.catalogId === CatalogIdTypes.Bags).price;
    } catch (e) {
      return null;
    }
  }

  totalPaymentsCollected(): number {
    try {
      const cart = this.storeRecorder.getCurrentCart();

      return cart.grandTotal;
    } catch (e) {
      return null;
    }
  }

  totalRegularBagsAllowanceUsed(): number {
    try {
      return this.totalRegularBagsAllowance() - this.totalRegularBags();
    } catch (e) {
      return null;
    }
  }

  allPaxInCart(): string[] {
    const cart = this.storeRecorder.getCurrentCart();
    if (cart && cart.items) {
      return [...new Set(cart.items.map((item) => item.associatedPassenger.passengerId))];
    }
    return [];
  }

  allPaxInTrip(): Passenger[] {
    const trip = this.storeRecorder.getCurrentTrip();
    if (trip) {
      return trip.passengers;
    }
    return [];
  }

  itemsInCartByPassenger(passengerId: string): CartItem[] {
    const cart = this.storeRecorder.getCurrentCart();
    if (cart && cart.items) {
      return cart.items.filter((item) => item.associatedPassenger.passengerId === passengerId);
    }
    return [];
  }

  bagsCatalogByPassenger(passengerId: string): PassengerCatalog {
    const trip = this.storeRecorder.getCurrentTrip();
    if (trip && trip.passengers) {
      const passenger = trip.passengers.find((passenger) => passenger.id === passengerId);
      return passenger ? passenger.bagsCatalog : null;
    }
    return null;
  }

  getSelectedLanguage() {
    return this.storeRecorder.getAppState().language.languageId;
  }

  getCartGrandTotal(): number {
    const cart = this.storeRecorder.getCurrentCart();
    return cart.grandTotal;
  }

  isKTNPresent(): boolean {
    const trip = this.storeRecorder.getCurrentTrip();
    let ktn = '';
    if (trip) {
      ktn = trip.passengers.map((pax) => pax.knownTravelerNumber).join(',');
    }
    return !!ktn;
  }

  isInfantPresent(): boolean {
    const trip = this.storeRecorder.getCurrentTrip();
    let hasInfant = false;
    if (trip) {
      hasInfant = trip.passengers.some((pax) => pax.hasInfant === true);
    }
    return hasInfant;
  }

  getMilitaryType(passengerId: string): string {
    const segmentDetails = this.storeRecorder.getCurrentTrip().getSegmentDetailsAndPax();
    const segment = segmentDetails.find((segment) => segment.passenger.id === passengerId);
    if (segment && segment.segmentDetail && segment.segmentDetail.flightDetails) {
      return segment.segmentDetail.flightDetails[0].militaryTravelType;
    }
    return null;
  }

  getDomainName() {
    return this.environmentService.getEnvironment().logging.domain;
  }

  getApplicationName() {
    return this.environmentService.getEnvironment().logging.applicationName;
  }

  getKioskLocation() {
    return kioskLocation.getValue();
  }
}
