import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-standby-confirmation',
  templateUrl: './standby-confirmation.component.html',
  styleUrls: ['./standby-confirmation.component.scss'],
})
export class StandbyConfirmationComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
