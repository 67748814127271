import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { LoggingModule } from '../logging/logging.module';

import { API } from './api.service';
import { TripService } from './trip/trip.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, LoggingModule, HttpClientModule],
  providers: [API, TripService],
})
export class ApiModule {}
