import { Injectable } from '@angular/core';

import { LogEvent } from './log-event.interface';

@Injectable()
export class Logger {
  constructor() {}

  log(event: LogEvent) {}
}
