import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { AppState, NextSegmentSelected } from '../../../state';
import { Logging } from '../../../services/logging/logging.service';
import { AppRoutes } from '~app/app-routes';
import { AccessibilityService } from '../../../services/accessibility/accessibility.service';

@Component({
  selector: 'app-more-segments-checkin',
  templateUrl: './more-segments-checkin.component.html',
  styleUrls: ['./more-segments-checkin.component.scss'],
})
export class MoreSegmentsCheckinComponent implements OnInit, AfterViewInit {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private logging: Logging,
    private el: ElementRef,
    private accessibilityService: AccessibilityService
  ) {}

  ngOnInit() {
    this.logging.infoUiNextSegmentCheckinDisplayed(0);
  }

  ngAfterViewInit() {
    const allElements = this.el.nativeElement.querySelectorAll('[tabindex]');
    this.accessibilityService.setElementsToRead(allElements);
  }

  onExitSegmentEv() {
    this.logging.infoUiNextSegmentCheckInNoSelected(0);
    this.router.navigate([AppRoutes.CONFIRMATION]);
  }

  onShowNextSegmentEv() {
    this.logging.infoUiNextSegmentCheckInYesSelected(0);
    this.store.dispatch(new NextSegmentSelected());
  }
}
