import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AppState,
  Cart,
  CheckInButtonClicked,
  ContinueToSeatsAndBagsClicked,
  EmptyCheckout,
  Passenger,
  SetReferToAgentDontPrintError,
  SetTimeoutError,
  Trip,
  TripEffects,
} from '~app/state';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ConfigService } from '~app/services/api/config/config.service';
import { Logging } from '~app/services/logging/logging.service';
import { AppRoutes } from '~app/app-routes';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-itinerary-services',
  templateUrl: './itinerary-services.component.html',
  styleUrls: ['./itinerary-services.component.scss'],
})
export class ItineraryServicesComponent implements OnInit, OnDestroy {
  public configSubscribe: Subscription;
  public printSelected = false;
  public hasPaxChecked = false;
  public seatPreferenceConfig: boolean;
  public standbyUpgradeConfig: boolean;
  public enableSeatsConfig: boolean;
  public enableBagsConfig: boolean;
  public isNonRevSpaceAvailable: boolean;
  public passengers: Passenger[];
  public showSeatPreference: boolean;
  public showChangeSeats: boolean;
  public isMidPoint: boolean;
  public hasBagsCheckedIn: boolean;
  public showSeatMapUnAvailModal = false;
  public unsubscribe$ = new Subject<void>();
  private continueButtonLabel: string;
  private continueButtonIconClass: string;
  public continueButtonRedirectRoute: string;
  public continueButtonId: string;

  @Output() messageEvent = new EventEmitter<Array<any>>();
  @Input() trip: Trip;
  @Input() cart: Cart;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    public configService: ConfigService,
    private logging: Logging
  ) {
    this.configSubscribe = this.configService.config.pipe(takeUntil(this.unsubscribe$)).subscribe((config) => {
      if (!config.configuration) {
        return;
      }

      this.seatPreferenceConfig = config.configuration.seatPreference;
      this.standbyUpgradeConfig = config.configuration.standbyUpgrade;
      this.enableSeatsConfig = config.configuration.enableSeats;
      this.enableBagsConfig = config.configuration.enableBags;
    });
  }

  ngOnInit() {
    if (!this.trip) {
      return;
    }

    this.passengers = this.trip.passengers;
    this.hasPaxChecked = this.trip.activeSegment.hasCheckedInPax();
    this.isNonRevSpaceAvailable = this.trip.isNonRevSpaceAvailable();
    this.showSeatPreference = this.isNonRevSpaceAvailable && !this.hasPaxChecked && this.seatPreferenceConfig;
    this.showChangeSeats = !this.isNonRevSpaceAvailable && this.enableSeatsConfig;
    this.isMidPoint = this.trip.activeSegment.isOnMidPointJourney();
    this.hasBagsCheckedIn = this.trip.activeSegment.getNumBags() > 0;
    this.setButtonContent();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkInButtonClicked() {
    this.logging.infoUiNoBagsToCheckSelected(0);
    // If we do not have a cart id, we know this is the first time through the flow
    if (!this.cart.id) {
      this.store.dispatch(new CheckInButtonClicked());
    }

    // If we have a cart id and we have a grand total that is greater than 0, we need to collect payment
    if (this.cart.id && this.cart.grandTotal > 0) {
      this.router.navigate([AppRoutes.PAYMENT]);
    }

    // If we have a cart id, and the grand total is equal to zero
    if (this.cart.id && this.cart.grandTotal === 0) {
      // If we have items in the cart we know we have free entitlements and should process the empty checkout
      if (this.cart.items.length > 0) {
        this.store.dispatch(new EmptyCheckout());
      }
      // No matter the above conditions, we must navigate to the hazmat screen
      this.router.navigate([AppRoutes.HAZMAT_PROHIBITED]);
    }
  }

  showStandbyButton() {
    const show = this.passengers.some((passenger) => {
      return passenger.isHawaiianMilesEliteMember();
    });

    return show && this.standbyUpgradeConfig;
  }

  continueToBagsAndSeatsClick() {
    if (this.isDomesticOnly()) {
      return;
    }
    this.logging.infoUiCheckBagsSelected(0);

    this.store.dispatch(new ContinueToSeatsAndBagsClicked());
    this.router.navigate([this.continueButtonRedirectRoute]);
  }

  isDomesticOnly(): boolean {
    //Bags and Seats disabled
    return !this.enableBagsConfig && !this.enableSeatsConfig;
  }

  isSeatsOnly(): boolean {
    //Bags disabled and Seats enabled
    return !this.enableBagsConfig && !this.isNonRevSpaceAvailable && this.enableSeatsConfig;
  }

  isBagsOnly(): boolean {
    //Bags enabled and Seats disabled
    return this.enableBagsConfig && (!this.enableSeatsConfig || this.isNonRevSpaceAvailable);
  }

  isSeatsAndBags(): boolean {
    //Bags and Seats enabled
    return this.enableBagsConfig && this.enableSeatsConfig && !this.isNonRevSpaceAvailable;
  }

  setButtonContent(): void {
    if (this.isSeatsOnly()) {
      this.continueButtonLabel = 'itinerary.itineraryService.viewChange';
      this.continueButtonIconClass = 'app-icon--seat';
      this.continueButtonRedirectRoute = AppRoutes.SEATS;
      this.continueButtonId = 'itinerary-services-change-seats-button';
    }

    if (this.isBagsOnly()) {
      this.continueButtonLabel = 'itinerary.itineraryService.checkBags';
      this.continueButtonIconClass = 'app-icon--bags';
      this.continueButtonRedirectRoute = AppRoutes.BAGS;
      this.continueButtonId = 'itinerary-services-check-bags-button';
    }

    if (this.isSeatsAndBags()) {
      this.continueButtonLabel = 'itinerary.itineraryService.seatsAndBags';
      this.continueButtonIconClass = 'app-icon--arrow-forward';
      this.continueButtonRedirectRoute = AppRoutes.SEATS;
      this.continueButtonId = 'itinerary-services-continue-to-seats-and-bags-button';
    }
  }

  displayContinueButton(): boolean {
    //Continue button should only be displayed if at least one of enableBagsConfig and enableSeatsConfig is true
    return !this.isDomesticOnly();
  }
}
