import { BagTagPrinterActionTypes } from './bagtag-printer.actions';

export interface BagTagPrinterState {
  deviceName: string | null;
  deviceMode: string | null;
  deviceLogs: string[];
  bagTagPecTabLoadingComplete: string;
  bagTagPrintingComplete: string;
  bagTagFailed: string;
  bagTagPectabFailed: string;
  bagTagPectabLoaded: string;
  bagTagPrinterStatusChange: [number, boolean];
  bagTagPrinted: string;
  bagTagPrinterStatusIsOK: string;
}

export const initialState: BagTagPrinterState = {
  deviceName: '',
  deviceMode: '',
  deviceLogs: [],
  bagTagPecTabLoadingComplete: '',
  bagTagPrintingComplete: '',
  bagTagFailed: '',
  bagTagPectabFailed: '',
  bagTagPectabLoaded: '',
  bagTagPrinterStatusChange: [-1, false],
  bagTagPrinted: '',
  bagTagPrinterStatusIsOK: '',
};

export function BagTagPrinterReducers(state: BagTagPrinterState = initialState, action): BagTagPrinterState {
  let callbackLogs = '';
  // Different action reducer processes
  switch (action.type) {
    case BagTagPrinterActionTypes.BagTagPrinterEnable: {
      callbackLogs = 'Event: bagTag-enable';
      state.deviceName = 'bagTag';
      state.deviceMode = action.payload;
      state.deviceLogs = [callbackLogs];
      state.bagTagPecTabLoadingComplete = '';
      state.bagTagPrintingComplete = '';
      state.bagTagFailed = '';
      state.bagTagPectabFailed = '';
      state.bagTagPectabLoaded = '';
      state.bagTagPrinterStatusChange = [-1, false];
      state.bagTagPrinted = '';
      state.bagTagPrinterStatusIsOK = '';
      return { ...state };
    }
    case BagTagPrinterActionTypes.BagTagPrinterDisable: {
      callbackLogs = 'Event: bagTag-disable';
      state.deviceName = 'bagTag';
      state.deviceMode = action.payload;
      state.deviceLogs = [callbackLogs];
      state.bagTagPecTabLoadingComplete = '';
      state.bagTagPrintingComplete = '';
      state.bagTagFailed = '';
      state.bagTagPectabFailed = '';
      state.bagTagPectabLoaded = '';
      state.bagTagPrinterStatusChange = [-1, false];
      state.bagTagPrinted = '';
      state.bagTagPrinterStatusIsOK = '';
      return { ...state };
    }
    case BagTagPrinterActionTypes.BagTagPECTabLoadingComplete: {
      callbackLogs = 'Event: bagTagPECTabLoadingComplete';
      state.deviceLogs = [callbackLogs];
      state.bagTagPecTabLoadingComplete = action.payload.bagTagPecTabLoadingComplete;
      return { ...state };
    }
    case BagTagPrinterActionTypes.BagTagPrintingComplete: {
      callbackLogs = 'Event: bagTagPrintingComplete';
      state.deviceLogs = [callbackLogs];
      state.bagTagPrintingComplete = action.payload.bagTagPrintingComplete;
      return { ...state };
    }
    case BagTagPrinterActionTypes.BagTagFailed: {
      callbackLogs = 'Event: bagTagFailed';
      state.deviceLogs = [callbackLogs];
      state.bagTagFailed = action.payload.bagTagFailed;
      return { ...state };
    }
    case BagTagPrinterActionTypes.BagTagPectabFailed: {
      callbackLogs = 'Event: bagTagPectabFailed';
      state.deviceLogs = [callbackLogs];
      if (state.bagTagPectabFailed != null && state.bagTagPectabFailed !== '') {
        state.bagTagPectabFailed = state.bagTagPectabFailed + '|' + action.payload.bagTagPectabFailed;
      } else {
        state.bagTagPectabFailed = action.payload.bagTagPectabFailed;
      }
      return { ...state };
    }
    case BagTagPrinterActionTypes.BagTagPectabLoaded: {
      callbackLogs = 'Event: bagTagPectabLoaded';
      state.deviceLogs = [callbackLogs];
      if (state.bagTagPectabLoaded != null && state.bagTagPectabLoaded !== '') {
        state.bagTagPectabLoaded = state.bagTagPectabLoaded + '|' + action.payload.bagTagPectabLoaded;
      } else {
        state.bagTagPectabLoaded = action.payload.bagTagPectabLoaded;
      }
      return { ...state };
    }
    case BagTagPrinterActionTypes.BagTagPrinterStatusChange: {
      callbackLogs = 'Event: bagTagPrinterStatusChange';
      state.deviceLogs = [callbackLogs];
      state.bagTagPrinterStatusChange = action.payload.bagTagPrinterStatusChange;
      return { ...state };
    }
    case BagTagPrinterActionTypes.BagTagPrinted: {
      callbackLogs = 'Event: bagTagPrinted';
      state.deviceLogs = [callbackLogs];
      state.bagTagPrinted = action.payload.bagTagPrinted;
      return { ...state };
    }
    case BagTagPrinterActionTypes.BagTagPrinterStatusIsOK: {
      callbackLogs = 'Event: bagTagPrinterStatusIsOK';
      state.bagTagPrinterStatusIsOK = action.payload.bagTagPrinterStatusIsOK;
      state.deviceLogs = [callbackLogs];
      return { ...state };
    }
    default:
      callbackLogs = 'default: ' + JSON.stringify(action.payload);
      state.deviceLogs = [callbackLogs];
      return state;
  }
}
