import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '~app/app-routes';
import { Observable, Subscription } from 'rxjs';
import { AppState, selectCurrentTrip, Trip } from '~app/state';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { MILITARY_TRAVEL_TYPE } from '~app/models/military/military.model';

@Component({
  selector: 'app-military-thank-you',
  templateUrl: './military-thank-you.component.html',
  styleUrls: ['./military-thank-you.component.scss'],
})
export class MilitaryThankYouComponent implements OnInit {
  displayLeisureCopy = false;
  trip$: Observable<Trip>;
  public tripSubscription: Subscription;

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit() {
    this.trip$ = this.store.select(selectCurrentTrip);

    this.tripSubscription = this.trip$.pipe(take(1)).subscribe((trip: Trip) => {
      this.displayLeisureCopy = trip
        .getSegmentDetailsAndPaxSelected()
        .some(
          (segmentDetailAndPax) =>
            segmentDetailAndPax.segmentDetail.flightDetails[0].militaryTravelType ===
            MILITARY_TRAVEL_TYPE.LEISURE_TRAVEL
        );
    });
  }

  navigateToBags() {
    this.router.navigateByUrl(AppRoutes.BAGS);
  }
}
