import { PaxEditActions, PaxEditActionTypes } from './pax-edit.actions';
import { Passenger } from '..';

export interface PaxEditState {
  paxSelectedForEdit: Passenger | null;
  paxEditKTN: string | null;
  paxEditRedress: string | null;
  paxEditHawaiianMiles: string | null;
}

export function paxEditReducer(state = {} as PaxEditState, action: PaxEditActions): PaxEditState {
  switch (action.type) {
    case PaxEditActionTypes.PaxSelectedChanged: {
      const paxSelectedForEdit = action.payload.passenger;

      return {
        paxSelectedForEdit,
        paxEditKTN: paxSelectedForEdit ? paxSelectedForEdit.knownTravelerNumber : null,
        paxEditRedress: paxSelectedForEdit ? paxSelectedForEdit.redressNumber : null,
        paxEditHawaiianMiles: paxSelectedForEdit ? paxSelectedForEdit.hawaiianMilesNumber : null,
      };
    }

    case PaxEditActionTypes.PaxEditInfoUpdated: {
      const { ktn, redress, hawaiianMiles } = action.payload;

      return {
        ...state,
        paxEditKTN: ktn || (ktn === undefined ? state.paxEditKTN : null),
        paxEditRedress: redress || (redress === undefined ? state.paxEditRedress : null),
        paxEditHawaiianMiles: hawaiianMiles || (hawaiianMiles === undefined ? state.paxEditHawaiianMiles : null),
      };
    }

    default: {
      return state;
    }
  }
}
