import { PnrLocatorFlowActionTypes } from './pnr-locator-flow.action';

export interface PnrLocatorFlowState {
  lastName: string | null;
  firstName: string | null;
  flightNumber: string | null;
  dateOfBirth: string | null;
  destination: string | null;
  confirmationCode: string | null;
  eTicketNumber: string | null;
  hawaiianMilesNumber: string | null;
  lookupMethod: string | null;
}

export const pnrLocatorFlowInitialState: PnrLocatorFlowState = {
  lastName: '',
  firstName: '',
  flightNumber: '',
  dateOfBirth: '',
  destination: '',
  confirmationCode: '',
  eTicketNumber: '',
  hawaiianMilesNumber: '',
  lookupMethod: '',
};

export function PnrLocatorFlowReducer(state = pnrLocatorFlowInitialState, action): PnrLocatorFlowState {
  switch (action.type) {
    case PnrLocatorFlowActionTypes.pnrLocatorFlowUpdateState:
      for (const key in action.payload) {
        if (action.payload.hasOwnProperty(key)) {
          state[key] = action.payload[key];
        }
      }
      return state;

    case PnrLocatorFlowActionTypes.pnrLocatorFlowClearState:
      return {
        lastName: '',
        firstName: '',
        flightNumber: '',
        dateOfBirth: '',
        destination: '',
        confirmationCode: '',
        eTicketNumber: '',
        hawaiianMilesNumber: '',
        lookupMethod: '',
      };

    default:
      return state;
  }
}
