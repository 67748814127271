import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { AppRoutes } from '~app/app-routes';
import { military } from 'assets/i18n/en.json';
import { filter, takeUntil } from 'rxjs/operators';
import { AppState } from '~app/state';
import { Store } from '@ngrx/store';
import { PrintCounterAssist } from '~app/state';

@Component({
  selector: 'app-military',
  templateUrl: './military.component.html',
  styleUrls: ['./military.component.scss'],
})
export class MilitaryComponent implements OnInit, OnDestroy {
  pageTitle = '';
  unsubscribe$ = new Subject<void>();

  private element;
  private isRendered = false;

  @ViewChild('title', { static: false }) set updateElement(_) {
    this.updateACAAElementsToRead();
    this.isRendered = true;
  }

  constructor(
    private el: ElementRef,
    private accessibilityService: AccessibilityService,
    private router: Router,
    private store: Store<AppState>
  ) {
    this.element = el.nativeElement;

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event: NavigationEnd) => {
        this.pageTitle = AppRoutes.MILITARY_SELECTION.includes(event.url) ? military.selection.header : '';
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  startOver() {
    if (this.router.url === AppRoutes.MILITARY_NO_MATCH) {
      this.store.dispatch(
        new PrintCounterAssist({ alertMessageCode: 'AttemptToAddMilWaiver', alertReasonCode: 'MB01' })
      );
    }
  }

  onRouterActive() {
    if (!this.isRendered) {
      return;
    }

    this.updateACAAElementsToRead();
  }

  updateACAAElementsToRead() {
    const elements = [
      ...this.getHeaderElementsToRead(),
      ...this.getDetailElementsToRead(),
      ...this.getStartOverElementToRead(),
    ];

    this.accessibilityService.setPageElementsToRead({
      elements: elements,
    });
  }

  private getHeaderElementsToRead() {
    return Array.from(this.element.querySelectorAll('#military-title-text'));
  }

  private getDetailElementsToRead() {
    return Array.from(this.element.querySelectorAll('.military-details [tabindex]'));
  }

  private getStartOverElementToRead() {
    return Array.from(this.element.querySelectorAll('#start-over-exit-button'));
  }
}
