import { Injectable } from '@angular/core';
import { API } from './../api.service';

@Injectable({
  providedIn: 'root',
})
export class FlightService {
  public baseUrl = `${this.api.baseUrl}/${this.api.basePath}/${this.api.apiPaths.flights.defaultValue}`;

  constructor(private api: API) {}
  getFlightStatusDataBasedId(id: string) {
    if (this.api.getQueryParamsCount().length > 0) {
      this.api.clearQueryParams();
    }

    const url = `${this.baseUrl}/${id}/status`;
    this.api.updateCorrelationId();
    return this.api.doGetCall(url);
  }
}
