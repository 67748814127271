import { Segment } from '../state/trip/segment/segment.model';
import { SegmentDetail } from '../state';

export const itself = (i) => i;
export const to = (key) => (data) => data[key];
const destinations = require('../../assets/json/airport_names.json');

export function detectAnError(value) {
  if (value.type) {
    throw new Error(value.type);
  }

  return value;
}

export const extractParameterByName = (url) => (name) => {
  const normalizedName = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + normalizedName + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getParameterByName = (url) => (name) => {
  if (!url) {
    url = window.location.href;
  }

  const extract = extractParameterByName(url);
  return extract(name);
};

export const byNonEmptyString = (str) => str && str.length > 0;

export function getFirstParameterThatMatches(names, url) {
  return names.map(getParameterByName(url)).filter(byNonEmptyString)[0];
}

export function findFirstParameterThatMatches(names, url) {
  return names.filter(extractParameterByName(url)).filter(byNonEmptyString)[0];
}

export function getCityByAirportCode(code) {
  const dest = destinations.find((destination) => destination.AirportCode === code);
  if (dest) {
    return dest.DisplayShortName;
  }

  return '';
}

export function getCityLongNameByAirportCode(code) {
  const dest = destinations.find((destination) => destination.AirportCode === code);
  if (dest) {
    return dest.DisplayLongName;
  }

  return '';
}

export function sortByObject(obj, field, type) {
  obj.sort((d1, d2) => {
    if (d1[field] > d2[field]) {
      if (type === 'ASC') {
        return 1;
      } else if (type === 'DESC') {
        return -1;
      }
    }

    if (d1[field] < d2[field]) {
      if (type === 'ASC') {
        return -1;
      } else if (type === 'DESC') {
        return 1;
      }
    }

    return 0;
  });

  return obj;
}

export const getSegmentAlertCodesData = (segment: Segment): { alertMessageCode: string; alertReasonCode: string } => {
  const { alertMessageCode, alertReasonCode } = segment.details[0] as SegmentDetail;
  const firstAlertMessageCode = alertMessageCode.split(',')[0];
  const firstAlertReasonCode = alertReasonCode.split(',')[0];

  return {
    alertMessageCode: firstAlertMessageCode,
    alertReasonCode: firstAlertReasonCode,
  };
};

export const getCheckInOpenedDate = (date) => {
  return new Date(date.valueOf() - 1000 * 3600 * 24);
};

export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

export const activeSegmentByFlightCheckinStatus = (segments, flights) => {
  const openFlights = flights.filter((flight) => flight.isOpenForCheckIn());
  const openFlightsIds = openFlights.map((flight) => flight.id);
  const activeSegmentList = segments.filter(
    (segment) => openFlightsIds.indexOf(segment.details[0].flightDetails[0].flightId) >= 0
  );

  if (activeSegmentList.length >= 0) {
    return activeSegmentList[0];
  }

  return segments[0];
};

export const activeSegment = (trip) => {
  let segments = trip.segments;
  let flights = trip.flights;

  if (trip.segments.entries) {
    segments = trip.segments.entries;
  }

  if (trip.flights.entries) {
    flights = trip.flights.entries;
  }
  const segmentsWithMatchingOrigin = segments.filter((segment) => segment.origin === trip.kioskAirportCode);
  switch (segmentsWithMatchingOrigin.length) {
    case 1:
      return segmentsWithMatchingOrigin[0];
    case 0:
      return false;
    case segmentsWithMatchingOrigin.length > 1:
      return activeSegmentByFlightCheckinStatus(segmentsWithMatchingOrigin, flights);
  }
};

// For unit testing purposes.
export const utils = {
  setInterval: (cb, time) => setInterval(cb, time),
  navigator: (mockNavigator?) => {
    if (mockNavigator) {
      return mockNavigator;
    }

    return window.navigator;
  },
  setTimeout: (cb, time) => setTimeout(cb, time),
  ifPresent: (value) => !!value,
};

export enum SeatNumberParseEnum {
  SeeAgent = 'See Agent',
  undefined = ' - ',
}

export const seatNumberParse = (seatNumber) => {
  if (SeatNumberParseEnum[seatNumber]) {
    return SeatNumberParseEnum[seatNumber];
  }
  return seatNumber;
};

export enum KioskType {
  SMALL = 'S',
  LARGE = 'L',
}

export const getKioskSize = () => {
  return window.screen.height < 1000 ? KioskType.SMALL : KioskType.LARGE;
};

/**
 * Returns whether or not the arrays only contains the values of each other
 * @param firstArray the subset array to compare
 * @param secondArray the superset array to compare against
 */
export function containsOnly(firstArray: string[], secondArray: string[]) {
  return (
    secondArray.every((item) => firstArray.includes(item)) && firstArray.every((item) => secondArray.includes(item))
  );
}
