import { Action } from '@ngrx/store';

export enum ATBPrinterActionTypes {
  ATBPrinterEnable = '[ATB Printer] Device Enabled',
  ATBPrinterDisable = '[ATB Printer] Device Disabled',
  PECTabLoadingComplete = '[ATB Printer] PECTAB Loading Complete',
  TicketPrintingComplete = '[ATB Printer] Finished Printing',
  TicketFailed = '[ATB Printer] Ticket Failed',
  PectabFailed = '[ATB Printer] PECTAB Failed',
  PectabLoaded = '[ATB Printer] PECTAB Loaded',
  ATBPrinterStatusChange = '[ATB Printer] Status Change',
  TicketPrinted = '[ATB Printer] Ticket Printed',
  ATBPrinterStatusIsOK = '[ATB Printer] Status is Ok',
}

export class ATBPrinterEnable implements Action {
  readonly type = ATBPrinterActionTypes.ATBPrinterEnable;
  constructor(readonly payload: string) {}
}

export class ATBPrinterDisable implements Action {
  readonly type = ATBPrinterActionTypes.ATBPrinterDisable;
  constructor(readonly payload: string) {}
}

export class PECTabLoadingComplete implements Action {
  readonly type = ATBPrinterActionTypes.PECTabLoadingComplete;
  payload: { event_id: string; pecTabLoadingComplete: string };
  constructor(eventId: string, pecTabLoadingComplete: string) {
    this.payload = { event_id: eventId, pecTabLoadingComplete };
  }
}

export class TicketPrintingComplete implements Action {
  readonly type = ATBPrinterActionTypes.TicketPrintingComplete;
  payload: { event_id: string; ticketPrintingComplete: string };
  constructor(eventId: string, ticketPrintingComplete: string) {
    this.payload = { event_id: eventId, ticketPrintingComplete };
  }
}

export class TicketFailed implements Action {
  readonly type = ATBPrinterActionTypes.TicketFailed;
  payload: { event_id: string; ticketFailed: string };
  constructor(eventId: string, ticketFailed: string) {
    this.payload = { event_id: eventId, ticketFailed };
  }
}

export class PectabFailed implements Action {
  readonly type = ATBPrinterActionTypes.PectabFailed;
  payload: { event_id: string; pectabFailed: string };
  constructor(eventId: string, pectabFailed: string) {
    this.payload = { event_id: eventId, pectabFailed };
  }
}

export class PectabLoaded implements Action {
  readonly type = ATBPrinterActionTypes.PectabLoaded;
  payload: { event_id: string; pectabLoaded: string };
  constructor(eventId: string, pectabLoaded: string) {
    this.payload = { event_id: eventId, pectabLoaded };
  }
}

export class TicketPrinted implements Action {
  readonly type = ATBPrinterActionTypes.TicketPrinted;
  payload: { event_id: string; ticketPrinted: string };
  constructor(eventId: string, ticketPrinted: string) {
    this.payload = { event_id: eventId, ticketPrinted };
  }
}

export class ATBPrinterStatusIsOK implements Action {
  readonly type = ATBPrinterActionTypes.ATBPrinterStatusIsOK;
  payload: { event_id: string; atbPrinterStatusIsOK: string };
  constructor(eventId: string, atbPrinterStatusIsOK: string) {
    this.payload = { event_id: eventId, atbPrinterStatusIsOK };
  }
}

export class ATBPrinterStatusChange implements Action {
  readonly type = ATBPrinterActionTypes.ATBPrinterStatusChange;
  payload: { event_id: string; atbPrinterStatusChange: [number, boolean] };
  constructor(eventId: string, atbPrinterStatusChange: [number, boolean]) {
    this.payload = { event_id: eventId, atbPrinterStatusChange };
  }
}

export type ATBPrinterActions =
  | ATBPrinterEnable
  | ATBPrinterDisable
  | PECTabLoadingComplete
  | TicketPrintingComplete
  | TicketFailed
  | PectabFailed
  | PectabLoaded
  | ATBPrinterStatusChange
  | TicketPrinted
  | ATBPrinterStatusIsOK;
