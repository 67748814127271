import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { sortByObject } from '~app/utils/helpers';
import { take } from 'rxjs/operators';
import { ConfigService } from '~app/services/api/config/config.service';
import { Logging } from '~app/services/logging/logging.service';
import { AppState, PnrLocatorFlow, PnrLocatorFlowState, PnrLocatorFlowUpdateState } from '../../../../state';
import { AppRoutes } from '~app/app-routes';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { AccessibilityMode } from '../../../../decorators/accessibility.decorator';

@AccessibilityMode()
@Component({
  selector: 'app-by-destination',
  templateUrl: './by-destination.component.html',
  styleUrls: ['./by-destination.component.scss', './../locate-reservation.component.scss'],
})
export class ByDestinationComponent implements OnInit, OnDestroy, AfterViewInit {
  pnrLocatorFlow$: Observable<PnrLocatorFlow>;
  menuRoute = AppRoutes.MENU;
  public pnrLocatorFlowSubscriber;
  public selected;
  public selectedAirport;
  public codeDone = false;
  public airportCodeConfig;
  public destinations = require('../../../../../assets/json/airport_interisland_transpacific.json');
  public configSubscription: Subscription;
  public elements = [];

  constructor(
    public router: Router,
    private store: Store<AppState>,
    public pnrLocatorFlowStore: Store<PnrLocatorFlowState>,
    public configService: ConfigService,
    private logging: Logging,
    private accessibilityService: AccessibilityService,
    private el: ElementRef
  ) {
    this.pnrLocatorFlow$ = pnrLocatorFlowStore.pipe(select('pnrLocatorFlow'));
    this.configSubscription = this.configService.config.subscribe((x) => {
      if (x && x.configuration) {
        this.airportCodeConfig = x.airportCode;
      }
    });
  }

  ngOnInit() {
    this.logging.infoUiDestinationLookupPageDisplayed(0);
    this.destinations = sortByObject(this.destinations, 'DisplayShortName', 'ASC');
    this.destinations = this.destinations.filter((destination) => destination.AirportCode !== this.airportCodeConfig);
    this.pnrLocatorFlowSubscriber = this.pnrLocatorFlow$
      .pipe(take(1))
      .subscribe((value) => this.updateDestination(value.destination));
  }

  ngOnDestroy() {
    if (this.pnrLocatorFlowSubscriber) {
      this.pnrLocatorFlowSubscriber.unsubscribe();
    }
    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }
  }

  updateDestination(destination) {
    if (destination) {
      this.selected = destination;
      this.codeDone = true;
      this.destinations.map((destination) => {
        if (this.selected === destination.AirportCode) {
          this.selectedAirport = destination.destinationOption;
        }
      });
    }
  }

  select(dest) {
    if (this.selected === dest.AirportCode) {
      this.codeDone = false;
      this.selectedAirport = this.selected = '';
    } else {
      this.selected = dest.AirportCode;
      this.selectedAirport = dest.destinationOption;
      this.codeDone = true;
    }
  }

  redirect() {
    this.router.navigate([AppRoutes.LOCATE_RESERVATION_AIRPORT_CODE]);
  }

  validateDestination() {
    if (!this.codeDone) {
      return false;
    }
    this.logging.infoUiDestinationLookupPageContinueSelected(this.selected, 0);
    this.store.dispatch(
      new PnrLocatorFlowUpdateState({
        destination: this.selected,
        lookupMethod: 'destinationLastName',
      })
    );
    this.router.navigate([AppRoutes.LOCATE_RESERVATION_LAST_NAME]);
  }

  ngAfterViewInit(): void {}
}
