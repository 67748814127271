import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingService } from '~app/services/routing/routing.service';

@Component({
  selector: 'app-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss'],
})
export class FooterNavComponent implements OnInit, AfterViewInit {
  public inputIsValid: false;
  @Input() continueOnly = false;
  @Input() goBackToRoute;
  @Input() buttons;
  @Input() center;
  @Input() alignRight;
  @Input() continueButtonCopy = 'itinerary.continue';
  @Input() backButtonCopy = 'itinerary.goBack';
  @Output() continueClick = new EventEmitter<null>();
  @Output() centerBtnClick = new EventEmitter<null>();
  @Output() goBackClick = new EventEmitter<null>();
  @Output() messageEvent = new EventEmitter<Array<ElementRef>>();

  @Input() public smallerFontSize;
  @Input() public xSmallFontSize;

  @Input() set codeDone(value) {
    this.inputIsValid = value;
  }

  constructor(public router: Router, public routingService: RoutingService, private el: ElementRef) {}

  ngOnInit() {}

  goBackClicked() {
    this.goBackClick.emit();
    if (this.goBackToRoute) {
      return this.router.navigate(this.goBackToRoute);
    }
    this.router.navigate([this.routingService.getPrevCheckInRoute()]);
  }

  continueClicked() {
    this.continueClick.emit();
  }

  centerBtnClicked() {
    this.centerBtnClick.emit();
  }

  ngAfterViewInit(): void {
    this.messageEvent.emit(this.el.nativeElement.querySelectorAll('[tabindex]'));
  }
}
