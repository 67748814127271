import { Injectable, NgZone } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { PassportActionTypes, PassportInserted } from './passport.actions';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { AddAuthToken, AppState, ErrorActionTypes, SetPassportScanError, ToggleState } from '../..';
import { select, Store } from '@ngrx/store';
import { selectPassportData } from './passport.selector';
import { AuthenticationService } from '~app/services/api/authentication/authentication.service';
import { PnrLocatorFlowUpdateState } from '../../pnr-locator-flow/pnr-locator-flow.action';
import { activeSession, isPassportInReaderTooLong } from '../../../services/emitters/session-event-emitters';
import { DeviceService } from '../../../services/ha-cuss/device.service';
import { Router } from '@angular/router';
import { AppRoutes } from '~app/app-routes';

@Injectable({ providedIn: 'root' })
export class PassportEffects {
  passportData: any;
  intervalTimeInMillis = 1000;
  timeInSeconds = 0;
  passportInterval;

  constructor(
    private actions$: Actions,
    public authService: AuthenticationService,
    public deviceService: DeviceService,
    private store: Store<AppState>,
    private router: Router,
    private zone: NgZone
  ) {
    this.store.pipe(select(selectPassportData)).subscribe((data) => {
      this.passportData = data;
    });
  }

  @Effect({ dispatch: false })
  passportInserted$ = this.actions$.pipe(
    ofType(PassportActionTypes.PassportInserted),
    map((action: PassportInserted) => action.type),
    map(() => {
      this.store.dispatch(new ToggleState({ url: 'passport', state: true }));
      this.deviceService.disableBarcode();
      this.deviceService.disableCC();
      this.passportInterval = setInterval(() => {
        this.alertUserPassportLeftInReader();
        this.timeInSeconds++;
      }, this.intervalTimeInMillis);
    })
  );

  @Effect({ dispatch: false })
  readPassPort$ = this.actions$.pipe(
    ofType(PassportActionTypes.PassportRemoved),
    map(() => {
      this.store.dispatch(new ToggleState({ url: 'passport', state: false }));
      this.resetInterval();
      const INVALID_PASSPORT = 'Invalid Passport';
      const paxData = {
        firstName: this.passportData.firstName,
        lastName: this.passportData.lastName,
        dateOfBirth: this.passportData.dateOfBirth,
      };

      if (this.passportData === INVALID_PASSPORT || this.isValidPassportData()) {
        this.store.dispatch(new SetPassportScanError());
        this.zone.run(() => this.router.navigateByUrl(AppRoutes.ERROR_SCREEN));
      } else {
        activeSession.emit(true);
        this.store.dispatch(
          new PnrLocatorFlowUpdateState({
            firstName: this.passportData.firstName,
            lastName: this.passportData.lastName,
            dateOfBirth: this.passportData.dateOfBirth,
            lookupMethod: 'passport',
          })
        );
        this.authService.authenticate(paxData).then((response) => {
          if (response.type !== ErrorActionTypes.SetTimeoutError) {
            of(this.store.dispatch(new AddAuthToken(response)));
          }
        });
      }
    })
  );

  alertUserPassportLeftInReader() {
    /**
     * If the passport is still in the reader at 3 seconds, we display a page to the user asking them to remove
     * their passport
     */
    this.timeInSeconds >= 3 ? isPassportInReaderTooLong.emit(true) : isPassportInReaderTooLong.emit(false);
  }

  resetInterval() {
    clearInterval(this.passportInterval);
    this.timeInSeconds = 0;
    isPassportInReaderTooLong.emit(false);
  }

  private isValidPassportData() {
    return !this.passportData.firstName && !this.passportData.lastName && !this.passportData.dateOfBirth;
  }
}
