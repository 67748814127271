import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { AppRoutes } from '~app/app-routes';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';

@Component({
  selector: 'app-about-bags',
  templateUrl: './about-bags.component.html',
  styleUrls: ['./about-bags.component.scss'],
})
export class AboutBagsComponent implements OnInit, AfterViewInit {
  bagUrl = AppRoutes.BAGS;
  constructor(private accessibilityService: AccessibilityService, private el: ElementRef) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    const elements = this.el.nativeElement.querySelectorAll('[tabindex]');
    this.accessibilityService.setPageElementsToRead({
      elements: elements,
    });
  }
}
