import { AlertMessageCode, AlertReasonCode, ErrorModel } from '../../models/error/error.model';
import { Action } from '@ngrx/store';

export enum ErrorActionTypes {
  SetError = '[Error] Set Error',
  SetTooEarlyError = '[Error] Set Too Early Error',
  ClearError = '[Error] Clear Error',
  SetNotFoundError = '[Error] Not Found Error',
  SetTimeoutError = '[Error] Timeout Error',
  SetTimeoutGoToItineraryError = '[Error] Timeout Go To Itinerary Error',
  SetWrongMilesError = '[Error] Wrong Miles Error',
  SetDelayedFlightNotification = '[Error] Delayed Flight Notification',
  SetReferToAgentError = '[Error] Refer To Agent Error',
  SetReferToAgentDontPrintError = '[Error] Refer To Agent Dont Print Error',
  SetCancelledFlightError = '[Error] Cancelled Flight Error',
  SetTooLateError = '[Error] Too Late Error',
  SetTooLateWithPrintBoardingPassError = '[Error] Too Late Print Boarding Pass Error',
  SetTicketingIssue = '[Error] Ticketing Issue',
  SetPrintingIssue = '[Error] Printing Issue',
  SetBoardingPassError = '[Error] Do Not Print Boarding Pass',
  SetBagTagPrintingIssue = '[Error] BagTag Printing Issue',
  SetPassportScanError = '[Error] Passport Scan Error',
  SetRetryCardError = '[Error] Card Read Retry',
  SetSubsequentCardError = '[Error] Subsequent Card Error',
  SetPaymentCardError = '[Error] Payment Card Error',
  SetPrintBagTagsError = '[Error] Print Bag Tags Error',
  SetGenerateBagTagError = '[Error] Generate Bag Tag Error',
  SetGenerateDocumentError = '[Error] Generate Payment Receipt Error',
  SetFailedDocumentPrintError = '[Error] Failed Payment Receipt Print Error',
  SetMilitaryNoMatchError = '[Error] Military Match Error',
  SetSPFAndBPFError = '[Error] Seat Payment Failure and Bag Payment Failure',
  SetSAFAndBPFError = '[Error] Seat Assignment Failure and Bag Payment Failure',
  SetSPFError = '[Error] Seat Payment Failure',
  SetSAFError = '[Error] Seat Assignment Failure',
  SetBPFError = '[Error] Bag Payment Failure',
  SetSeatChangeError = '[Error] Seat Change Error',
}

export class SetError implements Action {
  readonly type = ErrorActionTypes.SetError;

  constructor(public payload: { error: ErrorModel }) {}
}

export class ClearError implements Action {
  readonly type = ErrorActionTypes.ClearError;
}

export class SetTooEarlyError implements Action {
  readonly type = ErrorActionTypes.SetTooEarlyError;

  constructor(public payload?: { checkInDate: Date; alertReasonCode?: string }) {}
}

export class SetNotFoundError implements Action {
  readonly type = ErrorActionTypes.SetNotFoundError;

  constructor(public payload?: { entry: string }) {}
}

export class SetDelayedFlightNotification implements Action {
  readonly type = ErrorActionTypes.SetDelayedFlightNotification;

  constructor(
    public payload?: {
      departureDate: Date;
      alertReasonCode?: string;
      okRoute?: string;
    }
  ) {}
}

export class SetTooLateError implements Action {
  readonly type = ErrorActionTypes.SetTooLateError;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetTooLateWithPrintBoardingPassError implements Action {
  readonly type = ErrorActionTypes.SetTooLateWithPrintBoardingPassError;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetWrongMilesError implements Action {
  readonly type = ErrorActionTypes.SetWrongMilesError;
}

export class SetTimeoutGoToItineraryError implements Action {
  readonly type = ErrorActionTypes.SetTimeoutGoToItineraryError;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetTimeoutError implements Action {
  readonly type = ErrorActionTypes.SetTimeoutError;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetReferToAgentError implements Action {
  readonly type = ErrorActionTypes.SetReferToAgentError;

  constructor(public payload?: { alertReasonCode?: string; alertMessageCode?: string }) {}
}

export class SetReferToAgentDontPrintError implements Action {
  readonly type = ErrorActionTypes.SetReferToAgentDontPrintError;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetCancelledFlightError implements Action {
  readonly type = ErrorActionTypes.SetCancelledFlightError;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetTicketingIssue implements Action {
  readonly type = ErrorActionTypes.SetTicketingIssue;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetPrintingIssue implements Action {
  readonly type = ErrorActionTypes.SetPrintingIssue;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetBoardingPassError implements Action {
  readonly type = ErrorActionTypes.SetBoardingPassError;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetBagTagPrintingIssue implements Action {
  readonly type = ErrorActionTypes.SetBagTagPrintingIssue;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetPassportScanError implements Action {
  readonly type = ErrorActionTypes.SetPassportScanError;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetCardRetryError implements Action {
  readonly type = ErrorActionTypes.SetRetryCardError;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetSubsequentCardError implements Action {
  readonly type = ErrorActionTypes.SetSubsequentCardError;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetPaymentCardError implements Action {
  readonly type = ErrorActionTypes.SetPaymentCardError;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetPrintBagTagsError implements Action {
  readonly type = ErrorActionTypes.SetPrintBagTagsError;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetGenerateBagTagError implements Action {
  readonly type = ErrorActionTypes.SetGenerateBagTagError;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetGenerateDocumentError implements Action {
  readonly type = ErrorActionTypes.SetGenerateDocumentError;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetFailedDocumentPrintError implements Action {
  readonly type = ErrorActionTypes.SetFailedDocumentPrintError;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetMilitaryNoMatchError implements Action {
  readonly type = ErrorActionTypes.SetMilitaryNoMatchError;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

export class SetSeatChangeError implements Action {
  readonly type = ErrorActionTypes.SetSeatChangeError;

  constructor(public payload?: { alertReasonCode?: string }) {}
}

/**
 * Superclass for all Partial Carts
 */
export class SetPartialSuccessError implements Action {
  readonly type: string;

  constructor(
    providedType: ErrorActionTypes,
    public payload?: { alertReasonCode?: AlertReasonCode; alertMessageCode?: AlertMessageCode }
  ) {
    this.type = providedType;
  }
}

export class SetSPFAndBPFError extends SetPartialSuccessError {
  constructor(
    providedType: ErrorActionTypes,
    payload?: { alertReasonCode?: AlertReasonCode; alertMessageCode?: AlertMessageCode }
  ) {
    super(providedType, payload);
  }
}

export class SetSAFAndBPFError extends SetPartialSuccessError {
  constructor(
    providedType: ErrorActionTypes,
    payload?: { alertReasonCode?: AlertReasonCode; alertMessageCode?: AlertMessageCode }
  ) {
    super(providedType, payload);
  }
}

export class SetSPFError extends SetPartialSuccessError {
  constructor(
    providedType: ErrorActionTypes,
    payload?: { alertReasonCode?: AlertReasonCode; alertMessageCode?: AlertMessageCode }
  ) {
    super(providedType, payload);
  }
}

export class SetSAFError extends SetPartialSuccessError {
  constructor(
    providedType: ErrorActionTypes,
    payload?: { alertReasonCode?: AlertReasonCode; alertMessageCode?: AlertMessageCode }
  ) {
    super(providedType, payload);
  }
}

export class SetBPFError extends SetPartialSuccessError {
  constructor(
    providedType: ErrorActionTypes,
    payload?: { alertReasonCode?: AlertReasonCode; alertMessageCode?: AlertMessageCode }
  ) {
    super(providedType, payload);
  }
}

export type ErrorActions =
  | SetError
  | ClearError
  | SetTooEarlyError
  | SetNotFoundError
  | SetTimeoutError
  | SetTimeoutGoToItineraryError
  | SetDelayedFlightNotification
  | SetReferToAgentError
  | SetReferToAgentDontPrintError
  | SetWrongMilesError
  | SetCancelledFlightError
  | SetTicketingIssue
  | SetTooLateError
  | SetTooLateWithPrintBoardingPassError
  | SetBoardingPassError
  | SetPrintingIssue
  | SetPassportScanError
  | SetPaymentCardError
  | SetPrintBagTagsError
  | SetGenerateBagTagError
  | SetGenerateDocumentError
  | SetFailedDocumentPrintError
  | SetMilitaryNoMatchError
  | SetSPFAndBPFError
  | SetSAFAndBPFError
  | SetSPFError
  | SetSAFError
  | SetBPFError
  | SetSeatChangeError;
