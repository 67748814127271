import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingService } from '~app/services/routing/routing.service';

@Component({
  selector: 'app-go-back-button',
  templateUrl: './go-back-button.component.html',
  styleUrls: ['./go-back-button.component.scss'],
})
export class GoBackButtonComponent {
  public internalRoute: any;

  @Input() set route(value) {
    this.internalRoute = value;
  }

  constructor(public router: Router, public routingService: RoutingService) {}

  goToRoute() {
    if (this.internalRoute) {
      this.router.navigate(this.internalRoute);
    } else {
      this.router.navigate([this.routingService.getPrevCheckInRoute()]);
    }
  }
}
