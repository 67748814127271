import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

import * as fromFlightStatus from './flight-status/flight-status.reducer';
import * as fromTrips from './trip/trip.reducer';
import * as fromPaxEdit from './pax-edit/pax-edit.reducer';
import * as fromCart from './cart/cart.reducer';

import * as fromBarcodeStatus from './ha-cuss-device/barcode/barcode.reducer';
import * as fromATBPrinterStatus from './ha-cuss-device/atb-printer/atb-printer.reducers';
import * as fromPassportStatus from './ha-cuss-device/passport/passport.reducer';
import * as fromBagTagPrinterStatus from './ha-cuss-device/bagtag-printer/bagtag-printer.reducers';
import * as fromCardReaderStatus from './ha-cuss-device/card-reader/card-reader.reducers';
import * as fromCussAccessibilityStatus from './ha-cuss-device/cuss-accessibility/cuss-accessibility.reducers';
import * as fromKeyboard from './keyboard/keyboard.reducer';
import * as fromLanguage from './language/language.reducer';
import * as fromErrors from './error/error.reducer';
import * as fromItineraryView from './ui/itinerary-view/itinerary-view.reducer';
import * as fromPnrLocatorFlow from './pnr-locator-flow/pnr-locator-flow.reducer';
import * as fromAuthentication from './auth-token/auth-token.reducer';
import * as fromLoadingState from './loading-flow/loading-flow.reducer';
import { ClearStateType } from './clearstate/clearstate.actions';

// Export all state reducers, selectors, actions, effects, etc here in order to import them from this file path to any UI component
// Export state models
export * from './trip/flight/flight.model';
export * from './flight-status/flight-status.model';
export * from './trip/passenger.model';
export * from './trip/segment/segment.model';
export * from './trip/segment/segment-detail.model';
export * from './trip/trip.model';
export * from './trip/segment/segment-flight.model';
export * from './ui/itinerary-view/scrollable-list.model';
export * from './auth-token/auth-token.model';
export * from './pnr-locator-flow/pnr-locator-flow.model';
export * from './keyboard/keyboard.model';
export * from './cart/cart.model';
// Export state reducers
export * from './pax-edit/pax-edit.reducer';
export * from './flight-status/flight-status.reducer';
export * from './trip/trip.reducer';
export * from './language/language.reducer';
export * from './error/error.reducer';
export * from './auth-token/auth-token.reducer';
export * from './cart/cart.reducer';
export * from './loading-flow/loading-flow.reducer';
export * from './pnr-locator-flow/pnr-locator-flow.reducer';
export * from './keyboard/keyboard.reducer';
export * from './cart/cart.reducer';
// Export state actions
export * from './cart/cart.actions';
export * from './pax-edit/pax-edit.actions';
export * from './flight-status/flight-status.actions';
export * from './error/error.action';
export * from './trip/segment/segment.actions';
export * from './trip/trip.actions';
export * from './ui/itinerary-view/itinerary-view.actions';
export * from './auth-token/auth-token.actions';
export * from './loading-flow/loading-flow.action';
export * from './pnr-locator-flow/pnr-locator-flow.action';
export * from './keyboard/keyboard.action';
export * from './document/document.actions';
export * from './ha-cuss-device/card-reader/card-reader.actions';
// Export state selectors
export * from './cart/cart.selector';
export * from './trip/trip.selectors';
export * from './flight-status/flight-status.selectors';
export * from './loading-flow/loading-flow.selectors';
export * from './pnr-locator-flow/pnr-locator-flow.selector';
export * from './pax-edit/pax-edit.selectors';
// Export state effects
export { TripEffects } from './trip/trip.effects';
export { LoadingStateEffects } from './loading-flow/loading-flow.effects';
export { FlightStatusEffects } from './flight-status/flight-status.effects';
export { StoreConfigService } from './state.config';
// Export hardware actions
export * from './ha-cuss-device/cuss-accessibility/cuss-accessibility.actions';

// Update the shape of the entire application state
export interface AppState {
  /**
   * Entities States
   */
  flightStatus?: fromFlightStatus.FlightStatusState;
  trips?: fromTrips.TripState;
  paxEdit?: fromPaxEdit.PaxEditState;
  carts?: fromCart.CartState;
  /**
   * UI States
   */
  loadingState?: fromLoadingState.LoadingFlowState;
  language?: fromLanguage.LanguageState;
  error?: fromErrors.ErrorState;
  keyboard?: fromKeyboard.KeyboardState;
  itineraryView?: fromItineraryView.ItineraryViewState;
  pnrLocatorFlow?: fromPnrLocatorFlow.PnrLocatorFlowState;
  authentication?: fromAuthentication.AuthTokenState;

  /** Kiosk Hardware Device state */
  barcode?: fromBarcodeStatus.BarcodeState;
  atbPrinter?: fromATBPrinterStatus.ATBPrinterState;
  passport?: fromPassportStatus.PassportState;
  bagTagPrinter?: fromBagTagPrinterStatus.BagTagPrinterState;
  cardReader?: fromCardReaderStatus.CardReaderState;
  cussAccessibility?: fromCussAccessibilityStatus.CussAccessibilityState;
}

// Add in feature reducer into combined reducer
export const reducers: ActionReducerMap<AppState> = {
  flightStatus: fromFlightStatus.flightStatusReducer,
  trips: fromTrips.tripReducer,
  paxEdit: fromPaxEdit.paxEditReducer,
  pnrLocatorFlow: fromPnrLocatorFlow.PnrLocatorFlowReducer,
  language: fromLanguage.languageReducer,
  error: fromErrors.errorReducer,
  keyboard: fromKeyboard.keyboardReducer,
  itineraryView: fromItineraryView.itineraryViewReducer,
  barcode: fromBarcodeStatus.barcodeReducers,
  atbPrinter: fromATBPrinterStatus.atbPrinterReducers,
  passport: fromPassportStatus.passportReducers,
  bagTagPrinter: fromBagTagPrinterStatus.BagTagPrinterReducers,
  cardReader: fromCardReaderStatus.cardReaderReducers,
  cussAccessibility: fromCussAccessibilityStatus.cussAccessibilityReducers,
  authentication: fromAuthentication.AuthTokenReducer,
  carts: fromCart.cartReducer,
  loadingState: fromLoadingState.loadingFlowReducer,
};

export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state, action) => {
    if (action.type === ClearStateType.ClearState) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearState];
