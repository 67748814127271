import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { airportCode } from '../../emitters/session-event-emitters';
import { AppRoutes } from '~app/app-routes';

@Injectable({
  providedIn: 'root',
})
export class ConfigGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): any {
    if (airportCode.getValue() === '') {
      this.router.navigate([AppRoutes.SPLASH_SCREEN]);
    }
    return true;
  }
}
