import { EventEmitter, Injectable } from '@angular/core';
import { Device, deviceIds } from '../../../assets/js/embross-device-manager';
import { ApplinkService } from '../hardware/applink.service';
import { BehaviorSubject } from 'rxjs';

export enum SPEECH_STATUS {
  NONE = 'NONE',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
}

export const speechStatus = new BehaviorSubject<SPEECH_STATUS>(SPEECH_STATUS.NONE);

export let hardwareDeviceStatus = {
  barcodeDevice: [] = [0, false],
  passportDevice: [] = [0, false],
};

export const isBarcodeAvailable = new EventEmitter<boolean>();
export const isPassportAvailable = new EventEmitter<boolean>();
export const leftToPrint = new EventEmitter<number>();
export const numberToPrint = new EventEmitter<number>();

@Injectable()
export class DeviceService {
  private devMgr;
  private barcodeReader: Device;
  private cardReader: Device;
  private passportReader: Device;
  private atbPrinter: Device;
  private bagTagPrinter: Device;
  private cussAccessibility: Device;

  constructor(private applinkService: ApplinkService) {
    /**
     * Get reference to singleton of device manager
     */
    this.devMgr = this.applinkService.getDeviceManager();

    /**
     * Assign Devices
     */
    this.barcodeReader = this.devMgr.getDevice(deviceIds.BARCODE_READER);
    this.cardReader = this.devMgr.getDevice(deviceIds.CARD_READER);
    this.passportReader = this.devMgr.getDevice(deviceIds.PASSPORT_READER);
    this.atbPrinter = this.devMgr.getDevice(deviceIds.ATB_PRINTER);
    this.bagTagPrinter = this.devMgr.getDevice(deviceIds.BAGTAG_PRINTER);
    this.cussAccessibility = this.devMgr.getDevice(deviceIds.CUSS_ACCESSIBILITY);
  }

  /*Disables all the devices which are used to locate a PNR*/
  public disableLookupDevices() {
    this.barcodeReader.disable();
    this.barcodeReader.OnDeviceEvent = null;
    this.passportReader.disable();
    this.passportReader.OnDeviceEvent = null;
    this.cardReader.disable();
    this.cardReader.OnDeviceEvent = null;
  }

  public playText(text) {
    speechStatus.next(SPEECH_STATUS.STARTED);
    this.cussAccessibility.play(text);
  }

  /*Connects to and returns barcode device*/
  public getBarcodeDevice() {
    return this.applinkService.getDeviceManager().getDevice(deviceIds.BARCODE_READER);
  }

  /*Disables current barcode device*/
  public disableBarcode() {
    this.barcodeReader.disable();
    this.barcodeReader.OnDeviceEvent = null;
  }

  /*Enables current barcode device*/
  public enableBarcode() {
    this.applinkService
      .getDeviceManager()
      .getDevice(deviceIds.BARCODE_READER)
      .enable();
  }

  /*Connects to and returns atb printing device*/
  public getAtbPrinterDevice() {
    return this.applinkService.getDeviceManager().getDevice(deviceIds.ATB_PRINTER);
  }

  /*Connects to and returns passport device*/
  public getPassportDevice() {
    return this.applinkService.getDeviceManager().getDevice(deviceIds.PASSPORT_READER);
  }

  /*Disables current passport device*/
  public disablePassport() {
    this.passportReader.disable();
    this.passportReader.OnDeviceEvent = null;
  }

  /*Connects to and returns bag tag device*/
  public getBagTagDevice() {
    return this.applinkService.getDeviceManager().getDevice(deviceIds.BAGTAG_PRINTER);
  }

  /*Connects to and returns CC device in info mode*/
  public getCreditCardDevice() {
    return this.applinkService.getDeviceManager().getDevice(deviceIds.CARD_READER);
  }

  /*Disables CC Reader*/
  public disableCC() {
    this.applinkService
      .getDeviceManager()
      .getDevice(deviceIds.CARD_READER)
      .disable();
  }
}
