export enum ApiRoutes {
  MACHINE_CONFIG = '/machine-config',
  LAUNCHER = '/launcher',
  CATALOGS = '/catalogs',
  TRIP_AUTHS = '/tripauths',
  TRIPS = '/trips',
  FLIGHTS = '/flights',
  STATUS = '/status',
  DOCUMENT = '/documents',
  RECEIPT = '/receipt',
  BAGTAG = '/bagTag',
  CARTS = '/carts',
  PAYMENTS = '/payments',
  BAGS = '/bags',
}
