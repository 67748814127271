import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import {
  AppState,
  CardReaderDisablePayment,
  CardReaderEnablePayment,
  CardReaderReadInternal,
  Cart,
  selectCart,
  selectCurrentTrip,
  Trip,
} from '../../../state';
import { Logging } from '~services/logging/logging.service';
import { accessibilityJson, configurationSettings } from '~services/emitters/session-event-emitters';

import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { AppRoutes } from '~app/app-routes';
import { sortByTabIndexValue } from '~app/utils/query-by-tabindex';
import { HaCussService } from '~services/ha-cuss/ha-cuss.service';
import { EnvironmentService } from '~services/environment/environment.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnDestroy, OnInit {
  cart$: Observable<Cart> = this.store.pipe(select(selectCart));
  trip$: Observable<Trip> = this.store.pipe(select(selectCurrentTrip));
  unsubscribe$: Subject<void> = new Subject<void>();

  currency: String = 'USD';
  altText = accessibilityJson.creditCardInsertion;

  private element;
  public isBagsEnabled = true;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private logging: Logging,
    private accessibilityService: AccessibilityService,
    private el: ElementRef,
    private haCussService: HaCussService,
    private environmentService: EnvironmentService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    this.cart$.pipe(takeUntil(this.unsubscribe$)).subscribe((cart: Cart) => {
      if (cart.grandTotal > 0) {
        this.store.dispatch(new CardReaderEnablePayment());
        if (!this.environmentService.getEnvironment().production) {
          this.validateCussStatus();
        }
      }
    });

    this.isBagsEnabled = configurationSettings.getValue().enableBags;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(new CardReaderDisablePayment());
  }

  modifyBaggageClicked() {
    this.logging.infoUiPaymentDueModifyBaggageSelected(0);
    this.router.navigate([AppRoutes.BAGS]);
  }

  onGridRendered() {
    this.updateACAAElementsToRead({
      startIndex: 0,
    });
  }

  onGridScrolled() {
    this.updateACAAElementsToRead({
      startIndex: 5,
    });
  }

  private validateCussStatus() {
    if (!this.haCussService.devManagerActive()) {
      this.store.dispatch(
        new CardReaderReadInternal('testScan', this.environmentService.getEnvironment().sampleData.cardData)
      );
    }
  }

  private updateACAAElementsToRead({ startIndex }) {
    const elementsToRead = [
      ...this.getHeaderElementsToRead(),
      ...this.getDetailElementsToRead(),
      ...this.getModifyBaggageElement(),
      ...this.getStartOverElementToRead(),
    ];

    this.accessibilityService.setPageElementsToRead({
      elements: elementsToRead,
      navigationIndex: startIndex,
    });
  }

  private getHeaderElementsToRead() {
    return Array.from(this.element.querySelectorAll('#payment-title-text, #payment-subtitle-text'));
  }

  private getDetailElementsToRead() {
    let paymentElements = this.element.querySelectorAll('.payment-instructions [tabindex]');
    paymentElements = sortByTabIndexValue(paymentElements);

    return Array.from(paymentElements);
  }

  private getModifyBaggageElement() {
    return Array.from(this.element.querySelectorAll('#payment-modify-baggage-button'));
  }

  private getStartOverElementToRead() {
    return Array.from(this.element.querySelectorAll('#start-over-exit-button'));
  }
}
