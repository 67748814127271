import * as fromCarts from './cart.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectCarts = createFeatureSelector<fromCarts.CartState>('carts');

export const selectCart = createSelector(
  selectCarts,
  (cartState) => cartState.cart
);

export const selectCartId = createSelector(
  selectCarts,
  (cartState) => {
    if (!cartState.cart) {
      return null;
    }
    return cartState.cart.id;
  }
);
