import { Injectable, OnDestroy } from '@angular/core';
import { DeviceService, SPEECH_STATUS, speechStatus } from '../ha-cuss/device.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { sortByTabIndexValue } from '~app/utils/query-by-tabindex';
import * as content from 'assets/i18n/en.json';
import { isAccessibilityMode } from '../emitters/session-event-emitters';
import { environment } from '~environments/environment';

// @TODO: refactor accessibility directive / service to add acaa-focus class
// based on current element and not on focus / blur
// https://ha-appdev.atlassian.net/browse/CXK-1752

@Injectable({
  providedIn: 'root',
})
export class AccessibilityService implements OnDestroy {
  private pageElementsToRead: ElementsToRead = {
    elements: [],
    navigationIndex: 0,
  };
  private modalElementsToRead: ElementsToRead[] = [];

  private roleAttributeName = 'data-accessibility-role';
  private appendAttributeName = 'data-accessibility-appended-text';
  private optionsAttributeName = 'data-accessibility-options';

  private speechStatus: SPEECH_STATUS;

  private unsubscribe$ = new Subject<void>();

  public speechStatus$ = new BehaviorSubject<SPEECH_STATUS>(SPEECH_STATUS.NONE);
  public isModalActive = new BehaviorSubject<Boolean>(false);

  private hardwareReadDelay = 5;

  constructor(private deviceService: DeviceService) {
    speechStatus.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      this.speechStatus = value;
      this.speechStatus$.next(this.speechStatus);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getSpeechStatus(): SPEECH_STATUS {
    return this.speechStatus;
  }

  /**
   * @deprecated
   */
  public setElementsToRead(elements, startIndex = 0, needsSorting = true) {
    this.setPageElementsToRead({
      elements: elements,
      navigationIndex: startIndex,
      needsSorting,
    });
  }

  public setPageElementsToRead(pageElementsToRead: ElementsToRead) {
    const { elements, navigationIndex, needsSorting } = pageElementsToRead;

    if (!elements) {
      return;
    }

    let elementsToRead = Array.from(elements);
    if (!elementsToRead || elementsToRead.length === 0) {
      return;
    }

    this.pageElementsToRead = {
      elements: elementsToRead,
      navigationIndex: navigationIndex || 0,
      needsSorting: needsSorting || needsSorting === undefined,
    };

    if (this.pageElementsToRead.needsSorting) {
      this.pageElementsToRead.elements = this.sortByTabIndex(elementsToRead);
    }

    const readFirstElement =
      pageElementsToRead.needsToReadFirstElement || pageElementsToRead.needsToReadFirstElement === undefined;

    if (this.hasModalElementsToRead() || !readFirstElement) {
      return;
    }

    this.readCurrentElement();
  }

  public addPageElementsToRead(newElementsToRead: NewElementsToRead) {
    if (!this.pageElementsToRead) {
      return;
    }

    let { elements, position, replace } = newElementsToRead;
    const pageElements = this.pageElementsToRead.elements;

    if (position < 0) {
      position = 0;
    }

    if (position > pageElements.length - 1) {
      position = pageElements.length - 1;
    }

    if (replace) {
      const deleteCount = elements.length;
      pageElements.splice(position, deleteCount);
    }

    pageElements.splice(position, 0, ...elements);
  }

  /**
   * @deprecated
   */
  public activateModalElementsToRead(modalElements, modalId) {
    this.setModalElementsToRead({
      id: modalId,
      elements: modalElements,
    });
  }

  public setModalElementsToRead(modalElementsToRead: ElementsToRead) {
    const { id, navigationIndex, elements, needsSorting, priority } = modalElementsToRead;

    if (this.getModalById(id)) {
      return;
    }

    const modalElements: ElementsToRead = {
      id: id,
      elements: elements,
      navigationIndex: navigationIndex || 0,
      needsSorting: needsSorting || needsSorting === undefined,
      priority: priority || 0,
    };

    if (modalElements.needsSorting) {
      modalElements.elements = this.sortByTabIndex(elements);
    }

    //Check if the modals array is empty
    if (!this.hasModalElementsToRead()) {
      this.pushAndSortModalsByZIndex(modalElements);
      this.waitAndReadCurrentElement();

      //Check if the modals array has items and current modal has higher priority
    } else if (this.hasModalElementsToRead() && this.isHighestPriority(modalElements)) {
      this.pushAndSortModalsByZIndex(modalElements);
      this.waitAndReadCurrentElement();
    } else {
      this.pushAndSortModalsByZIndex(modalElements);
    }
  }

  /**
   * @deprecated
   */
  public dismissModalElements(_ = [], __ = false, modalId: string) {
    this.dismissModalElementsToRead({ modalId });
  }

  public dismissModalElementsToRead(dismissElements: DismissElements) {
    const { modalId, needsToReadFirstElement } = dismissElements;

    this.modalElementsToRead = this.modalElementsToRead.filter((modalElementToRead) => {
      return modalElementToRead.id !== modalId;
    });

    const readFirstElement = needsToReadFirstElement || needsToReadFirstElement === undefined;

    if (!readFirstElement) {
      return;
    }

    const currentOpenModal = this.getCurrentOpenModal();

    if (!currentOpenModal || this.isHighestPriority(currentOpenModal)) {
      setTimeout(() => {
        this.waitAndReadCurrentElement();
      }, this.hardwareReadDelay);
    }
  }

  public readInstructions() {
    this.deviceService.playText(content.altText.headsetInserted);
  }

  public readCurrentElement() {
    const elementToRead = this.getCurrentElement();

    if (!elementToRead) {
      return;
    }

    let currentElementTextOutput = this.getCurrentElementTextOutput(elementToRead);
    let finalTextOutput = this.speechFormatterManager(currentElementTextOutput);

    if (this.isElementDisabled(elementToRead)) {
      finalTextOutput = 'Disabled ' + finalTextOutput;
    }

    if (isAccessibilityMode.getValue()) {
      this.addFocusClass(elementToRead);
      this.deviceService.playText(finalTextOutput);
    }
  }

  public waitAndReadCurrentElement() {
    const speechSubscriber$ = new Subject<void>();

    speechStatus.pipe(takeUntil(speechSubscriber$)).subscribe((status) => {
      if (status === SPEECH_STATUS.STARTED || status === SPEECH_STATUS.NONE) {
        return;
      }

      this.readCurrentElement();
      speechSubscriber$.next();
      speechSubscriber$.complete();
    });
  }

  public navigateToNextElement() {
    if (!isAccessibilityMode.getValue()) {
      return;
    }

    const previousElement = this.getCurrentElement();

    if (previousElement) {
      this.removeFocusClass(previousElement);
      previousElement.blur();
    }

    this.validateNextElement();
    this.readCurrentElement();
  }

  public navigateToPreviousElement() {
    if (!isAccessibilityMode.getValue()) {
      return;
    }

    const previousElement = this.getCurrentElement();

    if (previousElement) {
      this.removeFocusClass(previousElement);
      previousElement.blur();
    }

    this.validatePreviousElement();
    this.readCurrentElement();
  }

  public selectCurrentElement() {
    const currentElement = this.getCurrentElement();
    const elementRole = this.getAttributeOfElement(currentElement, this.roleAttributeName);
    const elementOptions: ElementOptions = this.getOptionsAttributeOfElement(currentElement);

    if (this.isElementDisabled(currentElement)) {
      return;
    }

    let currentElementTextOutput = this.getCurrentElementTextOutput(currentElement);
    currentElementTextOutput = currentElementTextOutput.toLowerCase();

    if (
      currentElementTextOutput.includes('button') ||
      currentElementTextOutput.includes('selectable input') ||
      elementRole.toLowerCase().includes('button')
    ) {
      // There is no click() function on an svg element so we have to simulate it by dispatching a custom event
      if (elementRole.toLowerCase().includes('svg')) {
        const clickEvent = new CustomEvent('click');
        currentElement.dispatchEvent(clickEvent);
      } else {
        currentElement.click();
      }
      let finalButtonTextOutput = currentElementTextOutput;

      // for every button select we have to tell the user of the outcome
      // so when they select a pax selected we want to let them know that it is now unselected
      // we still maintain current functionality with the catch all else
      if (currentElementTextOutput.includes('unselected')) {
        finalButtonTextOutput = finalButtonTextOutput.replace('unselected', 'selected');
      } else if (currentElementTextOutput.includes('selected')) {
        finalButtonTextOutput = finalButtonTextOutput.replace('selected', 'unselected');
      } else {
        finalButtonTextOutput = `${finalButtonTextOutput} selected`;
      }

      const appendedText = currentElement.getAttribute(this.appendAttributeName);
      finalButtonTextOutput += appendedText ? appendedText : '';

      if (isAccessibilityMode.getValue() && (!elementOptions || !elementOptions.skipReadOutOnSelect)) {
        this.deviceService.playText(finalButtonTextOutput);
      }
      currentElement.blur();
    }

    if (currentElementTextOutput.includes('input')) {
      currentElement.click();

      if (isAccessibilityMode.getValue()) {
        this.deviceService.playText(currentElementTextOutput);
      }
    }

    this.removeFocusClass(currentElement);
  }

  public setAndReadAllElements({ elements }): Observable<void> {
    this.setPageElementsToRead({
      elements,
      navigationIndex: -1,
      needsToReadFirstElement: false,
    });

    const statusUnsubscribe$ = new Subject<void>();

    return speechStatus.pipe(
      filter((status) => [SPEECH_STATUS.COMPLETED, SPEECH_STATUS.NONE].includes(status)),
      takeUntil(statusUnsubscribe$),
      map(() => {
        if (this.hasModalElementsToRead()) {
          return;
        }

        this.navigateToNextElement();

        if (this.pageElementsToRead.navigationIndex === this.pageElementsToRead.elements.length - 1) {
          statusUnsubscribe$.next();
          statusUnsubscribe$.complete();
        }
      })
    );
  }

  private getCurrentElement() {
    if (!this.pageElementsToRead) {
      return null;
    }

    const { elements, navigationIndex } = this.pageElementsToRead;
    let element = elements[navigationIndex];

    if (this.hasModalElementsToRead()) {
      element = this.getModalElementToRead();
    }

    return element;
  }

  // Add any text formatting logic here in order for screen reader to read appropriately
  private speechFormatterManager(content: string): string {
    if (content.toLocaleLowerCase().includes('seat')) {
      return this.formatSeatsForSpeech(content);
    }

    if (content.includes('Month (MM)')) {
      return this.formatMonthFormatForSpeech(content);
    }

    if (content.includes('(OGG)')) {
      return content.replace('OGG', ' ,O G G');
    }

    if (content.includes('HawaiianMiles')) {
      return content.replace('HawaiianMiles', ' Hawaiian Miles');
    }

    return content;
  }

  private sortByTabIndex(unsortedElements: any[]): any[] {
    return sortByTabIndexValue(unsortedElements);
  }

  private getAttributeOfElement(element, attribute: string): string {
    return element.getAttribute(attribute) || '';
  }

  private getOptionsAttributeOfElement(element): ElementOptions {
    return JSON.parse(element.getAttribute(this.optionsAttributeName)) || null;
  }

  private getCurrentElementTextOutput(element): string {
    const currentElement = element;

    if (!currentElement) {
      return '';
    }

    const elementRole = this.getAttributeOfElement(currentElement, this.roleAttributeName);
    const elementAltText = currentElement.getAttribute('alt');
    const elementAriaLabel = currentElement.getAttribute('aria-label');
    const elementAppendText = currentElement.getAttribute('data-accessibility-appended-text');

    if (!elementRole) {
      if (elementAltText) {
        return elementAltText.trim();
      }

      if (elementAriaLabel) {
        return elementAriaLabel.trim();
      }

      if (elementAppendText) {
        return elementAppendText.trim();
      }

      return currentElement.textContent;
    }

    if (elementRole.includes('input')) {
      const inputContent = currentElement.value.length > 0 ? currentElement.value : currentElement.placeholder;
      return elementRole.trim() + ' ' + inputContent.trim();
    }

    if (elementRole.includes('svg-button')) {
      return elementAriaLabel.trim();
    }

    if (elementRole === 'img') {
      return currentElement.alt.trim();
    }

    if (elementAriaLabel) {
      return elementRole.trim() + ' ' + elementAriaLabel.trim();
    }

    return elementRole.trim() + ' ' + currentElement.textContent.trim();
  }

  private validateNextElement() {
    let elementsToRead = this.pageElementsToRead;

    if (!elementsToRead) {
      return;
    }

    if (this.hasModalElementsToRead()) {
      elementsToRead = this.getCurrentOpenModal();
    }

    if (elementsToRead.navigationIndex === elementsToRead.elements.length - 1) {
      elementsToRead.navigationIndex = 0;
    } else {
      elementsToRead.navigationIndex = elementsToRead.navigationIndex + 1;
    }
  }

  private validatePreviousElement() {
    let elementsToRead = this.pageElementsToRead;

    if (!elementsToRead) {
      return;
    }

    if (this.hasModalElementsToRead()) {
      elementsToRead = this.getCurrentOpenModal();
    }

    if (elementsToRead.navigationIndex === 0) {
      elementsToRead.navigationIndex = elementsToRead.elements.length - 1;
    } else {
      elementsToRead.navigationIndex = elementsToRead.navigationIndex - 1;
    }
  }

  private isElementDisabled(element: HTMLElement): boolean {
    let isElementDisable = false;

    const disabledAttributeValue = element.getAttribute('disabled');

    if (disabledAttributeValue === '' || disabledAttributeValue === 'true') {
      isElementDisable = true;
    }

    if (element.classList.contains('disabled')) {
      isElementDisable = true;
    }

    if (disabledAttributeValue === 'false') {
      isElementDisable = false;
    }

    return isElementDisable;
  }

  private pushAndSortModalsByZIndex(modalElement: ElementsToRead) {
    this.modalElementsToRead.push(modalElement);
    this.modalElementsToRead.sort((a, b) => a.priority - b.priority);
  }

  private isHighestPriority(modalElement: ElementsToRead) {
    if (!modalElement || !this.modalElementsToRead[0]) {
      return;
    }

    return modalElement.priority > this.modalElementsToRead[0].priority;
  }

  private hasModalElementsToRead() {
    if (!this.modalElementsToRead) {
      return false;
    }

    return this.modalElementsToRead.length > 0;
  }

  private getModalElementToRead() {
    const { elements, navigationIndex } = this.getCurrentOpenModal();
    return elements[navigationIndex];
  }

  private getCurrentOpenModal() {
    const priorityModalToRead = this.modalElementsToRead.length - 1;
    return this.modalElementsToRead[priorityModalToRead];
  }

  private getModalById(modalId) {
    return this.modalElementsToRead.find((modalElementToRead) => {
      return modalElementToRead.id === modalId;
    });
  }

  private removeFocusClass(element: HTMLElement) {
    element.blur();
    element.classList.remove('acaa-focus');
  }

  private addFocusClass(element: HTMLElement) {
    element.focus({ preventScroll: true });
    element.classList.add('acaa-focus');
  }

  private formatSeatsForSpeech(speechString: string): string {
    // Adds a space between the numeric seat value & the corresponding letter. e.g. 19D -> 19, D
    let formattedSeatString = speechString.replace(/(\d)([^\d\s%])/g, '$1, $2');
    return formattedSeatString;
  }

  private formatMonthFormatForSpeech(speechString: string): string {
    return speechString.replace('(MM)', 'M M');
  }
}

export interface ElementsToRead {
  elements: any[];
  id?: string;
  navigationIndex?: number;
  needsSorting?: boolean;
  needsToReadFirstElement?: boolean;
  priority?: number;
}

export interface NewElementsToRead {
  elements: any[];
  position: number;
  replace?: boolean;
}

export interface DismissElements {
  modalId: string;
  needsToReadFirstElement?: boolean;
}

export interface ElementOptions {
  skipReadOutOnSelect?: boolean;
  role?: string;
}
