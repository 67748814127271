import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { of, Subscription } from 'rxjs';
import { Logging } from '../../services/logging/logging.service';
import { HaCussService } from '../../services/ha-cuss/ha-cuss.service';
import { DeleteCart } from '~app/state/cart/cart.actions';
import { socketAlive } from '../../services/emitters/session-event-emitters';
import { AppRoutes } from '~app/app-routes';
import { AccessibilityService } from '../../services/accessibility/accessibility.service';

@Component({
  selector: 'app-start-over',
  templateUrl: './start-over.component.html',
  styleUrls: ['./start-over.component.scss'],
})
export class StartOverComponent implements OnInit, OnDestroy, AfterViewInit {
  public showModal = false;
  public step = 'step-0';
  clearStateSubscription: Subscription;

  @Input() customTabIndex = '3';
  @Output() messageEvent = new EventEmitter<any>();
  @Output() clicked = new EventEmitter<any>();
  @Output() clickedYes = new EventEmitter<any>();
  @ViewChild('acaa', { static: false }) buttonElement: ElementRef;

  constructor(
    public router: Router,
    public logging: Logging,
    private store: Store<AppState>,
    private haCussService: HaCussService,
    private accessibilityService: AccessibilityService,
    private el: ElementRef
  ) {
    if (this.router.url.indexOf('locate-reservation') > 0) {
      this.step = 'step-1';
    }

    if (this.router.url.indexOf('itinerary') > 0 || this.router.url.indexOf('hazmat') > 0) {
      this.step = 'step-2';
    }
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.messageEvent.emit(this.el.nativeElement.querySelector('#exit-button'));
  }

  ngOnDestroy() {
    if (this.clearStateSubscription) {
      this.clearStateSubscription.unsubscribe();
    }
  }

  @ViewChild('startOverModal', { static: false }) set updateElementsToRead(elements) {
    if (!elements) {
      return;
    }

    if (this.showModal) {
      const elementsToRead = elements.nativeElement.querySelectorAll('.modal-holder [tabindex]');
      this.accessibilityService.activateModalElementsToRead(elementsToRead, 'start-over');
    }
  }

  toggleModal(status) {
    this.showModal = status;

    if (!this.showModal) {
      this.logging.infoUiStartOverPageNoSelected(0);
      this.accessibilityService.dismissModalElements(null, null, 'start-over');

      return;
    }

    this.clicked.emit();
    this.logging.infoUiStartOverSelected();
    this.logging.infoUiStartOverPageDisplayed(0);
  }

  doStartOver() {
    this.clickedYes.emit();
    this.logging.infoUiStartOverPageYesSelected(0);

    if (this.haCussService.getAtbPrinterStatus()[1] === true) {
      socketAlive.emit(false);
    }

    this.store.dispatch(new DeleteCart());

    this.accessibilityService.dismissModalElementsToRead({
      modalId: 'start-over',
      needsToReadFirstElement: false, // setPageElementsToRead will trigger loading when navigation happens
    });

    this.clearStateSubscription = of(this.store.dispatch({ type: 'clearState' })).subscribe(() => {
      this.logging.infoUiCheckinCancelled();
      this.router.navigate([AppRoutes.MENU]);
    });
  }
}
