import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState, selectCurrentTrip, Trip } from '../../../../state';
import { getCityByAirportCode } from '../../../../utils/helpers';

@Component({
  selector: 'app-flight-grid',
  templateUrl: './flight-grid.component.html',
  styleUrls: ['./flight-grid.component.scss'],
})
export class FlightGridComponent implements OnInit, OnDestroy {
  public buttons;
  public trip$: Observable<Trip>;
  public trip;
  public flights;

  constructor(private store: Store<AppState>) {
    this.trip$ = this.store.pipe(select(selectCurrentTrip));
  }

  @Input() public isMultiPax;

  ngOnInit() {
    this.trip = this.trip$.subscribe((value) => {
      if (value.flights) {
        this.flights = value.flights;
        this.flights.map((flight) => {
          flight.cityOrigin = getCityByAirportCode(flight.origin);
          flight.cityDestination = getCityByAirportCode(flight.scheduledDestination);
          flight.buttons = this.initButtons();
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.trip) {
      this.trip.unsubscribe();
    }
  }

  toogleButton(flight, button) {
    if (flight.buttons[button]) {
      flight.buttons = this.initButtons();
    } else {
      flight.buttons = this.initButtons();
      flight.buttons[button] = true;
      if (button === 'window') {
        flight.buttons.windowText = 'Selected';
      }
      if (button === 'aisle') {
        flight.buttons.aisleText = 'Selected';
      }
    }
  }

  initButtons() {
    return {
      window: false,
      windowText: 'Select',
      aisle: false,
      aisleText: 'Select',
      splitYes: false,
      splitNo: false,
    };
  }
}
