import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '~app/app-routes';
import { Logging } from '~services/logging/logging.service';

@Component({
  selector: 'app-military-no-match',
  templateUrl: './military-no-match.component.html',
  styleUrls: ['./military-no-match.component.scss'],
})
export class MilitaryNoMatchComponent implements OnInit {
  constructor(private router: Router, private logging: Logging) {}

  ngOnInit() {
    this.logging.infoApiMilitaryInfoMatchUnsuccessful(0);
  }

  navigateToBags() {
    this.router.navigateByUrl(AppRoutes.BAGS);
  }
}
