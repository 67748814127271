import { Flight } from './flight/flight.model';
import { Segment } from './segment/segment.model';
import { Passenger } from './passenger.model';
import { deepCopy } from '../../utils/helpers';
import { SegmentDetail } from './segment/segment-detail.model';

const byDepartureTime = (flightA, flightB) => {
  const dateA = flightA.scheduledDeparture.airportDateTimeString;
  const dateB = flightB.scheduledDeparture.airportDateTimeString;

  if (dateA && dateB) {
    return (new Date(dateA) as any) - (new Date(dateB) as any);
  }

  return 0;
};

export class Trip {
  id: string;
  version: string | null;
  confirmationCode: string | null;
  isGroupBooking: boolean | false;
  isPassTravel: boolean | false;
  isPositiveSpace: boolean | false;
  segments?: Segment[] | null;
  flights?: Flight[] | null;
  passengers?: Passenger[] | null;
  activeSegment?: Segment | null;
  kioskAirportCode?: string | null;

  static deserializeFromJson(tripJson: any, kioskOrigin: string): Trip {
    const trip = Object.assign(new Trip(), tripJson);

    trip.kioskAirportCode = kioskOrigin;

    const flights = tripJson.flights || [];

    trip.flights = flights.map((flight) => Flight.deserializeFromJson(flight)).sort(byDepartureTime);

    const passengers = tripJson.passengers || [];

    trip.passengers = passengers.map((passenger) => Passenger.deserializeFromJson(passenger));

    const segments = tripJson.segments || [];

    trip.segments = segments.map((segment) =>
      Segment.deserializeFromJson(segment, trip.flights, trip.kioskAirportCode)
    );

    trip.segments.forEach((segment) => segment.setFlights(trip.flights));

    if (tripJson.activeSegment) {
      trip.activeSegment = trip.segments.find((segment) => segment.id === tripJson.activeSegment.id);
    } else {
      trip.activeSegment = trip.findActiveSegment();
    }

    return trip;
  }

  static setSelectedSegmentDetails(trip, preSelectedPaxIds: []) {
    if (!trip || !trip.activeSegment || !trip.activeSegment.details) {
      return;
    }

    if (preSelectedPaxIds !== undefined) {
      preSelectedPaxIds.forEach((id) => {
        trip.activeSegment.details.find((segmentDetail) => segmentDetail.passengerId.includes(id)).selected = true;
      });
    } else {
      trip.activeSegment.details.forEach((segmentDetail) => {
        segmentDetail.selected = true;
      });
    }
  }

  public clone(): Trip {
    return Trip.deserializeFromJson(deepCopy(this), this.kioskAirportCode);
  }

  public findActiveSegment(): Segment {
    const segmentWithMatchingOrigin = this.getSegmentWithMatchingOrigin(this.kioskAirportCode);

    if (!segmentWithMatchingOrigin) {
      return null;
    }

    if (segmentWithMatchingOrigin.length > 1) {
      return this.getSegmentWithOpenFlight(segmentWithMatchingOrigin);
    }

    return segmentWithMatchingOrigin[0];
  }

  public hasNextSegmentsToCheckin(): boolean {
    return this.getNextSegmentToCheckIn() !== null;
  }

  public getNextSegmentToCheckIn(): Segment {
    const indexOfActiveSegment = this.segments.findIndex((segment) => {
      return this.activeSegment.id === segment.id;
    });
    let nextSegmentToCheckIn = null;

    for (let i = indexOfActiveSegment + 1; i < this.segments.length; i++) {
      if (this.segments[i].flights[0].isOpenForCheckIn() || this.segments[i].hasFirstFlightCancelledForCheckIn()) {
        nextSegmentToCheckIn = this.segments[i];
        break;
      }
    }

    return nextSegmentToCheckIn;
  }

  public getTotalPaxCountOnTrip(): number {
    return this.passengers.length;
  }

  public getSegmentWithMatchingOrigin(kioskOrigin): Array<Segment> {
    if (!this.segments) {
      return null;
    }

    const segmentsWithFlightMatchingOrigin = this.segments.filter((segment) => {
      const flights = segment.flights.filter((flight) => flight.originMatchesKioskAirport(kioskOrigin));
      return flights.length > 0;
    });

    if (segmentsWithFlightMatchingOrigin.length === 0) {
      return null;
    }

    return segmentsWithFlightMatchingOrigin;
  }

  public getSegmentWithOpenFlight(segments?: Array<Segment>): Segment {
    const segmentsToSearch = segments || this.segments;

    const segmentsWithOpenFlights = segmentsToSearch.filter((segment) => segment.flights[0].isOpenForCheckIn());

    if (segmentsWithOpenFlights.length > 0) {
      return segmentsWithOpenFlights[0];
    }

    return null;
  }

  public isNonRevSpaceAvailable(): boolean {
    // if isPassTravel and isPositiveSpace was undefined should return false
    return (this.isPassTravel && !this.isPositiveSpace) || false;
  }

  public hasInfant() {
    const passengersWithInfant = this.passengers.filter((passenger) => passenger.hasInfant === true);

    return passengersWithInfant.length > 0;
  }

  public getSelectedPassengerIds() {
    return this.activeSegment.details.filter((x) => x.selected).map((x) => x.passengerId);
  }

  public getPassengerNamesById(id) {
    const pax = this.passengers.filter((passenger) => passenger.id === id)[0];
    return pax.passengerName.firstName + ' ' + pax.passengerName.lastName;
  }

  public getSegmentDetailsAndPax(): SegmentDetailAndPax[] {
    const paxsAndSegmentDetails: SegmentDetailAndPax[] = [];

    if (!this.activeSegment || !this.activeSegment.details) {
      return paxsAndSegmentDetails;
    }

    this.activeSegment.details.map((segmentDetail) => {
      const passenger = this.passengers.filter((pax) => pax.id === segmentDetail.passengerId)[0];

      segmentDetail.segmentId = this.activeSegment.id;

      paxsAndSegmentDetails.push({ segmentDetail, passenger });
    });

    return paxsAndSegmentDetails;
  }

  public getSegmentDetailsAndPaxSelected(): SegmentDetailAndPax[] {
    const paxsAndSegmentDetails = this.getSegmentDetailsAndPax();
    return paxsAndSegmentDetails.filter((x) => x.segmentDetail.selected);
  }
}

export interface SegmentDetailAndPax {
  segmentDetail: SegmentDetail;
  passenger: Passenger;
}
