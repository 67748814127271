import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, selectTripState, SetTimeoutError, TripEffects, TripLoadRequested } from '~app/state';
import { Subject, Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { EndRelatedCartSession } from '~app/state/cart/cart.actions';
import { Router } from '@angular/router';
import { AppRoutes } from '~app/app-routes';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { isAccessibilityMode } from '~app/services/emitters/session-event-emitters';

@Component({
  selector: 'app-checkin-in-progress',
  templateUrl: './checkin-in-progress.component.html',
  styleUrls: ['./checkin-in-progress.component.scss'],
})
export class CheckInInProgressComponent implements OnInit, OnDestroy {
  public showModal = false;
  public cartSubscription: Subscription;
  public passengers: { paxId: string; paxName: string }[];
  unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private el: ElementRef,
    private accessibilityService: AccessibilityService,
    private changeRef: ChangeDetectorRef,
    private tripEffects: TripEffects
  ) {}

  @ViewChild('checkinInProgressModal', { static: false }) set updateAcaaElements(element) {
    if (this.showModal === true && isAccessibilityMode.getValue()) {
      const elements = element.nativeElement.querySelectorAll('[tabindex]');
      const zIndex = +window.getComputedStyle(this.el.nativeElement.querySelectorAll('.modal-holder')[0]).zIndex;

      this.accessibilityService.setModalElementsToRead({
        id: 'checkin-in-progress',
        elements: elements,
        priority: zIndex,
      });
    }
  }

  ngOnInit() {
    this.cartSubscription = this.store
      .select((state) => state.carts.cart)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((cart) => {
        if (cart.checkConcurrentCart()) {
          const passengersIds = cart.relatedCarts[0].associatedPassengers.map((x) => x.passengerId);

          this.store
            .select(selectTripState)
            .pipe(first())
            .subscribe((result) => {
              this.passengers = result.trip.passengers
                .filter((pax) => passengersIds.find((paxId) => paxId === pax.id))
                .map((pax) => {
                  return {
                    paxId: pax.id,
                    paxName: `${pax.passengerName.firstName} ${pax.passengerName.lastName}`,
                  };
                });

              this.tripEffects.catalogs$.pipe(takeUntil(this.unsubscribe$)).subscribe((catalogsResponse) => {
                if (!catalogsResponse || catalogsResponse instanceof SetTimeoutError) {
                  return;
                }

                this.showModal = true;
              });
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  cancel() {
    this.closeModal();
    this.store.dispatch(new TripLoadRequested());
    this.router.navigate([AppRoutes.ITINERARY]);
  }

  continue() {
    this.closeModal();
    this.store.dispatch(new EndRelatedCartSession());
  }

  closeModal() {
    this.showModal = false;
    this.changeRef.detectChanges();
    this.accessibilityService.dismissModalElementsToRead({
      modalId: 'checkin-in-progress',
    });
  }
}
