import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { BagTagPrinterActionTypes } from './bagtag-printer.actions';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { DocumentService } from '../../../services/api/document/document.service';
import { bagTagsPrinted } from '../../../services/emitters/session-event-emitters';
import { select, Store } from '@ngrx/store';
import { AppState, selectActiveSegment, selectCurrentTrip } from '../..';

@Injectable({ providedIn: 'root' })
export class BagTagPrinterEffects {
  constructor(private actions$: Actions, private documentService: DocumentService, private store: Store<AppState>) {}

  @Effect({ dispatch: false })
  bagTagPrinted$ = this.actions$.pipe(
    ofType(BagTagPrinterActionTypes.BagTagPrinted),
    withLatestFrom(this.store.pipe(select(selectActiveSegment)), this.store.pipe(select(selectCurrentTrip))),
    switchMap(async ([_, activeSegment, trip]) => {
      bagTagsPrinted.next(true);
      const result = await this.documentService.reportBagTagPrinted(
        this.documentService.getNextBagTagKey(),
        activeSegment,
        trip
      );
      switch (result.status) {
        case 500: {
          // We need to handle this error need requirements.
          break;
        }
        case 400: {
          // We need to handle or retry this error, need requirements.
        }
      }
    })
  );
}
