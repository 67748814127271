import { Action } from '@ngrx/store';

export enum AuthTokenActionTypes {
  AddAuthToken = '[Authentication] Add new token',
  ClearAuthToken = '[Authentication] Clear token state',
}

export class AddAuthToken implements Action {
  readonly type = AuthTokenActionTypes.AddAuthToken;
  constructor(private payload: {}) {}
}

export class ClearAuthToken implements Action {
  readonly type = AuthTokenActionTypes.ClearAuthToken;
}

export type AuthTokenActions = AddAuthToken | ClearAuthToken;
