import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';

import { deepEncode, deepSearch } from './utils';

import { LogLevels } from './log-levels';
import { Logger } from './logger.service';
import { DataSource } from './data-source.service';

import { LogEvent } from './log-event.interface';
import { findFirstParameterThatMatches, getFirstParameterThatMatches } from '../../utils/helpers';
import { Product } from '~app/state/trip/passenger.catalog.model';
import { configurationSettings } from '../emitters/session-event-emitters';
import { Configuration, MachineConfig } from '../api/config/config';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from '../../../shared/embross/helper';
import { ModuleTypes } from '~app/models/logging/logging';

@Injectable({ providedIn: 'root' })
export class Logging {
  constructor(private loggingBackend: Logger, private dataSource: DataSource) {
    this.sessionTime = new Date().getTime();
    this.hasCheckinStarted = false;

    configurationSettings.subscribe((config: Configuration) => {
      if (config && config.restrictedLogProperties) {
        this.restrictedProperties = [...this.restrictedProperties, ...config.restrictedLogProperties];
      }
    });
  }

  sessionTime: number;
  hasCheckinStarted: boolean;
  // For PII and security we must black list all possible variable names
  restrictedProperties = [
    'dateofbirth',
    'dob',
    'birthday',
    'loyalty',
    'lastname',
    'middlename',
    'passport',
    'passengerid',
    'cardnumber',
    'card number',
    'credit',
    'debit',
    'binnumber',
    'expirationmonth',
    'expirationyear',
    'expirationdate',
    'hm',
    'hmmiles',
    'hawaiianmiles',
    'cardname',
    'access_token',
    'token',
    'authorization',
    'authorizationid',
    'knowntravelernumber',
    'cac',
    'ktn',
    'redress',
    'secret',
    'client',
    'key',
    'password',
    'stream',
    'passport',
    'passportnumber',
    'profileid',
    'bearer',
    'boardingpassdata',
    'pin',
  ];

  static encodePayload(obj, addRestrictedProperties) {
    const encoded = deepEncode(obj, addRestrictedProperties);

    return encoded;
  }

  resetSession() {
    this.sessionTime = new Date().getTime();
  }

  // For testing purposes
  startCheckin() {
    this.hasCheckinStarted = true;
  }

  infoUiPnrLocateTime(elapsedTime?: number) {
    const keys = {
      timeLapsed: elapsedTime || 0,
    };

    this.logWithThisInfo({
      message: 'PnrLocateTime',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.IDLE_SESSION,
      keys,
    });
  }

  infoApiPnrSuccessful(
    request: HttpRequest<any>,
    response: HttpResponse<any>,
    lookupMethod: string,
    elapsedTime?: number
  ) {
    const lookupData = getFirstParameterThatMatches(
      [
        'confirmationCode',
        'flightNumber',
        'ticketNumber',
        'hawaiianMilesNumber',
        'flightNumberLastName',
        'destinationLastName',
        'barcode',
        'creditCard',
        'passport',
      ],
      request.urlWithParams
    );
    this.dataSource.onLoadedTrip(() => {
      const keys = {
        lookupMethod,
        lookupData,
        timeLapsed: elapsedTime || 0,
        alreadyCheckedIn: this.dataSource.hasAlreadyCheckedIn(),
        alreadyCheckedInPaxName: this.dataSource.getCheckedInPaxNames(),
        marketType: this.dataSource.getMarketType(),
        marketedBy: this.dataSource.getMarketedBy(),
        operatedBy: this.dataSource.getOperatedBy(),
        seatPreference: this.dataSource.getSeatPreference(),
        seatNumber: this.dataSource.getSeatNumbers(),
        nonRev: this.dataSource.isNonRev(),
        numberOfPax: this.dataSource.numberOfPax(),
        totalBags: this.dataSource.totalBags(),
        totalSpecialItems: this.dataSource.totalSpecialItems(),
        origin: this.dataSource.origin(),
        destination: this.dataSource.destination(),
        language: this.dataSource.language(),
        totalFlightsInSegment: this.dataSource.totalFlightsInSegment(),
        alreadyCheckedInPaxNames: this.dataSource.alreadyCheckedInPaxNames(),
        boardingStartTime: this.dataSource.boardingStartTime(),
        HAMemberStatus: this.dataSource.HAMemberStatus(),
        hawaiianMilesNumber: this.dataSource.hawaiianMilesNumber(),
        paxName: this.dataSource.paxNames(),
        positiveSpace: this.dataSource.positiveSpace(),
        ktnPresent: this.dataSource.isKTNPresent(),
        isInfantPresent: this.dataSource.isInfantPresent(),
        targetId: this.getTargetResource(request),
      };

      this.logWithThisInfo({
        correlationId: request.headers.get('x-root-correlation-id'),
        message: 'PnrSearchSuccessful',
        level: LogLevels.INFO,
        moduleName: ModuleTypes.USER_SESSION,
        keys,
      });
    });
  }

  private getTargetResource(request: HttpRequest<any>) {
    return request.url.split('/').reverse()[0];
  }

  logWithThisInfo(info: any) {
    const event = {
      applicationVersion: this.dataSource.appVersion(),
      environment: this.dataSource.environment(),
      kioskId: this.dataSource.kioskId(),
      userSessionId: this.dataSource.sessionId(),
      clientTimestamp: this.dataSource.localTime(),
      systemTimestamp: this.dataSource.systemTime(),
      channel: this.dataSource.channel(),
      confirmationCode: this.dataSource.confirmationCode(),
      language: this.dataSource.language(),
      browserUrl: this.dataSource.browserUrl(),
      userAgent: this.dataSource.userAgent(),
      domainName: this.dataSource.getDomainName(),
      applicationName: this.dataSource.getApplicationName(),
      kioskLocation: this.dataSource.getKioskLocation(),
      logId: uuidv4(),
      moduleName: !isEmpty(info.moduleName) ? info.moduleName : undefined,
      level: !isEmpty(info.level) ? info.level : undefined,
    };

    if (info.keys && info.keys.timeLapsed === 0) {
      info.keys.timeLapsed = new Date().getTime() - this.sessionTime;
    }

    const withInfo = { ...event, ...info } as LogEvent;

    this.loggingBackend.log(withInfo);
  }

  infoApiPnrFailed(request: HttpRequest<any>, response: HttpResponse<any> | HttpErrorResponse, elapsedTime?: number) {
    const lookupMethod = findFirstParameterThatMatches(
      [
        'confirmationCode',
        'flightNumber',
        'ticketNumber',
        'hawaiianMilesNumber',
        'flightNumberLastName',
        'destinationLastName',
        'barcode',
        'creditCard',
        'passport',
      ],
      request.urlWithParams
    );

    const lookupData = getFirstParameterThatMatches(
      [
        'confirmationCode',
        'flightNumber',
        'ticketNumber',
        'hawaiianMilesNumber',
        'flightNumberLastName',
        'destinationLastName',
        'barcode',
        'creditCard',
        'passport',
      ],
      request.urlWithParams
    );

    const keys = {
      lookupMethod,
      lookupData: lookupData,
      timeLapsed: elapsedTime || 0,
      targetId: this.getTargetResource(request),
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'PnrSearchFailed',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoApiCatalogsSuccessful(
    request: HttpRequest<any>,
    response: HttpResponse<any> | HttpErrorResponse,
    elapsedTime?: number
  ) {
    this.dataSource.onLoadedTrip(() => {
      const allPax = this.dataSource.allPaxInCart();
      let passengerEntries = [];

      allPax.forEach((pax) => {
        passengerEntries.push({
          paxId: pax,
          totalAllowances: this.dataSource.totalAllowances(pax),
        });
      });

      const keys = {
        timeLapsed: elapsedTime || 0,
        passengerEntries,
        targetId: this.getTargetResource(request),
      };

      this.logWithThisInfo({
        correlationId: request.headers.get('x-root-correlation-id'),
        message: 'GetCatalogSuccessful',
        level: LogLevels.INFO,
        moduleName: ModuleTypes.USER_SESSION,
        keys,
      });
    });
  }

  infoApiCatalogsFailed(
    request: HttpRequest<any>,
    response: HttpResponse<any> | HttpErrorResponse,
    elapsedTime?: number
  ) {
    const keys = {
      timeLapsed: elapsedTime || 0,
      targetId: this.getTargetResource(request),
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'GetCatalogFailed',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoApiBagSaveSuccessful(
    request: HttpRequest<any>,
    response: HttpResponse<any> | HttpErrorResponse,
    elapsedTime?: number
  ) {
    this.dataSource.onLoadedCart(() => {
      const allPax = this.dataSource.allPaxInCart();
      let passengerEntries = [];

      allPax.forEach((pax) => {
        const items = this.dataSource.itemsInCartByPassenger(pax);
        passengerEntries.push({
          paxId: pax,
          costPerPax: items.reduce((sum, item) => sum + +item.price, 0),
          products: items.map((product) => ({
            subcode: product.productId,
            quantity: product.quantity,
          })),
        });
      });

      const keys = {
        timeLapsed: elapsedTime || 0,
        passengerEntries,
      };

      this.logWithThisInfo({
        correlationId: request.headers.get('x-root-correlation-id'),
        message: 'BagsSaveSuccessful',
        level: LogLevels.INFO,
        moduleName: ModuleTypes.USER_SESSION,
        keys,
      });
    });
  }

  infoApiBagSaveFailed(
    request: HttpRequest<any>,
    response: HttpResponse<any> | HttpErrorResponse,
    elapsedTime?: number
  ) {
    const allPax = this.dataSource.allPaxInCart();
    let passengerEntries = [];

    allPax.forEach((pax) => {
      const items = this.dataSource.itemsInCartByPassenger(pax);
      passengerEntries.push({
        paxId: pax,
        subcode: items.map((item) => item.productId),
        costPerPax: items.reduce((sum, item) => sum + +item.price, 0),
      });
    });
    const keys = {
      timeLapsed: elapsedTime || 0,
      passengerEntries,
      targetId: this.getTargetResource(request),
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'BagsSaveFailed',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoApiGetCartSuccessful(
    request: HttpRequest<any>,
    response: HttpResponse<any> | HttpErrorResponse,
    elapsedTime?: number
  ) {
    const allPax = this.dataSource.allPaxInCart();
    let passengerEntries = [];

    allPax.forEach((pax) => {
      const items = this.dataSource.itemsInCartByPassenger(pax);
      passengerEntries.push({
        paxId: pax,
        subcode: items.map((item) => item.productId),
        costPerPax: items.reduce((sum, item) => sum + +item.price, 0),
      });
    });

    this.dataSource.onLoadedCart(() => {
      const keys = {
        timeLapsed: elapsedTime || 0,
        passengerEntries,
        targetId: this.getTargetResource(request),
      };

      this.logWithThisInfo({
        correlationId: request.headers.get('x-root-correlation-id'),
        message: 'GetCartSuccessful',
        level: LogLevels.INFO,
        moduleName: ModuleTypes.USER_SESSION,
        keys,
      });
    });
  }

  infoApiGetCartFailed(
    request: HttpRequest<any>,
    response: HttpResponse<any> | HttpErrorResponse,
    elapsedTime?: number
  ) {
    const keys = {
      timeLapsed: elapsedTime || 0,
      targetId: this.getTargetResource(request),
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'GetCartFailed',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoApiDeleteCartFailed(
    request: HttpRequest<any>,
    response: HttpResponse<any> | HttpErrorResponse,
    elapsedTime?: number
  ) {
    const keys = {
      timeLapsed: elapsedTime || 0,
      targetId: this.getTargetResource(request),
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'DeleteCartFailed',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoApiDeleteCartSuccessful(
    request: HttpRequest<any>,
    response: HttpResponse<any> | HttpErrorResponse,
    elapsedTime?: number
  ) {
    const keys = {
      timeLapsed: elapsedTime || 0,
      targetId: this.getTargetResource(request),
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'DeleteCartSuccessful',
      level: LogLevels.INFO,
      keys,
    });
  }

  infoUiCheckinStarted(flightData, elapsedTime?: number) {
    this.hasCheckinStarted = true;

    const keys = {
      ...flightData,
      timeLapsed: elapsedTime || 0,
    };

    this.logWithThisInfo({
      message: 'CheckinStarted',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiSessionTimedOut(elapsedTime?: number) {
    const keys = {
      timeLapsed: elapsedTime || 0,
    };

    this.logWithThisInfo({
      message: 'SessionTimedOut',
      level: LogLevels.INFO,
      timeLapsed: elapsedTime || 0,
      moduleName: ModuleTypes.IDLE_SESSION,
      keys,
    });
  }

  infoUiSessionStarted(elapsedTime?: number, config?: MachineConfig) {
    const keys = {
      languageSelected: this.dataSource.getSelectedLanguage(),
      timeLapsed: elapsedTime || 0,
      kioskLocation: config.airportCode,
      kioskConfig: config,
      bagTagStocks: config.bagTag,
      javascriptVersion: 'es2015',
      browserVersion: navigator.appVersion,
    };

    this.logWithThisInfo({
      message: 'SessionStarted',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.IDLE_SESSION,
      keys,
    });
  }

  infoUiSessionEnded(elapsedTime?: number) {
    const keys = {
      timeLapsed: elapsedTime || 0,
    };

    this.logWithThisInfo({
      message: 'SessionEnded',
      level: LogLevels.INFO,
      timeLapsed: elapsedTime || 0,
      moduleName: ModuleTypes.IDLE_SESSION,
      keys,
    });
  }

  infoUiCheckinComplete(elapsedTime?: number) {
    this.dataSource.onLoadedTrip(() => {
      const keys = {
        timeLapsed: elapsedTime || 0,
        origin: this.dataSource.origin(),
        destination: this.dataSource.destination(),
        marketType: this.dataSource.getMarketType(),
      };

      this.logWithThisInfo({
        message: 'CheckinComplete',
        level: LogLevels.INFO,
        moduleName: ModuleTypes.USER_SESSION,
        keys,
      });
    });
  }

  infoApiCheckinSuccessful(request: HttpRequest<any>, response: HttpResponse<any>, elapsedTime?: number) {
    const data = {
      alreadyCheckedInPaxNames: this.dataSource.alreadyCheckedInPaxNames(),
      numberOfSelectedPax: this.dataSource.numberOfSelectedPax(),
    };

    const keys = {
      ...data,
      timeLapsed: elapsedTime || 0,
      targetId: this.getTargetResource(request),
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'CheckinSuccessful',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoApiCheckinFailed(request: HttpRequest<any>, response: HttpErrorResponse, elapsedTime?: number) {
    const keys = {
      numberOfSelectedPax: this.dataSource.numberOfSelectedPax(),
      timeLapsed: elapsedTime || 0,
      targetId: this.getTargetResource(request),
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'CheckinFailed',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoApiPassengersUpdateSuccessful(request: HttpRequest<any>, response: HttpResponse<any>, elapsedTime?: number) {
    const data = this.paxUpdateData();

    const keys = {
      ...data,
      timeLapsed: elapsedTime || 0,
      targetId: this.getTargetResource(request),
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'PassengersUpdateSuccessful',
      level: LogLevels.INFO,
      keys,
    });
  }

  paxUpdateData() {
    const paxData = {
      KTNUpdatedData: this.dataSource.ktnUpdated(),
      redressUpdatedData: this.dataSource.redressUpdated(),
      HMUpdatedData: this.dataSource.hmUpdated(),
    };

    return paxData;
  }

  infoApiAlertReasonCode(request: HttpRequest<any>, response: HttpResponse<any>, elapsedTime?: number) {
    const searchAlert = (alertType) => (key, value) => {
      return key === `alert${alertType}Code` && value.length > 0;
    };

    const alertReasonCode = deepSearch(searchAlert('Reason'), response.body);
    const alertMessageCode = deepSearch(searchAlert('Message'), response.body);

    const keys = {
      timeLapsed: elapsedTime || 0,
      alertReasonCode,
      alertMessageCode,
      targetId: this.getTargetResource(request),
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'CheckInEligibilityWithAlertReasonCode',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoApiPassengersUpdateFailed(request: HttpRequest<any>, response: HttpErrorResponse, elapsedTime?: number) {
    const data = this.paxUpdateData();

    const keys = {
      ...data,
      timeLapsed: elapsedTime || 0,
      targetId: this.getTargetResource(request),
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'PassengersUpdateFailed',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoApiRequestEnded(
    request: HttpRequest<any>,
    response: HttpResponse<any> | HttpErrorResponse,
    elapsedTime?: number
  ) {
    let payload = null;
    const isPaymentApiCall = this.isPaymentUrl(request.url);

    if ((response as HttpResponse<any>).body) {
      const body = (response as HttpResponse<any>).body;
      payload = isPaymentApiCall ? {} : Logging.encodePayload(body, this.restrictedProperties);
    }

    const keys = {
      endpoint: request.url,
      requestHeaders: isPaymentApiCall ? {} : request.headers.keys(),
      responseHeaders: isPaymentApiCall ? {} : response.headers.keys(),
      responseCode: response.status,
      method: request.method,
      timeLapsed: elapsedTime || 0,
      targetId: this.getTargetResource(request),
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'RequestEnded',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.USER_SESSION,
      keys: { ...keys, payload },
    });
  }

  infoApiRequestStarted(request: HttpRequest<any>) {
    const isPaymentApiCall = this.isPaymentUrl(request.url);
    const payload = isPaymentApiCall ? {} : Logging.encodePayload(request.body, this.restrictedProperties);
    const requestHeaders = isPaymentApiCall ? {} : request.headers.keys();
    const queryParamKeys = [...request.params.keys()];

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'RequestStarted',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.USER_SESSION,
      keys: {
        payload,
        endpoint: request.url,
        queryParamKeys,
        requestHeaders,
        method: request.method,
        targetId: this.getTargetResource(request),
      },
    });
  }

  isPaymentUrl(url: string) {
    const normalizedUrl = url.toLowerCase();
    return normalizedUrl.includes('payment');
  }

  errorApiTrackException(request: HttpRequest<any>, response: HttpErrorResponse, elapsedTime?: number) {
    const keys = {
      timeLapsed: elapsedTime || 0,
      errorCode: response.status,
      errorCategory: 'API Exception',
      method: request.method,
      // This will ONLY log the endpoint WITHOUT query params
      endpoint: request.url,
      // We need to truncate the data since it may contain PII
      errorMessage: response.message.replace(/(([^\s]+\s\s*){3})(.*)/, '$1…'),
      targetId: this.getTargetResource(request),
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'TrackAPIException',
      level: LogLevels.ERROR,
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiAppInitialized(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'ApplicationInitialized',
      moduleName: ModuleTypes.IDLE_SESSION,
      keys,
    });
  }

  infoUiCheckinCancelled(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };
    if (this.hasCheckinStarted) {
      this.hasCheckinStarted = false;

      this.logWithThisInfo({
        level: LogLevels.INFO,
        message: 'CheckinCancelled',
        moduleName: ModuleTypes.USER_SESSION,
        keys,
      });
    }
  }

  infoUiHomePageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HomePageDisplayed',
      moduleName: ModuleTypes.IDLE_SESSION,
      keys,
    });
  }

  infoUiLanguageChanged(languageSelected: string, timeLapsed?: number) {
    const keys = {
      language: this.dataSource.language(),
      languageSelected,
      timeLapsed,
      fromPage: this.dataSource.url(),
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'LanguageChanged',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiChangeLanguageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'ChangeLanguagePageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiChangeLanguagePageLanguageSelected(languageSelected: string, timeLapsed?: number) {
    const keys = {
      languageSelected,
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'ChangeLanguagePageLanguageSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiStartOverSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
      fromPage: this.dataSource.url(),
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'StartOverSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiChangeLanguageSelected(selectedLanguage: string, timeLapsed?: number) {
    const keys = {
      timeLapsed,
      selectedLanguage,
      language: this.dataSource.language(),
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'ChangeLanguageSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiChangeLangPageGoBack(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'ChangeLanguagePageGoBackSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiAlreadyCheckedInPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'AlreadyCheckedInPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiAlreadyCheckedInPageCheckBagsSelected(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'AlreadyCheckedInPageCheckBagsSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiCheckBagsSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'CheckBagsSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiExitSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
      currentPage: this.dataSource.url(),
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'ExitSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiAlreadyCheckedinPagePrintOnlySelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      moduleName: ModuleTypes.USER_SESSION,
      message: 'AlreadyCheckedInPagePrintOnlySelected',
      keys,
    });
  }

  infoUiAlreadyCheckedinPageModifySelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'AlreadyCheckedInPageModifyItinerarySelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiNextSegmentCheckinDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'NextSegmentCheckInPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiNextSegmentCheckInYesSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'NextSegmentCheckInYesSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiNextSegmentCheckInNoSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'NextSegmentCheckInNoSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiTimeOutPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
      currentPage: this.dataSource.url(),
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'TimeOutPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiTimeOutPageYesSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
      currentPage: this.dataSource.url(),
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'TimeOutPageYesSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiTimeOutPageNoSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
      currentPage: this.dataSource.url(),
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'TimeOutPageNoSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiTimeOutPageTimeOut(timeLapsed?: number) {
    const keys = {
      timeLapsed,
      currentPage: this.dataSource.url(),
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'TimeOutPageTimeOut',
      moduleName: ModuleTypes.IDLE_SESSION,
      keys,
    });
  }

  infoUiStartOverPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'StartOverPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiStartOverPageYesSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'StartOverPageYesSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiStartOverPageNoSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'StartOverPageNoSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiItineraryPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'ItineraryPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiItineraryPagePassengerSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'ItineraryPagePassengerSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiItineraryPagePassengerUnselected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'ItineraryPagePassengerUnselected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiItineraryPageAddHMKTNRedressSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'ItineraryPageAddHMKTNRedressSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiKTNRedressPaxSelectionPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HMKTNRedressPaxSelectionPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiHMKTNRedressPageDisplayed(
    hmPresent: boolean,
    ktnPresent: boolean,
    redressPresent: boolean,
    timeLapsed?: number
  ) {
    const keys = {
      timeLapsed,
      hmPresent,
      ktnPresent,
      redressPresent,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HMKTNRedressPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiHMKTNRedressPageContinueSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HMKTNRedressPageContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiHMKTNRedressInfoPageSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HMKTNRedressInfoPageSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiHMKTNRedressInfoPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HMKTNRedressInfoPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiKTNRedressPageKTNSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HMKTNRedressPageKTNSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiKTNRedressPageRedressSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HMKTNRedressPageRedressSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiKTNRedressPageHawaiianMilesSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HMKTNRedressPageHMSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiEnterKTNPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'EnterKTNPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiEnterKTNPageContinueSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'EnterKTNPageContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiEnterRedressPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'EnterRedressPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiEnterRedressPageContinueSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'EnterRedressPageContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiEnterHawaiianMilesPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'EnterHawaiianMilesPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiEnterHawaiianMilesPageContinueSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'EnterHawaiianMilesPageContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiHazMatPage1Displayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HazMatPage1Displayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiHazMatPage1ContinueSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HazMatPage1ContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiHazMatPage2Displayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HazMatPage2Displayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiHazMatPage2ContinueSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HazMatPage2ContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiHazMatPage3Displayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HazMatPage3Displayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiHazMatPage3ContinueSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HazMatPage3ContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiHazMatPage4Displayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HazMatPage4Displayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiHazMatPage4ContinueSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HazMatPage4ContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiPrintingDocsPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'PrintingDocsPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiConfirmationPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'ConfirmationPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiErrorPageDisplayed(alertReasonCode?: string, alertMessageCode?: string, timeLapsed?: number) {
    const keys = {
      timeLapsed,
      alertReasonCode,
      alertMessageCode,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'ErrorPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiInvalidTrip(timeLapsed?: number) {
    const alertReasonCodes = this.dataSource.getAlertReasonCodesForActiveSegment();

    const keys = {
      timeLapsed,
      alertReasonCodes,
    };

    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'InvalidTrip',
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoUiKioskUnavailablePageDisplayed(timeLapsed?: number) {
    const keys = { timeLapsed };
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'KioskUnavailablePageDisplayed',
      moduleName: ModuleTypes.IDLE_SESSION,
      keys,
    });
  }

  infoUiFlightNumberLookupPageDisplayed(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'FlightNumberLookupPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiFlightNumberLookupPageContinueSelected(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'FlightNumberLookupPageContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiFlightNumberLastNameLookupPageDisplayed(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'FlightNumberLastNameLookupPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiFlightNumberLastNameLookupPageStartOverSelected(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'FlightNumberLastNameLookupPageStartOverSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiFlightNumberLastNameLookupPageContinueSelected(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'FlightNumberLastNameLookupPageContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiFlightNumberDayOfBirthLookupPageDisplayed(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'FlightNumberDayOfBirthLookupPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiFlightNumberDayOfBirthLookupPageContinueSelected(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'FlightNumberDayOfBirthLookupPageContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiConfirmationCodeLookupPageDisplayed(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'ConfirmationCodeLookupPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiConfirmationCodeLookupPageContinueSelected(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'ConfirmationCodeLookupPageContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiHawaiianMilesLookupPageDisplayed(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HawaiianMilesLookupPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiHawaiianMilesLookupPageContinueSelected(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'HawaiianMilesLookupPageContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiEticketLookupPageDisplayed(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'EticketLookupPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiEticketLookupPageContinueSelected(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'EticketLookupPageContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiDestinationLookupPageDisplayed(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'DestinationLookupPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiDestinationLookupPageContinueSelected(selectedDestination: string, timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'DestinationLookupPageContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed, selectedDestination },
    });
  }

  infoUiAirportCodeLookupPageDisplayed(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'AirportCodeLookupPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiAirportCodeLookupPageContinueSelected(selectedDestination: string, timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'AirportCodeLookupPageContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { selectedDestination, timeLapsed },
    });
  }

  infoUiDestinationLastNameLookupPageDisplayed(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'DestinationLastNameLookupPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiDestinationLastNameLookupPageContinueSelected(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'DestinationLastNameLookupPageContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiDestinationDayOfBirthLookupPageDisplayed(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'DestinationDayOfBirthLookupPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiDestinationDayOfBirthLookupPageContinueSelected(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'DestinationDayOfBirthLookupPageContinueSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiPaymentSuccessful(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'PaymentSuccessful',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiPaymentFailed(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'PaymentFailed',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoSessionBoardingPassesToPrint(numOfBoardingPasses: number, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'NumberOfBoardingPassesToPrint',
      moduleName: ModuleTypes.USER_SESSION,
      data: numOfBoardingPasses,
      level: LogLevels.INFO,
    });
  }

  infoSessionPaymentReceiptDataRequested(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'PaymentReceiptDataRequested',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
    });
  }

  infoSessionBagTagDataRequested(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BagTagDataRequested',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
    });
  }

  infoSessionDocumentPrintingDataRequested(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'DocumentPrintingDataRequested',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
    });
  }

  infoSessionPaymentReceiptDataPrinted(receiptData?, timeLapsed?: number) {
    const encodedReceiptData = Logging.encodePayload(receiptData, this.restrictedProperties);
    this.logWithThisInfo({
      message: 'PaymentReceiptDataPrinted',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
      keys: { receiptData: encodedReceiptData },
    });
  }

  infoSessionBagTagDataPrinted(bagTagData?, timeLapsed?: number) {
    const encodedbagTagData = Logging.encodePayload(bagTagData, this.restrictedProperties);
    this.logWithThisInfo({
      message: 'BagTagDataPrinted',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
      keys: { bagTagData: encodedbagTagData },
    });
  }

  infoSessionDocumentPrintingDataPrinted(boardingPassData?, timeLapsed?: number) {
    const encodedboardingPassData = Logging.encodePayload(boardingPassData, this.restrictedProperties);
    this.logWithThisInfo({
      message: 'DocumentPrintingDataPrinted',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
      keys: { boardingPassData: encodedboardingPassData },
    });
  }

  infoPassportDataInvalid() {
    this.logWithThisInfo({
      message: 'InvalidPassportScanned',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
    });
  }

  infoHardwareATBPrinterEnabled(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'ATBPrinterEnabled',
      moduleName: ModuleTypes.IDLE_SESSION,
      level: LogLevels.INFO,
      keys: { timeLapsed },
    });
  }

  infoHardwareBarcodeEnabled(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BarcodeEnabled',
      moduleName: ModuleTypes.IDLE_SESSION,
      level: LogLevels.INFO,
      keys: { timeLapsed },
    });
  }

  infoHardwareBarcodeScanned(barcodeScan: string, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BarcodeScanned',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
      keys: { timeLapsed },
    });
  }

  errorHardwareBarcodeDamaged(barcodeData: [], timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BarcodeError',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.ERROR,
      keys: { timeLapsed },
    });
  }

  errorHardwareBarcodeError(barcodeData: [], timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BarcodeError',
      level: LogLevels.ERROR,
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoHardwarePassportScanned(passportScan: string, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'PassportScanned',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
      keys: { timeLapsed },
    });
  }

  errorHardwarePassportDamaged(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'PassportDamaged',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.ERROR,
      keys: { timeLapsed },
    });
  }

  infoHardwareCounterAssistPrinted(counterAssistData: string, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'CounterAssistTicketPrinted',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
      keys: { timeLapsed },
    });
  }

  infoHardwareATBPrinterTicketPrinted(boardingPassData: string, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'ATBPrinterTicketPrinted',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
      keys: { timeLapsed },
    });
  }

  errorHardwareATBPrinterTicketFailed(ticketData: string, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'ATBPrinterTicketFailed',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.ERROR,
      keys: { timeLapsed },
    });
  }

  errorHardwareATBPrinterPectabFailed(pectabData: string, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'ATBPrinterPectabFailed',
      moduleName: ModuleTypes.USER_SESSION,
      data: pectabData,
      level: LogLevels.ERROR,
      keys: { timeLapsed },
    });
  }

  errorHardwareATBPrinterError(printerErrorCode: number, isFatalError: boolean, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'ATBPrinterError',
      hardwareError: {
        code: printerErrorCode,
        FatalError: isFatalError,
      },
      level: LogLevels.ERROR,
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoHardwareBagTagPrinterEnabled(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BagTagPrinterEnabled',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
      keys: { timeLapsed },
    });
  }

  infoHardwareBagTagPrinterTicketPrinted(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BagTagPrinterTicketPrinted',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
      keys: { timeLapsed },
    });
  }

  errorHardwareBagTagPrinterTicketFailed(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BagTagPrinterTicketFailed',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.ERROR,
      keys: { timeLapsed },
    });
  }

  errorHardwareBagTagPrinterPectabFailed(pectabData: string, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BagTagPrinterPectabFailed',
      moduleName: ModuleTypes.USER_SESSION,
      data: pectabData,
      level: LogLevels.ERROR,
      keys: { timeLapsed },
    });
  }

  errorHardwareBagTagPrinterError(printerErrorCode: number, isFatalError: boolean, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BagTagPrinterError',
      moduleName: ModuleTypes.USER_SESSION,
      hardwareError: {
        code: printerErrorCode,
        FatalError: isFatalError,
      },
      level: LogLevels.ERROR,
      keys: { timeLapsed },
    });
  }

  errorHardwareCardReaderError(cardReaderErrorCode: number, isFatalError: boolean) {
    this.logWithThisInfo({
      message: 'CardReaderError',
      moduleName: ModuleTypes.USER_SESSION,
      hardwareError: {
        code: cardReaderErrorCode,
        FatalError: isFatalError,
      },
      level: LogLevels.ERROR,
    });
  }

  infoHardwareCardReaderSwipe() {
    this.logWithThisInfo({
      message: 'CardReaderSwipe',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
    });
  }

  infoHardwareBadCardSwipe(swipeCount: number) {
    this.logWithThisInfo({
      message: 'BadCardSwiped',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
    });
  }

  infoHardwareBagSwipePayment() {
    this.logWithThisInfo({
      message: 'BagSwipePayment',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
    });
  }

  infoHardwareCreditCardDippedPaymentSuccessful() {
    this.logWithThisInfo({
      message: 'CreditCardDippedPaymentSuccessful',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
    });
  }

  infoHardwareCreditCardDippedPaymentFailed(errorMessage, attemptNumber: number) {
    this.logWithThisInfo({
      message: 'CreditCardDippedPaymentFailed',
      moduleName: ModuleTypes.USER_SESSION,
      hardwareError: errorMessage,
      attempt: attemptNumber,
      level: LogLevels.INFO,
    });
  }

  infoApiPaymentTokenizationSuccessful() {
    this.logWithThisInfo({
      message: 'PaymentTokenizationSuccessful',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
    });
  }

  infoApiPaymentTokenizationFailed(attemptNumber: number) {
    this.logWithThisInfo({
      message: 'PaymentTokenizationFailed',
      moduleName: ModuleTypes.USER_SESSION,
      attempt: attemptNumber,
      level: LogLevels.INFO,
    });
  }

  infoApiCheckoutSuccessful(timeLapsed?: number) {
    const allPax = this.dataSource.allPaxInCart();
    let passengerEntries = [];
    let numberOfFreeBags = 0;
    let numberOfPaidBags = 0;

    allPax.forEach((pax) => {
      const items = this.dataSource.itemsInCartByPassenger(pax);
      passengerEntries.push({
        paxId: pax,
        costPerPax: items.reduce((sum, item) => sum + +item.price, 0),
        products: items.map((product) => {
          +product.price === 0 ? (numberOfFreeBags += product.quantity) : (numberOfPaidBags += product.quantity);
          return {
            subcode: product.productId,
            quantity: product.quantity,
            cost: product.price,
            adjustment: product.adjustments,
          };
        }),
      });
    });

    this.dataSource.onLoadedTrip(() => {
      const keys = {
        timeLapsed,
        totalCharge: this.dataSource.getCartGrandTotal(),
        passengerEntries,
        numberOfPaidBags,
        numberOfFreeBags,
      };

      this.logWithThisInfo({
        message: 'CheckoutSuccessful',
        moduleName: ModuleTypes.USER_SESSION,
        level: LogLevels.INFO,
        keys,
      });
    });
  }

  infoApiCheckoutFailed(errorMessage) {
    this.logWithThisInfo({
      message: 'CheckoutFailed',
      moduleName: ModuleTypes.USER_SESSION,
      error: errorMessage,
      level: LogLevels.INFO,
    });
  }

  infoApiGetPaymentHeader() {
    this.logWithThisInfo({
      message: 'GetPaymentHeader',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
    });
  }

  infoUiNoBagsToCheckSelected(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'NoBagsToCheckSelected',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
      keys: {
        timeLapsed,
      },
    });
  }

  infoUiPaymentDueModifyBaggageSelected(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'PaymentDueModifyBaggageSelected',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
      keys: {
        timeLapsed,
      },
    });
  }

  infoUiSpecialItemsPageSelected(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'SpecialItemsPageSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiSpecialItemsPageDisplayed(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'SpecialItemsPageDisplayed',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiAccessibilityModeEntered(timeLapsed?: number, fromPage?: string) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'AccessibilityModeEntered',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed, fromPage },
    });
  }

  infoUiPrintDocumentFailed(
    timeLapsed?: number,
    documentType?: string,
    alertMessageCode?: string,
    alertReasonCode?: string
  ) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'PrintDocumentFailed',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed, documentType, alertMessageCode, alertReasonCode },
    });
  }

  infoApiBagItemMissingMaxQuantity(products) {
    this.logWithThisInfo({
      message: 'BagItemMissingMaxQuantity',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.USER_SESSION,
      keys: {
        productInfo: products.map((product) => ({
          subcode: product.subcode,
          commercialName: product.commercialName,
        })),
      },
    });
  }

  infoApiBagItemMaxQuantityZero(products) {
    this.logWithThisInfo({
      message: 'BagItemMaxQuantityZero',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.USER_SESSION,
      keys: {
        productInfo: products.map((product) => ({
          subcode: product.subcode,
          commercialName: product.commercialName,
        })),
      },
    });
  }

  logException(exception: any) {
    // This will be done as part of followup logging issues ticket
    this.logWithThisInfo({
      message: 'UnhandledException',
      level: LogLevels.ERROR,
      keys: {
        error: exception && exception.message ? exception.message : 'No error message present',
        stackTrace: exception && exception.stackTrace ? exception.stackTrace : 'No stack trace present',
        url: exception && exception.url ? exception.url : 'No url present',
      },
    });
  }

  infoBagLabelPending(specialItems: Product[]) {
    this.logWithThisInfo({
      message: 'BagLabelPending',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
      keys: {
        bagLabelPending: specialItems.map((specialItem: Product) => ({
          commercialName: specialItem.commercialName,
          marketedBy: this.dataSource.getMarketedBy(),
          subcode: specialItem.productId,
        })),
      },
    });
  }

  infoUiMilitaryPersonnelBagsOptionSelected(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'MilitaryPersonnelBagsOptionSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoUiMilitaryNoticePageGoBackSelected(timeLapsed?: number) {
    this.logWithThisInfo({
      level: LogLevels.INFO,
      message: 'MilitaryNoticePageGoBackSelected',
      moduleName: ModuleTypes.USER_SESSION,
      keys: { timeLapsed },
    });
  }

  infoApiMilitaryInfoMatchSuccessful(elapsedTime?: number) {
    const allPax = this.dataSource.allPaxInTrip();
    let passengerEntries = [];

    allPax.forEach((pax) => {
      passengerEntries.push({
        paxId: pax.id,
        totalAllowances: this.dataSource.totalAllowances(pax.id),
        militaryType: this.dataSource.getMilitaryType(pax.id),
      });
    });

    const keys = {
      timeLapsed: elapsedTime || 0,
      totalAllowances: this.dataSource.totalAllowances(),
      passengerEntries,
    };

    this.logWithThisInfo({
      message: 'MilitaryInfoMatchSuccessful',
      moduleName: ModuleTypes.USER_SESSION,
      level: LogLevels.INFO,
      keys,
    });
  }

  infoApiMilitaryInfoMatchUnsuccessful(elapsedTime?: number) {
    const allPax = this.dataSource.allPaxInTrip();
    let passengerEntries = [];

    allPax.forEach((pax) => {
      passengerEntries.push({
        paxId: pax.id,
        totalAllowances: this.dataSource.totalAllowances(pax.id),
        militaryType: this.dataSource.getMilitaryType(pax.id),
      });
    });

    const keys = {
      timeLapsed: elapsedTime || 0,
      totalAllowances: this.dataSource.totalAllowances(),
      passengerEntries,
    };

    this.logWithThisInfo({
      message: 'MilitaryInfoMatchUnsuccessful',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.USER_SESSION,
      keys,
    });
  }

  infoApiConfigResponse(environment: object) {
    const keys = {
      config: environment,
    };

    this.logWithThisInfo({
      message: 'ConfigResponse',
      level: LogLevels.INFO,
      moduleName: ModuleTypes.IDLE_SESSION,
      keys,
    });
  }
}
