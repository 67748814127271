import { Action } from '@ngrx/store';

export enum CussAccessibilityActionTypes {
  HeadsetInserted = '[CUSS Accessibility] Headset Inserted',
  HeadsetRemoved = '[CUSS Accessibility] Headset Removed',
  NavNext = '[CUSS Accessibility] Trackpad NavNext',
  NavPrevious = '[CUSS Accessibility] Trackpad NavPrevious',
  NavEnter = '[CUSS Accessibility] TrackPad NavEnter',
}

export type CussAccessibilityActions = HeadsetInserted | HeadsetRemoved;

export class HeadsetInserted implements Action {
  readonly type = CussAccessibilityActionTypes.HeadsetInserted;
  constructor(readonly payload?: string) {}
}

export class HeadsetRemoved implements Action {
  readonly type = CussAccessibilityActionTypes.HeadsetRemoved;
  constructor(readonly payload?: string) {}
}

export class NavNext implements Action {
  readonly type = CussAccessibilityActionTypes.NavNext;
  constructor(readonly payload?: string) {}
}

export class NavPrevious implements Action {
  readonly type = CussAccessibilityActionTypes.NavPrevious;
  constructor(readonly payload?: string) {}
}

export class NavEnter implements Action {
  readonly type = CussAccessibilityActionTypes.NavEnter;
  constructor(readonly payload?: string) {}
}
