import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  AddBag,
  AppState,
  Cart,
  Passenger,
  RemoveBag,
  SegmentDetail,
  selectActiveSegmentDetails,
  selectCart,
  selectCurrentTrip,
  Trip,
} from '~app/state';
import { Observable, Subject } from 'rxjs';
import { Product } from '~app/state/trip/passenger.catalog.model';
import { takeUntil } from 'rxjs/operators';
import { GridComponent } from '~app/shared/grid/grid.component';
import { TranslateService } from '@ngx-translate/core';
import { specialItemsCatalog } from '~app/services/emitters/session-event-emitters';
import { Logging } from '~app/services/logging/logging.service';
import { ConfigService } from '~app/services/api/config/config.service';

const specialItemsConfigContent: SpecialItemContent[] = require('../../../../../../data/media/images/content/special-items.json');

interface SpecialItemContent {
  id: string;
  label: string;
  icon: string;
}
@Component({
  selector: 'app-special-items-details',
  templateUrl: './special-items-details.component.html',
  styleUrls: ['./special-items-details.component.scss'],
})
export class SpecialItemsDetailsComponent implements OnInit, OnDestroy {
  private trip: Trip;
  @Output() gridScrolled = new EventEmitter<any>();
  @Input() isOA: boolean;

  @ViewChild(GridComponent, { static: false }) gridElement: GridComponent;

  private cart$: Observable<Cart>;
  public trip$: Observable<Trip>;

  private segmentsDetail$: Observable<SegmentDetail[]>;
  private segmentsDetail: SegmentDetail[];
  private paxSegment: SegmentDetail;

  private unsubscribe$ = new Subject<void>();

  public totalRegularBagsPrice;
  public totalRegularBagsItems;

  public totalSpecialItemsPrice;
  public totalSpecialItemsItems;

  public additionalTotalItems;
  public grandTotalForBags;

  private defaultIcon = 'assets/icons/icon-default-bag-item.png';
  private translationLabelPrefix = 'specialItems.specialItems.';

  private specialItemsNoLabel: Product[];

  specialItemsCatalog: any[] = [];
  selectedPax: Passenger;
  cart: Cart;

  private localStringConfig = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  private isCarSeatStrollerRestrictionsActive = false;

  private carSeatStrollerRestrictions = {
    '0F4': {
      limit: 1,
      dependsOn: '0G5',
    },
    '0G5': {
      limit: 1,
      dependsOn: '0F4',
    },
  };

  private configCustomLimits = {};
  private fruitProductID = '0IO';

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
    private loggingService: Logging,
    private configService: ConfigService
  ) {}

  ngOnInit() {
    this.configService.config.subscribe((configuration) => {
      if (!configuration || !configuration.configuration) {
        return;
      }

      this.isCarSeatStrollerRestrictionsActive = configuration.configuration.restrictCarseatStroller;
      this.configCustomLimits[this.fruitProductID] = configuration.configuration.maxQuantityFruit;
    });

    this.cart$ = this.store.pipe(select(selectCart), takeUntil(this.unsubscribe$));

    this.cart$.subscribe((cart) => {
      this.cart = cart;
      if (this.cart) {
        this.selectedPax = this.cart.paxSelectedForSpecialItems;
      }

      if (this.selectedPax) {
        this.specialItemsCatalog = this.sortCatalogPerConfigContentFile();
        this.updateValues();
      }
    });

    // We need to store the special items labels outside of the state for use when we go get documents to print
    specialItemsCatalog.next(this.specialItemsCatalog);

    this.segmentsDetail$ = this.store.pipe(select(selectActiveSegmentDetails), takeUntil(this.unsubscribe$));

    this.segmentsDetail$.subscribe((segmentsDetail) => {
      this.segmentsDetail = segmentsDetail;
      this.paxSegment = this.segmentsDetail.find((segmentDetail) => segmentDetail.passengerId === this.selectedPax.id);
    });

    this.trip$ = this.store.pipe(select(selectCurrentTrip));

    this.trip$.pipe(takeUntil(this.unsubscribe$)).subscribe((trip: Trip) => {
      this.trip = trip;
      this.updateValues();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onGridScrolled() {
    this.gridScrolled.emit();
  }

  private updateValues() {
    if (!this.trip || !this.trip.activeSegment) {
      return;
    }

    this.totalRegularBagsPrice = this.cart.getRegularBagsTotalPrice().toLocaleString('en', this.localStringConfig);
    this.totalRegularBagsItems = this.trip.activeSegment.getNumRegularBags();

    this.totalSpecialItemsPrice = this.cart.getSpecialItemsTotalPrice().toLocaleString('en', this.localStringConfig);
    this.totalSpecialItemsItems = this.trip.activeSegment.getNumSpecialItems();

    this.additionalTotalItems = this.trip.activeSegment.getNumBags();
    this.grandTotalForBags = this.cart.getGrandTotalPriceForBagsAndSpecialItems();
  }

  private sortCatalogPerConfigContentFile() {
    let paxSpecialItemCatalog;
    if (this.isOA) {
      paxSpecialItemCatalog = Array.from(this.selectedPax.getOABagsCatalog());
    } else {
      paxSpecialItemCatalog = Array.from(this.selectedPax.getSpecialBagsCatalog());
    }
    const sortedPaxSpecialItemsCatalog = [];
    this.specialItemsNoLabel = [];
    if (!this.isOA) {
      specialItemsConfigContent.forEach((specialItemContent: SpecialItemContent) => {
        const productIndex = paxSpecialItemCatalog.findIndex((product) => {
          return product.productId === specialItemContent.id;
        });

        let product = paxSpecialItemCatalog[productIndex];

        if (!product) {
          return;
        }

        product = this.setProductCustomLabels(product);
        product.icon = specialItemContent.icon;

        sortedPaxSpecialItemsCatalog.push(product);

        // removes the product from array so we can later use it to set default icons for the
        // products we didn't have a match
        paxSpecialItemCatalog.splice(productIndex, 1);
      });
    }

    // sets default icon / label for products we couldn't find a match
    paxSpecialItemCatalog.forEach((product) => {
      product = this.setProductDefaultLabelAndIcon(product);
    });
    if (this.specialItemsNoLabel.length > 0) {
      this.loggingService.infoBagLabelPending(this.specialItemsNoLabel);
    }

    // concat our sorted array (with custom icon / label) with the one we couldn't
    // have a match (with default icon / label)
    return sortedPaxSpecialItemsCatalog.concat(paxSpecialItemCatalog);
  }

  private setProductCustomLabels(product: Product): Product {
    const productId = product.productId;
    const translationProductPrefix = this.translationLabelPrefix + productId;

    product.productLabel = this.translate.instant(translationProductPrefix + '.label');

    if (product.attributes && product.attributes.length > 0) {
      const weightLimitTranslation = this.translate.instant(translationProductPrefix + '.weightLimit');
      const sizeLimitTranslation = this.translate.instant(translationProductPrefix + '.sizeLimit');

      product.weightLimitLabel = weightLimitTranslation;
      product.sizeLimitLabel = sizeLimitTranslation;
    }

    return product;
  }

  private setProductDefaultLabelAndIcon(product: Product): Product {
    product.icon = this.defaultIcon;
    product.productLabel = product.commercialName;

    if (product.attributes && product.attributes.length > 0) {
      product.weightLimitLabel = null;
      product.sizeLimitLabel = null;
      this.specialItemsNoLabel.push(product);
    }

    return product;
  }

  getNumberOfSpecialBagsForItem(item: Product): number {
    return this.paxSegment.getNumberOfSpecialBagsForItem(item);
  }

  decreaseItemCount(item: Product) {
    if (this.isMinimumValueOfSpecialItems(item, this.paxSegment)) {
      return;
    }

    this.paxSegment.removeSpecialItem(item);

    this.store.dispatch(
      new RemoveBag({
        productId: item.productId,
        passengerId: this.selectedPax.id,
        segmentId: this.paxSegment.segmentId,
      })
    );
  }

  increaseItemCount(item: Product) {
    if (this.isMaximumValueOfSpecialItems(item, this.paxSegment)) {
      return;
    }

    this.paxSegment.addSpecialItem(item);

    this.store.dispatch(
      new AddBag({
        productId: item.productId,
        passengerId: this.selectedPax.id,
        segmentId: this.paxSegment.segmentId,
      })
    );

    return item;
  }

  isMinimumValueOfSpecialItems(item: Product, segmentDetail: SegmentDetail) {
    const allSpecialItems = segmentDetail.getNumberOfSpecialBagsForItem(item);
    const minimumPossible = segmentDetail.getPaidSpecialItemsCountForItem(item);

    return allSpecialItems <= minimumPossible;
  }

  isMaximumValueProductCustomLimit(previousProductId = null, productId, segmentDetail) {
    const restrictions = this.carSeatStrollerRestrictions[productId];

    if (!restrictions) {
      return false;
    }

    let dependencyLimit = false;
    // restrictions.dependsOn !== previousProductId to avoid circular dependency
    if (restrictions.dependsOn && restrictions.dependsOn !== previousProductId) {
      dependencyLimit = this.isMaximumValueProductCustomLimit(productId, restrictions.dependsOn, segmentDetail);
    }

    const product = this.selectedPax.getSpecialBagsCatalog().find((product) => {
      return product.productId === productId;
    });

    return segmentDetail.getNumberOfSpecialBagsForItem(product) >= restrictions.limit || dependencyLimit;
  }

  isMaximumValueOfSpecialItems(item: Product, segmentDetail: SegmentDetail) {
    let isRestrictionLimit = false;
    if (this.isCarSeatStrollerRestrictionsActive) {
      isRestrictionLimit = this.isMaximumValueProductCustomLimit(null, item.productId, segmentDetail);
    }

    if (isRestrictionLimit) {
      return true;
    }

    const productConfigCustomLimit = this.configCustomLimits[item.productId];

    if (productConfigCustomLimit) {
      return segmentDetail.getNumberOfSpecialBagsForItem(item) >= productConfigCustomLimit;
    }

    if (!item.maxQuantity) {
      return false;
    }

    return segmentDetail.getNumberOfSpecialBagsForItem(item) >= item.maxQuantity;
  }
}
