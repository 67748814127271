import { Action } from '@ngrx/store';
import { FlightStatus } from './flight-status.model';

export enum FlightStatusActionTypes {
  FlightStatusRequested = '[FlightStatus] FlightStatus requested',
  FlightStatusLoaded = '[FlightStatus] FlightStatus loaded',
}

export class FlightStatusRequested implements Action {
  readonly type = FlightStatusActionTypes.FlightStatusRequested;
  constructor(public payload: { flightId: string }) {}
}

export class FlightStatusLoaded implements Action {
  readonly type = FlightStatusActionTypes.FlightStatusLoaded;
  constructor(public payload: { flightStatus: FlightStatus }) {}
}

export type FlightStatusActions = FlightStatusRequested | FlightStatusLoaded;
