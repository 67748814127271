export interface MachineConfig {
  airportCode: string;
  serviceHours: string;
  location: string;
  inService: boolean;
  agentAssist: boolean;
  environment: Environment;
  configuration: Configuration;
  timeout: Timeout;
  featureFlags: object | null;
  bagTag: BagTag;
  bagTagType: BagTagTypes;
}

export interface Configuration {
  loggingLevel: string;
  seatPreference: boolean;
  standbyUpgrade: boolean;
  enableInfants: boolean;
  enableInternational: boolean;
  enableBags: boolean;
  enableSpecialItems: boolean;
  enableSeats: boolean;
  enableReceipts: boolean;
  enableBagTags: boolean;
  maxRegularBagsSupported: number;
  restrictedLogProperties: string[];
}

export interface Timeout {
  singlePaxSec: number;
  multiPaxSec: number;
  noTimeoutSec: number;
  hazmatSec: number;
  moreSegmentsCheckinSec: number;
  errorSec: number;
  printDocumentsSec: number;
  scanHawaiianMilesSec: number;
  splashScreenSec: number;
  timeoutPopupSec: number;
  confirmationSec: number;
  menuSec: number;
}

export interface BagTag {
  brand: string;
  size: string;
  orientation: string;
}

export interface LauncherResponse {
  status: string;
  'launch-url': string;
  version: string;
  errorMessage: string;
  type?: string;
}

export interface Environment {
  _id: string;
  name: string;
  host: string;
  version: string;
}

export enum BagTagTypes {
  LINED = 'lined',
  LINERLESS = 'linerless',
}
