import { ATBPrinterActionTypes } from './atb-printer.actions';

export interface ATBPrinterState {
  deviceName: string | null;
  deviceMode: string | null;
  deviceLogs: string[];
  pecTabLoadingComplete: string;
  ticketPrintingComplete: string;
  ticketFailed: string;
  pectabFailed: string;
  pectabLoaded: string;
  ticketPrinted: string;
  atbPrinterStatusIsOK: string;
  atbPrinterStatusChange: [number, boolean];
}

export const initialState: ATBPrinterState = {
  deviceName: '',
  deviceMode: '',
  deviceLogs: [],
  pecTabLoadingComplete: '',
  ticketPrintingComplete: '',
  ticketFailed: '',
  pectabFailed: '',
  pectabLoaded: '',
  ticketPrinted: '',
  atbPrinterStatusIsOK: '',
  atbPrinterStatusChange: [-1, false],
};

export function atbPrinterReducers(state: ATBPrinterState = initialState, action): ATBPrinterState {
  let callbackLogs = '';
  // Different action reducer processes
  switch (action.type) {
    case ATBPrinterActionTypes.ATBPrinterEnable: {
      callbackLogs = 'Event: atb-enable';
      state.deviceName = 'atb';
      state.deviceMode = action.payload;
      state.deviceLogs = [callbackLogs];
      state.pecTabLoadingComplete = '';
      state.ticketPrintingComplete = '';
      state.ticketFailed = '';
      state.pectabFailed = '';
      state.pectabLoaded = '';
      state.ticketPrinted = '';
      state.atbPrinterStatusIsOK = '';
      state.atbPrinterStatusChange = [-1, false];
      return { ...state };
    }
    case ATBPrinterActionTypes.ATBPrinterDisable: {
      callbackLogs = 'Event: atb-disable';
      state.deviceName = 'atb';
      state.deviceMode = action.payload;
      state.deviceLogs = [callbackLogs];
      state.pecTabLoadingComplete = '';
      state.ticketPrintingComplete = '';
      state.ticketFailed = '';
      state.pectabFailed = '';
      state.pectabLoaded = '';
      state.ticketPrinted = '';
      state.atbPrinterStatusIsOK = '';
      state.atbPrinterStatusChange = [-1, false];
      return { ...state };
    }
    case ATBPrinterActionTypes.PECTabLoadingComplete: {
      callbackLogs = 'Event: pectabLoadingComplete';
      state.deviceLogs = [callbackLogs];
      state.pecTabLoadingComplete = action.payload.pecTabLoadingComplete;
      return { ...state };
    }
    case ATBPrinterActionTypes.TicketPrintingComplete: {
      callbackLogs = 'Event: ticketPrintingComplete';
      state.deviceLogs = [callbackLogs];
      state.ticketPrintingComplete = action.payload.ticketPrintingComplete;
      return { ...state };
    }
    case ATBPrinterActionTypes.TicketFailed: {
      callbackLogs = 'Event: ticketFailed';
      state.deviceLogs = [callbackLogs];
      state.ticketFailed = action.payload.ticketFailed;
      return { ...state };
    }
    case ATBPrinterActionTypes.PectabFailed: {
      callbackLogs = 'Event: pectabFailed';
      state.deviceLogs = [callbackLogs];
      state.pectabFailed = action.payload.pectabFailed;
      return { ...state };
    }
    case ATBPrinterActionTypes.PectabLoaded: {
      callbackLogs = 'Event: pectabLoaded';
      state.deviceLogs = [callbackLogs];
      if (state.pectabLoaded != null && state.pectabLoaded !== '') {
        state.pectabLoaded = state.pectabLoaded + '|' + action.payload.pectabLoaded;
      } else {
        state.pectabLoaded = action.payload.pectabLoaded;
      }
      return { ...state };
    }
    case ATBPrinterActionTypes.TicketPrinted: {
      callbackLogs = 'Event: ticketPrinted';
      state.deviceLogs = [callbackLogs];
      state.ticketPrinted = action.payload.ticketPrinted;
      return { ...state };
    }
    case ATBPrinterActionTypes.ATBPrinterStatusIsOK: {
      callbackLogs = 'Event: statusIsOk';
      state.deviceLogs = [callbackLogs];
      state.atbPrinterStatusIsOK = action.payload.atbPrinterStatusIsOK;
      return { ...state };
    }
    case ATBPrinterActionTypes.ATBPrinterStatusChange: {
      callbackLogs = 'Event: statusChange';
      state.deviceLogs = [callbackLogs];
      state.atbPrinterStatusChange = action.payload.atbPrinterStatusChange;
      return { ...state };
    }
    default:
      callbackLogs = 'default: ' + JSON.stringify(action.payload);
      state.deviceLogs = [callbackLogs];
      return state;
  }
}
