import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { ErrorModel } from '../../models/error/error.model';
import { ErrorState } from './../../state/error/error.reducer';
import { SetTimeoutError } from '../../state/error/error.action';
import { HeaderKeys } from './api.config';
import * as dateFns from 'date-fns';
import { format } from 'date-fns';
import { AppState } from '~app/state';
import { EnvironmentService } from '../environment/environment.service';
import { airportCode } from '../emitters/session-event-emitters';

@Injectable({ providedIn: 'root' })
export class API {
  readonly baseUrl = environment.api.baseUrl;
  readonly basePath = environment.api.basePath.default;
  readonly configBasePath = environment.api.basePath.config;
  readonly apiPaths = {
    tripauths: 'tripauths',
    trips: 'trips',
    flights: {
      defaultValue: 'flights',
      status: 'status',
    },
    document: {
      root: 'documents',
      boardingPass: 'boardingPass',
      counterAssist: 'counterAssist',
      receipt: 'receipt',
      bagTag: 'bagTag',
    },
    carts: 'carts',
    catalogs: 'catalogs',
    config: 'machine-config',
    launcher: 'launcher',
    payments: 'payments',
    bags: 'bags',
  };
  readonly apiQParams = {
    config: {
      machineId: {
        key: 'machineId',
      },
    },
    trips: {
      details: {
        key: 'details',
        value: 'true',
      },
      confirmationCode: {
        key: 'confirmationCode',
      },
      eTicketNumber: {
        key: 'ticketNumber',
      },
      hawaiianMilesNumber: {
        key: 'hawaiianMilesNumber',
      },
      origin: {
        key: 'origin',
      },
      startDate: {
        key: 'startDate',
      },
      endDate: {
        key: 'endDate',
      },
      flightNumber: {
        key: 'flightNumber',
      },
      destination: {
        key: 'destination',
      },
      firstName: {
        key: 'firstName',
      },
      lastName: {
        key: 'lastName',
      },
      dateOfBirth: {
        key: 'dob',
      },
      include: {
        key: 'include',
        value: {
          flights: 'flights',
          status: 'status',
          passengers: 'passengers',
        },
      },
      segmentId: {
        key: 'segmentId',
      },
      passengerIds: {
        key: 'passengerIds',
      },
    },
    carts: {
      cartId: {
        key: 'cartId',
      },
      bagsSource: {
        key: 'bagsSource',
      },
    },
    catalogs: {
      bagsSource: {
        key: 'bagsSource',
      },
    },
  };
  headers: HttpHeaders;
  queryParams: HttpParams;
  error$: Observable<ErrorModel>;

  constructor(
    private http: HttpClient,
    public store: Store<AppState>,
    private errorStore: Store<ErrorState>,
    private environmentService: EnvironmentService
  ) {
    this.headers = new HttpHeaders();
    this.queryParams = new HttpParams();
    this.appendDefaultHeaders();
    this.error$ = errorStore.pipe(select('error'));
  }

  appendDefaultHeaders() {
    const { xHaChannel, xHaBusinessDomain, contentType } = environment.api.headers;
    this.headers = this.headers.append(xHaChannel.key, xHaChannel.value);
    this.headers = this.headers.append(xHaBusinessDomain.key, xHaBusinessDomain.value);
    this.headers = this.headers.append(contentType.key, contentType.value);
  }

  appendQueryParams(key: string, value: string) {
    const qpValue = this.getQueryParam(key);
    if (qpValue === null && value != null && value.length > 0) {
      this.queryParams = this.queryParams.set(key, value);
    }
  }

  clearQueryParams() {
    this.getQueryParamsCount().forEach((qp) => {
      this.queryParams = this.queryParams.delete(qp);
    });
  }

  getQueryParam(key: string) {
    return this.queryParams.get(key);
  }

  getQueryParamsCount() {
    return this.queryParams.keys();
  }

  getUrlToTrip() {
    return `${this.baseUrl}/${this.basePath}/${this.apiPaths.trips}`;
  }

  getUrlToCart() {
    return `${this.baseUrl}/${this.basePath}/${this.apiPaths.carts}`;
  }

  clearHeaders() {
    const headers = this.getHeaderCount();
    if (!headers) {
      return;
    }

    this.getHeaderCount().forEach((h) => {
      this.headers = this.headers.delete(h);
    });

    this.appendDefaultHeaders();
  }

  private getHeaderCount() {
    return this.headers.keys();
  }

  async doGetCallTripAuth() {
    const concatenedUrl = `${this.baseUrl}/${this.basePath}/${this.apiPaths.tripauths}`;
    return this.doGetCall(concatenedUrl);
  }

  async doConfigCall() {
    const concatenedUrl = `${this.baseUrl}/${this.configBasePath}/${this.apiPaths.config}`;
    const authToken = environment.api.headers.Authorization.value;
    this.headers = this.headers.delete(HeaderKeys.Authorization);
    this.headers = this.headers.append(HeaderKeys.Authorization, authToken);
    const timeStamp = dateFns.format(new Date(), 'yyyy-MM-ddTHH:mm:ss.SSSZ');
    const correlationId = `config-exp-cx-config-admin-${timeStamp}`;
    this.headers = this.headers.set(HeaderKeys.XRootCorrelationId, correlationId);
    return this.doGetCall(concatenedUrl);
  }

  async doVersionCall() {
    const concatenedUrl = `${this.baseUrl}/${this.configBasePath}/${this.apiPaths.launcher}`;
    const authToken = environment.api.headers.Authorization.value;
    this.headers = this.headers.delete(HeaderKeys.Authorization);
    this.headers = this.headers.append(HeaderKeys.Authorization, authToken);
    const timeStamp = dateFns.format(new Date(), 'yyyy-MM-ddTHH:mm:ss.SSSZ');
    const correlationId = `config-exp-cx-config-admin-${timeStamp}`;
    this.headers = this.headers.set(HeaderKeys.XRootCorrelationId, correlationId);
    return this.doGetCall(concatenedUrl);
  }

  async doGetCallTrips() {
    return this.doGetCall(this.getUrlToTrip());
  }

  async doGetCall(url) {
    const result = await this.doRequest(this.http.get, { url }).toPromise();
    return result;
  }

  async doPostCartCall(url: string, data: object | Array<object>) {
    const result = await this.doRequest(this.http.post, {
      url,
      bodyData: data,
    }).toPromise();
    return result;
  }

  async doMockCall(url: string) {
    const result = await this.http.get(`/assets/mocks/${url}.json`).toPromise();
    return result;
  }

  async doPatchCall(url: string, data: object | Array<object>) {
    const result = await this.doRequest(this.http.patch, {
      url,
      bodyData: data,
    }).toPromise();
    return result;
  }

  async doPostCall(url: string, data: object | Array<object>, isTokenizer?: boolean | false) {
    const result = await this.doRequest(this.http.post, { url, bodyData: data }, isTokenizer).toPromise();
    return result;
  }

  async doDeleteCall(url: string, data: object | Array<object>) {
    const result = await this.doRequest(this.http.delete, { url }).toPromise();
    return result;
  }

  async doPutCall(url: string, data: object | Array<object>) {
    const result = await this.doRequest(this.http.put, {
      url,
      bodyData: data,
    }).toPromise();
    return result;
  }

  /**
   * Calls and returns http response call by setting its http verb method and additional data to be sent
   * @param httpFn - The http verb function to be used
   * @param httpData -A custom object which has the following attributes: url<string>, bodyData<any>.
   * @param isTokenizer - An optional argument for the tokenizing endpoint
   * These attributes will be set on the http verb method invocation
   */
  private doRequest(httpFn, httpData: { url: string; bodyData?: any }, isTokenizer?: boolean | false) {
    if (!isTokenizer) {
      this.headers = this.headers.set(
        environment.api.headers.xRootSessionId.key,
        window.sessionStorage.getItem('SESSIONID')
      );
    } else {
      const now = format(new Date(), 'YYYYMMDDHHMMSS');
      this.headers = this.headers.set('sessionId', now);
    }
    const correlationId = Math.ceil(Math.random() * 100000) + '';
    const { url } = httpData;
    const httpOptions: any = {
      headers: this.headers,
      observe: 'response',
    };

    const requestTime = new Date() as any;

    if (this.queryParams.keys().length > 0) {
      httpOptions.params = this.queryParams;
    }

    let observable;
    if (httpData.bodyData) {
      observable = httpFn.call(this.http, url, httpData.bodyData, httpOptions);
    } else {
      observable = httpFn.call(this.http, url, httpOptions);
    }

    return observable.pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((err) => {
        if (/\/passengers/.test(url)) {
          return of(err);
        } else if (/machine-config/.test(url)) {
          return [require('../../../assets/config.json')];
        } else if (/\/checkout/.test(url)) {
          return of(err);
        } else if (/\/bags/.test(url)) {
          return of(err);
        } else {
          return of(new SetTimeoutError());
        }
      })
    );
  }

  addDefaultHeaders(token?) {
    this.clearQueryParams();
    const tripQPs = this.apiQParams.trips;
    const includeQPValues = tripQPs.include.value;
    let authToken = environment.api.headers.Authorization.value;

    if (token) {
      authToken = token;
    }

    this.appendQueryParams(tripQPs.details.key, tripQPs.details.value);
    this.appendQueryParams(
      tripQPs.include.key,
      `${includeQPValues.flights},${includeQPValues.passengers},${includeQPValues.status}`
    );
    this.headers = this.headers.delete(HeaderKeys.Authorization);
    this.headers = this.headers.append(HeaderKeys.Authorization, authToken);
  }

  addDateQueryParams() {
    const queryParams = this.apiQParams.trips;
    this.appendQueryParams(queryParams.startDate.key, dateFns.format(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ'));
    this.appendQueryParams(
      queryParams.endDate.key,
      dateFns.format(new Date(new Date().getTime() + 24 * 60 * 60 * 1000), 'YYYY-MM-DDTHH:mm:ss.SSSZ')
    );
  }

  shouldIncludeDateQueryParams(key) {
    const needAtLeastOneOfKeys = ['flightNumber', 'destination', 'hawaiianMilesNumber', 'firstName'];
    return needAtLeastOneOfKeys.includes(key);
  }

  shouldIncludeAirportCode(key) {
    const needAtLeastOneOfKeys = ['flightNumber', 'destination', 'firstName'];
    return needAtLeastOneOfKeys.includes(key);
  }

  appendAirportCodeToQueryParams(airportCode) {
    this.appendQueryParams(this.apiQParams.trips.origin.key, airportCode);
  }

  updateCorrelationId(tripId?: string, method: string = 'GET') {
    const timeStamp = dateFns.format(new Date(), 'yyyy-MM-ddTHH:mm:ss.SSSZ');
    const tripIdFallback = tripId ? tripId : '';
    const correlationId = `${method}-exp-checkin-trips-${tripIdFallback}-${timeStamp}`;
    this.headers = this.headers.set(HeaderKeys.XRootCorrelationId, correlationId);
  }

  updateDeviceIdHeader(deviceId: string) {
    this.headers = this.headers.append(HeaderKeys.XHaDeviceId, deviceId);
  }

  addDepartmentCode() {
    // Add the departmentCode based on environment
    // If the environment is dev, send IBK, if test or prod send the real airport code
    this.environmentService.getEnvironment().name.match(/^(test|prod)$/)
      ? this.appendQueryParams('departmentCode', airportCode.getValue())
      : this.appendQueryParams('departmentCode', 'IBK');
  }
}
