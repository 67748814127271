import { Flight } from '../flight/flight.model';
import { MILITARY_TRAVEL_TYPE } from '~app/models/military/military.model';

export interface AncillaryItem {
  productId?: string;
  couponNumber: string;
  receiptNumber: string;
}

export class SegmentFlight {
  id: string;
  boardingZone: string | null;
  flightId: string | Flight | null;
  isPassportRequired: boolean | false;
  tsaPreCheck: boolean | false;
  lounge: string | null;
  seatCategory: string | null;
  seatNumber: string | null;
  boardingPassData: string | null;
  ticketNumber: string | null;
  boardingPassPassengerData: string | null;
  lapInfantBoardingPassData: string | null;
  segmentId: string | null;
  militaryTravelType: string | null;
  ancillaryItems: AncillaryItem[] | null;

  static deserializeFromJson(json): SegmentFlight {
    const segmentFlight = Object.assign(new SegmentFlight(), json);

    if (!segmentFlight.militaryTravelType) {
      segmentFlight.militaryTravelType = MILITARY_TRAVEL_TYPE.NONE;
    }

    return segmentFlight;
  }

  addExtraComfortSeat() {
    this.ancillaryItems = [
      {
        receiptNumber: '1731503100283',
        couponNumber: '01',
        productId: '0BV',
      },
    ];
  }
}
