import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { CartService } from '~app/services/api/cart/cart.service';
import { TripService } from '../../../services/api/trip/trip.service';
import { Cart } from '../../cart/cart.model';
import {
  AppState,
  CartActionTypes,
  CartCreated,
  CreateCart,
  ItineraryViewActionTypes,
  RelatedCartRetrieved,
  SegmentPaxSelectedForCheckin,
  selectCart,
  selectCurrentTrip,
  SetTimeoutError,
  TripChanged,
  CatalogLoadRequested,
  CartUpdated,
  SetReferToAgentDontPrintError,
} from '../../index';
import {
  CheckinPax,
  SegmentActionTypes,
  SegmentMilitaryPaxTravelType,
  BagsContinueButtonClicked,
} from './segment.actions';
import { Logging } from '../../../services/logging/logging.service';
import { AppRoutes } from '~app/app-routes';

@Injectable()
export class SegmentEffects {
  public carts$ = new Subject();
  public checkIn$ = new Subject();

  constructor(
    private actions$: Actions,
    public router: Router,
    private store: Store<AppState>,
    public tripService: TripService,
    public cartService: CartService,
    private logging: Logging
  ) {}

  @Effect()
  selectPaxForCheckin$ = this.actions$.pipe(
    ofType(SegmentActionTypes.SegmentPaxSelectedForCheckin),
    map((action: SegmentPaxSelectedForCheckin) => action.payload.segmentDetail),
    map((segmentDetail) => {
      segmentDetail.selected = !segmentDetail.selected;
      this.logSelectPassengerEvent(segmentDetail.selected);
      return new TripChanged({});
    })
  );

  @Effect()
  selectMilitaryPaxTravelType$ = this.actions$.pipe(
    ofType(SegmentActionTypes.SegmentMilitaryPaxTravelType),
    map((action: SegmentMilitaryPaxTravelType) => action.payload),
    map((payload) => {
      payload.forEach((segmentFlightDetail) => {
        segmentFlightDetail.segmentFlight.militaryTravelType = segmentFlightDetail.selectedMilitaryTravelType;
      });

      // TODO: add new log event for log story
      return new TripChanged({});
    })
  );

  @Effect({ dispatch: false })
  checkinPax$ = this.actions$.pipe(
    ofType(SegmentActionTypes.CheckinPax),
    withLatestFrom(this.store.select(selectCurrentTrip)),
    map(([_, trip]) => trip),
    switchMap((trip) => from(this.tripService.checkIn(trip))),
    map((trip) => {
      this.checkIn$.next(trip);
      this.checkIn$.complete();

      this.checkIn$ = new Subject();
    })
  );

  @Effect({ dispatch: false })
  endRelatedCartSession$ = this.actions$.pipe(
    ofType(CartActionTypes.EndRelatedCartSession),
    withLatestFrom(this.store.select(selectCart), this.store.select(selectCurrentTrip)),
    switchMap(([_, cart, trip]) => {
      if (cart && cart.relatedCarts && cart.relatedCarts.length > 0 && cart.relatedCarts[0].id) {
        cart.id = cart.relatedCarts[0].id;
        return from(this.cartService.deleteCart(trip, cart));
      } else {
        this.store.dispatch(new SetTimeoutError());
      }
    }),
    map(() => {
      this.store.dispatch(new CreateCart());
    }),
    catchError(() => {
      this.store.dispatch(new SetTimeoutError());
      return of(false);
    })
  );

  @Effect({ dispatch: false })
  createCart$ = this.actions$.pipe(
    ofType(CartActionTypes.CreateCart),
    withLatestFrom(this.store.select(selectCurrentTrip)),
    map(([_, trip]) => trip),
    switchMap((trip) =>
      from(this.cartService.createCart(trip)).pipe(
        map((cartsResult) => {
          this.carts$.next(trip);
          this.carts$.complete();

          this.carts$ = new Subject();

          if (cartsResult.results && cartsResult.results.length > 0 && cartsResult.results[0].relatedCarts) {
            const cart = Cart.deserializeFromJson(cartsResult.results[0]);
            if (cart.checkConcurrentCart()) {
              this.store.dispatch(new CartCreated({ cart }));
            } else if (cart.checkConcurrentRelatedCart()) {
              this.store.dispatch(new RelatedCartRetrieved({ cart }));
            }
          } else if (cartsResult.results && cartsResult.results[0] && !cartsResult.results[0].relatedCarts) {
            const cart = Cart.deserializeFromJson(cartsResult.results[0]);
            this.store.dispatch(new CartCreated({ cart }));
          }
        })
      )
    )
  );

  @Effect({ dispatch: false })
  continueToSeatsAndBagsButtonClicked$ = this.actions$.pipe(
    ofType(ItineraryViewActionTypes.ContinueToSeatsAndBagsClicked),
    withLatestFrom(this.store.select(selectCart)),
    map(([_, cart]) => {
      this.store.dispatch(new CheckinPax());
      if (!cart.id) {
        this.store.dispatch(new CreateCart());
      }
    })
  );

  @Effect({ dispatch: false })
  checkinbuttonclicked$ = this.actions$.pipe(
    ofType(ItineraryViewActionTypes.CheckInButtonClicked),
    map(() => {
      this.store.dispatch(new CheckinPax());
      this.checkIn$.subscribe(() => {
        this.router.navigate([AppRoutes.HAZMAT_PROHIBITED]);
      });
    })
  );

  @Effect({ dispatch: false })
  bagsContinueButtonClicked$ = this.actions$.pipe(
    ofType<BagsContinueButtonClicked>(SegmentActionTypes.BagsContinueButtonClicked),
    withLatestFrom(this.store.select(selectCart)),
    switchMap(([data, cart]) => {
      const cartItems = cart.items || [];
      const { tripId } = data.payload;

      if (cartItems.length === 0) {
        this.router.navigate([AppRoutes.HAZMAT_PROHIBITED]);
        return of('');
      }

      return this.cartService.updateCart(cart, tripId);
    }),
    map((updatedCartResponse) => {
      if (updatedCartResponse && updatedCartResponse.results && updatedCartResponse.results.length > 0) {
        const cart = Cart.deserializeFromJson(updatedCartResponse.results[0]);
        this.store.dispatch(new CartUpdated({ cart }));
      } else if (updatedCartResponse instanceof SetTimeoutError) {
        throw new Error();
      }
    }),
    catchError(() => of(this.store.dispatch(new SetReferToAgentDontPrintError({ alertReasonCode: '' }))))
  );

  public getCheckinObservable(): Observable<any> {
    return this.checkIn$;
  }

  public getCartsObservable(): Observable<any> {
    return this.carts$;
  }

  logSelectPassengerEvent(selected: boolean) {
    if (selected) {
      this.logging.infoUiItineraryPagePassengerSelected(0);
    } else {
      this.logging.infoUiItineraryPagePassengerUnselected(0);
    }
  }
}
