import { AppState } from '../../index';
import { createSelector } from '@ngrx/store';
import { BarcodeState } from './barcode.reducer';

const selectBarcode = (state: AppState) => state.barcode;

export const selectBarcodeData = createSelector(selectBarcode, (state: BarcodeState) => state.barcodeData);

export const selectBarcodeStatus = createSelector(selectBarcode, (state: BarcodeState) => state.barcodeStatusChange);

export const selectBarcodeStatusIsOk = createSelector(selectBarcode, (state: BarcodeState) => state.barcodeStatusIsOK);

export const selectBarcodeMode = createSelector(selectBarcode, (state: BarcodeState) => state.deviceMode);
