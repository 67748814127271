import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPaxEdit from './pax-edit.reducer';
import { selectCurrentTrip } from '../trip/trip.selectors';

export const selectPaxEditState = createFeatureSelector<fromPaxEdit.PaxEditState>('paxEdit');

export const selectCurrentPassenger = createSelector(selectPaxEditState, selectCurrentTrip, (paxEditState) => {
  return paxEditState.paxSelectedForEdit;
});

export const selectPassengerEditInfo = createSelector(
  selectPaxEditState,
  selectCurrentPassenger,
  (paxEditState, currentPassenger) => {
    if (!currentPassenger) {
      return null;
    }

    return {
      passenger: currentPassenger,
      ktn: paxEditState.paxEditKTN || currentPassenger.knownTravelerNumber,
      redress: paxEditState.paxEditRedress || currentPassenger.redressNumber,
      hawaiianMiles: paxEditState.paxEditHawaiianMiles || currentPassenger.hawaiianMilesNumber,
    };
  }
);
