import { Action } from '@ngrx/store';

export enum KeyboardActionTypes {
  SetContent = '[Keyboard] Set Data',
  AppendContent = '[Keyboard] Update Data',
  ResetContent = '[Keyboard] Reset Data',
  GoBack = '[Keyboard] Go Back',
  ResetState = '[Keyboard] Reset State',
}

export class SetContent implements Action {
  readonly type = KeyboardActionTypes.SetContent;
  constructor(private entry: string, private backSpace = false) {}
}

export class AppendContent implements Action {
  readonly type = KeyboardActionTypes.AppendContent;
  constructor(private entry: string) {}
}

export class ResetContent implements Action {
  readonly type = KeyboardActionTypes.ResetContent;
}

export class GoBack implements Action {
  readonly type = KeyboardActionTypes.GoBack;
}

export class ResetState implements Action {
  readonly type = KeyboardActionTypes.ResetState;
}
