import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';

@Component({
  selector: 'page-hazmat',
  templateUrl: './hazmat.component.html',
  styleUrls: ['./hazmat.component.scss'],
})
export class HazmatComponent implements OnInit {
  public showAgricultureText = true;
  private element;
  private isFirstRender = true;

  constructor(
    private accessibilityService: AccessibilityService,
    private el: ElementRef,
    private changeRef: ChangeDetectorRef
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {}

  onRouterActive() {
    this.updateACAAElementsToRead();
  }

  private updateACAAElementsToRead() {
    this.changeRef.detectChanges();

    const elementsToRead = [
      ...this.getHeaderElementsToRead(),
      ...this.getDetailElementsToRead(),
      ...this.getContinueButtonElementToRead(),
      ...this.getStartOverElementToRead(),
    ];

    this.accessibilityService.setPageElementsToRead({
      elements: elementsToRead,
      // element will be read by loading modal dismiss on first render
      needsToReadFirstElement: !this.isFirstRender,
    });

    this.isFirstRender = false;
  }

  private getHeaderElementsToRead() {
    const headerElement = Array.from(this.element.querySelectorAll('#hazmat-title-text'));
    return [...headerElement];
  }

  private getDetailElementsToRead() {
    return Array.from(this.element.querySelectorAll('.hazmat-details [tabindex]'));
  }

  private getContinueButtonElementToRead() {
    return Array.from(this.element.querySelectorAll('.hazmat-continue-button'));
  }

  private getStartOverElementToRead() {
    return Array.from(this.element.querySelectorAll('#start-over-exit-button'));
  }
}
