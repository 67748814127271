import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';

@Component({
  selector: 'app-locate-reservation-title',
  templateUrl: './locate-reservation-title.component.html',
  styleUrls: ['./locate-reservation-title.component.scss'],
})
export class LocateReservationTitleComponent implements OnInit, AfterViewInit {
  public size = 'app-text--xlarge-size';
  @Input() public title: string;
  @Input() public paxFirstName: string;
  @Input() public paxLastName: string;
  @Input() tabIndex = '0';
  @Input() set small(value) {
    if (value) {
      this.size = 'app-text--small-size';
    }
  }
  @Input() set smaller(value) {
    if (value) {
      this.size = 'app-text--xsmall-size';
    }
  }

  constructor(private accessibilityService: AccessibilityService, private el: ElementRef) {}

  ngOnInit() {}

  ngAfterViewInit(): void {}
}
