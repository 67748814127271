import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { accessibilityJson, isCardInReaderTooLong } from '../../services/emitters/session-event-emitters';
import { Subscription } from 'rxjs';
import { AccessibilityService } from '../../services/accessibility/accessibility.service';
import { LoadingService } from '../../services/ui/loading.service';

@Component({
  selector: 'app-remove-card',
  templateUrl: './remove-card.component.html',
  styleUrls: ['./remove-card.component.scss'],
})
export class RemoveCardComponent implements OnInit, OnDestroy {
  public timeLimitSubscribe: Subscription;
  public altText = accessibilityJson.creditCardRemoval;
  public showPopup = false;
  public elements;

  constructor(
    private accessibilityService: AccessibilityService,
    public changeRef: ChangeDetectorRef,
    public el: ElementRef,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.timeLimitSubscribe = isCardInReaderTooLong.subscribe((value) => {
      this.showPopup = value;
    });
  }

  ngOnDestroy() {
    if (this.timeLimitSubscribe) {
      this.timeLimitSubscribe.unsubscribe();
    }
  }

  @ViewChild('removeCardModal', { static: false }) set updateAcaaElements(element) {
    // This modal will always show after a loading modal has already been activated.
    // We need to ensure that we overwrite the elements to read in this specific case
    if (
      this.showPopup &&
      (!this.accessibilityService.isModalActive.getValue() || this.loadingService.isLoading.getValue())
    ) {
      this.elements = element.nativeElement.querySelectorAll('[tabindex]');
      this.accessibilityService.activateModalElementsToRead(this.elements, 'remove-cart');
    }
    if (!this.showPopup && this.accessibilityService.isModalActive.getValue()) {
      this.accessibilityService.dismissModalElements(null, null, 'remove-card');
    }
  }
}
