import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { select, Store } from '@ngrx/store';
import { AppState, SegmentDetailAndPax, selectCart, selectCurrentTrip, Trip } from '~app/state';
import { AddBag, PaxSelectedForSpecialItems, RemoveBag, UpdateCartRequested } from '~app/state/cart/cart.actions';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from '~app/services/api/config/config.service';
import { Cart } from '~app/state/cart/cart.model';
import { takeUntil } from 'rxjs/operators';
import { Logging } from '~app/services/logging/logging.service';
import { GridComponent } from '~app/shared/grid/grid.component';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';

@Component({
  selector: 'app-bags-details',
  templateUrl: './bags-details.component.html',
  styleUrls: ['./bags-details.component.scss'],
})
export class BagsDetailsComponent implements OnInit, OnDestroy {
  @Output() totalOfBagsChangedEvent = new EventEmitter<string>();
  @Output() messageEvent = new EventEmitter<any>();

  private trip: Trip;
  private remainingFreeBags = false;
  private totalBags = 0;

  public cart$: Observable<Cart>;
  public trip$: Observable<Trip>;
  public config$: Observable<any>;
  public segmentDetailsAndPax: SegmentDetailAndPax[] = [];

  public totalRegularBagsPrice;
  public totalRegularBagsItems;

  public totalSpecialItemsPrice;
  public totalSpecialItemsItems;

  public additionalTotalItems;
  public grandTotalForBags;

  cart: Cart;
  enableSpecialItemsConfig: boolean;
  unsubscribe$ = new Subject<void>();

  private localStringConfig = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  @Output() gridRendered = new EventEmitter<any>();
  @Output() gridScrolled = new EventEmitter<any>();
  @ViewChild(GridComponent, { static: false }) gridElement: GridComponent;

  constructor(
    private store: Store<AppState>,
    private configService: ConfigService,
    public changeRef: ChangeDetectorRef,
    private el: ElementRef,
    private accessibilityService: AccessibilityService,
    private logging: Logging
  ) {}

  ngOnInit() {
    this.configService.config.pipe(takeUntil(this.unsubscribe$)).subscribe((config) => {
      if (config.configuration) {
        this.enableSpecialItemsConfig = config.configuration.enableSpecialItems;
      }
    });

    this.cart$ = this.store.pipe(select(selectCart));

    this.cart$.pipe(takeUntil(this.unsubscribe$)).subscribe((cart: Cart) => {
      this.cart = cart;
      this.updateValues();
    });

    this.trip$ = this.store.pipe(select(selectCurrentTrip));

    this.trip$.pipe(takeUntil(this.unsubscribe$)).subscribe((trip: Trip) => {
      this.trip = trip;
      this.segmentDetailsAndPax = this.trip.getSegmentDetailsAndPaxSelected();
      this.totalBagsChanged();
      this.updateValues();

      this.gridRendered.emit({});
    });

    this.totalBagsChanged();
  }

  onGridScrolled(): void {
    this.gridScrolled.emit({});
  }

  updateValues() {
    if (!this.trip || !this.trip.activeSegment) {
      return;
    }

    this.totalRegularBagsPrice = this.cart.getRegularBagsTotalPrice().toLocaleString('en', this.localStringConfig);
    this.totalRegularBagsItems = this.trip.activeSegment.getNumRegularBags();

    this.totalSpecialItemsPrice = this.cart.getSpecialItemsTotalPrice().toLocaleString('en', this.localStringConfig);
    this.totalSpecialItemsItems = this.trip.activeSegment.getNumSpecialItems();

    this.additionalTotalItems = this.trip.activeSegment.getNumBags();
    this.grandTotalForBags = this.cart.getGrandTotalPriceForBagsAndSpecialItems();

    this.totalBagsChanged();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  increaseRegularBags(segmentDetailAndPax) {
    if (!this.isMaximumValueOfRegularBags(segmentDetailAndPax)) {
      segmentDetailAndPax.segmentDetail.addRegularBag();

      this.addRegularBagToCartState(segmentDetailAndPax);
      this.totalBagsChanged();
    }
  }

  decreaseRegularBags(segmentDetailAndPax) {
    if (!this.isMinimumValueOfRegularBags(segmentDetailAndPax)) {
      segmentDetailAndPax.segmentDetail.removeRegularBag();

      this.removeRegularBagToCartState(segmentDetailAndPax);
      this.totalBagsChanged();
    }
  }

  isMaximumValueOfRegularBags(segmentDetailAndPax: SegmentDetailAndPax) {
    const item = segmentDetailAndPax.passenger.getRegularBagsCatalog();

    if (!item) {
      return;
    }

    if (item.maxQuantity == undefined || item.maxQuantity == null) {
      return false;
    }

    return segmentDetailAndPax.segmentDetail.getNumOfRegularBags() >= item.maxQuantity;
  }

  isMinimumValueOfRegularBags(segmentDetailAndPax: SegmentDetailAndPax) {
    const allBags = segmentDetailAndPax.segmentDetail.getNumOfRegularBags();
    const minimumPossible = segmentDetailAndPax.segmentDetail.getPaidBagsCount() || 0;

    return allBags <= minimumPossible;
  }

  getTotalBags(): number {
    this.totalBags = this.segmentDetailsAndPax
      .map((segmentDetailAndPax) => {
        return segmentDetailAndPax.segmentDetail.getNumBags();
      })
      .reduce((sum: number, current: number) => sum + current, 0);

    return this.totalBags;
  }

  totalBagsChanged() {
    this.totalOfBagsChangedEvent.emit(this.getTotalBags().toString());
  }

  addRegularBagToCartState(segmentDetailAndPax: SegmentDetailAndPax) {
    this.store.dispatch(
      new AddBag({
        productId: segmentDetailAndPax.passenger.getRegularBagsCatalog().productId,
        passengerId: segmentDetailAndPax.passenger.id,
        segmentId: segmentDetailAndPax.segmentDetail.segmentId,
      })
    );
  }

  removeRegularBagToCartState(segmentDetailAndPax: SegmentDetailAndPax) {
    this.store.dispatch(
      new RemoveBag({
        productId: segmentDetailAndPax.passenger.getRegularBagsCatalog().productId,
        passengerId: segmentDetailAndPax.passenger.id,
        segmentId: segmentDetailAndPax.segmentDetail.segmentId,
      })
    );
  }

  goToSpecialItems(paxId) {
    this.logging.infoUiSpecialItemsPageSelected(0);
    this.store.dispatch(new PaxSelectedForSpecialItems({ passengerId: paxId }));

    if (this.cart) {
      this.store.dispatch(new UpdateCartRequested());
    }
  }
}
