import { Component, Input, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { KeyboardState } from '../../../state/keyboard/keyboard.reducer';
import { Keyboard } from '../../../state/keyboard/keyboard.model';
import { AppendContent } from '../../../state/keyboard/keyboard.action';

@Component({
  selector: 'spacebar',
  templateUrl: './spacebar.component.html',
  styleUrls: ['./spacebar.component.scss'],
})
export class SpacebarComponent implements OnDestroy {
  public keyboardSubscribe: Subscription;
  keyboard$: Observable<Keyboard>;

  public utilsValue = false;
  public disabledValue = false;
  public entry = '';

  @Input() public limit;
  @Input() public set disabled(value) {
    this.disabledValue = value;
  }

  @Input() set utils(value) {
    this.utilsValue = value;
  }

  constructor(private store: Store<KeyboardState>) {
    this.keyboard$ = store.pipe(select('keyboard'));
    this.keyboardSubscribe = this.keyboard$.subscribe((value) => {
      if (value) {
        this.entry = value.content;
      }
    });
  }

  space() {
    if (this.disabledValue || (this.limit !== undefined && this.entry.length >= this.limit)) {
      return false;
    }
    this.store.dispatch(new AppendContent(' '));
  }

  ngOnDestroy() {
    if (this.keyboardSubscribe) {
      this.keyboardSubscribe.unsubscribe();
    }
  }
}
