import { Component } from '@angular/core';
import { AppRoutes } from '~app/app-routes';
import { Router } from '@angular/router';
import { Logging } from '~app/services/logging/logging.service';

@Component({
  selector: 'app-military-confirmation',
  templateUrl: './military-confirmation.component.html',
  styleUrls: ['./military-confirmation.component.scss'],
})
export class MilitaryConfirmationComponent {
  public backRoute = AppRoutes.BAGS;
  public codeDone = true;

  constructor(private router: Router, private logging: Logging) {}

  continueToSelection() {
    this.router.navigate([AppRoutes.MILITARY_SELECTION]);
  }

  goBackClicked() {
    this.logging.infoUiMilitaryNoticePageGoBackSelected(0);
  }
}
