import { AppState } from '../../index';
import { createSelector } from '@ngrx/store';
import { ATBPrinterState } from './atb-printer.reducers';

const selectAtbPrinter = (state: AppState) => state.atbPrinter;

export const selectAtbPrinterValue = createSelector(
  selectAtbPrinter,
  (state: ATBPrinterState) => state.ticketPrintingComplete
);

export const selectAtbPrinterStatus = createSelector(
  selectAtbPrinter,
  (state: ATBPrinterState) => state.atbPrinterStatusChange
);

export const selectAtbPrinterMode = createSelector(selectAtbPrinter, (state: ATBPrinterState) => state.deviceMode);
