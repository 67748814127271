import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, selectCurrentTrip } from '../../state';
import { fromEvent, Observable, Subject } from 'rxjs';
import { bufferTime, filter, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AgentAssistRoutes } from '../../agent-assist/agent-assist-routes';
import { ConfigService } from '~services/api/config/config.service';
import { SelectLanguage } from '~app/state/language/language.action';
import { AppRoutes } from '~app/app-routes';
import { isEmpty } from '../../../shared/embross/helper';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isOhanaFlight = false;
  private logoEl: Element;
  private tapTimeout: number;
  private agentAssistEnabled: boolean;
  private logoTaps: Observable<Event[]>;
  private tripId;
  unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private el: ElementRef,
    private router: Router,
    private configService: ConfigService
  ) {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.store.pipe(select(selectCurrentTrip), takeUntil(this.unsubscribe$)).subscribe((trip) => {
      this.isOhanaFlight = trip.flights && trip.flights.length > 0 && trip.flights[0].isOaEmpire();
      this.tripId = trip.id;
    });

    // Time after 1st tap to complete 6 taps to get to agent assist
    this.tapTimeout = 3500;

    // Get reference to the logo in the header
    this.logoEl = this.el.nativeElement.querySelector('#main-logo');

    // Set Config subscription for Agent Assist
    this.configService.config.pipe(takeUntil(this.unsubscribe$)).subscribe((config) => {
      if (config.configuration) {
        this.agentAssistEnabled = config.agentAssist;
      }
    });

    // Hook up observable to element
    // After a specific time has passed, emit if we have 6 taps
    this.logoTaps = fromEvent(this.logoEl, 'click').pipe(
      bufferTime(this.tapTimeout),
      filter((tapsArray) => {
        return tapsArray.length >= 6 && this.canNavigate();
      })
    );

    // Logic to handle interaction with the logo for entering Agent Assist Mode
    this.watchTaps();
  }

  private canNavigate() {
    return (
      !this.router.url.includes(AgentAssistRoutes.MAIN) &&
      this.agentAssistEnabled &&
      !this.router.url.includes(AppRoutes.PRINT_DOCUMENTS) &&
      !isEmpty(this.tripId)
    );
  }

  private watchTaps() {
    // Subscribe to event and dispatch to Agent Assist if criteria match
    this.logoTaps.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.router.navigateByUrl(AgentAssistRoutes.MAIN).then(() => this.store.dispatch(new SelectLanguage('en')));
    });
  }
}
