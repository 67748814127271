import { Injectable } from '@angular/core';

import { Logger } from './logger.service';
import { LogEvent } from './log-event.interface';
import { LogLevels } from './log-levels';

@Injectable()
export class ConsoleLogger extends Logger {
  constructor() {
    super();
  }

  log(event: LogEvent) {
    let color = 'black';

    switch (event.level) {
      case LogLevels.DEBUG:
        color = 'green';
        break;

      case LogLevels.INFO:
        color = 'blue';
        break;

      case LogLevels.ERROR:
        color = 'red';
        break;
    }

    console.log(`%c${event.level} ${event.message}:`, `color: ${color}; font-size:14px;`);
    console.log(event);
  }
}
