import { Action } from '@ngrx/store';

export enum CardReaderActionTypes {
  CardReaderEnable = '[Card Reader] Device Enabled',
  CardReaderDisable = '[Card Reader] Device Disabled',
  CardReaderInserted = '[Card Reader] Card Inserted',
  CardReaderRemoved = '[Card Reader] Card Removed',
  CardReaderReadInternal = '[Card Reader] Card Read',
  CardReaderDamaged = '[Card Reader] Damaged',
  CardReaderStatusChange = '[Card Reader] Status Change',
  CardReaderStatusIsOK = '[Card Reader] Status Is Ok',
  CardReaderResetCount = '[Card Reader] Reset Count',
  CardReaderEnablePayment = '[Card Reader] Enable Payment',
  CardReaderDisablePayment = '[Card Reader] Disable Payment',
}

export type CardReaderActions =
  | CardReaderEnable
  | CardReaderDisable
  | CardReaderInserted
  | CardReaderRemoved
  | CardReaderReadInternal
  | CardReaderDamaged
  | CardReaderStatusChange
  | CardReaderStatusIsOK
  | CardReaderEnablePayment
  | CardReaderDisablePayment;

export class CardReaderEnable implements Action {
  readonly type = CardReaderActionTypes.CardReaderEnable;

  constructor(readonly payload: string) {}
}

export class CardReaderDisable implements Action {
  readonly type = CardReaderActionTypes.CardReaderDisable;

  constructor(readonly payload: string) {}
}

export class CardReaderInserted implements Action {
  readonly type = CardReaderActionTypes.CardReaderInserted;
  payload: { event_id: string; cardReaderInserted: string };

  constructor(eventId: string, cardReaderInserted: string) {
    this.payload = { event_id: eventId, cardReaderInserted };
  }
}

export class CardReaderRemoved implements Action {
  readonly type = CardReaderActionTypes.CardReaderRemoved;
  payload: { event_id: string; cardReaderRemoved: string };

  constructor(eventId: string, cardReaderRemoved: string) {
    this.payload = { event_id: eventId, cardReaderRemoved };
  }
}

export class CardReaderReadInternal implements Action {
  readonly type = CardReaderActionTypes.CardReaderReadInternal;
  payload: { event_id: string; cardReaderReadInternal: string };

  constructor(eventId: string, cardReaderReadInternal: string) {
    this.payload = { event_id: eventId, cardReaderReadInternal };
  }
}

export class CardReaderDamaged implements Action {
  readonly type = CardReaderActionTypes.CardReaderDamaged;
  payload: { event_id: string; cardReaderDamaged: string };

  constructor(eventId: string, cardReaderDamaged: string) {
    this.payload = { event_id: eventId, cardReaderDamaged };
  }
}

export class CardReaderStatusChange implements Action {
  readonly type = CardReaderActionTypes.CardReaderStatusChange;
  payload: { event_id: string; cardReaderStatusChange: [number, boolean] };

  constructor(eventId: string, cardReaderStatusChange: [number, boolean]) {
    this.payload = { event_id: eventId, cardReaderStatusChange };
  }
}

export class CardReaderStatusIsOK implements Action {
  readonly type = CardReaderActionTypes.CardReaderStatusIsOK;
  payload: { event_id: string; cardReaderStatusIsOK: string };

  constructor(eventId: string, cardReaderStatusIsOK: string) {
    this.payload = { event_id: eventId, cardReaderStatusIsOK };
  }
}

export class CardReaderResetCount implements Action {
  readonly type = CardReaderActionTypes.CardReaderResetCount;
}

export class CardReaderEnablePayment implements Action {
  readonly type = CardReaderActionTypes.CardReaderEnablePayment;
  payload: {};
  constructor() {}
}

export class CardReaderDisablePayment implements Action {
  readonly type = CardReaderActionTypes.CardReaderDisablePayment;
  payload: {};
  constructor() {}
}
