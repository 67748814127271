import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState, selectCurrentTrip, Trip } from '~app/state';
import { first } from 'rxjs/operators';
import { AppRoutes } from '~app/app-routes';

@Component({
  selector: 'app-seat-preference',
  templateUrl: './seat-preference.component.html',
  styleUrls: ['./seat-preference.component.scss'],
})
export class SeatPreferenceComponent implements OnInit {
  public trip$: Observable<Trip>;
  public isMultiPax = false;

  constructor(private router: Router, private store: Store<AppState>) {}

  ngOnInit() {
    this.store.pipe(select(selectCurrentTrip), first()).subscribe((trip) => {
      this.isMultiPax = trip.passengers.length > 1;
    });
  }

  goToItinerary() {
    this.router.navigate([AppRoutes.ITINERARY]);
  }
}
