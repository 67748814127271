import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState, PaxEditConfirmed, selectPassengerEditInfo } from '../../../../state';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Logging } from '~app/services/logging/logging.service';
import { AccessibilityService } from '~app/services/accessibility/accessibility.service';
import { AppRoutes } from '~app/app-routes';
import { SPEECH_STATUS } from '~app/services/ha-cuss/device.service';

@Component({
  selector: 'app-ktn-redress-inputs',
  templateUrl: './pax-edit-main.component.html',
  styleUrls: ['./pax-edit-main.component.scss'],
})
export class PaxEditMainComponent implements OnInit, AfterViewInit, OnDestroy {
  paxEditInfo$: Observable<any>;
  itineraryRoute = AppRoutes.ITINERARY;

  public showModal = false;
  language: string;
  public hmPresent = false;
  public ktnPresent = false;
  public redressPresent = false;
  private unsubscribe$ = new Subject<void>();
  private unsubscribeSpeechStatus$ = new Subject<void>();
  @ViewChild('ktnModal', { static: false }) ktnModalElement: ElementRef;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private logging: Logging,
    private changeRef: ChangeDetectorRef,
    private accessibilityService: AccessibilityService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.paxEditInfo$ = this.store.pipe(select(selectPassengerEditInfo));

    this.paxEditInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe((info) => {
      this.hmPresent = info.ktn;
      this.ktnPresent = info.ktn;
      this.redressPresent = info.redress;

      this.logging.infoUiHMKTNRedressPageDisplayed(this.hmPresent, this.ktnPresent, this.redressPresent, 0);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    const elements = this.getElements();
    this.accessibilityService.setElementsToRead(elements);
  }

  getElements() {
    return this.el.nativeElement.querySelectorAll('[tabindex]');
  }

  updatePassengerInfo() {
    this.logging.infoUiHMKTNRedressPageContinueSelected(0);
    this.store.dispatch(new PaxEditConfirmed({}));
  }

  redressInputClicked() {
    this.router.navigate([AppRoutes.PAX_EDIT_REDRESS]);
    this.logging.infoUiKTNRedressPageRedressSelected();
  }

  ktnInputClicked() {
    this.router.navigate([AppRoutes.PAX_EDIT_KTN]);
    this.logging.infoUiKTNRedressPageKTNSelected();
  }

  hmInputClicked() {
    this.router.navigate([AppRoutes.PAX_EDIT_HAWAIIAN_MILES]);
    this.logging.infoUiKTNRedressPageHawaiianMilesSelected();
  }

  toggleModal() {
    if (this.showModal) {
      this.showModal = false;
      this.changeRef.detectChanges();

      this.accessibilityService.dismissModalElements(null, null, 'pax-edit');
      return;
    }

    this.logging.infoUiHMKTNRedressInfoPageSelected(0);
    this.showModal = true;
    this.changeRef.detectChanges();

    this.logging.infoUiHMKTNRedressInfoPageDisplayed(0);
    const elements = this.ktnModalElement.nativeElement.querySelectorAll('[tabindex]');

    this.accessibilityService.speechStatus$
      .pipe(takeUntil(this.unsubscribeSpeechStatus$))
      .subscribe((status: SPEECH_STATUS) => {
        if (status === SPEECH_STATUS.COMPLETED || status === SPEECH_STATUS.NONE) {
          this.accessibilityService.activateModalElementsToRead(elements, 'pax-edit');
          this.unsubscribeSpeechStatus$.next();
        }
      });
  }
}
